import React from 'react';
import {connect} from 'react-redux';
import ProductCreation from './components/ProductCreation/Creation';
import Login from './components/Login/Login';
import Additional from './components/Additional/Additional';
import Company from './components/Company/Company';
import ProductInformation from './components/ProductInformation/Product';
import Dimension from './components/Dimension/Dimension';
import FileUpload from './components/FileUpload/File';
import ItemDetail from './components/ItemDetail/Item';
import ThankYou from './components/Thankyou/Thankyou';
import PageNotFound from './components/Shared/PageNotFound/PageNotFound';
import ApiErrorPage from './components/Shared/ApiErrorPage';
import Search from './components/Search/Search';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {Helmet} from 'react-helmet';

function App({loggedIn}) {
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage'))?.data
    ?.token;
  return (
    <>
      {/* <Helmet>
        <html lang="en" />
        <title>React Helmet Tutorial</title>
        <meta name="description" content="Tutorial for React Helmet" />
        <meta name="theme-color" content="#E6E6FA" />
        <meta
          httpEquiv="Content-Security-Policy"
          content="default-src 'self' *.bootstrapcdn.com *.cloudinary.com"
        />
        <meta httpEquiv="X-Frame-Options" content="DENY" />
      </Helmet> */}
      <Router>
        <Switch>
          <Route exact path="/" component={Login}></Route>
          <Route exact path="/login" component={Login}></Route>
          {loggedIn?.status === 'success' || token ? (
            <Route path="/search*" component={Search}></Route>
          ) : (
            <Redirect to="/login" />
          )}
          {loggedIn?.status === 'success' || token ? (
            <Route path="/creation" component={ProductCreation}></Route>
          ) : (
            <Redirect to="/login" />
          )}
          {loggedIn?.status === 'success' || token ? (
            <Route
              path="/2-Product Information"
              component={ProductInformation}
            ></Route>
          ) : (
            <Redirect to="/login" />
          )}
          {loggedIn?.status === 'success' || token ? (
            <Route path="/3-Company Information" component={Company}></Route>
          ) : (
            <Redirect to="/login" />
          )}
          {loggedIn?.status === 'success' || token ? (
            <Route path="/4-Dimensions" component={Dimension}></Route>
          ) : (
            <Redirect to="/login" />
          )}
          {loggedIn?.status === 'success' || token ? (
            <Route path="/5-Item Details" component={ItemDetail}></Route>
          ) : (
            <Redirect to="/login" />
          )}
          {loggedIn?.status === 'success' || token ? (
            <Route path="/6-Additional Details" component={Additional}></Route>
          ) : (
            <Redirect to="/login" />
          )}
          {loggedIn?.status === 'success' || token ? (
            <Route path="/fileupload" component={FileUpload}></Route>
          ) : (
            <Redirect to="/login" />
          )}
          {loggedIn?.status === 'success' || token ? (
            <Route path="/thankyou" component={ThankYou}></Route>
          ) : (
            <Redirect to="/login" />
          )}
          <Route path="/ApiErrorPage" component={ApiErrorPage} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </>
  );
}
export const mapStateToProps = (state) => {
  return {
    loggedIn: state.loginReducer.valotpMsg,
  };
};
export default connect(mapStateToProps, null)(App);
