import React from 'react';
import './Creation.css';
import Localization from '../../common/Localization';
import Layout from '../Shared/Layout/Layout';
import WelcomeHeader from '../Shared/WelcomeHeader/WelcomeHeader';
import FormHeader from '../Shared/FormHeader/FormHeader';
import {Link} from 'react-router-dom';
const CreationReturnData = ({props, selectgroup, selectcategory, selectsubcategory, selectproductType}) => {
    return (
        <>
     <Layout />
      <WelcomeHeader />
      <FormHeader
        group={selectgroup}
        category={selectcategory}
        subCategory={selectsubcategory}
        productType={selectproductType}
      />

      <div className="header-fix4" data-testid="header">
        <Link to={props?.fetchApiData?.sections ? '/creation' : '/creation'}>
          <div className="group-information2" data-testid="test1">{Localization.Groupinfo}</div>
        </Link>
          <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '2-Product Information')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '2-Product Information')].section : '/creation') : '/creation'}>
          <div className="product-information-creation" data-testid="test2">
            {Localization.Productinfo}
          </div>
        </Link>
          <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '3-Company Information')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '3-Company Information')].section : '/creation') : '/creation'}>
          <div className="company-creation" data-testid="test3">{Localization.Companyinfo}</div>
        </Link>
          <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '4-Dimensions')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '4-Dimensions')].section : '/creation') : '/creation'}>
          <div className="dimensions" data-testid="test4">{Localization.Dimensions}</div>
        </Link>
          <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '5-Item Details')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '5-Item Details')].section : '/creation') : '/creation'}>
          <div className="item" data-testid="test5">{Localization.Itemdetails}</div>
        </Link>
          <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')].section : '/creation') : '/creation'}>
          <div className="additional-creation" data-testid="test6">{Localization.Additional}</div>
        </Link>
        <Link to={props?.fetchApiData?.sections && props?.fetchApiData?.sections[props?.fetchApiData?.sections?.findIndex((p) => p?.section === '6-Additional Details')]?.section ? '/fileupload' : '/creation'}>
          <div className="file" data-testid="test7">{Localization.Fileupload}</div>
        </Link>
        <div className="line-creation" data-testid="test8"></div>
        <div className="oval-creation" data-testid="test9"></div>
        <div className="oval-creation1" data-testid="test10"></div>
        <div className="oval-creation2" data-testid="test11"></div>
        <div className="oval-creation3" data-testid="test12"></div>
        <div className="oval-creation4" data-testid="test13"></div>
        <div className="oval-creation5" data-testid="test14"></div>
        <div className="oval-creation6" data-testid="test15"></div>{' '}
      </div>
</>
);
};
export default CreationReturnData;
