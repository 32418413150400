import {takeLatest, call, put} from 'redux-saga/effects';
import {fetch, update, error} from '../slices/filterSlice';
import {fetchDependentFilter} from '../../services/filterService';

function* filterWorker(action) {
  try {
    const response = yield call(fetchDependentFilter, action.payload);
    if (response.status >= 200 && response.status < 300) {
      const payload = yield response.data;
      yield put(update(payload));
    }
  } catch (err) {
    yield put(error(err));
  }
}

export function* filterWatcher() {
  yield takeLatest('filters/fetch', filterWorker);
}

export const filterSaga = [filterWatcher()];
