export const companyAdd = ({item, index, prod, getProdVal}) => {
    let j = 0;
    let k = 0;
    if (item.fieldType === 'Select') {
      prod.map(
          (checkItem, checkIndex) => {
            const checkelement = checkItem;
            checkelement.attributes = checkItem.attributes.map(
                (checkSubItem, checkSubIndex) => {
                  if (item.attributeId === checkSubItem.attributeId) {
                    k++;
                  }
                  return checkSubItem;
                },
            );
            return checkelement;
          },
      );
      if (k > (item.attrNameLov.length - 1)) {
        return;
      }
    } else {
      prod.map(
          (checkItem, checkIndex) => {
            const checkelement = checkItem;
            checkelement.attributes = checkItem.attributes.map(
                (checkSubItem, checkSubIndex) => {
                  if (item.attributeId === checkSubItem.attributeId) {
                    j++;
                  }
                  return checkSubItem;
                },
            );
            return checkelement;
          },
      );
      if (j > 10) {
        return;
      }
    }
const classAttributeRes = prod.map((subItem, subIndex) => {
      const subelement = subItem;
      if (subelement.attributes[index]) {
        if (subelement.attributes[index].attributeId === item.attributeId) {
          const data = {...subelement.attributes[index]};
          data.multiValued = 'N';
          data.attributeNameValue = '';
          let indxPos;
          let sectionValue;
          if (data.sequence) {
            subelement.attributes.map((elm, ind) => {
              if (elm.attributeId === item.attributeId) {
                sectionValue = '' + (Number(elm.sequence) + 1);
              }
              return null;
            });
            indxPos = index + Number(sectionValue);
          } else {
            subelement.attributes[index].sequence = '0';
            sectionValue = '1';
            indxPos = index + 1;
          }
          data.sequence = sectionValue;
          data.attributeName = data.attributeName + '_' + (sectionValue);

          subelement.attributes.splice(indxPos, 0, data);
        }
      }
      return subelement;
    });
    getProdVal(classAttributeRes);
    return null;
  };
export const companyDelete =({index, selectedItem, prod, getProdDelVal}) => {
    let deleteIndex = 0;
    const classAttributeRes = prod.map((subItem, subIndex) => {
      const subelement = subItem;
      if (subelement.attributes) {
        subelement.attributes.map((dItem, dIndex) => {
          if (dIndex > index && dItem.attributeId === selectedItem.attributeId) {
            subelement.attributes[dIndex - 1].attributeNameValue = subelement.attributes[dIndex].attributeNameValue;
            deleteIndex = dIndex;
          }
          return dItem;
        });
        if (deleteIndex > 0) {
          subelement.attributes.splice(deleteIndex, 1);
        } else {
          subelement.attributes.splice(index, 1);
        }
      }
      return subelement;
    });
    getProdDelVal(classAttributeRes);
    return null;
  };
  export default (companyAdd, companyDelete);
