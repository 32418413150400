import {HttpService} from './HttpService';
import {PACK, GET_COMPONENT} from '../config/apiEndpoints';
import {getAuthToken} from '../components/utils/AuthenticationUtils';
import axios from 'axios';
export async function packAttributeApi(query) {
  try {
    const endpoint = PACK;
    const headers = {
      Authorization: `Bearer ${query}`,
    };
    return (await HttpService.get(endpoint, {headers: headers}));
  } catch (err) {
    return {err};
  }
}

export async function componentDetailsApi({code, l2CategoryCode, brandName}) {
  try {
    const endpoint = GET_COMPONENT+`/${code}?l2CategoryCode=${l2CategoryCode}&brandName=${brandName}`;
    const headers = {
      Authorization: `Bearer ${getAuthToken()}`,
      // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJnWXlIa3l3ZzV5ekRid1p0dnErZ2RIUFlVVWt5YjYrSWVEVU5iRFh5bU8zUCIsImV4cCI6MTcwMTQ1NDc0MSwiaWF0IjoxNzAxNDE4NzQxfQ.5ipSHTAaVVWJXmfyUXpJmcREu_HB5ZoFZa186m_4UVFZwmDm_Qz_qM77NQQv_yugb8iJMif3gI0sRoRC8oSgkw`,
    };
    return (await HttpService.get(endpoint, {headers: headers}));
    // return (await axios.get(`http://localhost:8080/vendorportal/${endpoint}`, {headers: headers}));
  } catch (err) {
    return {err};
  }
}
