import Axios from 'axios';
import config from '../config/config';
import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {getAuthToken} from '../components/utils/AuthenticationUtils';

Axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
Axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

console.log(document.cookie);
export const HttpService = Axios.create({
   'baseURL': config.apiGateway.apiUrl,
  // 'baseURL': 'http://localhost:8080/vendorportal',
  'timeout': 100000,
  'withCredentials': true,
  'xsrfCookieName': 'XSRF-TOKEN',
  'xsrfHeaderName': 'X-XSRF-TOKEN',
   'headers': {
  //  // 'X-XSRF-TOKEN': '250aaa09-f4e2-4671-853d-eabad3e72fc5',
  'Content-Type': 'application/json',
  'X-XSRF-TOKEN': document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    '$1',
  ),
   },
});

HttpService.interceptors.request.use(function(config) {
  console.log(config);
  console.log(document.cookie);
  config.withCredentials = true;
  config.headers['X-XSRF-TOKEN'] = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    '$1',
  );

  return config;
});


const httpLink = createHttpLink({
   uri: `${config.apiGateway.apiUrl}/graphql/`,
  // uri: `http://localhost:8080/vendorportal/graphql/`,
});

const authLink = setContext((_, {headers}) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${getAuthToken()}`,
      // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJnWXlIa3l3ZzV5ekRid1p0dnErZ2RIUFlVVWt5YjYrSWVEVU5iRFh5bU8zUCIsImV4cCI6MTcwMTQ1NDc0MSwiaWF0IjoxNzAxNDE4NzQxfQ.5ipSHTAaVVWJXmfyUXpJmcREu_HB5ZoFZa186m_4UVFZwmDm_Qz_qM77NQQv_yugb8iJMif3gI0sRoRC8oSgkw`,
    },
  };
});


export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
