export const classAttributeAdd = ({item, index, subGroupIdentifierName, prod, getProdVal}) => {
    let j = 0;
    let k = 0;
    if (item.fieldType === 'Select') {
      prod.map(
          (checkItem, checkIndex) => {
            const checkelement = checkItem;
            checkelement.attributes = checkItem.attributes.map(
                (checkSubItem, checkSubIndex) => {
                  if (item.attributeId === checkSubItem.attributeId) {
                    k++;
                  }
                  return checkSubItem;
                },
            );
            return checkelement;
          },
      );

      if (k > (item.attrNameLov.length - 1)) {
        return;
      }
    } else {
      prod.map(
          (checkItem, checkIndex) => {
            const checkelement = checkItem;
            checkelement.attributes = checkItem.attributes.map(
                (checkSubItem, checkSubIndex) => {
                  if (item.attributeId === checkSubItem.attributeId) {
                    j++;
                  }
                  return checkSubItem;
                },
            );
            return checkelement;
          },
      );

      if (j > 10) {
        return;
      }
    }
    const classAttributeRes = prod.map((subItem, subIndex) => {
      const subelement = subItem;
      if (subelement.attributes[index]) {
        if (subGroupIdentifierName === 'In The Box' && item.attributeId !=='SG-InTheBox-MainProduct') {
          if (subelement.attributes[index].attributeId === item.attributeId) {
            const data1 = {...subelement.attributes[index - 1]};
            data1.multiValued = 'N';
            data1.attributeNameValue = '';
            const data2 = {...subelement.attributes[index]};
            data2.multiValued = 'N';
            data2.attributeNameValue = '';
            let indxPos;
            let sectionValue;
            if (data1.sequence && data2.sequence) {
              subelement.attributes.map((elm, ind) => {
                if (elm.attributeId === item.attributeId) {
                  sectionValue = '' + (Number(elm.sequence) + 1);
                }
                return null;
              });
              indxPos = index - 1 + 2 * Number(sectionValue);
            } else {
              subelement.attributes[index - 1].sequence = '0';
              subelement.attributes[index].sequence = '0';
              sectionValue = '1';
              indxPos = index + 1;
            }
            data1.sequence = sectionValue;
            data2.sequence = sectionValue;
            data1.attributeName = data1.attributeName + '_' + (sectionValue);
            data2.attributeName = data2.attributeName + '_' + (sectionValue);
            subelement.attributes.splice(indxPos, 0, data1, data2);
          }
        } else if (
          subelement.attributes[index].attributeId === item.attributeId
        ) {
          const data = {...subelement.attributes[index]};
          data.multiValued = 'N';
          data.mandatory = 'Y';
          data.attributeNameValue = '';
          let indxPos;
          let sectionValue;
          if (data.sequence) {
            subelement.attributes.map((elm, ind) => {
              if (elm.attributeId === item.attributeId) {
                sectionValue = '' + (Number(elm.sequence) + 1);
              }
              return null;
            });
            indxPos = index + Number(sectionValue);
          } else {
            subelement.attributes[index].sequence = '0';
            sectionValue = '1';
            indxPos = index + 1;
          }
          data.sequence = sectionValue;
          data.attributeName = data.attributeName + '_' + sectionValue;
          subelement.attributes.splice(indxPos, 0, data);
        }
      }
      return subelement;
    });
    getProdVal(classAttributeRes);
  };
  export const classAttributeDelete = ({item, index, subGroupIdentifierName, selectedItem, prod, getProdDelVal}) => {
    const classAttributeRes = prod.map((subItem, subIndex) => {
      const subelement = subItem;
      if (subelement.attributes[index]) {
        if (item.sequence && subelement.attributes[index].attributeId === item.attributeId &&
                    subelement.attributes[index].sequence === item.sequence && item.fieldType === 'Select') {
          subelement.attributes[index].attrNameLov.map((lovItem, lovIndex) => {
            if (item.attributeNameValue === lovItem.lovName) {
              lovItem.isDisabled = false;
            }

            return lovItem;
          });
        }
        if (subGroupIdentifierName === 'In The Box' && item.attributeId !=='SG-InTheBox-MainProduct') {
          if (subelement.attributes[index].attributeId === item.attributeId) {
            subelement.attributes.splice(index, 1);
            subelement.attributes.splice(index - 1, 1);
          }
        } else if (
          subelement.attributes[index].attributeId === item.attributeId &&
                    subelement.attributes[index].sequence === item.sequence
        ) {
          subelement.attributes.splice(index, 1);
        }
      }
      return subelement;
    });
    getProdDelVal(classAttributeRes);
  };
export default (classAttributeAdd, classAttributeDelete);
