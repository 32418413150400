import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function dimensionReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.CORE_ATTRIBUTE_DIMENSION:
      return {...state, loading: true};
    case actionTypes.CORE_ATTRIBUTE_DIMENSION_COMPLETE:
      return {...state, loading: false, primaryData: action.payload, errorApiCheck: false};
    case actionTypes.CORE_ATTRIBUTE_DIMENSION_ERROR:
      return {...state, loading: false, errorMessage: action.payload, errorApiCheck: true};
      case actionTypes.API_ERROR:
        return {...state, loading: false, apiErrorMessage: action.payload, errorApiCheck: true};
    default:
      return state;
  }
}
