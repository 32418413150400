import * as actionTypes from './actionsTypes';

export const loadFetchData =({userId, token, isPreviewPage})=>{
  return {
    type: actionTypes.FETCH,
    payload: {userId: userId, token: token, isPreviewPage: isPreviewPage},
  };
};

export const loadArticleDetails = ({articleCode}) => {
  return {
    type: actionTypes.FETCH_ARTICLE_DETAILS,
    payload: {articleCode},
  };
};

export const loadSearchFilters = () => {
 return {
  type: actionTypes.FETCH_FILTERS,
 };
};
export const emptySavedResponse=()=>{
  return {
    type: actionTypes.EMPTYSAVE,

  };
};
