import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function filterReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_FILTERS:
      return {...state, loading: true, filters: null, error: null};
    case actionTypes.FETCH_FILTERS_COMPLETE:
      return {...state, loading: false, filters: action.payload, error: null};
    case actionTypes.FETCH_FILTERS_ERROR:
      return {...state, loading: false, error: action.payload, filters: null};
    default:
      return state;
  }
}
