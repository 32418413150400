/* eslint-disable no-invalid-this */
/* eslint-disable guard-for-in */
import React, {useEffect, useState} from 'react';
import Localization from '../../../common/Localization';
import {connect} from 'react-redux';
import {loadFetchData} from '../../../store/actions/fetchAction';
import Modal from 'react-modal';
import {logoutAction} from '../../../store/actions/loginAction';
import {Link} from 'react-router-dom';
import classes from './SearchHeader.module.css';
import {AUTO_LOGOUT_IN_MILLISECONDS, AUTO_LOGOUT_WARNING_IN_MILLISECONDS} from '../../utils/Constants';

function FormHeader(props) {
  const [, setFetchData] = useState();
  const [callapi, setCallapi] = useState(true);
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
    .token;
  const userId = localStorage.getItem('userId');
  const [isPreviewPage, setisPreviewPage] = useState(false);

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '45%',
      left: '47%',
      right: 'auto',
      bottom: 'auto',
      height: 285,
      width: 461,
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden',
    },
  };
  const [logoutWarning, setLogoutWarning] = useState(false);
  const [evt, setEvt] = React.useState(true);

  let warnTimeout = 0;
  let logoutTimeout = 0;
  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];
  const resetTimeout = () => {
    clearTimeoutVP();
    setTimeoutVP();
  };
  useEffect(() => {
    if (evt) {
      for (const i in events) {
        window.addEventListener(events[i], resetTimeout);
      }
      setEvt(false);
    }
  }, [events, evt, resetTimeout]);
  const logout = () => {
    setLogoutWarning(false);
    props.logoutService();
  };

  const setTimeoutVP = () => {
    const urlPage = window.location.href;
    if (!urlPage.includes('login')) {
      logoutTimeout = setTimeout(logout, AUTO_LOGOUT_IN_MILLISECONDS);
      warnTimeout = setTimeout(warn, AUTO_LOGOUT_WARNING_IN_MILLISECONDS);
    }
  };
  const clearTimeoutVP = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  const warn = () => {
    setLogoutWarning(true);
    for (const i in events) {
      //eslint-disable-line
      window.removeEventListener(events[i], resetTimeout);
    }
  };
  const proceed = () => {
    setLogoutWarning(false);

    for (const i in events) {
      //eslint-disable-line
      window.addEventListener(events[i], resetTimeout);
    }
    resetTimeout();
  };
  useEffect(() => {
    if (token && callapi) {
      props.fetchService({userId, token, isPreviewPage});
      setCallapi(false);
    }
  }, [callapi]);

  useEffect(() => {
    open();
    if (props.fetchApiData) {
      setFetchData(props.fetchApiData);
    }
  }, [props.fetchApiData]);

  function open() {
    if (
      document &&
      document.querySelector('#root > div > form > div.header-fix4')
    ) {
      if (
        document.querySelector('#root > div > form > div.header-fix4') !== null
      ) {
        if (
          document.querySelector('#root > div > form > div.header-fix4').style
            .display === 'block'
        ) {
          document.querySelector(
            '#root > div > form > div.header-fix4',
          ).style.display = 'none';
        } else {
          document.querySelector(
            '#root > div > form > div.header-fix4',
          ).style.display = 'block';
        }
      } else {
        if (
          document.querySelector('#root > div > div.header-fix4').style
            .display === 'block'
        ) {
          document.querySelector(
            '#root > div > div.header-fix4',
          ).style.display = 'none';
        } else {
          document.querySelector(
            '#root > div > div.header-fix4',
          ).style.display = 'block';
        }
      }
    }
  }
  const store = JSON.parse(localStorage.getItem('formHeader'));
  return (
    <div className={classes['gray-rectangle-item']}>
      <div className={classes['form-upload-item']} data-testid="testForm">
        <Link
          to={
            props?.fetchApiData?.sections?.length > 0 ?
              `/${
                  props.fetchApiData.sections[
                    props.fetchApiData.sections.length - 1
                  ].section
                }` :
              '/creation'
          }
          style={{textDecoration: 'none'}}
        >
          {Localization.Form}
        </Link>
      </div>
      {/* <div className="green-rectangle-item" /> */}

      <div
        className={classes['form-upload-item']}
        data-testid="testForm2"
        style={{left: '17%'}}
      >
        {Localization.Search}
      </div>
      <div
        className={classes['green-rectangle-item']}
        style={{left: '150px', width: '186px'}}
      />
      <img
        className={classes['menu']}
        alt="Menu"
        src={process.env.PUBLIC_URL + '/assets/open-menu.png'}
        onClick={open}
      />
      {logoutWarning && (
        <Modal
          isOpen={logoutWarning}
          contentLabel="Example Modal"
          style={customStyles}
          ariaHideApp={false}
        >
          <div className={classes['image']}>
            <img src={process.env.PUBLIC_URL + '/assets/633803.png'} alt="" />
          </div>
          <div className={classes['headerDiv']}>
            <h1 className={classes['header']}>{Localization.SessionTimeOut}</h1>
          </div>
          <div className={classes['border']} />

          <div>
            <h1 className={classes['alphabh']}>{Localization.Inactivity}</h1>
          </div>
          <div className={classes['omega']}>
            <button
              type="submit"
              className={classes['buttondiv-logout-warning1']}
              onClick={() => proceed()}
            >
              {Localization.StayLoggedIn}
            </button>

            <button
              type="submit"
              className={classes['buttondiv-logout-warning']}
              onClick={() => logout()}
            >
              {Localization.SaveLogout}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export const mapStateToProps = (state) => {
  return {
    fetchApiData: state.fetchReducer.fetchApiData,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchService: ({userId, token, isPreviewPage}) =>
      dispatch(loadFetchData({userId, token, isPreviewPage})),
    logoutService: () => dispatch(logoutAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormHeader);
