export const audioCategory = ({type, value, prod}) => {
    if (type.attributeName === 'Device Type') {
        if (
          value === '3D Blu-ray Home Theatre' ||
                            value === 'Blu-ray Home Theatre' ||
                            value === 'DVD Home Theatre'
        ) {
          prod?.map((checkItem, checkIndex) => {
            const checkelement = checkItem;
            checkelement.attributes = checkItem.attributes.map(
                (checkSubItem, checkSubIndex) => {
                  if (
                    checkSubItem.attributeId ===
                                            'SG-AudioPorts&Slots-OpticalDrive'
                  ) {
                    checkSubItem.mandatory = 'Y';
                  }
                  if (
                    checkSubItem.attributeId ===
                                            'SG-HomeAudioFunctions-ConsoleSpecifications'
                  ) {
                    checkSubItem.mandatory = 'Y';
                  }

                  return checkSubItem;
                },
            );
            return checkelement;
          });
        } else {
          prod?.map((checkItem, checkIndex) => {
            const checkelement = checkItem;
            checkelement.attributes = checkItem.attributes.map(
                (checkSubItem, checkSubIndex) => {
                  if (
                    checkSubItem.attributeId ===
                                            'SG-AudioPorts&Slots-OpticalDrive'
                  ) {
                    checkSubItem.mandatory = 'N';
                  }
                  if (
                    checkSubItem.attributeId ===
                                            'SG-HomeAudioFunctions-ConsoleSpecifications'
                  ) {
                    checkSubItem.mandatory = 'N';
                  }
                  return checkSubItem;
                },

            );
            return checkelement;
          });
        }
      }
      return null;
};
export const simDetail = ({value, prod}) => {
    if (value === '2' || value === '3' || value === '4') {
        prod?.map(
            (checkItem, checkIndex) => {
              const checkelement = checkItem;
              checkelement.attributes = checkItem.attributes.map(
                  (checkSubItem, checkSubIndex) => {
                    if (checkSubItem.attributeId === 'SG-SIMDetails-PrimarySIMType' || checkSubItem.attributeId === 'SG-SIMDetails-SecondarySIMType' ||
                                          checkSubItem.attributeId === 'SG-SIMDetails-DualSIMMode') {
                      checkSubItem.mandatory = 'Y';
                    }
                    return checkSubItem;
                  },
              );
              return checkelement;
            },
        );
      } else {
        prod?.map(
            (checkItem, checkIndex) => {
              const checkelement = checkItem;
              checkelement.attributes = checkItem.attributes.map(
                  (checkSubItem, checkSubIndex) => {
                    if (checkSubItem.attributeId === 'SG-SIMDetails-SecondarySIMType' || checkSubItem.attributeId === 'SG-SIMDetails-DualSIMMode') {
                      checkSubItem.mandatory = 'N';
                    }
                    return checkSubItem;
                  },
              );
              return checkelement;
            },
        );
      }
 return null;
};
export const primarySimDetail = ({prod, value}) => {
    if (value === '1') {
        prod?.map(
            (checkItem, checkIndex) => {
              const checkelement = checkItem;
              checkelement.attributes = checkItem.attributes.map(
                  (checkSubItem, checkSubIndex) => {
                    if (checkSubItem.attributeId === 'SG-SIMDetails-PrimarySIMType') {
                      checkSubItem.mandatory = 'Y';
                    }
                    return checkSubItem;
                  },
              );
              return checkelement;
            },
        );
      } else {
        prod?.map(
            (checkItem, checkIndex) => {
              const checkelement = checkItem;
              checkelement.attributes = checkItem.attributes.map(
                  (checkSubItem, checkSubIndex) => {
                    if (checkSubItem.attributeId === 'SG-SIMDetails-PrimarySIMType') {
                      checkSubItem.mandatory = 'N';
                    }
                    return checkSubItem;
                  },
              );
              return checkelement;
            },
        );
      }
      return null;
};
export const laptopCategory = ({value, prod}) => {
    if (value === 'Gaming') {
        prod?.map(
            (checkItem, checkIndex) => {
              const checkelement = checkItem;
              checkelement.attributes = checkItem.attributes.map(
                  (checkSubItem, checkSubIndex) => {
                    if (checkSubItem.attributeId === 'SG-LaptopScreenSpecifications-RefreshRate') {
                      checkSubItem.mandatory = 'Y';
                    }
                    return checkSubItem;
                  },
              );
              return checkelement;
            },
        );
      } else {
        prod?.map(
            (checkItem, checkIndex) => {
              const checkelement = checkItem;
              checkelement.attributes = checkItem.attributes.map(
                  (checkSubItem, checkSubIndex) => {
                    if (checkSubItem.attributeId === 'SG-LaptopScreenSpecifications-RefreshRate') {
                      checkSubItem.mandatory = 'N';
                    }
                    return checkSubItem;
                  },
              );
              return checkelement;
            },
        );
      }
      return null;
};
export const vaccumCategory = ({value, prod}) => {
    if (value === 'Dry Vacuum Cleaner' || value === 'Wet & Dry Vacuum Cleaner' || value === 'Portable Vacuum Cleaner' ||
                                value === 'Car Vacuum Cleaner' || value === 'Robotic Vacuum Cleaner' || value === 'Steam Mop Cleaner' || value === 'Mop') {
                  prod?.map(
                      (checkItem, checkIndex) => {
                        const checkelement = checkItem;
                        checkelement.attributes = checkItem.attributes.map(
                            (checkSubItem, checkSubIndex) => {
                              if (checkSubItem.attributeId === 'SG-SHDCategory-FormFactor') {
                                checkSubItem.mandatory = 'Y';
                              }


                              return checkSubItem;
                            },
                        );
                        return checkelement;
                      },
                  );
                } else {
                  prod?.map(
                      (checkItem, checkIndex) => {
                        const checkelement = checkItem;
                        checkelement.attributes = checkItem.attributes.map(
                            (checkSubItem, checkSubIndex) => {
                              if (checkSubItem.attributeId === 'SG-SHDCategory-FormFactor') {
                                checkSubItem.mandatory = 'N';
                              }


                              return checkSubItem;
                            },
                        );
                        return checkelement;
                      },
                  );
                }
                return null;
};
export const airPurifierCategory = ({value, prod}) => {
    if (value === 'Air Purifier & Dehumidifier' || value === 'Dehumidifier' || value === 'Humidifier' || value === 'Air Purifier & Humidifier') {
        prod?.map(
            (checkItem, checkIndex) => {
              const checkelement = checkItem;
              checkelement.attributes = checkItem.attributes.map(
                  (checkSubItem, checkSubIndex) => {
                    if (checkSubItem.attributeId === 'SG-AirPurifierFunctions-WaterBucketVolume') {
                      checkSubItem.mandatory = 'Y';
                    }


                    return checkSubItem;
                  },
              );
              return checkelement;
            },
        );
      } else {
        prod?.map(
            (checkItem, checkIndex) => {
              const checkelement = checkItem;
              checkelement.attributes = checkItem.attributes.map(
                  (checkSubItem, checkSubIndex) => {
                    if (checkSubItem.attributeId === 'SG-AirPurifierFunctions-WaterBucketVolume') {
                      checkSubItem.mandatory = 'N';
                    }


                    return checkSubItem;
                  },
              );
              return checkelement;
            },
        );
      }
      return null;
};
export const smartHomeIdeal = ({value, prod}) => {
  if (value === 'Hoverboard' || value === 'Electric Bike' || value === 'Electric Scooter' || value === 'RC-Car' || value === 'Companion Robot' ||
                                value === 'Robot Ball' || value === 'Robot Droid' || value === 'Robot Ollie' || value === 'Education Robot' || value === 'Rotating Globe' ||
                                value === 'Telescope' || value === 'Microscope' || value === 'Magic Wand Computer' || value === 'Learning System' || value === 'Massager') {
                  prod?.map(
                      (checkItem, checkIndex) => {
                        const checkelement = checkItem;
                        checkelement.attributes = checkItem.attributes.map(
                            (checkSubItem, checkSubIndex) => {
                              if (checkSubItem.attributeId === 'SG-SHDCategory-IdealFor') {
                                checkSubItem.mandatory = 'Y';
                              }


                              return checkSubItem;
                            },
                        );
                        return checkelement;
                      },
                  );
                } else {
                  prod?.map(
                      (checkItem, checkIndex) => {
                        const checkelement = checkItem;
                        checkelement.attributes = checkItem.attributes.map(
                            (checkSubItem, checkSubIndex) => {
                              if (checkSubItem.attributeId === 'SG-SHDCategory-IdealFor') {
                                checkSubItem.mandatory = 'N';
                              }


                              return checkSubItem;
                            },
                        );
                        return checkelement;
                      },
                  );
                }
                return null;
};
export const desktopResolution = ({value, prod}) => {
  if (value === 'All-in-One' || value === 'iMac') {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-DesktopScreenSpecifications-Resolution') {
                  checkSubItem.mandatory = 'Y';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-DesktopScreenSpecifications-Resolution') {
                  checkSubItem.mandatory = 'N';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const desktopRatio = ({value, prod}) => {
  if (value === 'All-in-One' || value === 'iMac') {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-DesktopScreenSpecifications-Ratio') {
                  checkSubItem.mandatory = 'Y';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-DesktopScreenSpecifications-Ratio') {
                  checkSubItem.mandatory = 'N';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const desktopWifiSupported = ({value, prod}) => {
  if (value === 'All-in-One' || value === 'iMac') {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-ProductNetworkConnectivity-WiFiSupported') {
                  checkSubItem.mandatory = 'Y';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-ProductNetworkConnectivity-WiFiSupported') {
                  checkSubItem.mandatory = 'N';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const hdmiType = ({value, prod}) => {
  if (value !== null) {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-Port&ConnectorDetails-NumberofHDMI') {
                  checkSubItem.mandatory = 'Y';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-Port&ConnectorDetails-NumberofHDMI') {
                  checkSubItem.mandatory = 'N';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const telivision3DType = ({value, prod}) => {
  if (value === '3D TV' || value === '3D Smart TV' || value === '3D Android Smart TV' || value === '3D OEM Smart TV') {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-TelevisionScreenSpecifications-3DType') {
                  checkSubItem.mandatory = 'Y';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-TelevisionScreenSpecifications-3DType') {
                  checkSubItem.mandatory = 'N';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const telivisionOSType = ({value, prod}) => {
  if (value === 'Smart TV' || value === 'Android Smart TV' || value === '3D Smart TV' || value === '3D Android Smart TV' || value === '3D OEM Smart TV' || value === 'OEM Smart TV') {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-TelevisionOperatingSystem-OSType') {
                  checkSubItem.mandatory = 'Y';
                }
                if (checkSubItem.attributeId === 'SG-TelevisionPorts&Slots-EthernetSupported') {
                  checkSubItem.mandatory = 'Y';
                }
                if (checkSubItem.attributeId === 'SG-TelevisionNetworkConnectivity-WiFiSupported') {
                  checkSubItem.mandatory = 'Y';
                }
                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-TelevisionOperatingSystem-OSType') {
                  checkSubItem.mandatory = 'N';
                }
                if (checkSubItem.attributeId === 'SG-TelevisionPorts&Slots-EthernetSupported') {
                  checkSubItem.mandatory = 'N';
                }

                if (checkSubItem.attributeId === 'SG-TelevisionNetworkConnectivity-WiFiSupported') {
                  checkSubItem.mandatory = 'N';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const telivisionStorageCapacity = ({value, prod}) => {
if (value === 'Smart TV' || value === 'Android Smart TV' || value === '3D Smart TV' || value === '3D Android Smart TV' || value === '3D OEM Smart TV' ||
                                value === 'OEM Smart TV' || value === 'Fire Stick Smart TV' || value === 'Smart Screen') {
                  prod?.map(
                      (checkItem, checkIndex) => {
                        const checkelement = checkItem;
                        checkelement.attributes = checkItem.attributes.map(
                            (checkSubItem, checkSubIndex) => {
                              if (checkSubItem.attributeId === 'SG-TelevisionStorageSpecification-StorageCapacity') {
                                checkSubItem.mandatory = 'Y';
                              }
                              if (checkSubItem.attributeId === 'SG-TelevisionInternalMemory-RAM') {
                                checkSubItem.mandatory = 'Y';
                              }
                              return checkSubItem;
                            },
                        );
                        return checkelement;
                      },
                  );
                } else {
                  prod?.map(
                      (checkItem, checkIndex) => {
                        const checkelement = checkItem;
                        checkelement.attributes = checkItem.attributes.map(
                            (checkSubItem, checkSubIndex) => {
                              if (checkSubItem.attributeId === 'SG-TelevisionStorageSpecification-StorageCapacity') {
                                checkSubItem.mandatory = 'N';
                              }
                              if (checkSubItem.attributeId === 'SG-TelevisionInternalMemory-RAM') {
                                checkSubItem.mandatory = 'N';
                              }


                              return checkSubItem;
                            },
                        );
                        return checkelement;
                      },
                  );
                }
                return null;
};
export const desktopEthernet = ({value, prod}) => {
  if (value === 'All-in-One' || value === 'iMac') {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-DesktopPorts&Slots-EthernetSpecifications') {
                  checkSubItem.mandatory = 'Y';
                }
                if (checkSubItem.attributeId === 'SG-DesktopPorts&Slots-EthernetSupported') {
                  checkSubItem.mandatory = 'Y';
                }
                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-DesktopPorts&Slots-EthernetSpecifications') {
                  checkSubItem.mandatory = 'N';
                }
                if (checkSubItem.attributeId === 'SG-DesktopPorts&Slots-EthernetSupported') {
                  checkSubItem.mandatory = 'N';
                }
                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const televisionInRadius = ({value, prod}) => {
  if (value === 'Curved Panel') {
  prod?.map(
      (checkItem, checkIndex) => {
        const checkelement = checkItem;
        checkelement.attributes = checkItem.attributes.map(
            (checkSubItem, checkSubIndex) => {
              if (checkSubItem.attributeId === 'SG-TelevisionScreenSpecifications-ScreenCurvature-InRadius') {
                checkSubItem.mandatory = 'Y';
              }
              return checkSubItem;
            },
        );
        return checkelement;
      },
  );
} else {
  prod?.map(
      (checkItem, checkIndex) => {
        const checkelement = checkItem;
        checkelement.attributes = checkItem.attributes.map(
            (checkSubItem, checkSubIndex) => {
              if (checkSubItem.attributeId === 'SG-TelevisionScreenSpecifications-ScreenCurvature-InRadius') {
                checkSubItem.mandatory = 'N';
              }
              return checkSubItem;
            },
        );
        return checkelement;
      },
  );
}
return null;
};
export const suitableType = ({value, prod}) => {
  if (value === 'Set Top Box' || value === 'Media Streaming Box' || value === 'Media Streaming Stick' || value === 'Media Streaming Dongle' || value === 'Bluetooth Receiver/Transmitter') {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-SHDCategory-SuitableFor') {
                  checkSubItem.mandatory = 'Y';
                }


                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-SHDCategory-SuitableFor') {
                  checkSubItem.mandatory = 'N';
                }


                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const airPurifierWaterBucket = ({value, prod}) => {
  if (value === 'Air Purifier & Dehumidifier' || value === 'Humidifier' || value === 'Dehumidifier' || value === 'Air Purifier & Humidifier') {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-AirPurifierFunctions-WaterBucketVolume') {
                  checkSubItem.mandatory = 'Y';
                }
                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-AirPurifierFunctions-WaterBucketVolume') {
                  checkSubItem.mandatory = 'N';
                }
                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const washerAndDryer = ({value, prod}) => {
  if (value === 'Washer & Dryer') {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-WasherDryerFeatures-DryerCapacity') {
                  checkSubItem.mandatory = 'Y';
                }
                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-WasherDryerFeatures-DryerCapacity') {
                  checkSubItem.mandatory = 'N';
                }
                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export default (audioCategory, simDetail, primarySimDetail, laptopCategory, vaccumCategory, airPurifierCategory, smartHomeIdeal, desktopResolution, desktopRatio, desktopWifiSupported, hdmiType, telivision3DType, telivisionOSType, telivisionStorageCapacity, desktopEthernet, televisionInRadius, airPurifierWaterBucket, suitableType, washerAndDryer);
