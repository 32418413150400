export const LOAD_VALIDATE_OTP = 'LOAD_VALIDATE_OTP';
export const VALIDATE_OTP_LOADING_COMPLETE = 'VALIDATE_OTP_LOADING_COMPLETE';
export const VALIDATE_OTP_LOADING_ERROR = 'VALIDATE_OTP_LOADING_ERROR';

export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_COMPLETE = 'SEND_OTP_COMPLETE';
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const VALIDATE_OTP = 'VALIDATE_OTP';
export const VALIDATE_OTP_COMPLETE = 'VALIDATE_OTP_COMPLETE';
export const VALIDATE_OTP_ERROR = 'VALIDATE_OTP_ERROR';
export const VALIDATE_OTP_ALREADY_LOGGEDIN_ERROR = 'VALIDATE_OTP_ALREADY_LOGGEDIN_ERROR';

export const CORE_ATTRIBUTE = 'CORE_ATTRIBUTE';
export const CORE_ATTRIBUTE_COMPLETE = 'CORE_ATTRIBUTE_COMPLETE';
export const CORE_ATTRIBUTE_ERROR = 'CORE_ATTRIBUTE_ERROR';

export const CORE_ATTRIBUTE_DIMENSION = 'CORE_ATTRIBUTE_DIMENSION';
export const CORE_ATTRIBUTE_DIMENSION_COMPLETE = 'CORE_ATTRIBUTE_DIMENSION_COMPLETE';
export const CORE_ATTRIBUTE_DIMENSION_ERROR= 'CORE_ATTRIBUTE_DIMENSION_ERROR';

export const CLASSIFICATION_ATTRIBUTE_ITEM='CLASSIFICATION_ATTRIBUTE_ITEM';
export const CLASSIFICATION_ATTRIBUTE_ITEM_COMPLETE='CLASSIFICATION_ATTRIBUTE_ITEM_COMPLETE';
export const CLASSIFICATION_ATTRIBUTE_ITEM_ERROR='CLASSIFICATION_ATTRIBUTE_ITEM_ERROR';

export const CLASSIFICATION_ATTRIBUTE='CLASSIFICATION_ATTRIBUTE';

export const CLASSIFICATION_ATTRIBUTE_COMPANY='CLASSIFICATION_ATTRIBUTE_COMPANY';
export const CLASSIFICATION_ATTRIBUTE_COMPANY_COMPLETE='CLASSIFICATION_ATTRIBUTE_COMPANY_COMPLETE';
export const CLASSIFICATION_ATTRIBUTE_COMPANY_ERROR='CLASSIFICATION_ATTRIBUTE_COMPANY_ERROR';

export const CLASSIFICATION_ATTRIBUTE_ADDITIONAL='CLASSIFICATION_ATTRIBUTE_ADDITIONAL';
export const CLASSIFICATION_ATTRIBUTE_ADDITIONAL_COMPLETE = 'CLASSIFICATION_ATTRIBUTE_ADDITIONAL_COMPLETE';
export const CLASSIFICATION_ATTRIBUTE_ADDITIONAL_ERROR = 'CLASSIFICATION_ATTRIBUTE_ADDITIONAL_ERROR';

export const SAVE = 'SAVE';
export const SAVE_COMPLETE = 'SAVE_COMPLETE';
export const SAVE_ERROR = 'SAVE_ERROR';
export const SAVE_BLANK = 'SAVE_BLANK';


export const FETCH = 'FETCH';
export const FETCH_COMPLETE = 'FETCH_COMPLETE';
export const FETCH_ERROR = 'FETCH_ERROR';

export const DELETE = 'DELETE';
export const DELETE_COMPLETE = 'DELETE_COMPLETE';
export const DELETE_ERROR = 'DELETE_ERROR';
export const DELETE_CURRENT_SESSION = 'DELETE_CURRENT_SESSION';
export const DELETE_CURRENT_SESSION_SUCCESS = 'DELETE_CURRENT_SESSION_SUCCESS';
export const DELETE_CURRENT_SESSION_FAILURE = 'DELETE_CURRENT_SESSION_FAILURE';

export const PRIMARY_HIERARCHY = 'PRIMARY_HIERARCHY';
export const PRIMARY_HIERARCHY_COMPLETE = 'PRIMARY_HIERARCHY_COMPLETE';
export const PRIMARY_HIERARCHY_ERROR = 'PRIMARY_HIERARCHY_ERROR';

export const SUBMIT = 'SUBMIT';
export const SUBMIT_COMPLETE = 'SUBMIT_COMPLETE';
export const SUBMIT_ERROR = 'SUBMIT_ERROR';

export const EMAIL = 'EMAIL';
export const EMAIL_COMPLETE = 'EMAIL_COMPLETE';
export const EMAIL_ERROR = 'EMAIL_ERROR';

export const GENERATE_ARTICLE_CODE = 'GENERATE_ARTICLE_CODE';
export const GENERATE_ARTICLE_CODE_COMPLETE = 'GENERATE_ARTICLE_CODE_COMPLETE';
export const GENERATE_ARTICLE_CODE_ERROR = 'GENERATE_ARTICLE_CODE_ERROR';

export const SUBMIT_URL = 'SUBMIT_URL';
export const SUBMIT_URL_COMPLETE = 'SUBMIT_URL_COMPLETE';
export const SUBMIT_URL_ERROR = 'SUBMIT_URL_ERROR';
export const EMPTYSAVE = 'EMPTYSAVE';

export const PACK_ATTRIBUTE = 'PACK_ATTRIBUTE';
export const PACK_ATTRIBUTE_COMPLETE = 'PACK_ATTRIBUTE_COMPLETE';
export const PACK_ATTRIBUTE_ERROR = 'PACK_ATTRIBUTE_ERROR';

export const SAVE_PACK = 'SAVE_PACK';
export const SAVE_PACK_COMPLETE = 'SAVE_PACK_COMPLETE';
export const SAVE_PACK_ERROR = 'SAVE_PACK_ERROR';

export const API_ERROR = 'API_ERROR';

export const DELETE_CLOUDINARY_ASSET = 'DELETE_CLOUDINARY_ASSET';
export const DELETE_CLOUDINARY_ASSET_COMPLETE = 'DELETE_CLOUDINARY_ASSET_COMPLETE';
export const DELETE_CLOUDINARY_ASSET_ERROR = 'DELETE_CLOUDINARY_ASSET_ERROR';

export const FETCH_ARTICLE_DETAILS = 'FETCH_ARTICLE_DETAILS';
export const FETCH_ARTICLE_DETAILS_COMPLETE = 'FETCH_ARTICLE_DETAILS_COMPLETE';
export const FETCH_ARTICLE_DETAILS_ERROR = 'FETCH_ARTICLE_DETAILS_ERROR';

export const FETCH_FILTERS = 'FETCH_FILTERS';
export const FETCH_FILTERS_COMPLETE = 'FETCH_FILTERS_COMPLETE';
export const FETCH_FILTERS_ERROR = 'FETCH_FILTERS_ERROR';

export const GET_COMPONENT = 'GET_COMPONENT';
export const GET_COMPONENT_SUCCESS = 'GET_COMPONENT_SUCCESS';
export const GET_COMPONENT_ERROR = 'GET_COMPONENT_ERROR';


