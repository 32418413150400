import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function componentReducer(state=initialState, action) {
    switch (action.type) {
     case actionTypes.GET_COMPONENT:
        return {...state, loading: true, result: null, error: null};
     case actionTypes.GET_COMPONENT_SUCCESS:
        return {...state, loading: false, result: action.payload, error: null};
     case actionTypes.GET_COMPONENT_ERROR:
        return {...state, loading: false, result: null, error: action.payload};
     default:
        return state;
    }
}
