import {HttpService} from './HttpService';
import {COREATTRIBUTE_SECTION_DIMENSION_SERVICE} from '../config/apiEndpoints';
export async function coreAttributeDimensionApi(query) {
  try {
    const endpoint = COREATTRIBUTE_SECTION_DIMENSION_SERVICE;
    const headers = {
      'Authorization': `Bearer ${query}`,
    };
    return (await HttpService.get(endpoint, {headers: headers}));
  } catch (err) {
    return {err};
  }
}
