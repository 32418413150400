const dev = {
  apiGateway: {
    REGION: 'development',
    baseUrl: '/',
    apiUrl: process.env.REACT_APP_BACKEND_API,
  },
};
const prod = {
  apiGateway: {
    REGION: 'prod',
    baseUrl: '/',
    apiUrl: process.env.REACT_APP_BACKEND_API,
  },
};

const config = process.env.NODE_ENV === 'production' ? prod : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiKey: process.env.REACT_APP_daslsjnaasdbkndb,
  cloudName: process.env.REACT_APP_hsgbxajdajsbhnkassahvdjhasvjhads || 'cromadev',
  uploadPreset: process.env.REACT_APP_asvghfsbbcsdhnjhcnhfchsd,
  ...config,
};
