import {EMAIL_VALIDATION_REGEX} from '../utils/Constants';

export const saveData = ({props, userId, token, prod, checkClickcontinue, getClassAttr}) => {
    let checkAttribute = false;
    if (!checkClickcontinue) {
      let emailCheck = false;
      const classAttributeR = prod?.map((subItem, subIndex) => {
        const subelement = subItem;
        subelement.attributes = subItem.attributes.map(
            (secSubItem, secSubIndex) => {
              const element = secSubItem;
              if (element.isCrossedminLength) {
                checkAttribute = true;
              } else if (element.fieldType === 'Email') {
                const reg = /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/;
                if (EMAIL_VALIDATION_REGEX.test(element.attributeNameValue) === false) {
                  emailCheck = true;
                } else {
                  emailCheck = false;
                }
              }
              return element;
            },
        );
        return subelement;
      });
      getClassAttr(classAttributeR);
      if ((checkAttribute===false)) {
        props.saveApi({userId, token, sendsaveData: {section: '3-Company Information', subGroups: prod}});
      }
    }
    return null;
  };

  export const saveDataToast = ({props, userId, token, prod, getCheckVal, getClassAttr, getConfirmCompany}) => {
    let checkAttribute = false;
    let emailCheck = false;
     let isValid = 0;
    let count = 0;
      prod?.forEach(
                    (checkItem, checkIndex) => {
                      const checkelement = checkItem;
                      checkelement.attributes = checkItem.attributes.map(
                          (checkSubItem) => {
                            if (checkSubItem?.attributeNameValue!== '' && checkSubItem?.mandatory === 'Y') {
                              isValid++;
                            }
                            count++;
                            return checkSubItem;
                          },
                      );
                         return checkelement;
                    },
                );
    const classAttributeR = prod?.map((subItem, subIndex) => {
      const subelement = subItem;
      subelement.attributes = subItem.attributes.map(
          (secSubItem, secSubIndex) => {
            const element = secSubItem;
            if (element.isCrossedminLength) {
              checkAttribute = true;
            } else if (element.fieldType === 'Email') {
              const reg = /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/;
              if (EMAIL_VALIDATION_REGEX.test(element.attributeNameValue) === false) {
                emailCheck = true;
              } else {
                emailCheck = false;
              }
            }
            return element;
          },
      );
      return subelement;
    });
    getClassAttr(classAttributeR);
    if ((checkAttribute===false)) {
      props.saveApi({userId, token, sendsaveData: {section: '3-Company Information', subGroups: prod}});
      getCheckVal(true);
        if (isValid === count) {
        getConfirmCompany(false);
        } else {
           getConfirmCompany(true);
        }
    }
    return null;
  };
  export default (saveData, saveDataToast);
