export const getAuthToken = () => {
  return (
    JSON.parse(localStorage.getItem('myValueInLocalStorage')) &&
    JSON.parse(localStorage.getItem('myValueInLocalStorage')).data.token
  );
};

export const getUserId = () => {
  return localStorage.getItem('userId');
};
