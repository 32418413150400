import {put, takeLatest, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {articleApi} from '../.././services/articleServices';

export function* articleWatcherAsyn(action) {
  try {
    const response = yield call(articleApi, action.payload.token);
    if (response.status >= 200 && response.status < 300) {
      const articleApiData = yield response.data;
      yield put({type: actionTypes.GENERATE_ARTICLE_CODE_COMPLETE, payload: articleApiData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.GENERATE_ARTICLE_CODE_ERROR, payload: error});
  }
}
export function* articleWatcher() {
  yield takeLatest(actionTypes.GENERATE_ARTICLE_CODE, articleWatcherAsyn);
}

export const articleSaga=[
  articleWatcher(),
];
