export const saveData=({props, userId, token, country, checkClickcontinue}) => {
    if (!checkClickcontinue) {
      props.saveApi({userId, token, sendsaveData: {section: '4-Dimensions', subGroups: country}});
    }
    return null;
  };
  export const saveDataToast= ({props, userId, token, country, getCheckVal, fetchPack, getConfirmDimension}) => {
  let isValid = 0;
    let count = 0;
      country.forEach(
                    (checkItem, checkIndex) => {
                      const checkelement = checkItem;
                      checkelement.attributes = checkItem.attributes.map(
                          (checkSubItem) => {
                            if (checkSubItem?.attributeNameValue!== '' && checkSubItem?.mandatory === 'Y') {
                              isValid++;
                            }
                            if (checkSubItem?.mandatory === 'Y') {
                            count++;
                              }
                            return checkSubItem;
                          },
                      );
                         return checkelement;
                    },
                );
    if (props.fetchApiData?.productType === 'pack' && fetchPack) {
      props.savePackApi({userId, token, sendPackData: {'components': fetchPack}});
      props.saveApi({userId, token, sendsaveData: {section: '4-Dimensions', subGroups: country}});
      getCheckVal(true);
      if (isValid === count) {
        getConfirmDimension(false);
        } else {
           getConfirmDimension(true);
        }
    } else {
      props.saveApi({userId, token, sendsaveData: {section: '4-Dimensions', subGroups: country}});
      getCheckVal(true);
      if (isValid === count) {
        getConfirmDimension(false);
        } else {
           getConfirmDimension(true);
        }
    }
    return null;
  };
  export const elementRange = ({element, value, subItem, secSubIndex, roundToTwo, roundToThree, setMax}) =>{
    const range = element.range.split('-');
    const min = Number(range[0]);
    const max = Number(range[1]);
    setMax(max);
     const re = new RegExp(element.validRegex);
    console.log(element.validRegex);
    // const re = new RegExp('^(\\d{0,3})(\\.(?!00)\\d{0,3})?$');
    // const re = new RegExp('^(?!0)(\\d{0,3})(\\.(?!00)\\d{0,3})?$');
    if (Number(value) >= min && Number(value) <= max) {
      if (value.toString().includes('.')) {
        const temp = value.toString().split('.');
        if (temp[1].length > 3) {
          return null;
        } else {
          element.attributeNameValue = value;
        }
      } else {
        element.attributeNameValue = value;
      }
      element.isCrossedRange = false;
    } else if (value === element.noDataProvidedValue) {
      element.attributeNameValue = value;
    } else {
      if (value === '') {
        element.attributeNameValue = value;
      } else {
        element.isCrossedRange = true;
      }
    }
     if (re.test(value) && element.isCrossedRange===false) {
    // if (element.isCrossedRange===false) {
      element.isInvalid = false;
      element.isCrossedLength = false;
      element.attributeNameValue = value;
      if (element.uom != null && element.uom === 'UoM-g') {
        subItem.attributes[secSubIndex + 1].attributeNameValue =
        '' + roundToThree(Number(value) / 1000);
        if (element.attributeId === 'netWeightCD') {
          subItem.attributes[secSubIndex + 1].attributeNameValue =
          '' + roundToTwo(Number(value) / 1000);
        }
      }
      if (element.uom != null && element.uom === 'UoM-Kg') {
        subItem.attributes[secSubIndex - 1].attributeNameValue =
        '' + roundToThree(Number(value) * 1000);
        if (element.attributeId === 'weightUnitCD - kg') {
          subItem.attributes[secSubIndex - 1].attributeNameValue =
          '' + roundToTwo(Number(value) * 1000);
        }
      }
      if (
        element.uom !== null &&
      element.uom === 'UoM-cm' &&
      element.attributeName === 'Width (cm)'
      ) {
        subItem.attributes[secSubIndex + 3].attributeNameValue =
        '' + roundToThree(Number(value) / 2.54);
      }
      if (
        element.uom !== null &&
      element.uom === 'UoM-Inches' &&
      element.attributeName === 'Width (in)'
      ) {
        subItem.attributes[secSubIndex - 3].attributeNameValue =
        '' + roundToThree(Number(value) / 0.393701);
      }
      if (
        element.uom !== null &&
      element.uom === 'UoM-cm' &&
      element.attributeName === 'Length (cm)'
      ) {
        subItem.attributes[secSubIndex + 3].attributeNameValue =
        '' + roundToThree(Number(value) / 2.54);
      }
      if (
        element.uom !== null &&
      element.uom === 'UoM-Inches' &&
      element.attributeName === 'Length (in)'
      ) {
        subItem.attributes[secSubIndex - 3].attributeNameValue =
        '' + roundToThree(Number(value) / 0.393701);
      }
      if (
        element.uom !== null &&
      element.uom === 'UoM-cm' &&
      element.attributeName === 'Height (cm)'
      ) {
        subItem.attributes[secSubIndex + 3].attributeNameValue =
        '' + roundToThree(Number(value) / 2.54);
      }
      if (
        element.uom !== null &&
      element.uom === 'UoM-Inches' &&
      element.attributeName === 'Height (in)'
      ) {
        subItem.attributes[secSubIndex - 3].attributeNameValue =
        '' + roundToThree(Number(value) / 0.393701);
      }
  } else {
                  element.isInvalid = true;
                  element.isCrossedLength = false;
                  return element;
  }
  };
export default (saveData, saveDataToast, elementRange);
