const Localization = {
  // Start: 'Start Selling',
  Start: 'Onboard',
  Enter: 'Enter OTP',
  Send: 'Send OTP',
  Otp: 'OTP will be sent to registered email id',
  Mobilenum: 'and registered mobile number',
  Your: ' your product on Croma',
  You: 'You will get a OTP via Email & SMS',
  Login: 'Login',
  didnt: 'Didn\'t receive OTP ?',
  Resend: 'Resend OTP',
  // Loginwith: 'Login with your Seller Account',
  Loginwith: 'Login',
  User: 'User Id',
  IncorrectUser: 'User Id is not registered with Croma.',
  PleaseEnterUserIDOTP: 'Please Enter User ID and OTP',
  PleaseEnterUserID: 'Please Enter User ID',
  All: 'All rights reserved.',
  Pre: 'Pre-registered users please login and submit product details in the portal.',
  reg: 'For registering new user/vendor or any query please email ',
  email: 'vendorsupport@croma.com',
  Welcome: 'Welcome Pacetel Systems Pvt Ltd Mah',
  Logout: 'Logout',
  Form: 'Form upload',
  Search: 'Search (Preview)',
  Filter: 'Filter By',
  Excel: 'Excel upload',
  Company: 'Company Information & Contacts',
  Manufacturer: 'Manufacturer/Importer Name ',
  Address: 'Address ',
  Customer: 'Customer Support Number ',
  Customersupport: 'Customer Support Email',
  Allrights: 'All rights reserved.',
  Save: 'Save',
  Continue: 'Continue',
  Preview: 'Preview',
  Confirm: 'Confirm',
  Productpath: 'Product path',
  Digital: '   Digital - Communication -  Mobile - Regular',
  Groupinfo: 'Group Information',
  Productinfo: 'Product Information',
  Companyinfo: 'Company Information',
  Dimensions: 'Dimensions',
  Itemdetails: 'Item Details',
  Additional: 'Additional Details',
  Fileupload: 'File Upload',
  Sellingprice: 'Selling Price',
  Discount: 'Discount',
  Productadditional: 'Product Additional Info',
  Thankyou: 'Thank You!',
  Vendorform: 'Vendor Form Submission Reference No :',
  Submission: 'Submission Date :',
  Pleasekeepthis: 'For further reference, you will receive an email acknowledgement with the details submitted.',
  Download: 'Download product summary',
  ContinueUpload: 'Create a New Product',
  Productdetails: 'Product Details',
  Selectbrand: 'Select Brand',
  Productname: 'Product Name ',
  Vendorarticle: 'Vendor Article code  ',
  Modelseries: 'Model Series',
  Modelnumber: 'Model Number',
  Ean: 'EAN',
  Hsncode: 'HSN Code',
  Country: 'Country Of Origin',
  Productfeatures: 'Product Features',
  Keyfeature: 'Key Feature',
  Color: 'Color',
  Demo: 'Demo / Installation',
  Yes: 'Yes',
  No: 'No',
  Manufacturingwarranty: 'Manufacturing Warranty',
  Inmonths: '(In Months)',
  Inthebox: 'In The Box',
  Mainproduct: 'Main Product ',
  Qty: 'Qty',
  Accessories: 'Accessories',
  Documents: 'Documents',
  Others: 'Others',
  Pricing: 'Pricing',
  Mrp: 'MRP',
  Mop: 'Mop',
  Poprice: 'PO Price',
  Asterisk: '*',
  ProductDimension: 'Product Dimensions',
  PackageDimension: 'Package Dimensions',
  MasterCartonDimension: 'Master Carton Dimensions',
  Gross: 'Gross Weight',
  Net: 'Net Weight ',
  Gm: 'gm',
  Kg: 'kg',
  Num: '190',
  Dec: '0.19',
  Length: 'Length',
  Height: 'Height',
  Width: 'Width',
  Cm: 'cm',
  Inches: 'inches',
  Mobilecategory: 'Mobile Category & OS',
  Processordetails: 'Processor Details',
  Storagespecification: 'Storage Specifications',
  Screenspecification: 'Screen Specifications',
  Simdetails: 'SIM Details & Battery',
  Networktechnology: 'Network Technology & Connectivity',
  Audiospecs: 'Audio  & Camera Spec',
  Arrow: '>',
  UserID: 'Please Enter UserID',
  Field: 'This field is required',
  Guideline: 'Guideline for file uploading',
  Image: 'Image',
  Video: 'Video',
  Manual: 'Manual',
  Brochure: 'Brochure',
  Misc: 'Misc',
  DropYourFilesHere: 'View Your Files Here!',
  Clickhere: 'Click Here To Upload',
  priceZero: 'Price cannot be 0.00',
  Character: 'No Special characters allowed',
  LogoutMessage: 'Are you sure want to log out ?',
  LogoutMessage1: 'Data entered will be saved only for 15 days.',
  VendorName: '“Name of article as per the brand system”',
  VendorCode: '“Article code as per the brand system”',
  keyFeatures: '“Mention the key important features that act as USP for the product and same can be displayed on ticket on the store and on website as well”',
  MarketerAddress: '“Enter all 3 if different manufacturer, importer and marketer”.',
  GenericMessage: 'Please Enter the Mandatory Fields',
  SummaryScreen: 'Please click on confirm to submit the vendor form else select cancel to edit.',
  DemoAndInstallation: '“Auto ID call generated in CRM if Yes is selected”',
  MinLength: 'Minimum length for this field is',
  MaxLength: 'Maximum length for this field is',
  Range: 'Please enter value between',
  MaxRange: 'Please enter valid value upto max of',
  InputMismatch: 'Duplicate Entries not allowed',
  VendorMOP: 'Selling price for CROMA',
  PackMessage: 'Primary Component cannot be deleted',
  Primary: 'Primary Component',
  PrimaryMessage: 'You are making this component as Primary Component',
  Disclaimer: 'All details filled will be used for multiple mapping/integration purposes. Hence kindly note & ensure all details mentioned are correct. For any queries/concern please email us on vendorsupport@croma.com',
  SessionTimeOut: 'Session Time-Out Warning',
  Inactivity: 'You’re being timed out due to inactivity. Please choose to stay logged in or log off. Otherwise, you will be logged out automatically',
  DownloadFailed: 'Download Failed!',
  LoadingDocument: 'Loading document...',
  StayLoggedIn: 'Stay Logged in',
  MOPError: 'MOP cannot be greater than MRP',
  ProductPriceRangeError: 'Min Price cannot be greater than Max Price',
  GrossError: 'Summation of Fridge and Freezer capacity cannot be greater than Gross Refrigerator capacity',
  AccessDenied: 'Access Denied',
  CountryofOriginInfoText: 'Origin is the country where brand was established or started.',
  CountryofBrandInfoText: 'Country where the particular product was manufactured. If product sold in India manufactured in multiple countries, please select multiple.',
  Cancel: 'Cancel',
  Only: 'Only',
  Digits: 'digits are allowed.',
  Component: 'Component ',
  UploadedFiles: 'Uploaded Files',
  ProductDetailsConfirmation: 'Product Details Confirmation',
  Group: 'Group',
  Category: 'Category',
  SubCategory: 'Sub-category',
  ProductType: 'Product Type',
  Close: 'Close',
  UserIDLogin: 'User ID',
  YouWish: 'You wish to continue with saved data or start new session?',
  SavedData: 'Saved Data',
  NewSession: 'New Session',
  AreYouSure: 'Are you sure you want to start new session?',
  DataEntered: 'Data entered in previous session will be deleted and cannot be restored',
  SelectGroup: 'Select Group',
  NoofComponent: 'No of Component',
  PleaseSelect: 'Please select No of Component',
  Home: 'Home',
  Letsgo: 'Lets go',
  PageMoved: 'Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place?',
  SaveLogout: 'Save & Logout',
  Try: 'and try from there.',
  SelectCategory: 'Select Category',
  SelectSubCategory: 'Select Sub-Category',
  SelectProductType: 'Select Product Type',
  SuccessfulSaveToast: 'Data saved successfully',
  UnsuccessfulSaveToast: 'Data not saved,please try after sometime',
  FileUploadToast: 'Please upload data before clicking Save else click Preview to Proceed',
  ApiErrorMsg: 'Our servers are facing a temporary technical problem. We regret the inconvenience caused. Please try after some time',
  HSN_code: 'For new HSN Creation, Kindly email to Departments-Buyingadmincore_IRL@croma.com for respective category team or department buying admin core',
  SimulaneousAssetUploadWarning: ' A maximum of 8 assets can be uploaded simultaneously',
  UserAlreadyLoggedin: 'User is already login in present',
  SessionAlready: 'User is already logged in. Do you want to continue with new session?',
  ClickContinue: 'Click Yes button to use new session.',
  SuccessfullSessionDeletion: 'Successfully Deleted from Redis',
};
export default Localization;
