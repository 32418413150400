import React from 'react';
import './Dimension.css';
import Localization from '../../common/Localization';
import Layout from '../Shared/Layout/Layout';
import WelcomeHeader from '../Shared/WelcomeHeader/WelcomeHeader';
import FormHeader from '../Shared/FormHeader/FormHeader';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import PackDimension from './packDimension';

const DimensionReturnData = ({
  props,
  country,
  getDisplayBox,
  fetchPack,
  showData,
  setShowData,
  savePackApi,
  saveDataToast,
  checkClickcontinue,
  checkClickcontinueVal,
  confirmDimension,
  contDisable,
  onClickContinue,
  packClickContinue,
}) => {
  const {handleSubmit} = useForm();
  const Welcome = location.welcome;
  return (
    <>
      <Layout />
      <WelcomeHeader Welcome={Welcome} />
      <FormHeader />
      <div className="header-fix4">
        <Link
          to={props?.fetchApiData?.sections ? '/creation' : '/4-Dimensions'}
        >
          <div className="group-information" data-testid="test1">
            {Localization.Groupinfo}
          </div>
        </Link>
        <Link
          to={
            props?.fetchApiData?.sections ?
              props?.fetchApiData.sections[
                  props?.fetchApiData.sections?.findIndex(
                    (p) => p.section === '2-Product Information',
                  )
                ]?.section :
              '/4-Dimensions'
          }
        >
          <div className="product-information" data-testid="test2">
            {Localization.Productinfo}
          </div>
        </Link>
        <Link
          to={
            props?.fetchApiData?.sections ?
              props?.fetchApiData?.sections[
                  props?.fetchApiData?.sections?.findIndex(
                    (p) => p?.section === '3-Company Information',
                  )
                ] ?
                props?.fetchApiData?.sections[
                    props?.fetchApiData?.sections?.findIndex(
                      (p) => p?.section === '3-Company Information',
                    )
                  ].section :
                '/4-Dimensions' :
              '/4-Dimensions'
          }
        >
          <div className="company-info-dm" data-testid="test3">
            {Localization.Companyinfo}
          </div>
        </Link>
        <Link
          to={
            props?.fetchApiData?.sections ?
              props?.fetchApiData?.sections[
                  props?.fetchApiData?.sections?.findIndex(
                    (p) => p?.section === '4-Dimensions',
                  )
                ] ?
                props?.fetchApiData?.sections[
                    props?.fetchApiData?.sections?.findIndex(
                      (p) => p?.section === '4-Dimensions',
                    )
                  ].section :
                '/4-Dimensions' :
              '/4-Dimensions'
          }
        >
          <div className="dimensions-dm" data-testid="test4">
            {Localization.Dimensions}
          </div>
        </Link>
        <Link
          to={
            confirmDimension && props?.fetchApiData?.sections ?
              props?.fetchApiData?.sections[
                  props?.fetchApiData?.sections?.findIndex(
                    (p) => p?.section === '5-Item Details',
                  )
                ] ?
                props?.fetchApiData?.sections[
                    props?.fetchApiData?.sections?.findIndex(
                      (p) => p?.section === '5-Item Details',
                    )
                  ].section :
                '/4-Dimensions' :
              '/4-Dimensions'
          }
        >
          <div className="item" data-testid="test5">
            {Localization.Itemdetails}
          </div>
        </Link>
        <Link
          to={
            confirmDimension && props?.fetchApiData?.sections ?
              props?.fetchApiData?.sections[
                  props?.fetchApiData?.sections?.findIndex(
                    (p) => p?.section === '6-Additional Details',
                  )
                ] ?
                props?.fetchApiData?.sections[
                    props?.fetchApiData?.sections?.findIndex(
                      (p) => p?.section === '6-Additional Details',
                    )
                  ].section :
                '/4-Dimensions' :
              '/4-Dimensions'
          }
        >
          <div className="additional-dm" data-testid="test6">
            {Localization.Additional}
          </div>
        </Link>
        <Link
          to={
            confirmDimension &&
            props?.fetchApiData?.sections &&
            props?.fetchApiData?.sections[
              props?.fetchApiData.sections?.findIndex(
                (p) => p?.section === '6-Additional Details',
              )
            ]?.section ?
              '/fileupload' :
              '/4-Dimensions'
          }
        >
          <div className="file-information" data-testid="test7">
            {Localization.Fileupload}
          </div>
        </Link>
        <div className="back-box" data-testid="test8"></div>
        <div className="color-box" data-testid="test9"></div>
        <div className="oval-dm-1" data-testid="test10"></div>
        <div className="oval-dm-2" data-testid="test11"></div>
        <div className="oval-dm-3" data-testid="test12"></div>
        <div className="oval-dm-4" data-testid="test13"></div>
        <div className="oval-dm-5" data-testid="test14"></div>
        <div className="oval-dm-6" data-testid="test15"></div>
        <div className="oval-dm-7" data-testid="test16"></div>
      </div>
      <div className="white-rectangle">
        <form
          className="dimension-hide"
          onSubmit={handleSubmit(packClickContinue)}
        >
          {!fetchPack ? (
            country &&
            Array.isArray(country) &&
            country.length > 0 &&
            country.map((item, index) => {
              return (
                <div key={index}>
                  <div className="row product-dimention">
                    {item.subGroupIdentifierName}
                  </div>
                  <div className="row dimention-box1">
                    {item.attributes.map((subItem, subIndex) => {
                      return (
                        <div className="item-box1 col-sm-2" key={subIndex}>
                          {getDisplayBox(subItem.type, subItem, subIndex)}
                        </div>
                      );
                    })}
                  </div>{' '}
                  {index !== country.length - 1 ? (
                    <div className="st-line-dimension" />
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })
          ) : (
            <div>
              {fetchPack && (
                <PackDimension
                  linkName="4-Dimensions"
                  packData={fetchPack}
                  showData={showData}
                  setShowData={setShowData}
                  savePackApi={props.savePackApi}
                  headerName
                />
              )}
            </div>
          )}
          <div className=" row additional-back">
            <a href="/3-Company Information">
              {' '}
              <img
                src={process.env.PUBLIC_URL + '/assets/camera-back.png'}
                alt="Arrow"
              />{' '}
            </a>
            <div className="dimension-button">
              <button
                type="submit"
                className="button-save-dimension"
                onClick={() => saveDataToast()}
              >
                <div>{Localization.Save}</div>
              </button>
              <button
                className="button-continue-dimension"
                disabled={
                  props?.fetchApiData?.productType === 'pack' && fetchPack ?
                    props.saveLoader && props.packLoader :
                    props.saveLoader
                }
                onClick={fetchPack ? packClickContinue : onClickContinue}
                onMouseDownCapture={() => checkClickcontinueVal(true)}
              >
                <div className="dimension-continue">
                  {Localization.Continue}
                </div>
              </button>
            </div>
          </div>
        </form>
        {confirmDimension && (
          <div className="confirmmsg-dimension">
            {Localization.GenericMessage}
          </div>
        )}
      </div>
    </>
  );
};
export default DimensionReturnData;
