import React, {useEffect, useState, useRef} from 'react';
import classes from './FilterInput.module.css';

const FilterInput = ({attribute: item, onChange}) => {
  return (
    <>
      <div className={classes['in-the-box']}>
        <input
          className={classes['rectangle3']}
          aria-label={item.attributeId}
          name={item.attributeId}
          type={'text'}
          value={item.attributeNameValue}
          onChange={(e) => onChange({...item, attributeNameValue: e.target.value})}
        />
      </div>
    </>
  );
};

export default FilterInput;
