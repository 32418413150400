import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function emailReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.EMAIL:
      return {...state, loading: true};
    case actionTypes.EMAIL_COMPLETE:
      return {...state, loading: false, emailApiData: action.payload};
    case actionTypes.EMAIL_ERROR:
      return {...state, loading: false, errorMessage: undefined};
    default:
      return state;
  }
}
