import {HttpService} from './HttpService';
import {ARTICLE_DETAIL} from '../config/apiEndpoints';
import {getAuthToken, getUserId} from '../components/utils/AuthenticationUtils';

export async function fetchArticleDetailsService(articleCode) {
  try {
    const endpoint = ARTICLE_DETAIL+`${articleCode}`;
    const headers = {
      'Authorization': `Bearer ${getAuthToken()}`,
    };
    // return (await HttpService.get(endpoint, {headers: headers}));
    return (await HttpService.get(endpoint, {headers: headers}));
  } catch (err) {
    return {err};
  }
}
