import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/rootReducer';
import {reducer} from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';
import {configureStore, createListenerMiddleware} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
// import filterReducer from './slices/filterSlice';

const sagaMiddleware = createSagaMiddleware();
const listenerMiddleware = createListenerMiddleware();
let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
    compose;
} else {
  composeEnhancers = compose;
}
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

// const store = configureStore({
//   reducer,
//   middleware: (getDefaultMiddleware) =>
//   getDefaultMiddleware().concat(sagaMiddleware),
//   devTools: process.env.NODE_ENV === 'development',
// });
sagaMiddleware.run(rootSaga);
setupListeners(store.dispatch);

export default store;
