import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function deleteReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.DELETE:
      return {...state, loading: true};
    case actionTypes.DELETE_COMPLETE:
      return {...state, loading: false, deleteApiData: action.payload};
    case actionTypes.DELETE_ERROR:
      return {...state, loading: false, errorMessage: undefined};
    case actionTypes.DELETE_CURRENT_SESSION_SUCCESS:
        return {...state, loading: false, deleteCurrentSessionDetails: action.payload};
    case actionTypes.DELETE_CURRENT_SESSION_FAILURE:
        return {...state, loading: false, deleteCurrentSessionDetails: null};
    default:
      return state;
  }
}

export function deleteCloudinaryAssetReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.DELETE_CLOUDINARY_ASSET:
      return {...state, loading: true};
    case actionTypes.DELETE_CLOUDINARY_ASSET_COMPLETE:
      return {...state, loading: false, deleteApiData: action.payload};
    case actionTypes.DELETE_CLOUDINARY_ASSET_ERROR:
      return {...state, loading: false, errorMessage: undefined};
    default:
      return state;
  }
}
