import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import './Creation.css';
import Localization from '../../common/Localization';
import 'react-dropdown/style.css';
import {loadPrimaryHierarchy} from '../../store/actions/creationAction';
import {loadSave} from '../../store/actions/saveAction';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {loadFetchData} from '../../store/actions/fetchAction';
import {loadDeleteData} from '../../store/actions/deleteAction';
import {loadPackAttribute} from '../../store/actions/packAction';
import {loadPackSave} from '../../store/actions/savePackAction';
import Toaster from '../Shared/Toaster';
import CreationReturnData from './CreationReturnData';
export const Creation = (props) => {
  const {register, handleSubmit} = useForm();
  const history = useHistory();
  const [group, setGroup] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [productType, setProductType] = useState([]);
  const [selectgroup, setSelectGroup] = useState('');
  const [selectcategory, setSelectCategory] = useState('');
  const [selectsubcategory, setSelectSubcategory] = useState('');
  const [selectproductType, setSelectProductType] = useState('');
  const [l0Category, setL0Category] = useState('');
  const [l1Category, setL1Category] = useState('');
  const [l2Category, setL2Category] = useState('');
  const [, setL3Category] = useState('');
  const [showgroup, setShowgroup] = useState(false);
  const [showcategory, setShowCategory] = useState(false);
  const [showsubcategory, setShowSubCategory] = useState(false);
  const [showproductType, setShowProductType] = useState(false);
  const [, setConfirmCreation] = useState(false);
  const [pack, setPack] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [selectPack, setSelectPack] = useState('');
  const userId = localStorage.getItem('userId');
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
    .token;
  const [sendPackData, setSendPack] = useState({});
  const [checkClickcontinue, setcheckClickcontinue] = useState(false);
  const toasterRef = useRef(null);
  const [varient, setVarient] = useState('');
  const [msg, setMsg] = useState('');
  const [check, setCheck] = useState(false);
  const [isPreviewPage, setisPreviewPage] = useState(false);

  useEffect(() => {
    props?.fetchService({userId, token, isPreviewPage});
  }, [props.saveApiData]);

  useEffect(() => {
    if (token) {
      const query = token;
      props?.primaryHierarchyApi(query);
    } else {
      props?.fetchService({userId, token, isPreviewPage});
    }
  }, [token]);
  useEffect(() => {
    if (props?.primaryData) {
      setGroup(props?.primaryData?.l0List);
    }
  }, [props?.primaryData]);
  useEffect(() => {
    if (props?.errorApiCheck === true) {
      history.push('/ApiErrorPage');
    }
  }, [props?.errorApiCheck]);

  useEffect(() => {
    if (props?.fetchApiData && props?.fetchApiData?.l0Category !== '') {
      setSelectGroup(props?.fetchApiData?.l0CategoryName);
      setL0Category(props?.fetchApiData?.l0Category);
      const tempCategory = group?.find(
        (g) => g?.l0CategoryName === props?.fetchApiData?.l0CategoryName,
      )?.l1List;
      setCategory(tempCategory);
      setSelectCategory(props?.fetchApiData?.l1CategoryName);
      setL1Category(props?.fetchApiData?.l1Category);
      const tempSubCategory = tempCategory?.find(
        (g) => g?.l1CategoryName === props?.fetchApiData?.l1CategoryName,
      )?.l2List;
      setSubcategory(
        tempCategory?.find(
          (g) => g?.l1CategoryName === props?.fetchApiData?.l1CategoryName,
        )?.l2List,
      );
      setSelectSubcategory(props?.fetchApiData?.l2CategoryName);
      setL2Category(props?.fetchApiData?.l2Category);
      setProductType(
        tempSubCategory?.find(
          (g) => g?.l2CategoryName === props?.fetchApiData?.l2CategoryName,
        )?.productTypeList,
      );
      setL3Category(props?.fetchApiData?.productType);
      setSelectProductType(props?.fetchApiData?.productTypeName);
      props?.fetchApiData?.productTypeName === 'Pack' ?
        props?.packAttributeApi(token) && setPack(true) :
        setPack(false);
      props?.fetchApiData?.components?.length > 0 &&
        setSelectPack(props?.fetchApiData?.components?.length);
    }
  }, [props?.fetchApiData, group]);
  function saveData() {
    if (!checkClickcontinue) {
      props?.saveApi({
        userId,
        token,
        sendsaveData: {
          l0Category: l0Category,
          l0CategoryName: selectgroup,
          l1Category: l1Category,
          l1CategoryName: selectcategory,
          l2Category: l2Category,
          l2CategoryName: selectsubcategory,
          productType: productType.find(
            (g) => g.productTypeName === selectproductType,
          )?.productType,
          productTypeName: selectproductType,
        },
      });
    }
  }
  const changeGroup = (event) => {
    setSendPack({});
    setPack(false);
    const groupValue = event.target.value;
    setSelectGroup(event.target.value);
    const obj = {
      group: event.target.value,
    };
    if (event.target.value === 'Select Group') {
      setCategory([]);
      setSubcategory([]);
    } else {
      localStorage.setItem('formHeader', JSON.stringify(obj));
      const store = JSON.parse(localStorage.getItem('formHeader'));
      delete store['category'];
      delete store['subCategory'];
      delete store['productType'];
      setCategory(
        group.find((g) => g.l0CategoryName === event.target.value).l1List,
      );
      setL0Category(
        group.find((g) => g.l0CategoryName === event.target.value).l0Category,
      );
      setShowgroup(false);
    }
    if (selectgroup !== '' && groupValue !== selectgroup) {
      props?.deleteService({userId, token});
      setSelectPack('No of Component');
      setSelectCategory('');
      setSelectSubcategory('');
      setSelectProductType('');
      setSubcategory([]);
      setProductType([]);
      localStorage.removeItem('url1');
      localStorage.removeItem('url2');
      localStorage.removeItem('url3');
      localStorage.removeItem('url4');
      localStorage.removeItem('url5');
      localStorage.removeItem('url6');
      localStorage.removeItem('url7');
    }
  };
  const changeCategory = (event) => {
    setPack(false);
    setSendPack({});
    props.fetchApiData.components = '';
    const categoryValue = event.target.value;
    setSelectCategory(event.target.value);
    let temp = JSON.parse(localStorage.getItem('formHeader'));

    delete temp['subCategory'];
    delete temp['productType'];
    temp = {...temp, category: event.target.value};
    localStorage.setItem('formHeader', JSON.stringify(temp));

    if (event.target.value === 'Select Category') {
      setSubcategory([]);
      setProductType([]);
    } else {
      setSubcategory(
        category.find((g) => g.l1CategoryName === event.target.value).l2List,
      );
      setL1Category(
        category.find((g) => g.l1CategoryName === event.target.value).l1Category,
      );
      setShowCategory(false);
    }
    if (selectcategory !== '' && categoryValue !== selectcategory) {
      props?.deleteService({userId, token});
      setSelectPack('No of Component');
      setSelectSubcategory('');
      setSelectProductType('');
      setProductType([]);
      localStorage.removeItem('url1');
      localStorage.removeItem('url2');
      localStorage.removeItem('url3');
      localStorage.removeItem('url4');
      localStorage.removeItem('url5');
      localStorage.removeItem('url6');
      localStorage.removeItem('url7');
    }
  };

  const changeSubCategory = (event) => {
    setPack(false);
    setSendPack({});
    const subCategoryValue = event.target.value;
    setSelectSubcategory(event.target.value);
    let temp = JSON.parse(localStorage.getItem('formHeader'));
    delete temp['productType'];
    temp = {...temp, subCategory: event.target.value};
    localStorage.setItem('formHeader', JSON.stringify(temp));

    if (event.target.value === 'Select Sub-Category') {
      setProductType([]);
    } else {
      setProductType(
        subcategory.find((g) => g.l2CategoryName === event.target.value)
          .productTypeList,
      );
      setL2Category(
        subcategory.find((g) => g.l2CategoryName === event.target.value)
          .l2Category,
      );
      setShowSubCategory(false);
    }
    if (selectsubcategory !== '' && subCategoryValue !== selectsubcategory) {
      props?.deleteService({userId, token});
      setSelectPack('No of Component');
      setSelectProductType('');
      localStorage.removeItem('url1');
      localStorage.removeItem('url2');
      localStorage.removeItem('url3');
      localStorage.removeItem('url4');
      localStorage.removeItem('url5');
      localStorage.removeItem('url6');
      localStorage.removeItem('url7');
    }
  };
  const changeProductCategory = (event) => {
    setPack(false);
    setSendPack({});
    props?.deleteService({userId, token});
    setSelectPack('No of Component');
    setSelectProductType(event.target.value);
    localStorage.removeItem('url1');
    localStorage.removeItem('url2');
    localStorage.removeItem('url3');
    localStorage.removeItem('url4');
    localStorage.removeItem('url5');
    localStorage.removeItem('url6');
    localStorage.removeItem('url7');
    setPack(event.target.value === 'Pack' ? true : false);
    event.target.value === 'Pack' && props?.packAttributeApi(token);
    let temp = JSON.parse(localStorage.getItem('formHeader'));
    temp = {...temp, productType: event.target.value};
    localStorage.setItem('formHeader', JSON.stringify(temp));
    setShowProductType(false);
  };

  const changePack = (event) => {
    if (event.target.value !== 'No of Component') {
      setShowPack(false);
    }
    setSelectPack(event.target.value);
    const temp = event.target.value;
    const copy = props?.packData ? JSON.parse(JSON.stringify(props?.packData)) : null;
    copy.status = 'Secondary';
    const componentArr = [];
    sendPackData.components = componentArr;
    sendPackData.components[0] = props?.packData;
    for (let i = 1; i < temp; i++) {
      sendPackData.components[i] = copy;
    }
    setSendPack(sendPackData);
    if (
      selectproductType === 'Pack' &&
      (selectPack === '' || selectPack === 'No of Component')
    ) {
      props?.saveApi({
        userId,
        token,
        sendsaveData: {
          l0Category: l0Category,
          l0CategoryName: selectgroup,
          l1Category: l1Category,
          l1CategoryName: selectcategory,
          l2Category: l2Category,
          l2CategoryName: selectsubcategory,
          productType: productType.find(
            (g) => g.productTypeName === selectproductType,
          )?.productType,
          productTypeName: selectproductType,
        },
      });
      props?.savePackApi({userId, token, sendPackData});
    }
  };

  const showToastMsg = (msgs, varients) => {
    setVarient(varients);
    setMsg(msgs);
    toasterRef.current.openToast();
  };
  useEffect(() => {
    if (props?.isSuccess !== undefined) {
      if (check && props?.isSuccess) {
        showToastMsg(Localization.SuccessfulSaveToast, 'success');
        setCheck(false);
      }
      if (props?.isSuccess === false && props?.isSuccessBlank === false) {
        showToastMsg(Localization.UnsuccessfulSaveToast, 'error');
      }
    }
  }, [check, props?.isSuccess, props?.isSuccessBlank]);

  const onClickContinue = () => {
    if (
      !selectgroup &&
      !selectcategory &&
      !selectsubcategory &&
      !selectproductType &&
      !selectPack
    ) {
      setShowgroup(true);
      setShowCategory(true);
      setShowSubCategory(true);
      setShowProductType(true);
      setShowPack(true);
      setConfirmCreation(true);
    } else if (
      selectgroup &&
      setSelectCategory &&
      setSelectSubcategory &&
      selectproductType &&
      selectPack &&
      selectPack !== 'No of Component'
    ) {
      setCheck(true);
      setTimeout(() => {
        history.push('/2-Product Information');
        localStorage.setItem('url1', '2-Product Information');
      }, 2000);
      setShowgroup(false);
      setShowCategory(false);
      setShowSubCategory(false);
      setShowProductType(false);
      setShowPack(false);
      setConfirmCreation(false);
    } else if (
      selectgroup &&
      setSelectCategory &&
      setSelectSubcategory &&
      selectproductType &&
      selectproductType === 'Regular'
    ) {
      setTimeout(() => {
        history.push('/2-Product Information');
        localStorage.setItem('url1', '2-Product Information');
      }, 2000);
      setShowgroup(false);
      setShowCategory(false);
      setShowSubCategory(false);
      setShowProductType(false);
      setShowPack(false);
      setConfirmCreation(false);

      props?.saveApi({
        userId,
        token,
        sendsaveData: {
          l0Category: l0Category,
          l0CategoryName: selectgroup,
          l1Category: l1Category,
          l1CategoryName: selectcategory,
          l2Category: l2Category,
          l2CategoryName: selectsubcategory,
          productType: productType.find(
            (g) => g.productTypeName === selectproductType,
          )?.productType,
          productTypeName: selectproductType,
        },
      });
      setCheck(true);
    } else {
      if (selectgroup === '' || selectgroup === 'Select Group') {
        setShowgroup(true);
        setConfirmCreation(true);
      }
      if (selectcategory === '' || selectcategory === 'Select Category') {
        setShowCategory(true);
        setConfirmCreation(true);
      }
      if (
        selectsubcategory === '' ||
        selectsubcategory === 'Select Sub-Category'
      ) {
        setShowSubCategory(true);
        setConfirmCreation(true);
      }
      if (
        selectproductType === '' ||
        selectproductType === 'Select Product Type'
      ) {
        setShowProductType(true);
        setConfirmCreation(true);
      }
      if (
        selectproductType === 'Pack' &&
        (selectPack === '' || selectPack === 'No of Component')
      ) {
        setShowPack(true);
      } else {
        setShowPack(false);
      }
    }
  };
  return (
    <div className="rectangle">
      <CreationReturnData
        props={props}
        selectgroup={selectgroup}
        selectcategory={selectcategory}
        selectsubcategory={selectsubcategory}
        selectproductType={selectproductType}
        group={group}
        showgroup={showgroup}
        changeGroup={changeGroup}
        register={register}
      />
      <div className="white-rectangle">
        <form onSubmit={handleSubmit(onClickContinue)}>
          <div className="group-information-creation">
            {' '}
            {Localization.Groupinfo}
          </div>
          <div className="group">
            <span>
              {Localization.Group}
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            </span>{' '}
            <span>
              {' '}
              {Localization.Category}
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            </span>
            <span>
              {' '}
              {Localization.SubCategory}
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            </span>
            <span>
              {Localization.ProductType}
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            </span>
          </div>
          <div className="group1">
            <div>
              <select
                aria-label="l0Category"
                type="select"
                className="product-box-product1"
                ref={register}
                disabled={!props.primaryData}
                value={selectgroup}
                onChange={changeGroup}
                name="l0Category"
              >
                <option ref={register}>{Localization.SelectGroup}</option>
                {Array.isArray(group) &&
                  group.map((e, key) => {
                    return (
                      <option ref={register} value={e.l0CategoryName} key={key}>
                        {e.l0Category} - {e.l0CategoryName}
                      </option>
                    );
                  })}
              </select>
              {showgroup && (
                <div className="errormsg">{Localization.Field}</div>
              )}
            </div>
            <div className="">
              <select
                aria-label="l1Category"
                type="select"
                value={selectcategory}
                className="product-box-product1"
                ref={register}
                disabled={!props.primaryData}
                onChange={changeCategory}
                name="l1Category"
              >
                <option ref={register}>{Localization.SelectCategory}</option>

                {Array.isArray(category) &&
                  category.map((e, key) => {
                    return (
                      <option ref={register} value={e.l1CategoryName} key={key}>
                        {e.l1Category} - {e.l1CategoryName}
                      </option>
                    );
                  })}
              </select>
              {showcategory && (
                <div className="errormsg">{Localization.Field}</div>
              )}
            </div>
            <div>
              <select
                aria-label="l2Category"
                type="select"
                className="product-box-product1"
                ref={register}
                disabled={!props.primaryData}
                value={selectsubcategory}
                onChange={changeSubCategory}
                name="l2Category"
              >
                <option ref={register}>{Localization.SelectSubCategory}</option>

                {Array.isArray(subcategory) &&
                  subcategory.map((e, key) => {
                    return (
                      <option value={e.l2CategoryName} key={key}>
                        {e.l2Category} - {e.l2CategoryName}
                      </option>
                    );
                  })}
              </select>
              {showsubcategory && (
                <div className="errormsg">{Localization.Field}</div>
              )}
            </div>
            <div className="">
              <select
                aria-label="productType"
                type="select"
                className="product-box-product1"
                ref={register}
                disabled={!props.primaryData}
                value={selectproductType}
                onBlur={() => selectproductType === 'Regular' && saveData()}
                onChange={changeProductCategory}
                name="productType"
              >
                <option ref={register}>{Localization.SelectProductType}</option>
                {Array.isArray(productType) &&
                  productType.map((e, key) => {
                    return (
                      <option value={e.productTypeName} key={key}>
                        {e.productTypeName}
                      </option>
                    );
                  })}
              </select>
              {showproductType && (
                <div className="errormsg">{Localization.Field}</div>
              )}
              {pack ? (
                <div className="">
                  <select
                    aria-label="productType"
                    type="select"
                    className="pack-box"
                    ref={register}
                    disabled={!props.primaryData}
                    value={selectPack}
                    onChange={changePack}
                    name="productType"
                  >
                    <option selected ref={register}>
                      {Localization.NoofComponent}
                    </option>
                    <option value="2"> 2</option>
                    <option value="3"> 3</option>
                    <option value="4"> 4</option>
                    <option value="5"> 5</option>
                    <option value="6"> 6</option>
                    <option value="7"> 7</option>
                    <option value="8"> 8</option>
                    <option value="9"> 9</option>
                    <option value="10"> 10</option>
                  </select>
                  {showPack && (
                    <div className="showGroup">{Localization.PleaseSelect}</div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>

          <div>
            <div className="creation-button">
              {props?.savePackApiData ? (
                <button
                  className="button-continue-group"
                  disabled={props?.packLoader}
                >
                  <div className="creation-continue">
                    {Localization.Continue}
                  </div>
                </button>
              ) : (
                <button
                  className="button-continue-group"
                  disabled={props?.saveLoader}
                  onClick={onClickContinue}
                  onMouseDown={() => setcheckClickcontinue(true)}
                  onMouseDownCapture={() => setcheckClickcontinue(true)}
                >
                  <div className="creation-continue">
                    {Localization.Continue}
                  </div>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="all-rights-reserved-group1">
        {Localization.Disclaimer}
      </div>
      <div className="all-rights-reserved-group">{Localization.Allrights}</div>
      <Toaster ref={toasterRef} varient={varient} msg={msg} />
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    primaryData: state.creationReducer.primaryData,
    saveApiData: state.saveReducer.saveApiData,
    fetchApiData: state.fetchReducer.fetchApiData,
    deleteApiData: state.deleteReducer.deleteApiData,
    packData: state.packReducer.packData,
    savePackApiData: state.savePackReducer.loading,
    isSuccess: state.saveReducer.isSuccess,
    isSuccessBlank: state.saveReducer.isSuccessBlank,
    saveLoader: state.saveReducer.loading,
    packLoader: state.savePackReducer.loading,
    errorApiCheck: state.creationReducer.errorApiCheck,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    primaryHierarchyApi: (query) => dispatch(loadPrimaryHierarchy(query)),
    saveApi: ({userId, token, sendsaveData}) =>
      dispatch(loadSave({userId, token, sendsaveData})),
    fetchService: ({userId, token, isPreviewPage}) =>
      dispatch(loadFetchData({userId, token, isPreviewPage})),
    deleteService: ({userId, token}) =>
      dispatch(loadDeleteData({userId, token})),
    packAttributeApi: (query) => dispatch(loadPackAttribute(query)),
    savePackApi: ({userId, token, sendPackData}) =>
      dispatch(loadPackSave({userId, token, sendPackData})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Creation);
