import React, {useEffect, useState} from 'react';
import '../ProductInformation/pack.css';
import './PackItemDetails.css';
import Localization from '../../common/Localization';
import {useForm} from 'react-hook-form';
import Select from 'react-select';
import {elementRange, elementRegex, packAddData, packDeleteData} from './packItemFunctions';
import {preventNonNumericalInput} from '../Shared/nonNumericalInputFunction';
import {sanitize} from '../utils/utils';

function PackItem(props) {
  const {register} = useForm();
  const [country1, setCountry1] = useState([]);
  const userId = localStorage.getItem('userId');
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
      .token;
  const [productArray, setProductArray] = useState(null);

  useEffect(() => {
    if (props?.packData) {
      const coreAttributesR = props?.packData?.map((packItem, index) => {
        return packItem?.sections?.map((item) => {
          if (item.section === props?.linkName) {
            return item.subGroups.map((subItem, subIndex) => {
              const subelement = subItem;
              subelement.attributes = subItem?.attributes?.map(
                  (secSubItem, secSubIndex) => {
                    const element = secSubItem;
                    element.attributeNameValue = secSubItem.attributeNameValue;
                    if (element.attrNameLov!==null) {
                      element.attrNameLov.map((lovItem, lovIndex)=>{
                        lovItem['value']=lovItem.lovName;
                        lovItem['label']=lovItem.lovName;
                        return null;
                      });
                    }
                    return element;
                  },
              );
              return subelement;
            });
          }
          return null;
        });
      });
      const packAttributesR = coreAttributesR.map((item, index) => {
        return item[props?.packData?.[0]?.sections?.findIndex((p) => p.section === props?.linkName)]?.map((subItem, subIndex) => {
          return subItem;
        });
      });
      setCountry1(packAttributesR);
      const createProductArray = props?.packData?.map((packItem, index) => {
        return packItem?.sections?.map((item) => {
          if (item.section === '2-Product Information') {
            return item.subGroups.map((subItem, subIndex) => {
              const subelement = subItem;
              subelement.attributes = subItem?.attributes?.map(
                  (secSubItem, secSubIndex) => {
                    const element = secSubItem;
                    if (secSubItem.attributeNameValue===null) {
                      secSubItem.attributeNameValue='';
                    }
                    element.attributeNameValue = secSubItem.attributeNameValue;
                    if (element.attrNameLov!==null) {
                      element.attrNameLov.map((lovItem, lovIndex)=>{
                        lovItem['value']=lovItem.lovName;
                        lovItem['label']=lovItem.lovName;
                        return null;
                      });
                    }
                    return element;
                  },
              );
              return subelement;
            });
          }
          return null;
        });
      });
      const productArrayItem = createProductArray.map((item, index) => {
        return item[props?.packData?.[0]?.sections?.findIndex((p) => p.section === '2-Product Information')]?.map((subItem, subIndex) => {
          return subItem;
        });
      });

      setProductArray(productArrayItem);
    }
  }, [props.packData, props.linkName]);

  useEffect(() => {
    console.log('saving pack data....');
    if (props.packData && props.savePackApi) {
      saveData();
    }
  }, [country1]);

  const negativeAllow=(evt)=>{
    preventNonNumericalInput(evt);
  };
  const CustomStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? '#49A7A2' : isSelected ? '#49A7A2' : isFocused ? '#E0E3E4' : null,
        color: isDisabled ? '#ffffff' : isSelected ? '#ffffff ' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };
  const getCountryVal = (country1Val) => {
    setCountry1(country1Val);
  };
  const addData = (item, indexPos, index, subGroupIdentifierName) => {
    packAddData({item, index, indexPos, country1, getCountryVal, subGroupIdentifierName});
  };
  const getCountryDelVal = (country_1) => {
    setCountry1(country_1);
  };
  const deleteData = (item, indexPos, index, subGroupIdentifierName) => {
    packDeleteData({item, index, indexPos, country1, getCountryDelVal, subGroupIdentifierName});
  };
  function saveData() {
    props.savePackApi({userId, token, sendPackData: {'components': props.packData}});
  }
  const changeData = (selectedItem, indexPos, value) => {
    const packAttributesR = country1.map((item, index) => {
      return item?.map((subItem, subIndex)=>{
        const subelement = subItem;
        subelement.attributes = subItem.attributes.map(
            (secSubItem, secSubIndex) => {
              const element = secSubItem;
            if (element.sequence) {
                if (
                  selectedItem.sequence === element.sequence && indexPos === index &&
              selectedItem.attributeId === element.attributeId
                ) {
                  if (element.attrNameLov != null) {
                    element.attrNameLov.map((lovItem, lovIndex) => {
                      if (element.attributeNameValue === lovItem.lovName) {
                        lovItem.isDisabled = false;
                      }
                      if (value === lovItem.lovName) {
                        lovItem.isDisabled = true;
                      }

                      return lovItem;
                    });
                  }
                  if (element.range != null) {
                    elementRange({element, value});
                    return element;
                  }
                  if (element.validRegex != null) {
                    elementRegex({element, value, subelement});
                    return element;
                  }
                  element.attributeNameValue = value;
                }
              } else if (selectedItem.attributeId === element.attributeId && indexPos === index ) {
                // to disable select box option item without section
                if (element.uom !== null) {
                  if (element.uom === 'cm') {
                    subItem.attributes[secSubIndex + 1].attributeNameValue =
                  '' + Number(value) *0.393701;
                  }
                  if (element.uom === 'Inches') {
                    subItem.attributes[secSubIndex - 1].attributeNameValue =
                  '' + Number(value) / 2.54;
                  }
                }
                if (element.attrNameLov != null) {
                  element.attrNameLov.map((lovItem, lovIndex) => {
                    if (value === lovItem.lovName) {
                      lovItem.isDisabled = true;
                    } else {
                      lovItem.isDisabled = false;
                    }
                    return lovItem;
                  });
                }
                if (element.range != null) {
                  elementRange({element, value});
                  return element;
                }
                if (element.validRegex != null) {
                  elementRegex({element, value, subelement});
                  return element;
                }
                element.attributeNameValue = value;
              }
              return element;
            },
        );
        return subelement;
      });
    });
    setCountry1(packAttributesR);
  };
  const getDisplayBox = (fieldType, item, index, subGroupIdentifierName, indexPos) => {
    if (item.fieldType === 'Select') {
      return (
        <div>
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          {item.multiValued === 'Y' ? (
            <div className="in-the-box">
              <Select className="select-box inthebox" options={item.attrNameLov} maxMenuHeight={200}
               aria-label={item.attributeId}
               value={{value: item.attributeNameValue, label: item.attributeNameValue}}
                styles={CustomStyles}
                onBlur={saveData}
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  changeData(item, indexPos, e.lovName);
                }}
              />
              {subGroupIdentifierName !== 'In The Box' && (
                <div className="plus-item">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                    alt="plus"
                    onClick={() => addData(item, indexPos, index, subGroupIdentifierName)}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <Select className="select-box inthebox" aria-label={item.attributeId}
               options={item.attrNameLov} maxMenuHeight={200} value={{value: item.attributeNameValue, label: item.attributeNameValue}}
                styles={CustomStyles}
                onBlur={saveData}
                onChange={(e) => {
                  changeData(item, indexPos, e.lovName);
                }}
              />
              {subGroupIdentifierName !== 'In The Box' &&
                  item.sequence &&
                  item.sequence !== 0 ? (
                <div className="delete-item">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                    alt="delete"
                    onClick={() =>
                      deleteData(item, indexPos, index, subGroupIdentifierName)
                    }
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          )}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">{Localization.Range} {item.range}</div>
          )}
          {item.isInvalid && (
            <div className="errormsg">
              {Localization.Character}
            </div>
          )}
          {(item.attributeNameValue === ''|| item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showDetails && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else if (item.fieldType === 'Textarea') {
      return (
        <div className="comlabel1">
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          <textarea
            aria-label={item.attributeId}
            name={item.attributeId}
            onBlur={saveData}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            value={item.attributeNameValue}
            className="companyArea"
            ref={register}
            readOnly={item.readOnly === 'Y' ? true : false}
            onChange={(e) => {
              changeData(item, indexPos, sanitize(e.target.value));
            }}
          />{' '}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">{Localization.Range} {item.range}</div>
          )}
          {item.isInvalid && (
            <div className="errormsg">
              {Localization.Character}
            </div>
          )}
          {(item.attributeNameValue === ''|| item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showDetails && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else if (item.fieldType === 'Radio') {
      return (
        <div className="comlabel1">
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          {item.attrNameLov.map((radioItem, radioIndex) => {
            return (
              <span key={radioIndex}>
                <input
                  aria-label={item.attributeId}
                  type="radio"
                  onBlur={saveData}
                  value={radioItem.lovName}
                  checked={item.attributeNameValue === radioItem.lovName}
                  readOnly={item.readOnly === 'Y' ? true : false}
                  onChange={(e) => {
                    changeData(item, indexPos, e.target.value);
                  }}
                />
                <span className="radiolabel">{radioItem.lovName}</span>
              </span>
            );
          })}{' '}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">{Localization.Range} {item.range}</div>
          )}
          {item.isInvalid && (
            <div className="errormsg">
              {Localization.Character}
            </div>
          )}
          {(item.attributeNameValue === ''|| item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showDetails && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else {
      return (
        <div className="">
          <div>
            {item.attributeName}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          {item.multiValued === 'Y' ? (
            <div className="in-the-box">
              {' '}
              <input
                aria-label={item.attributeId}
               name={item.attributeId}
                onBlur={saveData}
                type={item.attributeDataType === 'String' ? 'text' : 'number'}
                value={item.attributeNameValue}
                readOnly={item.readOnly === 'Y' ? true : false}
                className={
                  item.attributeName === 'Qty' ? 'qty-item-box' : 'rectangle3'
                }
                onKeyDown={(evt) => {
                  if (item.attributeDataType!== 'String')(evt.key === 'e' || evt.key === 'E' ||evt.key==='+'||evt.key==='-') && evt.preventDefault()&& negativeAllow(evt);
                }}
                onKeyPress= {(evt)=>{
                  item.attributeDataType !== 'String' && negativeAllow(evt);
                }}
                ref={register}
                onChange={(e) => {
                  changeData(item, indexPos, sanitize(e.target.value));
                }}

              />
              <div
                className={
                  item.attributeName === 'Qty' ? 'pack-plus-item-Qty' : 'plus-item'
                }
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                  alt="plus"
                  onClick={() => addData(item, indexPos, index, subGroupIdentifierName)}
                />
              </div>
            </div>
          ) : (
            <div>
              {item.attributeName === '' ? (
                <div className="space"></div>
              ) : (
                <div></div>
              )}
              <input
                aria-label={item.attributeId}
                name={item.attributeId}
                onBlur={saveData}
                type={item.attributeDataType === 'String' ? 'text' : 'number'}
                value={item.attributeNameValue}
                onKeyDown={(evt) => {
                  if (item.attributeDataType!== 'String')(evt.key === 'e' || evt.key === 'E' ||evt.key==='+'||evt.key==='-') && evt.preventDefault()
                  ;
                }}
                onKeyPress= {(evt)=>{
                  item.attributeDataType !== 'String' && negativeAllow(evt);
                }}
                className={
                  item.readOnly ?
                    'hiddenBox' :
                    subGroupIdentifierName === 'In The Box' &&
                    item.attributeId !== 'SG-InTheBox-OthersDetails' ?
                    'qty-item-box' :
                    'rectangle3'
                }
                ref={register}
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  changeData(item, indexPos, sanitize(e.target.value));
                }}
              />

                {(item.sequence && item.sequence !== 0 && item.attributeId!== 'SG-InTheBox-OthersDetails')? (
                <div
                  className={
                    subGroupIdentifierName === 'In The Box' ?
                      'pack-delete-item-Qty' :
                      'delete-item'
                  }
                >
                  <img
                    className={item.attributeId==='SG-InTheBox-MainProduct-Quantity'?'delete-img':'delete-img1'}
                    src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                    alt="delete"
                    onClick={() =>
                      deleteData(item, indexPos, index, subGroupIdentifierName)
                    }
                  />
                </div>
              ) : (
                <span>

                </span>
              )}
            </div>
          )}{item.attributeName === 'Screen Size(in CM)' ||
          item.attributeName === '' ? (
            <span>
              <div className="arrow-icon-item">
                <img
                  src={process.env.PUBLIC_URL + 'assets/arrow.png'}
                  alt="arrow"
                />
              </div>
            </span>
          ) : (
            ''
          )}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">{Localization.Range} {item.range}</div>
          )}
          {item.isInvalid && (
            <div className="errormsg">
              {Localization.Character}
            </div>
          )}
          {(item.attributeNameValue === ''|| item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showDetails && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    }
  };
  return (
    <div>
      {country1 &&
        Array.isArray(country1) &&
        country1.length > 0 &&
        country1.map((item, indexes) => {
          return (
            <div className="packColor">
              <div className="pack-component-dimension">{Localization.Component}{indexes+1} - {productArray[indexes][0].attributes[0].attributeNameValue}</div>
              <div>
                {item?.map((items, index) => {
                  return (
                    <div className="pack-rectangle">
                      <div className="row item-information1">
                        {items.subGroupIdentifierName}
                      </div>
                      <div className="row company-box1" style={{gap: '0'}}>
                        {items.attributes.map((subItem, subIndex) => {
                          return (
                            <div className="item-pack col-sm-6 col-md-6 col-lg-3 col-xs-6">
                              {getDisplayBox(
                                  subItem.fieldType,
                                  subItem,
                                  subIndex,
                                  items.subGroupIdentifierName,
                                  indexes,
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}
export default PackItem;
