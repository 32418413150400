import {put, takeLatest, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {emailApi} from '../.././services/emailServices';


export function* emailWatcherAsyn(action) {
  try {
    const response = yield call(emailApi, action.payload.token, action.payload.data, action.payload.articleCode);
    if (response.status >= 200 && response.status < 300) {
      const emailApiData = yield response.data;
      yield put({type: actionTypes.EMAIL_COMPLETE, payload: emailApiData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.EMAIL_ERROR, payload: error});
  }
}
export function* emailWatcher() {
  yield takeLatest(actionTypes.EMAIL, emailWatcherAsyn);
}

export const emailSaga=[
  emailWatcher(),
];

