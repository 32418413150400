import React, {useEffect, useState} from 'react';
import classes from './ArticleDetails.module.css';
import {
  Box,
  Unstable_Grid2 as Grid,
  Typography,
  Skeleton,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useDispatch, useSelector} from 'react-redux';
import {loadArticleDetails} from '../../../store/actions/fetchAction';
import Section from './Section';
import config from '../../../config/config';
import {IMAGE_BASE_URL} from '../../utils/Constants';

const ArticleDetails = ({article, onCancel, onConfirm}) => {
  console.log(article);
  const dispatch = useDispatch();
  const details = useSelector((state) => state.fetchArticleReducer);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {article: product} = details;
  useEffect(() => {
    if (article.articleCode) {
      dispatch(loadArticleDetails({articleCode: article.articleCode}));
    }
  }, [article]);
  const thumbnails =
    article?.images &&
    article?.images.filter(
      (img) =>
        !img.includes('.pdf') && !img.includes('.mp3') && !img.includes('.mov'),
    );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          flexGrow: 1,
        }}
      >
        <Grid container columns={12} spacing={{xs: 0, md: 0, lg: 0}}>
          <Grid lg={2} md={3} xs={4} sm={5}>
            <img
              src={
                article.images &&
                article.images.length > 0 &&
                thumbnails &&
                thumbnails.length > 0 ?
                  config.cloudName.includes('prod') ?
                    `https://media.croma.com/image/upload/${thumbnails[0]}` :
                    `${IMAGE_BASE_URL}/image/upload/${thumbnails[0]}` :
                  '/assets/undraw_xmas_snowman_k-7-yf.svg'
              }
              width={'100%'}
              // className={classes.selector}
              alt={article.articleCode}
            />
          </Grid>
          <Grid lg={9} md={8} xs={7} sm={6} sx={{marginLeft: '10px'}}>
            <Stack justifyContent={'space-between'} sx={{height: '100%'}}>
              <header>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{fontWeight: '600', fontFamily: 'inherit'}}
                >
                  {article.articleCode}
                </Typography>
                <Box sx={{marginTop: '3px'}}>
                  {details.loading && (
                    <Skeleton
                      variant="rectangular"
                      width={'100%'}
                      height={20}
                    />
                  )}
                  {details.article && (
                    <Typography
                      id="modal-modal-description"
                      variant="subtitle1"
                      // component="h2"
                      sx={{
                        fontWeight: '600',
                        fontFamily: 'inherit',
                        color: '#0F8686',
                      }}
                    >
                      {product?.productName ?
                        product?.productName :
                        product?.sections &&
                          product?.sections.length > 0 &&
                          product?.sections[0]?.subGroups[0]?.attributes[0]
                            .attributeNameValue}
                    </Typography>
                  )}
                </Box>
              </header>
              <Box>
                {details.loading && (
                  <Skeleton variant="rectangular" width={'100%'} height={20} />
                )}
                <Typography
                  id="modal-modal-description"
                  variant="subtitle2"
                  // component="h2"
                  sx={{
                    fontWeight: '600',
                    fontFamily: 'inherit',
                    // color: '#0F8686',
                    color: '#7B7B7B',
                  }}
                >
                  {product?.createdBy}
                </Typography>
                <Typography
                  id="modal-modal-description"
                  variant="subtitle2"
                  // component="h2"
                  sx={{
                    fontWeight: '600',
                    fontFamily: 'inherit',
                    color: '#0F8686',
                    flexDirection: 'column-reverse',
                    color: '#7B7B7B',
                  }}
                >
                  {product?.codeCreationDate}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          {/* <Grid lg={4} md={6} xs={12} sm={12}>
            Time Stamp
          </Grid> */}
        </Grid>

        <Stack justifyContent={'space-evenly'} mt={2}>
          <Section
            product={product}
            expanded={expanded}
            panel={'panel1'}
            onChange={handleChange}
            label={'Group Information'}
            section={{
              section: 'creation',
              subGroups: [
                {
                  subGroupIdentifier: 'ph',
                  subGroupIdentifierName: null,
                  attributes: [
                    {
                      attributeId: 'l0Category',
                      attributeName: 'Group',
                      attributeNameValue: product?.l0CategoryName,
                      mandatory: 'Y',
                    },
                    {
                      attributeId: 'l1Category',
                      attributeName: 'Category',
                      attributeNameValue: product?.l1CategoryName,
                      mandatory: 'Y',
                    },
                    {
                      attributeId: 'l2Category',
                      attributeName: 'Sub-Category',
                      attributeNameValue: product?.l2CategoryName,
                      mandatory: 'Y',
                    },
                    {
                      attributeId: 'productType',
                      attributeName: 'Product Type',
                      attributeNameValue: product?.productTypeName,
                      mandatory: 'Y',
                    },
                  ],
                },
              ],
            }}
          />

          {product?.sections &&
            product?.sections.length > 0 &&
            product?.sections.map((section, i) => (
              <Section
                product={product}
                expanded={expanded}
                panel={`${'panel' + (i + 2)}`}
                onChange={handleChange}
                label={section.section.substr(2)}
                section={section}
                {...(product?.components &&
                  product?.components.length > 0 && {
                    components: product?.components?.map(
                      (component) =>
                        component?.sections
                          .filter((sec) => sec.section === section.section)
                          .map((c) => {
                            return {...c, status: component.status};
                          })[0],
                    ),
                  })}
                key={section.section}
              />
            ))}

          <Section
            product={product}
            expanded={expanded}
            panel={'panel7'}
            onChange={handleChange}
            label={'File Upload'}
            section={{
              section: 'fileupload',
              subGroups: [
                {
                  subGroupIdentifier: 'fu',
                  subGroupIdentifierName: null,
                  attributes: [
                    {
                      attributeId: 'images',
                      attributeName: 'Images',
                      attributeNameValue: product?.images,
                      mandatory: 'N',
                    },
                    {
                      attributeId: 'videos',
                      attributeName: 'Videos',
                      attributeNameValue: product?.videos,
                      mandatory: 'N',
                    },
                    {
                      attributeId: 'manual',
                      attributeName: 'Manuals',
                      attributeNameValue: product?.manual,
                      mandatory: 'N',
                    },
                    {
                      attributeId: 'others',
                      attributeName: 'Others',
                      attributeNameValue: product?.others,
                      mandatory: 'N',
                    },
                  ],
                },
              ],
            }}
          />
          {/* <Accordion
            elevation={0}
            sx={{marginTop: 1}}
            expanded={expanded === 'panel7'}
            onChange={handleChange('panel7')}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon color="#0F8686" sx={{color: '#0F8686'}} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{width: '100%', background: '#CBF3F3'}}
            >
              <Typography
                sx={{
                  color: '#0F8686',
                  fontWeight: '600',
                  fontFamily: 'inherit',
                }}
              >
                File Upload
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion> */}
        </Stack>
      </Box>
    </>
  );
};

export default ArticleDetails;
