import * as actionTypes from './actionsTypes';

export const loadPackSave=({userId, token, sendPackData})=>{
  return {
    type: actionTypes.SAVE_PACK,
    payload: {userId: userId, token: token, data: sendPackData},
  };
};

export const fetchComponent = ({code, l2CategoryCode, brandName}) => {
  return {
    type: actionTypes.GET_COMPONENT,
    payload: {code, l2CategoryCode, brandName},
  };
};
