import {put, takeEvery, call, takeLatest} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {fetchArticleDetailsService} from '../../services/fetchArticleServices';

export function* fetchArticleWatcherAsyn(action) {
  try {
    const response = yield call(
      fetchArticleDetailsService,
      action.payload.articleCode,
    );
    if (response.status >= 200 && response.status < 300) {
      const fetchApiData = yield response.data;
      if (fetchApiData?.productType.toLowerCase() === 'pack') {
        if (fetchApiData?.sections && fetchApiData?.sections.length > 0) {
          fetchApiData?.sections.splice(2, 0, {
            section: '4-Dimensions',
            subGroups: [],
          });
        }
      }
      yield put({type: actionTypes.FETCH_ARTICLE_DETAILS_COMPLETE, payload: fetchApiData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.FETCH_ARTICLE_DETAILS_ERROR, payload: error});
  }
}
export function* fetchArticleWatcher() {
  yield takeLatest(actionTypes.FETCH_ARTICLE_DETAILS, fetchArticleWatcherAsyn);
}

export const fetchArticleSaga = [fetchArticleWatcher()];
