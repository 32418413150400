import React, {useEffect, useState, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
  LocalizationProvider,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
}));

const FilterDate = ({attribute, onChange}) => {
  const defaulStartDateString = '09/09/1970 00:00:00';
  const {filters} = useSelector((state) => state.filters);
  const creationStartDate = filters && filters['creationStartDate'];
  const creationEndDate = filters && filters['creationEndDate'];
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [maxDateTime, setMaxDateTime] = useState(new Date());
  const [minDateTime, setMinDateTime] = useState(
    new Date(defaulStartDateString),
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange({
      ...attribute,
      attributeNameValue: date.toLocaleString(),
    });
  };

  useEffect(() => {
    if (
      attribute.attributeId === 'creationStartDate' &&
      creationEndDate &&
      creationEndDate.attributeNameValue &&
      Date.parse(creationEndDate.attributeNameValue) > 0
    ) {
      setMaxDateTime(new Date(creationEndDate.attributeNameValue));
    }
  }, [creationEndDate]);

  useEffect(() => {
    if (
      attribute.attributeId === 'creationEndDate' &&
      creationStartDate &&
      creationStartDate.attributeNameValue &&
      Date.parse(creationStartDate.attributeNameValue) > 0
    ) {
      setMinDateTime(new Date(creationStartDate.attributeNameValue));
    }
  }, [creationStartDate]);

  const classes = useStyles();
  return (
    <>
      <form className={classes.container} noValidate>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            defaultValue={dayjs(new Date())}
            sx={{width: '100%', backgroundColor: '#fff', border: 'none'}}
            format="DD/MM/YYYY hh:mm:ss A"
            {...(attribute.attributeId === 'creationStartDate' &&
            creationEndDate &&
            Date.parse(creationEndDate.attributeNameValue) > 0 ?
              {maxDateTime: dayjs(maxDateTime)} :
              {maxDateTime: dayjs(new Date())})}
            orientation="landscape"
            autoFocus
            onChange={handleDateChange}
            value={dayjs(selectedDate)}
            {...(attribute.attributeId === 'creationEndDate' &&
            creationStartDate &&
            Date.parse(creationStartDate.attributeNameValue) > 0 ?
              {minDateTime: dayjs(minDateTime)} :
              {minDateTime: dayjs(new Date(defaulStartDateString))})}
          />
        </LocalizationProvider>
      </form>
    </>
  );
};

export default FilterDate;
