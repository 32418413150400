import React, {useEffect, useState, useRef} from 'react';
import {
  BlobProvider,
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
  Image,
} from '@react-pdf/renderer';
import './Thankyou.css';
import Localization from '../../common/Localization';
import Layout from '../Shared/Layout/Layout';
import WelcomeHeader from '../Shared/WelcomeHeader/WelcomeHeader';
import {loadFetchData} from '../../store/actions/fetchAction';
import {loadArticle} from '../../store/actions/articleCodeAction';
import {loadDeleteData} from '../../store/actions/deleteAction';
import {loadEmail} from '../../store/actions/emailAction';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Modal from 'react-modal';
import Toaster from '../Shared/Toaster';
import {styles} from './thankyouStyles';
import {logoutAction} from '../../store/actions/loginAction';
import {
  AUTO_LOGOUT_IN_MILLISECONDS,
  AUTO_LOGOUT_WARNING_IN_MILLISECONDS,
} from '../utils/Constants';

const Thankyou = ({
  fetchApiData,
  fetchService,
  articleApiData,
  articleService,
  emailService,
  isSuccess,
  ...props
}) => {
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
    .token;
  const userId = localStorage.getItem('userId');
  const [callapi, setCallapi] = useState(true);
  const history = useHistory();
  const componentRef = useRef();
  const [, setarticleCodeData] = useState('');
  const [pdfReady, setPdfReady] = useState(false);
  const [check, setCheck] = useState(false);
  const toasterRef = useRef(null);
  const [varient, setVarient] = useState('');
  const [msg, setMsg] = useState('');
  const [isPreviewPage, setisPreviewPage] = useState(false);

  useEffect(() => {
    if (token && callapi) {
      // !fetchApiData && fetchService({userId, token, isPreviewPage});
      // fetchService({userId, token, isPreviewPage});
      // articleService({userId, token});
      setCallapi(false);
    }
  }, [callapi]);

  useEffect(() => {
    if (localStorage.getItem('url7') !== 'thankyou') {
      history.push('/fileupload');
    }
  }, [history]);

  useEffect(() => {
    if (articleApiData && fetchApiData) {
      setarticleCodeData(articleApiData);
      setPdfReady(true);
    }
  }, [articleApiData, fetchApiData]);

  useEffect(() => {
    if (isSuccess !== undefined) {
      if (isSuccess) {
        showToastMsg(Localization.SuccessfulSaveToast, 'success');
        setCheck(false);
      }
      if (isSuccess === false && props.isSuccessBlank === false) {
        showToastMsg(Localization.UnsuccessfulSaveToast, 'error');
      }
      return () => {
        if (history.action === 'POP') {
          logout();
        }
      };
    }
  }, [check, isSuccess, history.action]);

  const showToastMsg = (msgs, varients) => {
    setVarient(varients);
    setMsg(msgs);
    toasterRef.current.openToast();
  };
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '50%',
      left: '47%',
      right: 'auto',
      bottom: 'auto',
      height: '25%',
      width: 350,
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden',
    },
  };
  const [logoutWarning, setLogoutWarning] = useState(false);
  const [evt, setEvt] = React.useState(true);

  const warnTimeout = 0;
  let logoutTimeout = 0;

  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];
  useEffect(() => {
    if (evt) {
      // eslint-disable-next-line guard-for-in
      for (const i in events) {
        typeof window !== 'undefined' &&
          window.addEventListener(events[i], resetTimeout);
      }
      setEvt(false);
    }

    if (articleApiData && fetchApiData) {
      setarticleCodeData(articleApiData);
      setPdfReady(true);
    }
  }, []);
  const logout = () => {
    setLogoutWarning(false);
    props.logoutService();
    if (typeof window !== 'undefined') {
      // window.location.href = '/';
    }
  };

  const setTimeoutVP = () => {
    const urlPage = typeof window !== 'undefined' && window.location.href;
    if (!urlPage.includes('login')) {
      // logoutTimeout = setTimeout(logout, 10 * 60 * 1000);
      logoutTimeout = setTimeout(logout, AUTO_LOGOUT_IN_MILLISECONDS);
      warnTimeout = setTimeout(warn, AUTO_LOGOUT_WARNING_IN_MILLISECONDS);
    }
  };
  const clearTimeoutVP = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  const warn = () => {
    setLogoutWarning(true);
    for (const i in events) {
      //eslint-disable-line
      if (Object.hasOwn(i, events)) {
        window.removeEventListener(events[i], resetTimeout);
      }
    }
  };

  const resetTimeout = () => {
    clearTimeoutVP();
    setTimeoutVP();
  };
  const newProduct = () => {
    setPdfReady(false);
    setarticleCodeData('');
    window.localStorage.removeItem('formHeader');
    window.location.href = '/creation';
    localStorage.removeItem('url1');
    localStorage.removeItem('url2');
    localStorage.removeItem('url3');
    localStorage.removeItem('url4');
    localStorage.removeItem('url5');
    localStorage.removeItem('url6');
    localStorage.removeItem('url7');
  };

  const proceed = () => {
    setLogoutWarning(false);
    // eslint-disable-next-line guard-for-in
    for (const i in events) {
      typeof window !== 'undefined' &&
        window.addEventListener(events[i], resetTimeout);
    }

    resetTimeout();
  };
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <View style={styles.omega} wrap={true}>
          <View style={styles.ome} wrap={true}>
            <Image src={process.env.PUBLIC_URL + '/assets/form.png'} alt="" />
          </View>
          <View style={styles.omega1} wrap={true}>
            <Text style={styles.alphabh} wrap={true}>
              {Localization.ProductDetailsConfirmation}
            </Text>
            <View style={styles.alpha} wrap={true}>
              <Text wrap={true}>
                {
                  JSON.parse(localStorage.getItem('myValueInLocalStorage'))
                    ?.data?.username
                }
              </Text>
            </View>
          </View>
        </View>
        <View wrap={true}>
          <View wrap={true}>
            <View style={styles.strip} wrap={true}>
              <Text style={styles.FileStructure}>{Localization.Groupinfo}</Text>
            </View>
          </View>
          {
            <View style={styles.FileData} wrap={true}>
              <View style={styles.groupfile} wrap={true}>
                <Text style={styles.keyText} wrap={true}>
                  {Localization.Group}
                </Text>
                <Text style={styles.keyText} wrap={true}>
                  {Localization.Category}
                </Text>
                <Text style={styles.keyText} wrap={true}>
                  {Localization.SubCategory}
                </Text>
                <Text style={styles.keyText} wrap={true}>
                  {Localization.ProductType}
                </Text>
              </View>
              <View style={styles.groupvalue} wrap={true}>
                <Text style={styles.valueText} wrap={true}>
                  {fetchApiData.l0CategoryName}
                </Text>
                <Text style={styles.valueText} wrap={true}>
                  {fetchApiData.l1CategoryName}
                </Text>
                <Text style={styles.valueText} wrap={true}>
                  {fetchApiData.l2CategoryName}
                </Text>
                <Text style={styles.valueText} wrap={true}>
                  {fetchApiData.productTypeName}
                </Text>
              </View>
            </View>
          }
        </View>
        <View wrap={true}>
          <View style={styles.strip} wrap={true}>
            <View wrap={true}>
              <Text style={styles.FileStructure}>
                {Localization.Productinfo}
              </Text>
            </View>
          </View>

          <View style={styles.FileData} wrap={true}>
            {fetchApiData &&
              fetchApiData?.sections?.map((item, index) => {
                if (item.section === '2-Product Information') {
                  return item.subGroups?.map((subItem, subIndex) => {
                    return (
                      <View style={styles.dummy4} key={subIndex} wrap={true}>
                        <Text style={styles.header} wrap={true}>
                          {subItem.subGroupIdentifierName}
                        </Text>

                        <View style={styles.flexDirectionRow} wrap={true}>
                          {subItem.attributes.map((secSubItem, secSubIndex) => {
                            return (
                              <View
                                style={styles.dummy1}
                                key={secSubIndex}
                                wrap={true}
                              >
                                <Text style={styles.dummy2} wrap={true}>
                                  {secSubItem.attributeName}
                                </Text>
                                <Text style={styles.dummy3} wrap={true}>
                                  {secSubItem.attributeNameValue}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    );
                  });
                }
                return null;
              })}
            {fetchApiData?.components &&
              fetchApiData?.components?.map((item, index) => {
                return item?.sections?.map((mainItem, indexes) => {
                  if (mainItem?.section === '2-Product Information') {
                    return (
                      <View style={styles.packColor} wrap={true}>
                        {mainItem.subGroups.map((subItem, subIndex) => {
                          return (
                            <View
                              style={styles.dummy4}
                              key={subIndex}
                              wrap={true}
                            >
                              <Text style={styles.header} wrap={true}>
                                {subItem.subGroupIdentifierName}
                              </Text>

                              <View style={styles.flexDirectionRow} wrap={true}>
                                {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <View
                                        style={styles.dummy1}
                                        key={secSubIndex}
                                        wrap={true}
                                      >
                                        <Text style={styles.dummy2} wrap={true}>
                                          {secSubItem.attributeName}
                                        </Text>
                                        <Text style={styles.dummy3} wrap={true}>
                                          {secSubItem.attributeNameValue}
                                        </Text>
                                      </View>
                                    );
                                  },
                                )}
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    );
                  }
                  return null;
                });
              })}
          </View>
        </View>
        <View>
          <View style={styles.strip} wrap={true}>
            <View>
              <Text style={styles.FileStructure} wrap={true}>
                {Localization.Companyinfo}
              </Text>
            </View>
          </View>

          <View style={styles.FileData} wrap={true}>
            {fetchApiData &&
              fetchApiData?.sections?.map((item, index) => {
                if (item.section === '3-Company Information') {
                  return item.subGroups.map((subItem, subIndex) => {
                    return (
                      <View style={styles.dummy4} key={subIndex} wrap={true}>
                        <Text style={styles.header} wrap={true}>
                          {subItem.subGroupIdentifierName}
                        </Text>

                        <View style={styles.flexDirectionRow} wrap={true}>
                          {subItem.attributes.map((secSubItem, secSubIndex) => {
                            return (
                              <View
                                style={styles.dummy1}
                                key={secSubIndex}
                                wrap={true}
                              >
                                <Text style={styles.dummy2} wrap={true}>
                                  {secSubItem.attributeName}
                                </Text>
                                <Text style={styles.dummy3} wrap={true}>
                                  {secSubItem.attributeNameValue}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    );
                  });
                }
                return null;
              })}
            {fetchApiData?.components &&
              fetchApiData?.components?.map((item, index) => {
                return item?.sections?.map((mainItem, indexes) => {
                  if (mainItem?.section === '3-Company Information') {
                    return (
                      <View style={styles.packColor} wrap={true}>
                        {mainItem.subGroups.map((subItem, subIndex) => {
                          return (
                            <View
                              style={styles.dummy4}
                              key={subIndex}
                              wrap={true}
                            >
                              <Text style={styles.header} wrap={true}>
                                {subItem.subGroupIdentifierName}
                              </Text>

                              <View style={styles.flexDirectionRow} wrap={true}>
                                {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <View
                                        style={styles.dummy1}
                                        key={secSubIndex}
                                        wrap={true}
                                      >
                                        <Text style={styles.dummy2} wrap={true}>
                                          {secSubItem.attributeName}
                                        </Text>
                                        <Text style={styles.dummy3} wrap={true}>
                                          {secSubItem.attributeNameValue}
                                        </Text>
                                      </View>
                                    );
                                  },
                                )}
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    );
                  }
                  return null;
                });
              })}
          </View>
        </View>
        <View>
          <View style={styles.strip} wrap={true}>
            <View>
              <Text style={styles.FileStructure}>
                {Localization.Dimensions}
              </Text>
            </View>
          </View>

          <View style={styles.FileData} wrap={true}>
            {fetchApiData &&
              fetchApiData?.sections?.map((item, index) => {
                if (item.section === '4-Dimensions') {
                  return item.subGroups.map((subItem, subIndex) => {
                    return (
                      <View style={styles.dummy4} key={subIndex} wrap={true}>
                        <Text style={styles.header} wrap={true}>
                          {subItem.subGroupIdentifierName}
                        </Text>

                        <View style={styles.flexDirectionRow} wrap={true}>
                          {subItem.attributes.map((secSubItem, secSubIndex) => {
                            return (
                              <View
                                style={styles.dummy1}
                                key={secSubIndex}
                                wrap={true}
                              >
                                <Text style={styles.dummy2} wrap={true}>
                                  {secSubItem.attributeName}
                                </Text>
                                <Text style={styles.dummy3} wrap={true}>
                                  {secSubItem.attributeNameValue}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    );
                  });
                }
                return null;
              })}
            {fetchApiData?.components &&
              fetchApiData?.components?.map((item, index) => {
                return item?.sections?.map((mainItem, indexes) => {
                  if (mainItem?.section === '4-Dimensions') {
                    return (
                      <View style={styles.packColor} wrap={true}>
                        {mainItem.subGroups.map((subItem, subIndex) => {
                          return (
                            <View
                              style={styles.dummy4}
                              key={subIndex}
                              wrap={true}
                            >
                              <Text style={styles.header} wrap={true}>
                                {subItem.subGroupIdentifierName}
                              </Text>

                              <View style={styles.flexDirectionRow} wrap={true}>
                                {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <View
                                        style={styles.dummy1}
                                        key={secSubIndex}
                                        wrap={true}
                                      >
                                        <Text style={styles.dummy2} wrap={true}>
                                          {secSubItem.attributeName}
                                        </Text>
                                        <Text style={styles.dummy3} wrap={true}>
                                          {secSubItem.attributeNameValue}
                                        </Text>
                                      </View>
                                    );
                                  },
                                )}
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    );
                  }
                  return null;
                });
              })}
          </View>
        </View>
        <View wrap={true}>
          <View style={styles.strip} wrap={true}>
            <View wrap={true}>
              <Text style={styles.FileStructure}>
                {Localization.Itemdetails}
              </Text>
            </View>
          </View>

          <View style={styles.FileData} wrap={true}>
            {fetchApiData &&
              fetchApiData?.sections?.map((item, index) => {
                if (item.section === '5-Item Details') {
                  return item.subGroups?.map((subItem, subIndex) => {
                    return (
                      <View style={styles.dummy4} key={subIndex} wrap={true}>
                        <Text style={styles.header} wrap={true}>
                          {subItem.subGroupIdentifierName}
                        </Text>

                        <View style={styles.flexDirectionItem} wrap={true}>
                          {subItem.attributes.map((secSubItem, secSubIndex) => {
                            return (
                              <View
                                style={styles.innerViewItem}
                                key={secSubIndex}
                                wrap={true}
                              >
                                <Text style={styles.dummy2} wrap={true}>
                                  {secSubItem.attributeName}
                                </Text>
                                <View style={{width: 120}}>
                                  <Text
                                    style={styles.textValueItem}
                                    wrap={true}
                                  >
                                    {secSubItem.attributeNameValue}
                                  </Text>
                                </View>
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    );
                  });
                }
                return null;
              })}
            {fetchApiData?.components &&
              fetchApiData?.components?.map((item, index) => {
                return item?.sections?.map((mainItem, indexes) => {
                  if (mainItem?.section === '5-Item Details') {
                    return (
                      <View style={styles.packColorItem} wrap={true}>
                        {mainItem.subGroups.map((subItem, subIndex) => {
                          return (
                            <View
                              style={styles.dummy4}
                              key={subIndex}
                              wrap={true}
                            >
                              <Text style={styles.header} wrap={true}>
                                {subItem.subGroupIdentifierName}
                              </Text>

                              <View
                                style={styles.flexDirectionItem}
                                wrap={true}
                              >
                                {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <View
                                        style={styles.innerViewItem}
                                        key={secSubIndex}
                                        wrap={true}
                                      >
                                        <Text style={styles.dummy2} wrap={true}>
                                          {secSubItem.attributeName}
                                        </Text>
                                        <View
                                          style={{width: 120}}
                                          wrap={true}
                                        >
                                          <Text
                                            style={styles.textValueItem}
                                            wrap={true}
                                          >
                                            {secSubItem.attributeNameValue}
                                          </Text>
                                        </View>
                                      </View>
                                    );
                                  },
                                )}
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    );
                  }
                  return null;
                });
              })}
          </View>
        </View>
        <View>
          <View style={styles.strip}>
            <View>
              <Text style={styles.FileStructure}>
                {Localization.Additional}
              </Text>
            </View>
          </View>
          <View style={styles.FileData} wrap={true}>
            {fetchApiData &&
              fetchApiData?.sections?.map((item, index) => {
                if (item.section === '6-Additional Details') {
                  return item.subGroups?.map((subItem, subIndex) => {
                    return (
                      <View style={styles.dummy5} key={subIndex} wrap={true}>
                        <Text style={styles.headeradd} wrap={true}>
                          {subItem.subGroupIdentifierName}
                        </Text>

                        <View style={styles.flexDirectionRow} wrap={true}>
                          {subItem.attributes.map((secSubItem, secSubIndex) => {
                            return (
                              <View
                                style={styles.dummy1}
                                key={secSubIndex}
                                wrap={true}
                              >
                                <Text style={styles.dummy2} wrap={true}>
                                  {secSubItem.attributeName}
                                </Text>
                                <Text style={styles.dummy3} wrap={true}>
                                  {secSubItem.attributeNameValue}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    );
                  });
                }
                return null;
              })}
            {fetchApiData?.components &&
              fetchApiData?.components?.map((item, index) => {
                return item?.sections?.map((mainItem, indexes) => {
                  if (mainItem?.section === '6-Additional Details') {
                    return (
                      <View style={styles.packColor} wrap={true}>
                        {mainItem.subGroups.map((subItem, subIndex) => {
                          return (
                            <View
                              style={styles.dummy5}
                              key={subIndex}
                              wrap={true}
                            >
                              <Text style={styles.headeradd} wrap={true}>
                                {subItem.subGroupIdentifierName}
                              </Text>

                              <View style={styles.flexDirectionRow} wrap={true}>
                                {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <View
                                        style={styles.dummy1}
                                        key={secSubIndex}
                                        wrap={true}
                                      >
                                        <Text style={styles.dummy2} wrap={true}>
                                          {secSubItem.attributeName}
                                        </Text>
                                        <Text style={styles.dummy3} wrap={true}>
                                          {secSubItem.attributeNameValue}
                                        </Text>
                                      </View>
                                    );
                                  },
                                )}
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    );
                  }
                  return null;
                });
              })}
          </View>
        </View>
        <View style={styles.strip} wrap={true}>
          <Text style={styles.FileStructure}>{Localization.Disclaimer}</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="rectangle">
      <Layout />
      <WelcomeHeader articleApiData={articleApiData} />
      <div className="white-rectangle-ty">
        <div className="thank-you">
          <img
            src={process.env.PUBLIC_URL + '/assets/Thank You!.png'}
            alt=""
          ></img>
        </div>
        <div className="homecoming">
          <img
            src={process.env.PUBLIC_URL + '/assets/home-sign.png'}
            data-testid="home-icon"
            alt="home"
            onClick={() => newProduct()}
          ></img>
        </div>
        <div className="vendor-form-submission" data-testid="text1">
          {Localization.Vendorform}
        </div>
        <p className="vendor-form-number">{articleApiData?.articleCode}</p>
        <div className="submission-date">{Localization.Submission}</div>
        <p className="date">
          {articleApiData?.codeCreationDate?.substring(0, 9)}
        </p>
        <div className="please-keep-this-ref">
          {Localization.Pleasekeepthis}
        </div>
        <div style={{visibility: 'hidden'}}>
          <div ref={componentRef}>
            <div className="omega">
              <div className="ome">
                <img src={process.env.PUBLIC_URL + '/assets/form.png'} alt="" />
              </div>
              <div className="omega1">
                <h2 className="alphabh">
                  {Localization.ProductDetailsConfirmation}
                </h2>
                <div className="alpha">
                  <span>
                    {
                      JSON.parse(localStorage.getItem('myValueInLocalStorage'))
                        ?.data?.username
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <button className="button-continueUpload" onClick={() => newProduct()}>
          <div className="continueUpload">{Localization.ContinueUpload}</div>
        </button>
      </div>
      <div className="all-rights-reserved-thanku">
        {Localization.Disclaimer}
      </div>
      <div className="all-rights-reserved">{Localization.Allrights}</div>
      <Toaster ref={toasterRef} varient={varient} msg={msg} />

      <>
        {pdfReady ? (
          <BlobProvider document={<MyDocument />}>
            {({blob, url, loading, error}) => {
              const data = new FormData();
              const articleCodeValue = articleApiData?.articleCode;
              const file = new File(
                [blob],
                articleCodeValue +
                  '_' +
                  articleApiData?.codeCreationDate?.substring(0, 9) +
                  '.pdf',
              );
              data.append('file', file);
              loading ?
                console.log('') :
                emailService({userId, token, data, articleCodeValue});
              return <div></div>;
            }}
          </BlobProvider>
        ) : (
          ''
        )}
        <button className="button-download">
          <div className="download-report">{Localization.Download}</div>
        </button>
        {pdfReady ? (
          <PDFDownloadLink
            document={<MyDocument />}
            fileName={
              articleApiData?.articleCode +
              '_' +
              articleApiData?.codeCreationDate?.substring(0, 9) +
              '.pdf'
            }
          >
            {({blob, url, loading, error}) => {
              return loading ? (
                <h1>{Localization.LoadingDocument}</h1>
              ) : (
                <button className="button-download">
                  <div className="download-report">{Localization.Download}</div>
                </button>
              );
            }}
          </PDFDownloadLink>
        ) : (
          <p>{Localization.DownloadFailed}</p>
        )}
      </>
      {logoutWarning && (
        <Modal
          isOpen={logoutWarning}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <div className="image">
            <img src={process.env.PUBLIC_URL + '/assets/633803.png'} alt="aa" />
          </div>
          <div className="headerDiv">
            <h1 className="header"> {Localization.SessionTimeOut}</h1>
          </div>
          <div className="border" />
          <div>
            <h1 className="alphabh">{Localization.Inactivity}</h1>
          </div>
          <div className="omega">
            <button
              type="submit"
              className="buttondiv-logout-warning1"
              onClick={() => proceed()}
            >
              {Localization.StayLoggedIn}
            </button>

            <button
              type="submit"
              className="buttondiv-logout-warning"
              onClick={() => logout()}
            >
              {Localization.SaveLogout}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    fetchApiData: state.fetchReducer.fetchApiData,
    articleApiData: state.articleReducer.articleApiData,
    deleteApiData: state.deleteReducer.deleteApiData,
    isSuccess: state.articleReducer.isSuccess,
    isSuccessBlank: state.saveReducer.isSuccessBlank,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchService: ({userId, token, isPreviewPage}) =>
      dispatch(loadFetchData({userId, token, isPreviewPage})),
    articleService: ({userId, token}) =>
      dispatch(loadArticle({userId, token})),
    deleteService: ({userId, token}) =>
      dispatch(loadDeleteData({userId, token})),
    emailService: ({userId, token, data, articleCodeValue}) =>
      dispatch(loadEmail({userId, token, data, articleCodeValue})),
    logoutService: () => dispatch(logoutAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);
