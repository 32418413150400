import React from 'react';
import Localization from '../../../common/Localization';
import './WelcomeHeader.css';
import Modal from 'react-modal';
import {connect, useDispatch} from 'react-redux';
import {logoutAction} from '../../../store/actions/loginAction';

function WelcomeHeader({Welcome, articleApiData}) {
  const data='Welcome';
  const dispatch = useDispatch();
  const logoutService = () => dispatch(logoutAction());
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '50%',
      left: '47%',
      right: 'auto',
      bottom: 'auto',
      height: 260,
      width: 440,
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden',
    },
  };
  function closeModal() {
    setIsOpen(false);
  }
  function logout() {
    logoutService();
  //  window.location.href = '/';
  }

  return (
    <div>
      <div className='user-icon'>
        <img src={process.env.PUBLIC_URL + '/assets/user.png'} alt="user" />
      </div>
      <div className='logout-icon'>
        <img src={process.env.PUBLIC_URL + '/assets/logout.png'} alt="logout" />
      </div>
      <div className='welcome-pacetel-syst'>{data} { JSON.parse(localStorage.getItem('myValueInLocalStorage'))?.data?.username}</div>
      <div className='logout'><div className="logoutHref" onClick={() =>
        setIsOpen(true)
      }>{Localization.Logout}</div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      > <div>
          <img
            className="logoutIcon"
            src={process.env.PUBLIC_URL + '/assets/logoutIcon.png'}
            alt="logoutIcon"
          />

        </div>
        <div className='LogoutMessage'>{Localization.LogoutMessage}</div>
       { articleApiData ===undefined &&
       <span className='LogoutMessage1' >{Localization.LogoutMessage1}</span>}
        <div>
          <div className="noBody">
            <button className='button-No'
              type="No"
              onClick={()=>
                closeModal()}>
              <div className="No">{Localization.Cancel}</div>
            </button>
          </div>
          <div className="yesBody">
            <button className='button-Yes'
              type="Yes"
              onClick={() =>
                logout()}>
              <div className="Yes">{Localization.Yes}</div>
            </button>
          </div>
        </div>
      </Modal>
      </div>
    </div>
  );
}

// export const mapStateToProps = (state) => {
//   return {};
// };

// export const mapDispatchToProps = (dispatch) => {
//   return {
//     logoutService: () => dispatch(logoutAction()),
//   };
// };


// export default connect(mapStateToProps, mapDispatchToProps)(WelcomeHeader);
export default WelcomeHeader;
