import React from 'react';
import './Company.css';
import Localization from '../../common/Localization';
import Layout from '../Shared/Layout/Layout';
import WelcomeHeader from '../Shared/WelcomeHeader/WelcomeHeader';
import FormHeader from '../Shared/FormHeader/FormHeader';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';

const CompanyReturnData = ({props, prod, getDisplayBox, saveDataToast, checkClickcontinueVal, confirmCompany, contDisable, onClickContinue, fetchPack}) => {
    const {handleSubmit} = useForm();
 return (
<>
<Layout />
<WelcomeHeader />
<FormHeader />
<div className="header-fix4">
  <Link to={props?.fetchApiData?.sections ? '/creation':'/3-Company Information'}>
    <div className="group-information">{Localization.Groupinfo}</div>
  </Link>
       <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '2-Product Information')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '2-Product Information')].section : '/3-Company Information') : '/3-Company Information'}>
    <div className="product-information1">{Localization.Productinfo}</div>
  </Link>
       <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '3-Company Information')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '3-Company Information')].section : '/3-Company Information') : '/3-Company Information'}>
    <div className="company-info1">{Localization.Companyinfo}</div>
  </Link>
       <Link to={confirmCompany && props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '4-Dimensions')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '4-Dimensions')].section : '/3-Company Information') : '/3-Company Information'}>
    <div className="dimensions">{Localization.Dimensions}</div>
  </Link>
       <Link to={confirmCompany && props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '5-Item Details')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '5-Item Details')].section : '/3-Company Information') : '/3-Company Information'}>
    <div className="item">{Localization.Itemdetails}</div>
  </Link>
       <Link to={confirmCompany && props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')].section : '/3-Company Information') : '/3-Company Information'}>
    <div className="additional-company">{Localization.Additional}</div>
  </Link>
  <Link to={confirmCompany && props?.fetchApiData?.sections && props?.fetchApiData?.sections[props?.fetchApiData?.sections?.findIndex((p) => p?.section === '6-Additional Details')]?.section ? '/fileupload' : '/3-Company Information'}>
    <div className="file">{Localization.Fileupload}</div>
  </Link>
  <div className="oval"></div>
  <div className="line-rectangle"></div>
  <div className="rectangle1"></div>
  <div className="oval-copy"></div>
  <div className="rectangle2"></div>
  <div className="oval-copy1"></div>
  <div className="oval-copy2"></div>
  <div className="oval-copy3"></div>
  <div className="oval-copy4"></div>
  <div className="oval-copy5"></div>
</div>
<div className="white-rectangle-company">
        <form onSubmit={handleSubmit(onClickContinue)}>
          {prod &&
            Array.isArray(prod) &&
            prod.length > 0 &&
            prod.map((item, index) => {
              return (
                <div key={index}>
                  <div className="row comp-information">
                    {item.subGroupIdentifierName}
                  </div>
                  <div className="row company-box1">
                    {item.attributes.map((subItem, subIndex) => {
                      return (
                        <div className="comp-box1 col-xl-3 col-lg-4 col-md-6 col-sm-6" key={subIndex}>
                          {getDisplayBox(subItem.type, subItem, subIndex)}
                        </div>
                      );
                    })}
                  </div>
                  {index !== prod.length - 1 ? (
                    <div className="st-line-product" />
                  ) : (
                      <div></div>
                    )}
                </div>
              );
            })}
          <div className=" row company-back">
            <a href="/2-Product Information">
              {' '}
              <img
                src={process.env.PUBLIC_URL + '/assets/camera-back.png'}
                alt="Arrow"
              />{' '}
            </a>
            <div className="item-button">
              <button
                aria-label={Localization.Save}
                type="submit"
                data-testid="submitToast"
                className="button-save-comp"
                onClick={() => saveDataToast()}
              >
                <div>{Localization.Save}</div>
              </button>
              <button
                className="button-continue-comp"
                disabled={(props?.fetchApiData?.productType === 'pack' && fetchPack ?(props?.saveLoader&&props?.packLoader):(props?.saveLoader))}
                onClick={onClickContinue} onMouseDownCapture={() => checkClickcontinueVal(true)}
              >
                {Localization.Continue}
              </button>
            </div>
          </div>
          {confirmCompany && <div className="confirmmsg-company">{Localization.GenericMessage}</div>}
        </form>
      </div>
</>
);
};
export default CompanyReturnData;
