import React, {useEffect, useState, useRef, useContext} from 'react';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import CreateIcon from '@mui/icons-material/Create';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UpdateIcon from '@mui/icons-material/Update';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import {
  IconButton,
  Tooltip,
  Typography,
  createTheme,
  styled,
} from '@mui/material';
import PreviewModal from '../Modal/PreviewModal';
import ArticleDetails from '../Preview/ArticleDetails';
import Feed from '../Feed/Feed';
import Reference from '../Reference/Reference';
import classes from './SearchData.module.css';
import {SearchContext} from '../../../context/SearchContext';
import config from '../../../config/config';
import {IMAGE_BASE_URL} from '../../utils/Constants';
const StyledDataGrid = styled(DataGrid)(({theme}) => ({
  'border': 0,
  'color':
    theme.palette.mode === 'light' ?
      'rgba(0,0,0,.85)' :
      'rgba(255,255,255,0.85)',
  'WebkitFontSmoothing': 'auto',
  'letterSpacing': 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader,.MuiDataGrid-columnHeaders': {
    'backgroundColor': theme.palette.mode === 'light' ? '#f9fafb' : '#1d1d1d',
    'color': '#60686E',
    'whiteSpace': 'normal',
    'wordBreak': 'normal',
    'textTransform': 'uppercase',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
      fontSize: '0.85rem',
    },
    '& .MuiDataGrid-columnHeadersInner': {
      backgroundColor: theme.palette.mode === 'light' ? '#f9fafb' : '#1d1d1d',
    },
  },
  // '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
  //   //borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  // },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    }`,
  },
  '& .MuiDataGrid-cell': {
    color:
      theme.palette.mode === 'light' ?
        'rgba(0,0,0,.85)' :
        'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  // ...customCheckbox(theme),
}));
const SearchData = () => {
  const context = useContext(SearchContext);
  const {result, queryLoading, onFetchPaginatedRows} = context;
  const articles = result?.items.filter((item) => item != null);
  const [article, setArticle] = useState(null);
  const [feed, setFeed] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [reference, setReference] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setArticle(null);
    setFeed(null);
    setOpen(false);
    setReference(null);
  };
  const columns2 = [
    {
      field: 'articleCode',
      headerName: 'Article Code',
      headerClassName: classes['grid-header'],
      width: 120,
      renderCell: (params) => (
        <>
          <Tooltip title={params.row.articleCode}>
            <Typography
              variant="body1"
              sx={{fontFamily: 'inherit', color: '#0F8686', fontWeight: 600}}
            >
              {params.row.articleCode}
            </Typography>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerClassName: classes['grid-header'],
      minWidth: 180,
      hide: false,
      type: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Download Feed">
                <DownloadIcon />
              </Tooltip>
            }
            label="Download Feed"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Create Product from Reference">
                <CreateIcon />
              </Tooltip>
            }
            onClick={() => setReference(params.row)}
            label="Create Variant"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Re-Submit">
                <UpdateIcon />
              </Tooltip>
            }
            onClick={() => setFeed(params.row)}
            label="Re-Submit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Details">
                <ReadMoreIcon />
              </Tooltip>
            }
            onClick={() => setArticle(params.row)}
            label="Details"
          />,
          <GridActionsCellItem
            showInMenu
            icon={<DownloadIcon />}
            label="Download Feed"
          />,
          <GridActionsCellItem
            showInMenu
            icon={<CreateIcon />}
            onClick={() => setReference(params.row)}
            label="Create Variant"
          />,
          <GridActionsCellItem
            showInMenu
            icon={<UpdateIcon />}
            onClick={() => setFeed(params.row)}
            label="Re-Submit"
          />,
          <GridActionsCellItem
            showInMenu
            icon={<ReadMoreIcon />}
            onClick={() => setArticle(params.row)}
            label="Details"
          />,
        ];
      },
    },
    {
      field: 'images',
      headerName: 'Images',
      width: 80,
      description: 'Image assets uploaded for this article',
      renderCell: (params) => {
        const {images} = params.row;
        if (images && images.length > 0) {
          const thumbnails = images.filter(
            (img) =>
              !img.includes('.pdf') &&
              !img.includes('.mp4') &&
              !img.includes('.mov'),
          );
          return (
            <>
              {thumbnails.length > 0 && (
                <img
                  width={50}
                  src={
                    config.cloudName.includes('prod') ?
                      `https://media.croma.com/image/upload/${thumbnails[0]}` :
                      `${IMAGE_BASE_URL}/image/upload/${thumbnails[0]}`
                  }
                />
              )}
            </>
          );
        }
        return <></>;
      },
    },
    {
      field: 'l2CategoryName',
      headerName: 'Sub-Category',
      width: 210,
    },
    {
      field: 'l2Category',
      headerName: 'Sub-Category Code',
      width: 150,
    },
    {
      field: 'l1CategoryName',
      headerName: 'Category',
      width: 210,
    },
    {
      field: 'l1Category',
      headerName: 'Category Code',
      width: 110,
    },
    {
      field: 'l0CategoryName',
      headerName: 'Group',
      width: 110,
    },
    {
      field: 'l0Category',
      headerName: 'Group Code',
      width: 110,
    },
    {
      field: 'productTypeName',
      headerName: 'Product Type',
      width: 110,
    },
    {
      field: 'codeCreationDate',
      headerName: 'Creation Date',
      width: 190,
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  useEffect(() => {
    if (article || feed || reference) {
      handleOpen();
    }
  }, [article, feed, reference]);

  const displayDetails = (article) => {
    return displayModal(<ArticleDetails article={article} />, 800);
  };

  const displayFeed = (feed) => {
    return displayModal(<Feed feed={feed} onClose={handleClose} />, 850, {
      paddingBottom: 0,
      background: '#ebebeb',
    });
  };

  const displayReference = (reference) => {
    return displayModal(
      <Reference reference={reference} onClose={handleClose} />,
      850,
      {
        paddingBottom: 0,
      },
    );
  };
  const displayModal = (wrappedComponent, width, sx) => (
    <PreviewModal
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      width={width}
      sx={sx}
    >
      {wrappedComponent}
    </PreviewModal>
  );

  const handlePageModelChange = async ({page: start, pageSize: size}) => {
    console.log('Page Model Changed');

    if (result) {
      // Confirm on what scenarios graphql query will be called
      if (!result || (result && result?.last)) {
        return null;
      }

      // When is result.last == false, then there is data that is yet to be fetched

      if (!result.last && articles) {
        // 1. Calculate the total no of pages with the ui page size
        const totalUIPageCount = Math.ceil(result.totalItems / pageSize);
        const lastUIPagePos =
          totalUIPageCount - 1 < 0 ? 0 : totalUIPageCount - 1;

        // Taking articles as the values can be appended
        const loadedUIPageCount = Math.ceil(articles.length / pageSize);
        const lastLoadedUIPagePos =
          loadedUIPageCount - 1 < 0 ? 0 : loadedUIPageCount - 1;

        const currentUIPagePos = page;

        if (
          lastLoadedUIPagePos == currentUIPagePos - 2 &&
          lastLoadedUIPagePos < lastUIPagePos &&
          currentUIPagePos < lastUIPagePos
        ) {
          // Call the query graphql service with updated offset
          const currentServicePagePos = result?.currentPage;

          const size = result.pageSize < pageSize ? pageSize : result.pageSize;
          let start = currentServicePagePos;

          if (size <= result.pageSize) {
            start += 1;
          }

          await onFetchPaginatedRows({start, size});
        }
      }
    }
    return null;
  };
  return (
    <>
      <ArticleGrid
        columns={columns2}
        rows={articles ? articles : []}
        loading={queryLoading}
        onPaginationModelChange={handlePageModelChange}
      />
      {article && displayDetails(article)}
      {feed && displayFeed(feed)}
      {reference && reference?.articleCode && displayReference(reference)}
    </>
  );
};

export default SearchData;

export function ArticleGrid({
  columns,
  rows,
  loading,
  onPaginationModelChange,
}) {
  console.log(rows);
  return (
    <Box sx={{height: 390, width: '100%', marginTop: '10px'}}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        density="standard"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          sorting: {
            sortModel: [{field: 'articleCode', sort: 'desc'}],
          },
          pinnedColumns: {left: ['articleCode'], right: ['actions']},
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        disableRowSelectionOnClick
        columnBuffer={10}
        columnThreshold={10}
        rowBuffer={5}
        rowThreshold={1000}
        loading={loading}
        getRowId={(row) => row.articleCode}
      />
    </Box>
  );
}
