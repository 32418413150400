import React from 'react';
import './Product.css';
import Layout from '../Shared/Layout/Layout';
import Localization from '../../common/Localization';
import WelcomeHeader from '../Shared/WelcomeHeader/WelcomeHeader';
import FormHeader from '../Shared/FormHeader/FormHeader';
import {useForm} from 'react-hook-form';
import Pack from './pack';
import {Link} from 'react-router-dom';
const ProductReturn = ({props, country, getDisplayBox, fetchPack, showContent, setShowContent, changeData, saveDataToast, checkClickcontinueVal, onClickContinue, confirmProduct, linkedComponents, linkedComponentsLoading, fetchService, brandName}) => {
    const {handleSubmit} = useForm();
    return (
        <>
         <Layout />
      <WelcomeHeader />
      <FormHeader />
      <div className="header-fix4">
        <Link to={props?.fetchApiData?.sections ? '/creation' : '/2-Product Information'}>
          {' '}
          <div className="group-information" data-testid='test1'> {Localization.Groupinfo}</div>
        </Link>
          <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '2-Product Information')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '2-Product Information')].section : '/2-Product Information') : '/2-Product Information'}>
            <div className="product-information" data-testid='test2'> {Localization.Productinfo}</div>
          </Link>
          <Link to={confirmProduct && props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '3-Company Information')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '3-Company Information')].section : '/2-Product Information') : '/2-Product Information'}>
            <div className="company-info" data-testid='test3'>{Localization.Companyinfo}</div>
          </Link>
          <Link to={confirmProduct && props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '4-Dimensions')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '4-Dimensions')].section : '/2-Product Information') : '/2-Product Information'}>
          {' '}
          <div className="dimensions-information" data-testid='test4'>
            {Localization.Dimensions}
          </div>
        </Link>
          <Link to={confirmProduct && props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '5-Item Details')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '5-Item Details')].section : '/2-Product Information') : '/2-Product Information'}>
          <div className="item-information" data-testid='test5'>{Localization.Itemdetails}</div>
        </Link>
          <Link to={confirmProduct && props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')].section : '/2-Product Information') : '/2-Product Information'}>
          <div className="additional-information" data-testid='test6'>
            {Localization.Additional}
          </div>
        </Link>
        <Link to={confirmProduct && props?.fetchApiData?.sections && props?.fetchApiData?.sections[props?.fetchApiData.sections?.findIndex((p) => p?.section === '6-Additional Details')]?.section ? '/fileupload' : '/2-Product Information'}>
          <div className="file-information" data-testid='test7'>{Localization.Fileupload}</div>
        </Link>
        <div className="gray-line" data-testid='test8'></div>
        <div className="straight-line"></div>
        <div className="oval"></div>
        <div className="straight-line1"></div>
        <div className="oval1" data-testid='test9'></div>
        <div className="oval2" data-testid='test10'></div>
        <div className="oval3" data-testid='test11'></div>
        <div className="oval4" data-testid='test12'></div>
        <div className="oval5" data-testid='test13'></div>
        <div className="oval6" data-testid='test14'></div>
        <div className="oval7" data-testid='test15'></div>
      </div>
      <div className="white-modal-item">
        <form onSubmit={handleSubmit(onClickContinue)}>
          {country &&
            Array.isArray(country) &&
            country.length > 0 &&
            country.map((item, index) => {
              return (
                <div key={index}>
                  <div className="row item-information1">
                    {item.subGroupIdentifierName}
                  </div>
                  <div className="row company-box1">
                    {item.attributes.map((subItem, subIndex) => {
                      return (
                        <div className="item-box2 col-sm-6 col-lg-3 col-md-6" key={subIndex}>
                          {getDisplayBox(subItem.type, subItem, subIndex)}
                        </div>
                      );
                    })}
                  </div>
                  {index !== country.length - 1 ? (
                    <div className="st-line-product" />
                  ) : (
                      <div></div>
                    )}
                </div>
              );
            })}

          {props?.fetchApiData?.productType === 'pack' && fetchPack && <Pack
            linkName="2-Product Information"
            packData={fetchPack}
            showContent={showContent}
            setShowContent={setShowContent}
            savePackApi={props.savePackApi}
            changeData={changeData}
            getDisplayBox={getDisplayBox}
            productType={props?.fetchApiData?.productType}
            linkedComponents={linkedComponents}
            linkedComponentsLoading={linkedComponentsLoading}
            fetchService={fetchService}
            brandName={brandName}
          />}
          <div className=" row product-back">
            <a href="/creation">
              {' '}
              <img
                src={process.env.PUBLIC_URL + '/assets/camera-back.png'}
                alt="Arrow"
              />{' '}
            </a>
            <div className="product-button">
              <button
                type="submit"
                className="button-save-product"
                onClick={() => saveDataToast()
                }

              >
                <div>{Localization.Save}</div>
              </button>
              <button
                className="button-continue-product" disabled={props?.fetchApiData?.productType === 'pack' && fetchPack ? (props?.saveLoader && props?.packLoader) : (props?.saveLoader)}
                onClick={onClickContinue} onMouseDownCapture={()=>checkClickcontinueVal(true)} onMouseDown={()=>checkClickcontinueVal(true)}
              >
                <div className="product-continue">{Localization.Continue}</div>
              </button>{' '}
            </div>
          </div>
          {confirmProduct && (
            <div className="confirmmsg-product">
              {Localization.GenericMessage}
            </div>
          )}
        </form>
      </div>
        </>
        );
    };
export default ProductReturn;

