import React, {useEffect, useState, useRef} from 'react';
import SearchData from './SearchData';

const SearchResult = () => {
  return (
    <section
      style={{
        width: 'calc(72% - 35px)',
        height: '100%',
        // border: '1px solid black',
        padding: '20px',
        fontWeight: '600',
      }}
    >
        <header>{'Articles Created'.toLocaleUpperCase()}</header>
        <SearchData />
    </section>
  );
};

export default SearchResult;
