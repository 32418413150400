import {HttpService} from './HttpService';
import {EMAIL} from '../config/apiEndpoints';
export async function emailApi(token, data, articleCode) {
  try {
    const endpoint = EMAIL+ '?articleCode='+`${articleCode}`;
    return (await HttpService.post( endpoint, data, {headers: {'Authorization': `Bearer ${token}`, 'Accept': '*/*', 'Content-Type': 'multipart/form-data'}}));
  } catch (err) {
    return {err};
  }
}
