export const preventNonNumericalInput = (e) => {
    e = e || window.event;
    const charCode = (typeof e.which == 'undefined') ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);
    if (e.key === '.' && e.target.value.indexOf('.') !== -1) e.preventDefault();
    if (!charStr.match(/^[0-9\.]+$/)) {
      e.preventDefault();
    }
    return null;
  };
  export default (preventNonNumericalInput);
