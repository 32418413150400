import React, {useContext, useEffect} from 'react';
import {
  Stack,
  Card,
  CardContent,
  CardActions,
  ThemeProvider,
  Box,
  Typography,
  Button,
  styled,
  createTheme,
  Alert,
} from '@mui/material';
import ArticleDetails from '../Preview/ArticleDetails';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {SearchContext} from '../../../context/SearchContext';
import CircularProgress from '@mui/material/CircularProgress';

const CustomcontainedButton = styled(Button)(({theme}) => ({
  'background': theme.status.primary,
  '&:hover': {
    background: theme.status['primary:hover'],
  },
  // '&.Mui-checked': {
  //   color: theme.status.danger,
  // },
}));

const CustomTextButton = styled(Button)(({theme}) => ({
  'color': theme.status.primary,
  'borderColor': theme.status.primary,
  '&:hover': {
    color: '#c17262',
    background: '#f8f0ef',
  },
}));

const CustomContainedContentCopyIcon = styled(ContentCopyIcon)(({theme}) => ({
  'color': 'white',
  'fill': '#fff',
  '&:hover': {
    // fill: '#c17262',
  },
}));

const theme = createTheme({
  status: {
    'primary': '#f28f7b',
    'primary:hover': '#d9806e',
  },
});

const Reference = ({reference: product, onClose}) => {
  const context = useContext(SearchContext);
  const {onClone, clone} = context;
  return (
    <Stack
      sx={{
        margin: 0,
        padding: 0,
        minHeight: 200,
        fontFamily: 'inherit',
        justifyContent: 'space-between',
      }}
    >
      <ArticleDetails article={product} />
      <Card
        variant="elevation"
        elevation={5}
        sx={{
          marginX: '-32px',
          marginBottom: '-32px',
          height: 100,
          padding: 5,
          //   width: '100%',
          position: 'sticky',
          bottom: 0,
          display: 'flex',
          paddingX: '20px',
          paddingY: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: 0,
            width: '100%',
          }}
        >
          <CardContent
            sx={{
              flex: '1 0 auto',
              padding: 0,
              margin: 0,
              flex: 2,
              maxWidth: '70%',
            }}
          >
            <Alert variant="outlined" severity="warning" color="error">
              Please note on cloning this article, your existing saved data that
              is yet to be published to PIM will be overwritten.
            </Alert>
          </CardContent>

          <CardActions
            sx={{
              flex: '1 0 auto',
              padding: 0,
              margin: 0,
              //  background: '#808080',
              flexDirection: 'row-reverse',
              justifySelf: 'flex-end',
              // justifyContent: 'space-between',
              alignContent: 'flex-end',
              maxWidth: '30%',
            }}
          >
            <ThemeProvider theme={theme}>
              <CustomcontainedButton
                color="primary"
                variant="contained"
                disableElevation
                sx={{marginLeft: 1, fontFamily: 'inherit'}}
                disabled={clone && clone.loading}
                onClick={() =>
                  onClone({
                    articleCode: product.articleCode,
                    group: product?.l0CategoryName,
                    category: product?.l1CategoryName,
                    subCategory: product?.l2CategoryName,
                    productType: product?.productTypeName,
                  })
                }
                startIcon={
                  clone && clone.loading ? (
                    <CircularProgress
                      color="inherit"
                      size={15}
                      sx={{color: 'grey'}}
                      disableShrink
                    />
                  ) : (
                    <CustomContainedContentCopyIcon />
                  )
                }
              >
                Clone
              </CustomcontainedButton>
              <CustomTextButton
                variant="text"
                disableElevation
                sx={{marginLeft: 1, fontFamily: 'inherit'}}
                onClick={onClose}
              >
                Cancel
              </CustomTextButton>
            </ThemeProvider>
          </CardActions>
        </Box>
      </Card>
    </Stack>
  );
};

export default Reference;
