import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {coreAttributeApi} from '../../services/productServices';


export function* productWatcherAsyn(action) {
  try {
    const response = yield call(coreAttributeApi, action.payload);
    if (response.status >= 200 && response.status < 300) {
      const primaryData = yield response.data;

      yield put({type: actionTypes.CORE_ATTRIBUTE_COMPLETE, payload: primaryData});
    } else if (!(response.status >= 200 && response.status < 300)) {
      yield put({type: actionTypes.API_ERROR, payload: null});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.CORE_ATTRIBUTE_ERROR, payload: error});
  }
}
export function* productWatcher() {
  yield takeEvery(actionTypes.CORE_ATTRIBUTE, productWatcherAsyn);
}

export const productSaga=[
  productWatcher(),
];
