import React from 'react';
import Modal from 'react-modal';
import Localization from '../../common/Localization';
import './File.css';

const FilePreviewModal = ({fetchData, modalIsOpen, afterOpenModal, closeModal, customStyles, expanded5, getExpanded5, expanded, getExpanded, expanded2, getExpanded2, expanded1, getExpanded1, expanded3, getExpanded3, expanded4, getExpanded4, expanded6, getExpanded6, callThankyou, error}) => {
  const maxlimit = 20;

return (
<Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div className="omega">
              <div className="summary-screen-msg">
                {Localization.SummaryScreen}
              </div>
              <div className="ome">
                <img src={process.env.PUBLIC_URL + '/assets/form.png'} alt="" />
              </div>
              <div className="omega1">
                <h2 className="alphabh">{Localization.ProductDetailsConfirmation}</h2>
                <div className="alpha">
                  <span>{JSON.parse(localStorage.getItem('myValueInLocalStorage'))?.data?.username}</span>
                </div>
              </div>
            </div>
            <div>
              <div
                className="FileUpload"
                onClick={() => {
                  getExpanded5(!expanded5);
                }}
              >
                <div>
                  <p className="FileStructure">{Localization.Groupinfo}</p>
                </div>
                <div className="image1">
                  {expanded5 ? (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/up-arrow.png'}
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/down-arrow.png'}
                    alt=""
                  />
                )}
                </div>
              </div>
              {expanded5 && (
                <div className="FileData">
                  <div className="group-file">
                    <span>{Localization.Group}<span style={{color: 'red', marginLeft: '5px'}}>*</span></span>
                    <span>{Localization.Category}<span style={{color: 'red', marginLeft: '5px'}}>*</span></span>
                    <span>{Localization.SubCategory}<span style={{color: 'red', marginLeft: '5px'}}>*</span></span>
                    <span>{Localization.ProductType}<span style={{color: 'red', marginLeft: '5px'}}>*</span></span>
                  </div>
                  <div className="group-value">
                    <span>{fetchData?.l0CategoryName}</span>
                    <span>{fetchData?.l1CategoryName}</span>
                    <span>{fetchData?.l2CategoryName}</span>
                    <span>{fetchData?.productTypeName}</span>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className="FileUpload"
                onClick={() => {
                  getExpanded(!expanded);
                }}
              >
                <div>
                  <p className="FileStructure">{Localization.Productinfo}</p>
                </div>
                <div className="image1">
                  {expanded ? (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/up-arrow.png'}
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/down-arrow.png'}
                    alt=""
                  />
                )}
                </div>
              </div>
              {expanded && (
                <div className="FileData">
                  {fetchData?.sections?.map((item) => {
                    if (item.section === '2-Product Information') {
                      return item?.subGroups?.map((subItem, subIndex) => {
                        return (
                          <div className="dummy4" key={subIndex}>
                            <h1 className="headerName">
                              {subItem.subGroupIdentifierName}
                            </h1>

                            <div className="row grid-section">
                              {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <div className="dummy1" key={secSubIndex}>
                                        <div className="dummy2" >
                                          {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                        </div>
                                        <div className="dummy3">
                                          {' '}
                                          {secSubItem.attributeNameValue}
                                        </div>{' '}
                                      </div>
                                    );
                                  },
                              )}
                            </div>
                          </div>
                        );
                      });
                    }
                    return null;
                  })}
                  {fetchData?.components && fetchData?.components?.map((item, index)=>{
                    return item?.sections?.map((mainItem, indexes)=>{
                      if (mainItem?.section === '2-Product Information') {
                        return (<div className="packColor">
                          { mainItem.subGroups.map((subItem, subIndex) => {
                            return (
                              <div className="dummy4" key={subIndex}>
                                <h1 className="headerName">
                                  {subItem.subGroupIdentifierName}
                                </h1>

                                <div className="row grid-section">
                                  {subItem.attributes.map(
                                      (secSubItem, secSubIndex) => {
                                        return (
                                          <div className="dummy1" key={secSubIndex}>
                                            <div className="dummy2">
                                              {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                            </div>
                                            <div className="dummy3">
                                              {' '}
                                              {secSubItem.attributeNameValue}
                                            </div>{' '}
                                          </div>
                                        );
                                      },
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>);
                      }
                      return null;
                    });
                  })}
                </div>
              )}
            </div>

            <div>
              <div
                className="FileUpload"
                onClick={() => {
                  getExpanded2(!expanded2);
                }}
              >
                <p className="FileStructure">{Localization.Companyinfo}</p>
                <div className="image1">
                  {expanded2 ? (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/up-arrow.png'}
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/down-arrow.png'}
                    alt=""
                  />
                )}
                </div>
              </div>
              {expanded2 && (
                <div className="FileData">
                  {fetchData?.sections?.map((item) => {
                    if (item.section === '3-Company Information') {
                      return item.subGroups.map((subItem, subIndex) => {
                        return (
                          <div className="dummy4" key={subIndex}>
                            <h1 className="headerName">
                              {subItem.subGroupIdentifierName}
                            </h1>

                            <div className="row grid-section2">
                              {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <div className="dummy1" key={secSubIndex}>
                                        <div className="dummy2">
                                          {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                        </div>
                                        <div className="dummy3">
                                          {' '}
                                          {secSubItem.attributeNameValue}
                                        </div>{' '}
                                      </div>
                                    );
                                  },
                              )}
                            </div>
                          </div>
                        );
                      });
                    }
                    return null;
                  })}
                  {fetchData?.components && fetchData?.components?.map((item, index)=>{
                    return item?.sections?.map((mainItem, indexes)=>{
                      if (mainItem?.section === '3-Company Information') {
                        return (<div className="packColor">
                          { mainItem.subGroups.map((subItem, subIndex) => {
                            return (
                              <div className="dummy4" key={subIndex}>
                                <h1 className="headerName">
                                  {subItem.subGroupIdentifierName}
                                </h1>

                                <div className="row grid-section2">
                                  {subItem.attributes.map(
                                      (secSubItem, secSubIndex) => {
                                        return (
                                          <div className="dummy1" key={secSubIndex}>
                                            <div className="dummy2">
                                              {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                            </div>
                                            <div className="dummy3">
                                              {' '}
                                              {secSubItem.attributeNameValue}
                                            </div>{' '}
                                          </div>
                                        );
                                      },
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>);
                      }
                      return null;
                    });
                  })}
                </div>
              )}
            </div>

            <div>
              <div
                className="FileUpload"
                onClick={() => {
                  getExpanded1(!expanded1);
                }}
              >
                <p className="FileStructure">{Localization.Dimensions}</p>
                <div className="image1">
                  {expanded1 ? (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/up-arrow.png'}
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/down-arrow.png'}
                    alt=""
                  />
                )}
                </div>
              </div>
              {expanded1 && (
                <div className="FileData">
                  {fetchData?.sections?.map((item) => {
                    if (item.section === '4-Dimensions') {
                      return item.subGroups.map((subItem, subIndex) => {
                        return (
                          <div className="dummy4" key={subIndex}>
                            <h1 className="headerName">
                              {subItem.subGroupIdentifierName}
                            </h1>

                            <div className="row grid-section3">
                              {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <div className="dummy1" key={secSubIndex}>
                                        <div className="dummy2">
                                          {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                        </div>
                                        <div className="dummy3">
                                          {' '}
                                          {secSubItem.attributeNameValue}
                                        </div>{' '}
                                      </div>
                                    );
                                  },
                              )}
                            </div>
                          </div>
                        );
                      });
                    }
                    return null;
                  })}
                  {fetchData?.components && fetchData?.components?.map((item, index)=>{
                    return item?.sections?.map((mainItem, indexes)=>{
                      if (mainItem?.section === '4-Dimensions') {
                        return (<div className="packColor">
                          { mainItem.subGroups.map((subItem, subIndex) => {
                            return (
                              <div className="dummy4" key={subIndex}>
                                <h1 className="headerName">
                                  {subItem.subGroupIdentifierName}
                                </h1>

                                <div className="row grid-section3">
                                  {subItem.attributes.map(
                                      (secSubItem, secSubIndex) => {
                                        return (
                                          <div className="dummy1" key={secSubIndex}>
                                            <div className="dummy2">
                                              {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                            </div>
                                            <div className="dummy3">
                                              {' '}
                                              {secSubItem.attributeNameValue}
                                            </div>{' '}
                                          </div>
                                        );
                                      },
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>);
                      }
                      return null;
                    });
                  })}
                </div>
              )}
            </div>

            <div>
              <div
                className="FileUpload"
                onClick={() => {
                  getExpanded3(!expanded3);
                }}
              >
                <p className="FileStructure">{Localization.Itemdetails}</p>
                <div className="image1">
                  {expanded3 ? (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/up-arrow.png'}
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/down-arrow.png'}
                    alt=""
                  />
                )}
                </div>
              </div>
              {expanded3 && (
                <div className="FileData">
                  {fetchData?.sections?.map((item) => {
                    if (item.section === '5-Item Details') {
                      return item.subGroups?.map((subItem, subIndex) => {
                        return (
                          <div className="dummy4" key={subIndex}>
                            <h1 className="headerName">
                              {subItem.subGroupIdentifierName}
                            </h1>

                            <div className="row high grid-section">
                              {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <div className="dummy1" key={secSubIndex}>
                                        <div className="dummy2">
                                          {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                        </div>
                                        <div className="dummy3">
                                          {' '}
                                          {secSubItem.attributeNameValue}
                                        </div>{' '}
                                      </div>
                                    );
                                  },
                              )}
                            </div>
                          </div>
                        );
                      });
                    }
                    return null;
                  })}
                  {fetchData?.components && fetchData?.components?.map((item, index)=>{
                    return item?.sections?.map((mainItem, indexes)=>{
                      if (mainItem?.section === '5-Item Details') {
                        return (<div className="packColor">
                          { mainItem.subGroups.map((subItem, subIndex) => {
                            return (
                              <div className="dummy4" key={subIndex}>
                                <h1 className="headerName">
                                  {subItem.subGroupIdentifierName}
                                </h1>

                                <div className="row grid-section">
                                  {subItem.attributes.map(
                                      (secSubItem, secSubIndex) => {
                                        return (
                                          <div className="dummy1" key={secSubIndex}>
                                            <div className="dummy2">
                                              {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                            </div>
                                            <div className="dummy3">
                                              {' '}
                                              {secSubItem.attributeNameValue}
                                            </div>{' '}
                                          </div>
                                        );
                                      },
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>);
                      }
                      return null;
                    });
                  })}
                </div>
              )}
            </div>

            <div>
              <div
                className="FileUpload"
                onClick={() => {
                  getExpanded4(!expanded4);
                }}
              >
                <p className="FileStructure">{Localization.Additional}</p>
                <div className="image1">
                  {expanded4 ? (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/up-arrow.png'}
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/down-arrow.png'}
                    alt=""
                  />
                )}
                </div>
              </div>
              {expanded4 && (
                <div className="FileData">
                  {fetchData?.sections?.map((item) => {
                    if (item.section === '6-Additional Details') {
                      return item.subGroups?.map((subItem, subIndex) => {
                        return (
                          <div className="dummy4" key={subIndex}>
                            <h1 className="headerName">
                              {subItem.subGroupIdentifierName}
                            </h1>

                            <div className="row grid-section">
                              {subItem.attributes.map(
                                  (secSubItem, secSubIndex) => {
                                    return (
                                      <div className="dummy1" key={secSubIndex}>
                                        <div className="dummy2">
                                          {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                        </div>
                                        <div className="dummy3">
                                          {' '}
                                          {secSubItem.attributeNameValue}
                                        </div>{' '}
                                      </div>
                                    );
                                  },
                              )}
                            </div>
                          </div>
                        );
                      });
                    }
                    return null;
                  })}
                  {fetchData?.components && fetchData?.components?.map((item, index)=>{
                    return item?.sections?.map((mainItem, indexes)=>{
                      if (mainItem?.section === '6-Additional Details') {
                        return (<div className="packColor">
                          { mainItem.subGroups.map((subItem, subIndex) => {
                            return (
                              <div className="dummy4" key={subIndex}>
                                <h1 className="headerName">
                                  {subItem.subGroupIdentifierName}
                                </h1>

                                <div className="row grid-section">
                                  {subItem.attributes.map(
                                      (secSubItem, secSubIndex) => {
                                        return (
                                          <div className="dummy1" key={secSubIndex}>
                                            <div className="dummy2">
                                              {secSubItem.attributeName} {secSubItem.mandatory === 'Y' && <span style={{color: 'red', marginLeft: '5px'}}>*</span>}
                                            </div>
                                            <div className="dummy3">
                                              {' '}
                                              {secSubItem.attributeNameValue}
                                            </div>{' '}
                                          </div>
                                        );
                                      },
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>);
                      }
                      return null;
                    });
                  })}
                </div>
              )}
            </div>

            <div>
              <div
                className="FileUpload"
                onClick={() => {
                  getExpanded6(!expanded6);
                }}
              >
                <div>
                  <p className="FileStructure">{Localization.Fileupload}</p>
                </div>
                <div className="image1">
                  {expanded6 ? (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/up-arrow.png'}
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/down-arrow.png'}
                    alt=""
                  />
                )}
                </div>
              </div>
              {expanded6 && (
                <>
                  <div style={{height: 'auto', width: '100%', display: 'flex',
                    flexDirection: 'row'}}>
                    <div style={{height: '80%', width: '30%', flexDirection: 'column'}}>
                      <div style={{font: 14, fontWeight: 'bold', fontFamily: 'Mercedes', marginLeft: '3.9%'}} >{Localization.Image}</div>
                      <div className="File-value">

                        {fetchData?.images?.map((item) => {
                          return (

                            <span>  <img style={{margin: '2.5%'}}
                              src={process.env.PUBLIC_URL + '/assets/Upload.png'}
                              alt=""
                            />{(item.substring((item.lastIndexOf('/'))+1)).length>maxlimit?(item.substring((item.lastIndexOf('/'))+1)).substring(0, maxlimit)+'...':item.substring((item.lastIndexOf('/'))+1)}</span>);
                        })}
                      </div>
                    </div>

                    <div style={{height: '80%', width: '30%', flexDirection: 'column'}}>
                      <div style={{font: 14, fontWeight: 'bold', fontFamily: 'Mercedes', marginLeft: '3.9%'}} >{Localization.Video}</div>
                      <div className="File-value">
                        {fetchData?.videos?.map((item) => {
                          return (
                            <span><img style={{margin: '2.5%'}}
                              src={process.env.PUBLIC_URL + '/assets/Upload.png'}
                              alt=""
                            />{(item.substring((item.lastIndexOf('/'))+1)).length>maxlimit?(item.substring((item.lastIndexOf('/'))+1)).substring(0, maxlimit)+'...':item.substring((item.lastIndexOf('/'))+1)}</span>);
                        })}
                      </div>
                    </div>

                    <div style={{height: '80%', width: '30%', flexDirection: 'column'}}>
                      <div style={{font: 14, fontWeight: 'bold', fontFamily: 'Mercedes', marginLeft: '3.9%'}} >{Localization.Manual}</div>
                      <div className="File-value">
                        {fetchData?.manual?.map((item) => {
                          return (
                            <span><img style={{margin: '2.5%'}}
                              src={process.env.PUBLIC_URL + '/assets/Upload.png'}
                              alt=""
                            />{(item.substring((item.lastIndexOf('/'))+1)).length>maxlimit?(item.substring((item.lastIndexOf('/'))+1)).substring(0, maxlimit)+'...':item.substring((item.lastIndexOf('/'))+1)}</span>);
                        })}
                      </div>
                    </div>
                  </div>


                  <div style={{height: 'auto', width: '100%', display: 'flex', marginTop: '5%',
                    flexDirection: 'row'}}>
                    <div style={{height: '80%', width: '30%', flexDirection: 'column'}}>
                      <div style={{font: 14, fontWeight: 'bold', fontFamily: 'Mercedes', marginLeft: '2.5%'}} >{Localization.Brochure}</div>
                      <div className="File-value">
                        {fetchData?.brochure?.map((item) => {
                          return (
                            <span><img style={{margin: '2.5%'}}
                              src={process.env.PUBLIC_URL + '/assets/Upload.png'}
                              alt=""
                            />{(item.substring((item.lastIndexOf('/'))+1)).length>maxlimit?(item.substring((item.lastIndexOf('/'))+1)).substring(0, maxlimit)+'...':item.substring((item.lastIndexOf('/'))+1)}</span>);
                        })}
                      </div>
                    </div>
                    <div style={{height: '80%', flexDirection: 'column', marginLeft: '1.9%'}}>
                      <div style={{font: 14, fontWeight: 'bold', fontFamily: 'Mercedes', marginLeft: '3.9%'}} >{Localization.Misc}</div>
                      <div className="File-value">
                        {fetchData?.others?.map((item) => {
                          return (
                            <span><img style={{margin: '2.5%'}}
                              src={process.env.PUBLIC_URL + '/assets/Upload.png'}
                              alt=""
                            />{(item.substring((item.lastIndexOf('/'))+1)).length>maxlimit?(item.substring((item.lastIndexOf('/'))+1)).substring(0, maxlimit)+'...':item.substring((item.lastIndexOf('/'))+1)}</span>);
                        })}
                      </div>
                    </div>
                  </div>

                </>


              )}
            </div>
            <div style={{marginTop: '20px'}}>
            {error && (<span style={{color: 'red', marginTop: '20px'}}>***Mandatory attribute details are missing. Please re-check the preview details.</span>)}
            </div>
            <div className="preview-disclaimer">
              {Localization.Disclaimer}
            </div>
            <div className="buttonsimp">
              <button type="submit" className="button-continue-file1" onClick={() => callThankyou()}>
                {Localization.Confirm}
              </button>
              <button
                type="submit"
                className="button-save-file1"
                onClick={closeModal}
              >
                {Localization.Cancel}
              </button>
            </div>
          </Modal>
);
};
export default FilePreviewModal;
