import * as actionTypes from './actionsTypes';

export const loadSendOtp=(user)=>{
  return {
    type: actionTypes.SEND_OTP,
    payload: {loginId: user},
  };
};

export const loadValidateOtp=({user, otp})=>{
  return {
    type: actionTypes.VALIDATE_OTP,
    payload: {user: user, otp: otp},
  };
};

export const logoutAction = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};


