import {combineReducers} from 'redux';

import loginReducer from './loginReducer';
import creationReducer from './creationReducer';
import productReducer from './productReducer';
import dimensionReducer from './dimensionReducer';
import companyReducer from './companyReducer';
import additionalReducer from './additionalReducer';
import itemReducer from './itemReducer';
import saveReducer from './saveReducer';
import fetchReducer from './fetchReducer';
import fetchArticleReducer from './fetchArticleReducer';
import filterReducer from './filterReducer';
import deleteReducer, {deleteCloudinaryAssetReducer} from './deleteReducer';
import submitReducer from './submitReducer';
import emailReducer from './emailReducer';
import articleReducer from './articleReducer';
import youTubeUrlReducer from './youTubeUrlReducer';
import packReducer from './packReducer';
import savePackReducer from './savePackReducer';
import filters from '../slices/filterSlice';
import componentReducer from './componentReducer';
export default combineReducers({
  loginReducer,
  creationReducer,
  productReducer,
  dimensionReducer,
  companyReducer,
  additionalReducer,
  itemReducer,
  saveReducer,
  fetchReducer,
  fetchArticleReducer,
  filterReducer,
  submitReducer,
  emailReducer,
  articleReducer,
  deleteReducer,
  deleteCloudinaryAssetReducer,
  youTubeUrlReducer,
  packReducer,
  savePackReducer,
  filters,
  componentReducer,
});

export const reducer = {
  loginReducer,
  creationReducer,
  productReducer,
  dimensionReducer,
  companyReducer,
  additionalReducer,
  itemReducer,
  saveReducer,
  fetchReducer,
  fetchArticleReducer,
  filterReducer,
  submitReducer,
  emailReducer,
  articleReducer,
  deleteReducer,
  deleteCloudinaryAssetReducer,
  youTubeUrlReducer,
  packReducer,
  savePackReducer,
  filters,
  componentReducer,
};
