import React, {useEffect, useState} from 'react';
import {Typography, Box, Container, Link} from '@mui/material';
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.jest';
import ErrorBoundary from '../../Shared/ErrorBoundary';
import config from '../../../config/config';
import {IMAGE_BASE_URL} from '../../utils/Constants';

const Attribute = ({attribute, section}) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }

  return (
    <>
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <Typography
          sx={{
            fontWeight: '600',
            fontFamily: 'inherit',
          }}
        >
          {attribute.attributeName}{' '}
          {attribute.mandatory.toUpperCase() == 'Y' && (
            <sup style={{color: 'red'}}>*</sup>
          )}
        </Typography>
        {section !== 'fileupload' && (
          <Typography sx={{fontFamily: 'inherit'}}>
            {attribute?.attributeNameValue}
          </Typography>
        )}
        {attribute.attributeId !== 'images' &&
          attribute.attributeId !== 'manual' &&
          attribute.attributeId !== 'videos' &&
          section === 'fileupload' && (
            <>
              {attribute?.attributeNameValue &&
                [].concat(attribute?.attributeNameValue).map((val) => (
                  <Box key={attribute?.attributeId}>
                    <Link
                      href={`https://media.croma.com/raw/upload/${encodeURIComponent(
                        val,
                      )}`}
                    >
                      <Typography sx={{fontFamily: 'inherit'}}>
                        {val}
                      </Typography>
                    </Link>
                  </Box>
                ))}
            </>
          )}

        {(attribute.attributeId === 'images' ||
          attribute.attributeId === 'manual' ||
          attribute.attributeId === 'videos') &&
          attribute?.attributeNameValue &&
          attribute?.attributeNameValue.map((image) => (
            <React.Fragment key={image}>
              <section
                style={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  width: '100px',
                  marginRight: '10px',
                }}
              >
                {!image.includes('.pdf') &&
                  !image.includes('.mp4') &&
                  !image.includes('.mov') && (
                    <img
                      src={
                        config.cloudName.includes('prod') ?
                          `https://media.croma.com/image/upload/${image}` :
                          `${IMAGE_BASE_URL}/image/upload/${image}`
                      }
                      style={{width: 'inherit', margin: '10px'}}
                    />
                  )}
                {(image.includes('.mp4') || image.includes('.mov')) && (
                  <video
                    width={100}
                    height={100}
                    style={{cursor: 'pointer'}}
                    onClick={() =>
                      window.open(
                        `https://media.croma.com/video/upload/${image}`,
                      )
                    }
                  >
                    <source
                      src={`https://media.croma.com/video/upload/${image}`}
                      style={{width: 'inherit', margin: '10px'}}
                      type="video/mp4"
                    />
                  </video>
                )}
                {image.includes('.pdf') && (
                  <ErrorBoundary fallback={<p>Something went wrong</p>}>
                    <Document
                      file={`https://media.croma.com/image/upload/${encodeURIComponent(
                        image,
                      )}`}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={() => {}}
                      noWorker={false}
                    >
                      <Box mx={0} sx={{cursor: 'pointer'}}>
                        <Page
                          pageNumber={pageNumber}
                          width={100}
                          style={{margin: 10}}
                          onClick={() =>
                            window.open(
                              `https://media.croma.com/image/upload/${encodeURIComponent(
                                image,
                              )}`,
                            )
                          }
                        />
                      </Box>
                    </Document>
                  </ErrorBoundary>
                )}
              </section>
            </React.Fragment>
          ))}
      </ErrorBoundary>
    </>
  );
};

export default Attribute;
