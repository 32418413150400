import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
  Suspense,
} from 'react';
import ReactJson from 'react-json-view';
import {useDropzone} from 'react-dropzone';
const hash = require('object-hash');
import PublishIcon from '@mui/icons-material/Publish';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import Ajv from 'ajv';
import ajvFormats from 'ajv-formats';

import RegularSchema from '../../../config/schemas/regular.json';
import PackSchema from '../../../config/schemas/pack.json';

const ajv = new Ajv({allErrors: true});
ajvFormats(ajv);

import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Stack,
  Alert,
  CardActions,
  Button,
  createTheme,
  ThemeProvider,
  styled,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import {exportJson, getDateStamp} from '../../utils/utils';
import Toaster from '../../Shared/Toaster';
import {ToasterContext} from '../../../context/ToasterContext';

import classes from './Feed.module.css';
import {SearchContext} from '../../../context/SearchContext';
import config from '../../../config/config';
import {IMAGE_BASE_URL} from '../../utils/Constants';

const CustomcontainedButton = styled(Button)(({theme}) => ({
  'background': theme.status.primary,
  '&:hover': {
    background: theme.status['primary:hover'],
  },
  // '&.Mui-checked': {
  //   color: theme.status.danger,
  // },
}));

const CustomOutlinedButton = styled(Button)(({theme}) => ({
  'color': theme.status.primary,
  'borderColor': theme.status.primary,
  '&:hover': {
    borderColor: '#c17262',
    background: '#f8f0ef',
  },
}));

const CustomTextButton = styled(Button)(({theme}) => ({
  'color': theme.status.primary,
  'borderColor': theme.status.primary,
  '&:hover': {
    color: '#c17262',
    background: '#f8f0ef',
  },
}));

const CustomContainedPublishIcon = styled(PublishIcon)(({theme}) => ({
  'color': 'white',
  'fill': '#fff',
  '&:hover': {
    // fill: '#c17262',
  },
}));

const CustomOutlinedUploadFileIcon = styled(UploadIcon)(({theme}) => ({
  'color': theme.status.primary,
  'fill': theme.status.primary,
  '&:hover': {
    fill: '#c17262',
  },
}));

const CustomOutlinedDownloadIcon = styled(DownloadIcon)(({theme}) => ({
  'color': theme.status.primary,
  'fill': theme.status.primary,
  '&:hover': {
    fill: '#c17262',
  },
}));

const theme = createTheme({
  status: {
    'primary': '#f28f7b',
    'primary:hover': '#d9806e',
  },
});

const Feed = ({feed, onClose}) => {
  const context = useContext(ToasterContext);
  const searchContext = useContext(SearchContext);
  const {
    kafkaMessage,
    loadingKafkaMessage: loading,
    getKafkaMessage,
    onRetrigger,
    retrigger,
    setRetrigger,
  } = searchContext;
  const {showToastMsg} = context;
  const product = kafkaMessage ? JSON.parse(kafkaMessage) : null;
  console.log(product);
  const [body, setBody] = useState(product);
  const [errorData, setErrorData] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  // const [state, setState] = React.useState({
  //   message: '',
  //   varient: '',
  // });
  // const {message, varient} = state;
  const fileupload = useRef();
  const toasterRef = useRef(null);
  const regularValidate = ajv.compile(RegularSchema);
  const packValidate = ajv.compile(PackSchema);
  // console.log(hash(body));
  const thumbnails =
    product?.images &&
    product?.images.length > 0 &&
    product?.images.filter(
      (image) =>
        !image.includes('.pdf') &&
        !image.includes('.mp4') &&
        !image.includes('.mov') &&
        !image.includes('.mp3') &&
        !image.includes('.xls') &&
        !image.includes('.xlsx') &&
        !image.includes('.doc') &&
        !image.includes('.docx') &&
        !image.includes('.csv'),
    );

  const revert = () => setBody(product);

  const submit = () => {
    console.log({...body, code: product?.code});
    onRetrigger(JSON.stringify({...body, code: product?.code}), product?.code);
  };

  const download = () => {
    let filename = `${product?.code}_${getDateStamp()}`;
    if (hash(body) !== hash(product)) {
      filename += '_locally_modified';
    } else {
      filename += '_original';
    }
    exportJson({...body, code: product?.code}, filename);
  };

  const readFileOnUpload = (uploadedFile) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      try {
        // Parse the JSON and Validate against Schema
        const result = JSON.parse(fileReader.result);
        let valid = false;
        if (product?.typeofProduct) {
          if (product?.typeofProduct === 'regular') {
            valid = regularValidate(result);
            if (!valid) {
              console.log(regularValidate.errors);
              setValidationErrors(regularValidate.errors);
              throw new Error(
                `Schema Validation Error.Please import valid JSON data for Regular Product (${product?.code})`,
              );
            }
          } else if (product?.typeofProduct === 'pack') {
            valid = packValidate(result);
            if (!valid) {
              console.log(packValidate.errors);
              setValidationErrors(packValidate.errors);
              throw new Error(
                `Schema Validation Error. ${'\n'}Please import valid JSON data for Pack Product (${
                  product?.code
                })`,
              );
            }
          }
        } else {
          throw new Error('Product Type not available.');
        }

        if (valid) {
          setBody({...result, code: product?.code});
          if (result?.code === product?.code) {
            showToastMsg('Data has been successfully imported', 'success');
          } else {
            showToastMsg(
              `Data has been successfully imported but imported code (${result?.code}) has been overwritten by original code (${product?.code})`,
              'warning',
            );
          }
        }
        setErrorData(null);
      } catch (e) {
        setErrorData(e.message);
        showToastMsg(e.message, 'error');
        console.log(e);
      }
    };
    if (uploadedFile !== undefined) {
      fileReader.readAsText(uploadedFile);
    }
  };

  const upload = () => {
    fileupload.current.click();
  };

  useEffect(() => {
    getKafkaMessage(feed?.articleCode);
    setBody(product);
  }, []);

  useEffect(() => {
    if (product) {
      setBody(product);
    }
    if (loading) {
      setBody(null);
    }
  }, [kafkaMessage, loading]);

  useEffect(() => {
    if (
      !retrigger.loading &&
      !retrigger.error &&
      retrigger.status === 'successfully pushed'
    ) {
      setRetrigger({loading: false, error: null, status: null});
      onClose();
    }
  }, [retrigger]);

  if (loading || !body || !Object.keys(body)) {
    return (
      <Stack
        className={classes.feed__container}
        sx={{minHeight: '50px'}}
      >{`Loading...`}</Stack>
    );
  }
  return (
    <Stack className={classes.feed__container}>
      <header>
        <Box sx={{marginBottom: 0}}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontFamily: 'inherit',
              color: '#0F8686',
            }}
          >
            ARTICLE FEED
          </Typography>
          <Alert severity="warning" sx={{marginY: 1}}>
            {/* <AlertTitle>Note</AlertTitle> */}
            All the fields in this feed are editable, but any changes to{' '}
            <i>
              <strong>code</strong>
            </i>{' '}
            will be ignored.
            <br />
            For array related and other complex structural changes, additions
            and deletions, this feed can be downloaded in JSON format, modified
            and can be re-uploaded or dropped in here.
          </Alert>
        </Box>
        <hr />
      </header>
      <Box sx={{width: '100%', minHeight: 70}}>
        <ReactJson
          src={body}
          onEdit={(edit) => {
            console.log(edit);
            setBody((prev) => edit.updated_src);
            return true;
          }}
          name={false}
          style={{
            color: 'red',
          }}
        />
      </Box>
      <Card
        variant="elevation"
        elevation={5}
        sx={{
          marginX: '-32px',
          marginBottom: '-32px',
          height: 100,
          padding: 5,
          //   width: '100%',
          position: 'sticky',
          bottom: 0,
          display: 'flex',
          paddingX: '20px',
          paddingY: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: 0,
            width: '100%',
          }}
        >
          <Box
            sx={{
              marginRight: 2,
              height: '100%',
              width: 70,
              // background: 'yellow',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <aside>
              <img
                src={
                  thumbnails && thumbnails.length > 0 ?
                    config.cloudName.includes('prod') ?
                      `https://media.croma.com/image/upload/${thumbnails[0]}` :
                      `${IMAGE_BASE_URL}/image/upload/${thumbnails[0]}` :
                    '/assets/undraw_xmas_snowman_k-7-yf.svg'
                }
                width={50}
              />
            </aside>
          </Box>

          <CardContent
            sx={{
              flex: '1 0 auto',
              padding: 0,
              margin: 0,
              flex: 2,
              maxWidth: '40%',
            }}
          >
            <Typography
              component="div"
              variant="h6"
              sx={{fontFamily: 'inherit', fontWeight: '600'}}
            >
              {product?.code}
            </Typography>
            <Typography
              component="div"
              variant="subtitle1"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: '#0F8686',
              }}
            >
              {product?.vendorProductName}
            </Typography>
            <Typography
              component="div"
              variant="subtitle2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: '#808080 !important',
                marginTop: '10px',
                textTransform: 'capitalize',
              }}
            >
              {product?.l2CategoryName} | {product?.l2Category} |{' '}
              {product?.typeofProduct}
            </Typography>
          </CardContent>

          <CardActions
            sx={{
              flex: '1 0 auto',
              padding: 0,
              margin: 0,
              //  background: '#808080',
              flexDirection: 'row-reverse',
              justifySelf: 'flex-end',
              // justifyContent: 'space-between',
              alignContent: 'flex-end',
              maxWidth: '100%',
            }}
          >
            <ThemeProvider theme={theme}>
              <CustomcontainedButton
                color="primary"
                variant="contained"
                disableElevation
                sx={{marginLeft: 1, fontFamily: 'inherit'}}
                // disabled={hash(body) === hash(product) || retrigger?.loading}
                disabled={retrigger?.loading}
                onClick={submit}
                startIcon={
                  retrigger?.loading ? (
                    <CircularProgress
                      color="inherit"
                      size={15}
                      sx={{color: 'grey'}}
                      disableShrink
                    />
                  ) : (
                    <CustomContainedPublishIcon />
                  )
                }
              >
                Publish
              </CustomcontainedButton>
              <CustomOutlinedButton
                variant="outlined"
                disableElevation
                sx={{marginLeft: 1, fontFamily: 'inherit'}}
                onClick={upload}
                startIcon={<CustomOutlinedUploadFileIcon />}
              >
                Upload
              </CustomOutlinedButton>
              <CustomOutlinedButton
                variant="outlined"
                disableElevation
                sx={{marginLeft: 1, fontFamily: 'inherit'}}
                onClick={download}
                startIcon={<CustomOutlinedDownloadIcon />}
              >
                Download
              </CustomOutlinedButton>

              <CustomTextButton
                variant="text"
                disableElevation
                sx={{marginLeft: 1, fontFamily: 'inherit'}}
                disabled={hash(body) === hash(product)}
                onClick={revert}
              >
                Revert
              </CustomTextButton>
              <input
                ref={fileupload}
                type="file"
                onChange={(e) => readFileOnUpload(e.target.files[0])}
                accept=".json"
                style={{display: 'none'}}
              />
            </ThemeProvider>
          </CardActions>
        </Box>
      </Card>
    </Stack>
  );
};

export default Feed;
