import {HttpService} from './HttpService';
import {SUBMIT, RETRIGGER} from '../config/apiEndpoints';
import {getAuthToken, getUserId} from '../components/utils/AuthenticationUtils';
export async function submitApi(token, sendsubmitData) {
  try {
    const endpoint = SUBMIT;
    return await HttpService.post(endpoint, null, {
      headers: {Authorization: `Bearer ${token}`},
    });
  } catch (err) {
    return {err};
  }
}

export async function retriggerApi({msg}) {
  try {
    const endpoint = RETRIGGER;
    const data = {
      msg,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    };
    return HttpService.post(endpoint, data, headers);
    // return HttpService.post(`http://localhost:8080/vendorportal/publish`, data, headers);
  } catch (err) {
    return {err};
  }
}
