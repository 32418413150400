export const saveData = ({props, userId, token, country, checkClickcontinue, mrp, mop, element}) => {
    let checkCoreAttribute = false;
    let checkApiErr = false;
    if (!checkClickcontinue) {
      if (element.isCrossedminLength || (Number(mrp) < Number(mop))) {
        checkCoreAttribute = true;
      } else if ((checkCoreAttribute === false)) {
        props.saveApi({userId, token, sendsaveData: {section: '2-Product Information', subGroups: country}});
        checkApiErr = true;
      }
    }
    return null;
  };
  export const saveDataToast = ({props, userId, token, country, getCheckVal, mrp, mop, element, fetchPack, getConfirmProduct}) => {
    let isValid = 0;
    let count = 0;
    let mandatoryAttributeCount = 0;
      country.forEach(
                    (checkItem, checkIndex) => {
                      const checkelement = checkItem;
                      checkelement.attributes = checkItem.attributes.map(
                          (checkSubItem) => {
                            if (checkSubItem?.attributeNameValue!== '' && checkSubItem?.mandatory === 'Y') {
                              isValid++;
                            }
                            if (checkSubItem?.mandatory === 'Y') {
                              mandatoryAttributeCount++;
                            }
                            count++;
                            return checkSubItem;
                          },
                      );
                         return checkelement;
                    },
                );
    if (props.fetchApiData?.productType === 'pack' && fetchPack) {
      props.savePackApi({userId, token, sendPackData: {'components': fetchPack}});
      getCheckVal(true);
      getConfirmProduct(isValid);
    } else {
      let checkCoreAttribute = false;
      if (element.isCrossedminLength || (Number(mrp) < Number(mop))) {
        checkCoreAttribute = true;
      } else if ((checkCoreAttribute === false)) {
        props.saveApi({userId, token, sendsaveData: {section: '2-Product Information', subGroups: country}});
          getCheckVal(true);
        // if (isValid == count)
        if (isValid == mandatoryAttributeCount) {
         getConfirmProduct(false);
        } else {
           getConfirmProduct(true);
        }
      }
    }
    return null;
  };
export default (saveData, saveDataToast);
