import React, {useEffect, useState, useRef} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import './File.css';
import Layout from '../Shared/Layout/Layout';
import FormHeader from '../Shared/FormHeader/FormHeader';
import WelcomeHeader from '../Shared/WelcomeHeader/WelcomeHeader';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {
  loadFetchData,
  emptySavedResponse,
} from '../../store/actions/fetchAction';
import {fetchPhotos, openUploadWidget} from '../utils/CloudinaryService';
import {loadYoutubeUrl} from '../../store/actions/youTubeUrlAction';
import Toaster from '../Shared/Toaster';
import GuidelineModal from '../GuidelineModal/GuidelineModal';
import axios from 'axios';
import config from '../../config/config';
import {SIGNATURE as BaseSignature} from '../../config/apiEndpoints';
import FilePreviewModal from './FilePreviewModal';
import FileReturnData from './FileReturnData';
import Localization from '../../common/Localization';
import {loadArticle} from '../../store/actions/articleCodeAction';

function File(props) {
  const maxParallelFileUploadCount = 8;
  const {handleSubmit} = useForm();
  const [expanded, setExpanded] = useState(false);
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);
  const [expanded6, setExpanded6] = useState(false);
  const [fetchData, setFetchData] = useState({});
  const [images, setImages] = useState([]);
  const [images2, setImages2] = useState([]);
  const [images3, setImages3] = useState([]);
  const [images4, setImages4] = useState([]);
  const [images5, setImages5] = useState([]);

  const [onlyimages, setOnlyImages] = useState([]);
  const [onlyimages2, setOnlyImages2] = useState([]);
  const [onlyimages3, setOnlyImages3] = useState([]);
  const [onlyimages4, setOnlyImages4] = useState([]);
  const [onlyimages5, setOnlyImages5] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [uploaded2, setUploaded2] = useState(false);
  const [uploaded3, setUploaded3] = useState(false);
  const [uploaded4, setUploaded4] = useState(false);
  const [uploaded5, setUploaded5] = useState(false);
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
    .token;
  const userId = localStorage.getItem('userId');
  const SIGNATURE = BaseSignature;
  const [isPreviewPage, setisPreviewPage] = useState(false);
  const [callapi, setCallapi] = useState(true);
  const sendUrlData = {
    images: images,
    others: images5,
    manual: images4,
    brochure: images3,
    videos: images2,
  };
  const store_path = JSON.parse(localStorage.getItem('formHeader'));
  const folder_path =
    (store_path?.category ? store_path.category : '') +
    (store_path?.subCategory ? '/' + store_path.subCategory : '');
  const [, setPublic_id] = useState();
  const [, setPublic_id2] = useState();
  const [, setPublic_id3] = useState();
  const [, setPublic_id4] = useState();
  const [, setPublic_id5] = useState();
  const toasterRef = useRef(null);
  const [varient, setVarient] = useState('');
  const [msg, setMsg] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [show, setShow] = useState(false);
  const [chkResponse, setchkResponse] = useState(false);
  const [chkSavedResponse, setchkSavedResponse] = useState(false);
  const [chkUpload, setchkUpload] = useState(false); // check for image upload
  const [chkUpload2, setchkUpload2] = useState(false); // check for video upload
  const [chkUpload3, setchkUpload3] = useState(false); // check for manual upload
  const [chkUpload4, setchkUpload4] = useState(false); // check for brochure upload
  const [chkUpload5, setchkUpload5] = useState(false); // check for misc upload
  const [chk, setChk] = useState(false); // check for media api call
  const [chk1, setChk1] = useState(false); // check for media api unsuccess response
  const [fetchUpload, setfetchUpload] = useState(false); // check for fetching data
  const [fetchUpload1, setfetchUpload1] = useState(false); // chek for setting the fetched data
  const deleteApiData = useSelector(
    (state) => state.deleteCloudinaryAssetReducer.deleteApiData,
  );
  const fetchApiData = useSelector((state) => state.fetchReducer.fetchApiData);

  const articleGenerationApiData = useSelector((state) => state.articleReducer);

  const showModal = () => {
    setShow(!show);
  };
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    if (
      images.length !== 0 ||
      images2.length !== 0 ||
      images3.length !== 0 ||
      images4.length !== 0 ||
      images5.length !== 0
    ) {
      props.youTubeUrlApi({userId, token, sendUrlData});
      setchkSavedResponse(true);
    } else {
      setchkResponse(true);
      props.fetchService({userId, token, isPreviewPage: true});
      setCallapi(false);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('url6') !== 'fileupload') {
      history.push('/6-Additional Details');
    }
  }, [history]);
  function callApiUrl() {
    if (
      images.length !== 0 ||
      images2.length !== 0 ||
      images3.length !== 0 ||
      images4.length !== 0 ||
      images5.length !== 0
    ) {
      props.youTubeUrlApi({userId, token, sendUrlData});
      setChk(true);
    } else {
      showToastMsg(Localization.FileUploadToast, 'error');
    }
  }

  const showToastMsg = (msgs, varients) => {
    setVarient(varients);
    setMsg(msgs);
    toasterRef.current.openToast();
  };
  function callThankyou() {
    // history.push('/thankyou');

    props.articleService({userId, token});

    // history.replace('/thankyou');
    // localStorage.setItem('url7', 'thankyou');
  }

  useEffect(() => {
    if (
      articleGenerationApiData?.errorMessage &&
      !articleGenerationApiData?.isSuccess &&
      !articleGenerationApiData?.loading &&
      !articleGenerationApiData?.articleApiData
    ) {
      // show error message
      console.log(articleGenerationApiData?.errorMessage.err.response.data.message);
      showToastMsg('Mandatory attribute details are missing.', 'error');
    }
  }, [articleGenerationApiData?.errorMessage]);

  useEffect(() => {
    if (
      !articleGenerationApiData?.errorMessage &&
      articleGenerationApiData?.isSuccess &&
      !articleGenerationApiData?.loading &&
      articleGenerationApiData?.articleApiData
    ) {
      history.replace('/thankyou');
      localStorage.setItem('url7', 'thankyou');
    }
  }, [articleGenerationApiData?.articleApiData]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '70%',
      width: '70%',
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden',
    },
  };

  useEffect(() => {
    // reconcilliation call
    if (
      onlyimages.length != images.length ||
      (props.fetchApiData?.images &&
        onlyimages.length != props.fetchApiData?.images.length)
    ) {
      props.fetchService({userId, token, isPreviewPage});
    }
  }, [onlyimages, onlyimages2, onlyimages3, onlyimages4, onlyimages5]);

  function beginImageUpload() {
    openUploadWidget(
      {
        cloud_name: config.cloudName,
        clientAllowedFormats: [
          'ai',
          'bmp',
          'ps',
          'ept',
          'eps3',
          'eps',
          'gif',
          'jpg',
          'jpe',
          'jpeg',
          'pdf',
          'png',
          'psd',
          'arw',
          'cr2',
          'svg',
          'tga',
          'tif',
          'tiff',
          'webp',
        ],
        folder: 'Croma Assets/' + folder_path + '/Images/',
        upload_preset: config.uploadPreset,
        maxImageFileSize: 500000000,
        sources: ['local', 'url'],
        tags: ['image'],
        prepareUploadParams: (cb, params) => {
          params = [].concat(params);
          Promise.all(
            params.map((req) =>
              axios
                .post(`${config.apiGateway.apiUrl}` + SIGNATURE, req, {
                  headers: {Authorization: `Bearer ${token}`},
                })
                .then((response) =>
                  Object.assign(
                    {
                      signature: response.data,
                      api_key: config.apiKey,
                      response,
                    },
                    response,
                  ),
                ),
            ),
          ).then((photos) => cb(photos.length === 1 ? photos[0] : photos));
        },
        cropping: false,
        multiple: true,
        maxFiles: maxParallelFileUploadCount,
      },
      (err, photos) => {
        if (!err) {
          if (photos.event === 'success') {
            setUploaded(true);
            setImages((images) => [
              ...images,
              photos.info.public_id +
                (photos.info.format ? '.' + photos.info.format : ''),
            ]);
            setOnlyImages((images) => [
              ...images,
              photos.info.public_id +
                (photos.info.format ? '.' + photos.info.format : ''),
            ]);
            setPublic_id(
              photos.info.original_filename +
                (photos.info.format ? '.' + photos.info.format : ''),
            );
            setchkUpload(true);
            // Call the service here
          }
        }
      },
    );
  }

  function beginVideoUpload() {
    openUploadWidget(
      {
        cloud_name: config.cloudName,
        clientAllowedFormats: [
          'mp4',
          'mpeg',
          'mov',
          'ts',
          'm2ts',
          'mts',
          'mkv',
          'mpeg',
          'wmv',
          'avi',
          'flv',
          '3g2',
          'm2v',
          '3gp',
        ],
        folder: 'Croma Assets/' + folder_path + '/Videos/',
        upload_preset: config.uploadPreset,
        maxVideoFileSize: 500000000,
        sources: ['local', 'url'],
        tags: ['video'],
        prepareUploadParams: (cb, params) => {
          params = [].concat(params);
          Promise.all(
            params.map((req) =>
              axios
                .post(`${config.apiGateway.apiUrl}` + SIGNATURE, req, {
                  headers: {Authorization: `Bearer ${token}`},
                })
                .then((response) =>
                  Object.assign(
                    {
                      signature: response.data,
                      api_key: config.apiKey,
                      response,
                    },
                    response,
                  ),
                ),
            ),
          ).then((photos2) => cb(photos2.length === 1 ? photos2[0] : photos2));
        },
        cropping: false,
        multiple: true,
        maxFiles: maxParallelFileUploadCount,
      },
      (err2, photos2) => {
        if (!err2) {
          if (photos2.event === 'success') {
            setUploaded2(true);
            setImages2((images2) => [
              ...images2,
              photos2.info.public_id +
                (photos2.info.format ? '.' + photos2.info.format : ''),
            ]);
            setOnlyImages2((images2) => [
              ...images2,
              photos2.info.public_id +
                (photos2.info.format ? '.' + photos2.info.format : ''),
            ]);
            setPublic_id2(
              photos2.info.original_filename +
                (photos2.info.format ? '.' + photos2.info.format : ''),
            );
            setchkUpload2(true);
          }
        }
      },
    );
  }
  function beginBrochureUpload() {
    openUploadWidget(
      {
        cloud_name: config.cloudName,
        clientAllowedFormats: ['pdf', 'doc', 'docx', 'pptx', 'ppt'],
        folder: 'Croma Assets/' + folder_path + '/Brochure/',
        upload_preset: config.uploadPreset,
        maxFileSize: 500000000,
        sources: ['local', 'url'],
        tags: ['brochure'],
        prepareUploadParams: (cb, params) => {
          params = [].concat(params);
          Promise.all(
            params.map((req) =>
              axios
                .post(`${config.apiGateway.apiUrl}` + SIGNATURE, req, {
                  headers: {Authorization: `Bearer ${token}`},
                })
                .then((response) =>
                  Object.assign(
                    {
                      signature: response.data,
                      api_key: config.apiKey,
                      response,
                    },
                    response,
                  ),
                ),
            ),
          ).then((photos3) => cb(photos3.length === 1 ? photos3[0] : photos3));
        },
        cropping: false,
        multiple: true,
        maxFiles: maxParallelFileUploadCount,
      },
      (err3, photos3) => {
        if (!err3) {
          if (photos3.event === 'success') {
            setUploaded4(true);
            setImages3((images3) => [
              ...images3,
              photos3.info.public_id +
                (photos3.info.format ? '.' + photos3.info.format : ''),
            ]);
            setOnlyImages3((images3) => [
              ...images3,
              photos3.info.public_id +
                (photos3.info.format ? '.' + photos3.info.format : ''),
            ]);
            setPublic_id3(
              photos3.info.original_filename +
                (photos3.info.format ? '.' + photos3.info.format : ''),
            );
            setchkUpload3(true);
          }
        }
      },
    );
  }
  function beginManualUpload() {
    openUploadWidget(
      {
        cloud_name: config.cloudName,
        clientAllowedFormats: ['pdf', 'doc', 'docx', 'ppt', 'pptx'],
        folder: 'Croma Assets/' + folder_path + '/Manuals/',
        upload_preset: config.uploadPreset,
        maxFileSize: 500000000,
        sources: ['local', 'url'],
        tags: ['manual'],
        prepareUploadParams: (cb, params) => {
          params = [].concat(params);
          Promise.all(
            params.map((req) =>
              axios
                .post(`${config.apiGateway.apiUrl}` + SIGNATURE, req, {
                  headers: {Authorization: `Bearer ${token}`},
                })
                .then((response) =>
                  Object.assign(
                    {
                      signature: response.data,
                      api_key: config.apiKey,
                      response,
                    },
                    response,
                  ),
                ),
            ),
          ).then((photos4) => cb(photos4.length === 1 ? photos4[0] : photos4));
        },
        cropping: false,
        multiple: true,
        maxFiles: maxParallelFileUploadCount,
      },
      (err4, photos4) => {
        if (!err4) {
          if (photos4.event === 'success') {
            setUploaded3(true);
            setImages4((images4) => [
              ...images4,
              photos4.info.public_id +
                (photos4.info.format ? '.' + photos4.info.format : ''),
            ]);
            setOnlyImages4((images4) => [
              ...images4,
              photos4.info.public_id +
                (photos4.info.format ? '.' + photos4.info.format : ''),
            ]);
            setPublic_id4(
              photos4.info.original_filename +
                (photos4.info.format ? '.' + photos4.info.format : ''),
            );
            setchkUpload4(true);
          }
        }
      },
    );
  }
  function beginMiscUpload() {
    openUploadWidget(
      {
        cloud_name: config.cloudName,
        folder: 'Croma Assets/' + folder_path + '/Misc/',
        upload_preset: config.uploadPreset,
        maxFileSize: 500000000,
        sources: ['local', 'url'],
        tags: ['misc'],
        prepareUploadParams: (cb, params) => {
          params = [].concat(params);
          Promise.all(
            params.map((req) =>
              axios
                .post(`${config.apiGateway.apiUrl}` + SIGNATURE, req, {
                  headers: {Authorization: `Bearer ${token}`},
                })
                .then((response) =>
                  Object.assign(
                    {
                      signature: response.data,
                      api_key: config.apiKey,
                      response,
                    },
                    response,
                  ),
                ),
            ),
          ).then((photos5) => cb(photos5.length === 1 ? photos5[0] : photos5));
        },
        cropping: false,
        multiple: true,
        maxFiles: maxParallelFileUploadCount,
      },
      (err5, photos5) => {
        if (!err5) {
          if (photos5.event === 'success') {
            setUploaded5(true);
            setImages5((images5) => [
              ...images5,
              photos5.info.public_id +
                (photos5.info.format ? '.' + photos5.info.format : ''),
            ]);
            setOnlyImages5((images5) => [
              ...images5,
              photos5.info.public_id +
                (photos5.info.format ? '.' + photos5.info.format : ''),
            ]);
            setPublic_id5(
              photos5.info.original_filename +
                (photos5.info.format ? '.' + photos5.info.format : ''),
            );
            setchkUpload5(true);
          }
        }
      },
    );
  }

  useEffect(() => {
    if (chkUpload || images.length > 0) {
      props.youTubeUrlApi({userId, token, sendUrlData});
      setchkUpload(false);
      setfetchUpload(true);
    }
    if (chkUpload2 || images2.length > 0) {
      props.youTubeUrlApi({userId, token, sendUrlData});
      setchkUpload2(false);
      setfetchUpload(true);
    }
    if (chkUpload3 || images3.length > 0) {
      props.youTubeUrlApi({userId, token, sendUrlData});
      setchkUpload3(false);
      setfetchUpload(true);
    }
    if (chkUpload4 || images4.length > 0) {
      props.youTubeUrlApi({userId, token, sendUrlData});
      setchkUpload4(false);
      setfetchUpload(true);
    }
    if (chkUpload5 || images5.length > 0) {
      props.youTubeUrlApi({userId, token, sendUrlData});
      setchkUpload5(false);
      setfetchUpload(true);
    }
  }, [chkUpload, chkUpload2, chkUpload3, chkUpload4, chkUpload5]);

  useEffect(() => {
    if (token && callapi) {
      !props?.fetchApiData &&
        props.fetchService({userId, token, isPreviewPage});
      setCallapi(false);
    }
  }, [callapi]);

  useEffect(() => {
    if (props?.fetchApiData) {
      setFetchData(props.fetchApiData);
    } else if (fetchApiData) {
      setFetchData(fetchApiData);
    }
    if (fetchApiData?.images != null && fetchApiData?.images.length >= 0) {
      setImages(fetchApiData?.images);
      setOnlyImages(fetchApiData?.images);
    }
    if (fetchApiData?.videos != null && fetchApiData?.videos.length >= 0) {
      setImages2(fetchApiData?.videos);
      setOnlyImages2(fetchApiData?.videos);
    }
    if (fetchApiData?.brochure != null && fetchApiData?.brochure.length >= 0) {
      setImages3(fetchApiData?.brochure);
      setOnlyImages3(fetchApiData?.brochure);
    }
    if (fetchApiData?.manual != null && fetchApiData?.manual.length >= 0) {
      setImages4(fetchApiData?.manual);
      setOnlyImages4(fetchApiData?.manual);
    }
    if (fetchApiData?.others != null && fetchApiData?.others.length >= 0) {
      setImages5(fetchApiData?.others);
      setOnlyImages5(fetchApiData?.manual);
    }
  }, [props.fetchApiData, fetchApiData]);
  useEffect(() => {
    if (chkSavedResponse && props?.saveUrlData) {
      props.emptySavedResponse();

      setchkResponse(true);
      props.fetchService({userId, token, isPreviewPage});
      setCallapi(false);
      setchkSavedResponse(false);
    }
    if (chk && props?.saveUrlData && props?.isSuccess) {
      props.emptySavedResponse();
      props.fetchService({userId, token, isPreviewPage});
      setChk1(true);
      setChk(false);
      setCallapi(false);
      showToastMsg(Localization.SuccessfulSaveToast, 'success');
    }
    if (fetchUpload && props?.saveUrlData && props?.isSuccess) {
      props.emptySavedResponse();
      props.fetchService({userId, token, isPreviewPage});
      setfetchUpload1(true);
      setfetchUpload(false);
    }
  }, [chkSavedResponse, props.saveUrlData, chk, fetchUpload, props.isSuccess]);

  useEffect(() => {
    if (chkResponse) {
      setFetchData(props.fetchApiData);
      setIsOpen(true);
      setchkResponse(false);
    }

    if (chk1) {
      setFetchData(props.fetchApiData);
      setChk1(false);
    }
    if (fetchUpload1) {
      setFetchData(props.fetchApiData);
      setfetchUpload1(false);
    }
  }, [props.fetchApiData]);

  useEffect(() => {
    fetchPhotos('image', setImages);
    fetchPhotos('video', setImages2);
    fetchPhotos('brochure', setImages3);
    fetchPhotos('manual', setImages4);
    fetchPhotos('misc', setImages5);
    const store = JSON.parse(localStorage.getItem('formHeader'));

    setPdfUrl(
      'https://media.croma.com/image/upload/v1609513147/Croma%20Assets/' +
        store?.category.split(' ').join('%20') +
        '/' +
        store?.subCategory.split(' ').join('%20') +
        '/Guidelines.pdf',
    );
  }, []);
  const getExpanded5 = (expanded_5) => {
    setExpanded5(expanded_5);
  };
  const getExpanded = (expanded_) => {
    setExpanded(expanded_);
  };
  const getExpanded2 = (expanded_2) => {
    setExpanded2(expanded_2);
  };
  const getExpanded1 = (expanded_1) => {
    setExpanded1(expanded_1);
  };
  const getExpanded3 = (expanded_3) => {
    setExpanded3(expanded_3);
  };
  const getExpanded4 = (expanded_4) => {
    setExpanded4(expanded_4);
  };
  const getExpanded6 = (expanded_6) => {
    setExpanded6(expanded_6);
  };

  const refetch = async () => {
    console.log('refetch called');
    // props.fetchService({userId, token, isPreviewPage});
  };

  useEffect(() => {
    console.log('recall fetchApi called');
    props.emptySavedResponse();
    props.fetchService({userId, token, isPreviewPage});
    // fetchPhotos('image', setImages);
    // fetchPhotos('video', setImages2);
    // fetchPhotos('brochure', setImages3);
    // fetchPhotos('manual', setImages4);
    // fetchPhotos('misc', setImages5);
  }, [deleteApiData]);

  return (
    <>
      {show && <GuidelineModal onClose={showModal} show={show} url={pdfUrl} />}
      <div className="rectangle">
        <Layout />
        <WelcomeHeader />
        <FormHeader />
        <form onSubmit={handleSubmit(() => {})}>
          <FileReturnData
            props={props}
            fetchApiData={fetchApiData}
            showModal={showModal}
            uploaded={uploaded}
            images={images}
            beginImageUpload={beginImageUpload}
            fetchData={fetchData}
            uploaded2={uploaded2}
            images2={images2}
            beginVideoUpload={beginVideoUpload}
            uploaded3={uploaded3}
            images4={images4}
            beginManualUpload={beginManualUpload}
            uploaded4={uploaded4}
            images3={images3}
            beginBrochureUpload={beginBrochureUpload}
            uploaded5={uploaded5}
            images5={images5}
            beginMiscUpload={beginMiscUpload}
            callApiUrl={callApiUrl}
            openModal={openModal}
            refetch={refetch}
            onlyimages={onlyimages}
            onlyimages2={onlyimages2}
            onlyimages3={onlyimages3}
            onlyimages4={onlyimages4}
            onlyimages5={onlyimages5}
          />
        </form>

        <div>
          <FilePreviewModal
            fetchData={fetchData}
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            customStyles={customStyles}
            expanded5={expanded5}
            getExpanded5={getExpanded5}
            expanded={expanded}
            getExpanded={getExpanded}
            expanded2={expanded2}
            getExpanded2={getExpanded2}
            expanded1={expanded1}
            getExpanded1={getExpanded1}
            expanded3={expanded3}
            getExpanded3={getExpanded3}
            expanded4={expanded4}
            getExpanded4={getExpanded4}
            expanded6={expanded6}
            getExpanded6={getExpanded6}
            callThankyou={callThankyou}
            {...(articleGenerationApiData?.errorMessage &&
              {error: articleGenerationApiData?.errorMessage?.err?.response?.data})}
          />
        </div>
        <Toaster ref={toasterRef} varient={varient} msg={msg} />
      </div>
    </>
  );
}
export const mapStateToProps = (state) => {
  return {
    fetchApiData: state.fetchReducer.fetchApiData,
    saveUrlData: state.youTubeUrlReducer.saveUrlData,
    isSuccess: state.youTubeUrlReducer.isSuccess,
    isSuccessBlank: state.saveReducer.isSuccessBlank,
    deleteApiData: state.deleteCloudinaryAssetReducer.deleteApiData,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchService: ({userId, token, isPreviewPage}) =>
      dispatch(loadFetchData({userId, token, isPreviewPage})),
    youTubeUrlApi: ({userId, token, sendUrlData}) =>
      dispatch(loadYoutubeUrl({userId, token, sendUrlData})),
    emptySavedResponse: () => dispatch(emptySavedResponse()),
    articleService: ({userId, token}) =>
      dispatch(loadArticle({userId, token})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(File);
