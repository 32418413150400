import {XSS_SANITIZE_REGEX} from './Constants';

/**
 * Validates input text to prevent XSS attacks
 * @param {String} input
 * @returns {boolean}
 */
export const isSanitized = (input) => {
  console.log(input);
  console.log(!XSS_SANITIZE_REGEX.test(input));
  console.log('===================');
  return !XSS_SANITIZE_REGEX.test(input);
};

/**
 * Sanitizes input text to prevent XSS attacks
 * @param {String} input
 * @returns {String}
 */

export const sanitize = (input) => {
  return input.replace(XSS_SANITIZE_REGEX, '');
};

export const exportJson = (obj, filename) => {
  if (!filename.endsWith('.json')) {
    filename += '.json';
  }

  const json = JSON.stringify(obj, null, 2);
  const blob = new Blob([json], {type: 'application/json'});
  const href = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;
  link.download = filename;
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const getDateStamp = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + '' + mm + '' + yyyy;
  return formattedToday;
};

export const updateFormHeader = ({
  group,
  category,
  subCategory,
  productType,
}) => {
  const formHeader = {
    group,
    category,
    subCategory,
    productType,
  };

  localStorage.setItem('formHeader', JSON.stringify(formHeader));
};
