export const preventNonNumericalInputDefault = (e) => {
  e = e || window.event;
  const charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
  const charStr = String.fromCharCode(charCode);
  if (e.key === '.' && e.target.value.indexOf('.') !== -1) e.preventDefault();
  /* eslint-disable no-useless-escape */
  if (!charStr.match(/^[0-9\.-]+$/)) {
    e.preventDefault();
  }
  return null;
};
export const saveData = ({
  props,
  userId,
  token,
  prod,
  checkClickcontinue,
  fridgeValue,
  freezerValue,
  grossValue,
}) => {
  let checkClassAttribute = false;
  if (!checkClickcontinue) {
    if (Number(fridgeValue) + Number(freezerValue) > Number(grossValue)) {
      checkClassAttribute = true;
    } else if (checkClassAttribute === false) {
      props.saveApi({
        userId,
        token,
        sendsaveData: {section: '5-Item Details', subGroups: prod},
      });
    }
  }
  return null;
};
export const saveDataToast = ({
  props,
  userId,
  token,
  prod,
  fridgeValue,
  freezerValue,
  grossValue,
  getCheckVal,
  getConfirmItem,
  fetchPack,
}) => {
  let isValid = 0;
  let count = 0;
  prod?.forEach((checkItem, checkIndex) => {
    const checkelement = checkItem;
    checkelement.attributes = checkItem.attributes.map((checkSubItem) => {
      if (
        checkSubItem?.attributeNameValue !== '' &&
        checkSubItem?.mandatory === 'Y'
      ) {
        isValid++;
      }
      if (checkSubItem?.mandatory === 'Y') {
        count++;
      }
      return checkSubItem;
    });
    return checkelement;
  });
  if (props.fetchApiData?.productType === 'pack' && fetchPack) {
    props.savePackApi({
      userId,
      token,
      sendPackData: {components: fetchPack},
    });
    getCheckVal(true);
    if (isValid === count) {
      getConfirmItem(false);
    } else {
      getConfirmItem(true);
    }
  } else {
    let checkClassAttribute = false;
    if (Number(fridgeValue) + Number(freezerValue) > Number(grossValue)) {
      checkClassAttribute = true;
    } else if (checkClassAttribute === false) {
      props.saveApi({
        userId,
        token,
        sendsaveData: {section: '5-Item Details', subGroups: prod},
      });
      getCheckVal(true);
      if (isValid === count) {
        getConfirmItem(false);
      } else {
        getConfirmItem(true);
      }
    }
  }
  return null;
};
export const elementRange = ({element, value}) => {
  const range = element.range.split('-');
  const min = Number(range[0]);
  const max = Number(range[1]);
  if (Number(value) >= min && Number(value) <= max) {
    if (value.toString().includes('.')) {
      const temp = value.toString().split('.');
      if (temp[1].length > 2) {
        return null;
      } else {
        element.attributeNameValue = value;
      }
    } else {
      element.attributeNameValue = value;
    }
    element.isCrossedRange = false;
  } else if (value === element.noDataProvidedValue) {
    element.attributeNameValue = value;
  } else {
    if (value === '') {
      element.attributeNameValue = value;
    } else {
      element.isCrossedRange = true;
    }
  }
};
export const elementRegex = ({element, value, subelement}) => {
  const re = new RegExp(element.validRegex);
  if (value === '') {
    element.attributeNameValue = '';
  } else if (element.length !== '' && value.length > Number(element.length)) {
    if (subelement.subGroupIdentifierName === 'In The Box') {
      if (element.attributeName.includes('Quantity')) {
        element.isCrossedLength = false;
      }
    } else {
      element.isCrossedLength = true;
    }
    return element;
  } else if (re.test(value)) {
    element.isInvalid = false;
    element.isCrossedLength = false;
    element.attributeNameValue = value;
  } else {
    if (subelement.subGroupIdentifierName === 'In The Box') {
      if (element.attributeName.includes('Quantity')) {
        element.isInvalid = false;
      }
    } else {
      element.isInvalid = true;
      element.isCrossedLength = false;
    }
    return element;
  }
};
export const onClickContinue = ({
  props,
  userId,
  token,
  prod,
  fridgeValue,
  freezerValue,
  grossValue,
  minProductPriceRange,
  maxProductPriceRange,
  fetchPack,
  getShowDetails,
  getConfirmItem,
  getCheck,
  proceed,
  getClassAttr,
}) => {
  let checkClassAttribute = false;
  let checkDuplicate = false;
  let packCoreAttribute = false;
  /* eslint-disable no-unused-vars */
  const classAttributeR = prod?.map((subItem, subIndex) => {
    const subelement = subItem;
    subelement.attributes = subItem.attributes.map(
      (secSubItem, secSubIndex) => {
        const element = secSubItem;
        if (
          ((element.attributeNameValue === '' ||
            element.attributeNameValue === null) &&
            element.mandatory === 'Y') ||
          Number(fridgeValue) + Number(freezerValue) > Number(grossValue) ||
          Number(maxProductPriceRange) < Number(minProductPriceRange)
        ) {
          checkClassAttribute = true;
        }
        if (element.sequence) {
          subItem.attributes.map((checkItem, checkIndex) => {
            if (
              element.attributeId === checkItem.attributeId &&
              element.attributeNameValue !== '' &&
              subelement.subGroupIdentifierName !== 'In The Box' &&
              secSubIndex !== checkIndex &&
              element.attributeNameValue === checkItem.attributeNameValue
            ) {
              checkItem.isDuplicate = true;
              checkDuplicate = true;
            }
            return checkItem;
          });
        }
        return element;
      },
    );
    return subelement;
  });
  getClassAttr(classAttributeR);
  if (fetchPack) {
    const packAttributesR = fetchPack?.map((packItem, index) => {
      return packItem?.sections?.map((item) => {
        if (item.section === '5-Item Details') {
          return item.subGroups.map((subItem, subIndex) => {
            const subelement = subItem;
            subelement.attributes = subItem?.attributes?.map(
              (secSubItem, secSubIndex) => {
                const element = secSubItem;
                if (
                  (element.attributeNameValue === '' ||
                    element.attributeNameValue === null) &&
                  element.mandatory === 'Y'
                ) {
                  packCoreAttribute = true;
                }
                return element;
              },
            );
            return subelement;
          });
        }
        return null;
      });
    });
  }
  if (checkClassAttribute) {
    getShowDetails(true);
    getConfirmItem(true);
    props.saveApi({
      userId,
      token,
      sendsaveData: {section: '5-Item Details', subGroups: prod},
    });
  } else if (!packCoreAttribute && !checkClassAttribute) {
    getShowDetails(false);
    getConfirmItem(false);

    props.saveApi({
      userId,
      token,
      sendsaveData: {section: '5-Item Details', subGroups: prod},
    });
    getCheck(true);
    if (checkDuplicate === false) {
      proceed();
    }
  } else {
    getShowDetails(true);
    getConfirmItem(true);
    props.saveApi({
      userId,
      token,
      sendsaveData: {section: '5-Item Details', subGroups: prod},
    });
  }
};
export default (preventNonNumericalInputDefault,
saveData,
saveDataToast,
elementRange,
elementRegex,
onClickContinue);
