import React from 'react';
import SearchReturnData from './SearchReturnData';
import Localization from '../../common/Localization';
import {ToasterContextProvider} from '../../context/ToasterContext';
import {SearchContextProvider} from '../../context/SearchContext';

const Search = () => {
  return (
    <ToasterContextProvider>
      <SearchContextProvider>
        <div className="rectangle">
          <SearchReturnData />
          <div className="all-rights-reserved-ad1">
            {Localization.Disclaimer}
          </div>
          <div className="all-rights-reserved-ad">{Localization.Allrights}</div>
        </div>
      </SearchContextProvider>
    </ToasterContextProvider>
  );
};

export default React.memo(Search);
