import {HttpService} from './HttpService';
import {FILTER} from '../config/apiEndpoints';
import {getAuthToken, getUserId} from '../components/utils/AuthenticationUtils';

export async function fetchFilter() {
  try {
    const endpoint = FILTER+`?userId=${getUserId()}`;
    const headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    // return (await HttpService.get(endpoint, {headers: headers}));
    return await HttpService.get(endpoint, {
      headers: headers,
    });
  } catch (err) {
    return {err};
  }
}

export async function fetchDependentFilter({attributeId, parentAttribute}) {
  const token = getAuthToken();
  console.log(attributeId);
  console.log(parentAttribute.attributeId);
  console.log(parentAttribute.attributeNameValue);
  try {
    const endpoint = FILTER;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    // return (await HttpService.get(endpoint, {headers: headers}));
    return await HttpService.get(
      `http://localhost:3000/json/attribute.json?attributeId=${attributeId}&parentId=${
        parentAttribute.attributeId
      }&values=${encodeURIComponent(parentAttribute.attributeNameValue)}`,
      {headers: headers},
    );
  } catch (err) {
    return {err};
  }
}
