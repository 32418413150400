import React, {useEffect, useState, useRef} from 'react';
import FilterDropdown from './FilterDropdown';
import FilterDate from './FilterDate';
import FilterInput from './FilterInput';
import FilterRadio from './FilterRadio';
import classes from './FilterAttribute.module.css';
import {useDispatch, useSelector} from 'react-redux';
import {fetch, update} from '../../../store/slices/filterSlice';

const FilterAttribute = ({attribute}) => {
  const data = useSelector((state) => state.filters);
  const dispatch = useDispatch();
  const {filters, loading, error} = data;

  const filter = filters && filters[attribute.attributeId];

  const handleChange = (value) => {
    console.log(value);
    dispatch(update(value));
  };

  return (
    <>
      {filter && (
        <article className={classes['filter-attribute']}>
          <header className={classes['filter-attribute__header']}>
            {attribute.attributeName}
          </header>
          <main>
            {(filter.attributeDataType.toLowerCase() === 'enum' ||
              filter.fieldType === 'Select') && (
              <FilterDropdown attribute={filter} onChange={handleChange} />
            )}
            {filter.attributeDataType === 'date' && (
              <FilterDate attribute={filter} onChange={handleChange} />
            )}
            {(filter.attributeDataType.toLowerCase() === 'string' ||
              filter.attributeDataType === 'text') && (
              <FilterInput attribute={filter} onChange={handleChange} />
            )}
            {(filter.attributeDataType.toLowerCase() === 'radio' ||
              filter.fieldType === 'Radio') && (
              <FilterRadio attribute={filter} onChange={handleChange} />
            )}
          </main>
        </article>
      )}
    </>
  );
};

export default FilterAttribute;
