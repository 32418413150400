import React, {Fragment} from 'react';
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Attribute from './Attribute';

const Section = ({
  label,
  product,
  section,
  components,
  expanded,
  panel,
  onChange,
}) => {
  console.log(components);
  return (
    <>
      <Accordion
        elevation={0}
        sx={{marginTop: 1}}
        expanded={expanded === panel}
        onChange={onChange(panel)}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon color="#0F8686" sx={{color: '#0F8686'}} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{width: '100%', background: '#CBF3F3'}}
        >
          <Typography
            sx={{
              color: '#0F8686',
              fontWeight: '600',
              fontFamily: 'inherit',
            }}
          >
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {section?.subGroups.map((subgroup) => (
            <Grid
              container
              columns={12}
              mt={3}
              columnSpacing={{lg: 5, md: 5, xs: 5, sm: 5}}
              spacing={{xs: 1, md: 1, lg: 1, sm: 1}}
              key={
                subgroup?.subGroupIdentifier + subgroup?.subGroupIdentifierName
              }
            >
              {subgroup?.subGroupIdentifierName && (
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <Typography
                    variant="h6"
                    component="h2"
                    mt={2}
                    mb={1}
                    sx={{fontFamily: 'inherit', fontWeight: '600'}}
                  >
                    {subgroup?.subGroupIdentifierName}
                  </Typography>
                </Grid>
              )}
              {section.section !== 'fileupload' &&
                subgroup?.attributes.map((attribute) => (
                  <Grid
                    item
                    lg={3}
                    md={3}
                    xs={6}
                    sm={6}
                    key={attribute.attributeId}
                  >
                    <Attribute attribute={attribute} section={section.section} />
                  </Grid>
                ))}
              {section.section === 'fileupload' &&
                subgroup?.attributes.map((attribute) => (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sm={12}
                    key={attribute.attributeId}
                  >
                    <Attribute attribute={attribute} section={section.section} />
                  </Grid>
                ))}
            </Grid>
          ))}

          {components &&
            components.length > 0 &&
            components.map((component, i) => (
              <Fragment key={Math.random(i) * 100}>
                {component?.section && (
                  <Box className={'packColor'} mx={-2} px={2}>
                    {component?.subGroups.map((subgroup) => (
                      <Grid
                        container
                        columns={12}
                        mt={3}
                        columnSpacing={{lg: 5, md: 5, xs: 5, sm: 5}}
                        spacing={{xs: 1, md: 1, lg: 1, sm: 1}}
                        key={
                          subgroup?.subGroupIdentifier +
                          subgroup?.subGroupIdentifierName
                        }
                      >
                        {subgroup?.subGroupIdentifierName && (
                          <Grid item lg={12} md={12} xs={12} sm={12}>
                            <Typography
                              variant="h6"
                              component="h2"
                              mt={2}
                              mb={1}
                              sx={{fontFamily: 'inherit', fontWeight: '600'}}
                            >
                              {subgroup?.subGroupIdentifierName}
                            </Typography>
                          </Grid>
                        )}
                        {subgroup?.attributes.map((attribute) => (
                          <Grid
                            item
                            lg={3}
                            md={3}
                            xs={6}
                            sm={6}
                            key={attribute.attributeId}
                          >
                            <Attribute attribute={attribute} section={section.section} />
                          </Grid>
                        ))}
                      </Grid>
                    ))}
                  </Box>
                )}
              </Fragment>
            ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Section;
