import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {
  deleteApi,
  deleteCloudinaryAssetApi,
  deleteCurrentSessionApi,
} from '../.././services/deleteServices';

export function* deleteWatcherAsyn(action) {
  try {
    const response = yield call(deleteApi, action.payload.token);
    if (response.status >= 200 && response.status < 300) {
      const deleteApiData = yield response.data;
      yield put({type: actionTypes.DELETE_COMPLETE, payload: deleteApiData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.DELETE_ERROR, payload: error});
  }
}
export function* deleteWatcher() {
  yield takeEvery(actionTypes.DELETE, deleteWatcherAsyn);
}

export function* deleteCloudinaryAssetWorker(action) {
  try {
    const response = yield call(
      deleteCloudinaryAssetApi,
      action.payload.payload,
      action.payload.token,
    );
    if (response.status >= 200 && response.status < 300) {
      const deleteApiData = yield response.data;
      yield put({
        type: actionTypes.DELETE_CLOUDINARY_ASSET_COMPLETE,
        payload: deleteApiData,
      });
    } else {
      throw response;
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_CLOUDINARY_ASSET_ERROR,
      payload: error,
    });
  }
}

export function* deleteCloudinaryAssetWatcher() {
  yield takeEvery(
    actionTypes.DELETE_CLOUDINARY_ASSET,
    deleteCloudinaryAssetWorker,
  );
}

export function* deleteCurrentSessionAsyn(action) {
  try {
    const response = yield call(deleteCurrentSessionApi, action.payload.userId);
    if (response.status >= 200 && response.status < 300) {
      const deleteApiData = yield response.data;
      yield put({type: actionTypes.DELETE_CURRENT_SESSION_SUCCESS, payload: deleteApiData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.DELETE_CURRENT_SESSION_FAILURE, payload: error});
  }
}

export function* deleteCurrentSession() {
  yield takeEvery(actionTypes.DELETE_CURRENT_SESSION, deleteCurrentSessionAsyn);
}

export const deleteSaga = [deleteWatcher(), deleteCloudinaryAssetWatcher(), deleteCurrentSession()];
