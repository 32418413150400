import {HttpService} from './HttpService';
import {PACK_ADDITION} from '../config/apiEndpoints';
import axios from 'axios';
export async function savePackApi(token, sendPackData) {
  try {
    // const token2 = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJnWXlIa3l3ZzV5ekRid1p0dnErZ2RIUFlVVWt5YjYrSWVEVU5iRFh5bU8zUCIsImV4cCI6MTcwMTQ1NDc0MSwiaWF0IjoxNzAxNDE4NzQxfQ.5ipSHTAaVVWJXmfyUXpJmcREu_HB5ZoFZa186m_4UVFZwmDm_Qz_qM77NQQv_yugb8iJMif3gI0sRoRC8oSgkw';
    const endpoint = PACK_ADDITION;
    return (await HttpService.post( endpoint, JSON.stringify(sendPackData), {headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'}}));
    // return (await axios.post( `http://localhost:8080/vendorportal/saveComponentData?userId=${userId}`, JSON.stringify(sendPackData), {headers: {'Authorization': `Bearer ${token2}`, 'Content-Type': 'application/json'}}));
  } catch (err) {
    return {err};
  }
}
