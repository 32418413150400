import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import './Product.css';
import Localization from '../../common/Localization';
import {loadCoreAttribute} from '../../store/actions/productAction';
import {loadSave} from '../../store/actions/saveAction';
import {loadFetchData} from '../../store/actions/fetchAction';
import {useHistory} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {loadPackSave} from '../../store/actions/savePackAction';
import Select from 'react-select';
import Toaster from '../Shared/Toaster';
import {element} from 'prop-types';
import ProductReturn from './ProductReturn';
import {saveData, saveDataToast} from './productFunction';
import {isSanitized, sanitize} from '../utils/utils.js';
import {PAGE_RENDER_TIMEOUT_IN_MILLISECONDS} from '../utils/Constants';
import {useSelector} from 'react-redux';
import {
  gql,
  useBackgroundQuery,
  useLazyQuery,
  useSuspenseQuery,
} from '@apollo/client';

const GET_COMPONENTS = gql`
  query getData($param: String!, $brandName: String!) {
    getPackComponentsByPHCategory(l2Category: $param, brandName: $brandName) {
      lovId
      lovName
    }
  }
`;

export const Information = (props) => {
  const history = useHistory();
  const [getComponents, {loading, data}] = useLazyQuery(GET_COMPONENTS);
  const [brandName, setBrandName] = useState(null);
  const article = useSelector((state) => state.fetchReducer.fetchApiData);
  const [showContent, setShowContent] = useState();
  const [country, setCountry] = useState([]);
  const [, setSendSaveData] = useState();
  const [confirmProduct, setConfirmProduct] = useState();
  const userId = localStorage.getItem('userId');
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
    .token;
  const [fetchPack, setFetchPack] = useState(null);
  const toasterRef = useRef(null);
  const [varient, setVarient] = useState('');
  const [msg, setMsg] = useState('');
  const [check, setCheck] = useState(false);
  const [mrp, setMrp] = useState('');
  const [mop, setMop] = useState('');
  const [checkClickcontinue, setcheckClickcontinue] = useState(false);
  const [isPreviewPage, setisPreviewPage] = useState(false);

  useEffect(() => {
    if (token) {
      props?.coreAttributeApi(token);

      props?.fetchService({userId, token, isPreviewPage});
    }
  }, [token]);
  useEffect(() => {
    if (localStorage.getItem('url1') !== '2-Product Information') {
      history.push('/creation');
    }
  }, [history]);
  useEffect(() => {
    if (props?.errorApiCheck === true) {
      history.push('/ApiErrorPage');
    }
  }, [props?.errorApiCheck]);
  useEffect(() => {
    if (
      props?.fetchApiData &&
      props?.fetchApiData?.sections?.[0]?.subGroups != null
    ) {
      const coreAttributesR = props?.fetchApiData?.sections?.[0]?.subGroups.map(
        (subItem, subIndex) => {
          const subelement = subItem;

          subelement.attributes = subItem.attributes.map(
            (secSubItem, secSubIndex) => {
              const element = secSubItem;

              if (article && article.productType === 'pack' &&
                props?.fetchApiData?.components &&
                element.attributeId === 'SG-ManufacturerDetails-Brand' &&
                element.attributeNameValue &&
                element.attributeNameValue.length > 0
              ) {
                if (
                  article &&
                  article.l2Category &&
                  article.l2Category.length > 0
                ) {
                  const l2CategoryCode = article.l2Category;
                  const brandName = element.attributeNameValue;
                  setBrandName(brandName);

                  fetchComponents({l2CategoryCode, brandName});
                }
              }

              if (element.attrNameLov !== null) {
                element.attrNameLov.map((lovItem, lovIndex) => {
                  lovItem['value'] = lovItem.lovName;
                  lovItem['label'] = lovItem.lovName;
                  return null;
                });
              }
              if (element.attributeId === 'mop') {
                setMop(element.attributeNameValue);
              }
              if (element.attributeId === 'mrp') {
                setMrp(element.attributeNameValue);
              }
              return element;
            },
          );
          return subelement;
        },
      );
      setCountry(coreAttributesR);
      // console.log(props?.fetchApiData);
      props?.fetchApiData?.components &&
        setFetchPack(props?.fetchApiData?.components);
    } else {
      const coreAttributesR = props?.primaryData?.subGroups.map(
        (subItem, subIndex) => {
          const subelement = subItem;

          subelement.attributes = subItem.attributes.map(
            (secSubItem, secSubIndex) => {
              const element = secSubItem;
              element.attributeNameValue = '';
              if (element.attrNameLov !== null) {
                element.attrNameLov.map((lovItem, lovIndex) => {
                  lovItem['value'] = lovItem.lovName;
                  lovItem['label'] = lovItem.lovName;
                  return lovItem;
                });
              }
              if (element.attributeId === 'mop') {
                setMop('');
              }
              if (element.attributeId === 'mrp') {
                setMrp('');
              }
              return element;
            },
          );
          return subelement;
        },
      );
      // console.log(props?.fetchApiData);
      props?.fetchApiData?.components &&
        setFetchPack(props?.fetchApiData?.components);
      setCountry(coreAttributesR);
    }
  }, [props?.fetchApiData, props?.primaryData]);

  const showToastMsg = (msgs, varients) => {
    setVarient(varients);
    setMsg(msgs);
    toasterRef.current.openToast();
  };

  const fetchData = () =>{
    if (token) {
      props?.fetchService({userId, token, isPreviewPage});
    }
  };

  const saving = () => {
    saveData({
      props,
      userId,
      token,
      country,
      checkClickcontinue,
      mrp,
      mop,
      element,
    });
  };
  const getCheckVal = (checks) => {
    setCheck(checks);
  };
  const getConfirmProduct = (confirmProd) => {
    setConfirmProduct(confirmProd);
  };
  const savingToast = () => {
    saveDataToast({
      props,
      userId,
      token,
      country,
      getCheckVal,
      mrp,
      mop,
      element,
      getConfirmProduct,
    });
  };
  useEffect(() => {
    if (props?.isSuccess !== undefined) {
      if (check && props?.isSuccess) {
        showToastMsg(Localization.SuccessfulSaveToast, 'success');
        setCheck(false);
      }
      if (props?.isSuccess === false && props?.isSuccessBlank === false) {
        showToastMsg(Localization.UnsuccessfulSaveToast, 'error');
      }
    }
  }, [check, props?.isSuccess]);

  const fetchComponents = ({l2CategoryCode, brandName}) => {
    getComponents({
      variables: {
        param: l2CategoryCode,
        brandName,
      },
    });
  };

  const changeData = (id, value) => {
    if (id === 'mrp') {
      setMrp(value);
    }
    if (id === 'mop') {
      setMop(value);
    }

    if (
      fetchPack &&
      props?.fetchApiData?.components &&
      id === 'SG-ManufacturerDetails-Brand' &&
      value &&
      value.length > 0
    ) {
      if (
        article &&
        article.l2Category &&
        article.l2Category.length > 0 &&
        value
      ) {
        const l2CategoryCode = article.l2Category;
        const brandName = value;
        setBrandName(value);
        fetchComponents({l2CategoryCode, brandName});
      }
    }

    const coreAttributesR = country.map((subItem, subIndex) => {
      const subelement = subItem;
      element.isgreater = false;
      subelement.attributes = subItem.attributes.map(
        (secSubItem, secSubIndex) => {
          const element = secSubItem;
          if (
            (element.isCrossedLength === true &&
              value.length <= Number(element.length)) ||
            element.isInvalid === true
          ) {
            element.isCrossedLength = false;
            element.isInvalid = false;
          }
          if (id === element.attributeId) {
            if (element.validRegex != null) {
              const re = new RegExp(element.validRegex);
              if (value === '') {
                element.attributeNameValue = '';
              } else if (
                element.length !== '' &&
                value.length > Number(element.length)
              ) {
                element.isCrossedLength = true;
                return element;
              } else if (
                element.length !== '' &&
                element.length.includes(',')
              ) {
                if (
                  value.toString().includes('.') &&
                  Number(value.match(/\./g).length) > 1
                ) {
                  return element;
                }
                const tempd = element.length.split(',');
                const befored = Number(tempd[0]);
                const afterd = Number(tempd[1]);
                if (value.length > befored && !value.toString().includes('.')) {
                  element.isCrossedLength = true;
                } else if (value.toString().includes('.')) {
                  const temp = value.toString().split('.');
                  if (temp[1].length > afterd) {
                  } else {
                    if (re.test(value) && isSanitized(value)) {
                      element.attributeNameValue = value;
                      element.isCrossedLength = false;
                    }
                  }
                } else {
                  if (re.test(value) && isSanitized(value)) {
                    element.attributeNameValue = value;
                    element.isCrossedLength = false;
                  }
                }
                return element;
              } else if (
                element.minLength != null &&
                value.length < Number(element.minLength)
              ) {
                element.isCrossedminLength = true;

                if (re.test(value) && isSanitized(value)) {
                  element.isInvalid = false;
                  element.attributeNameValue = value;
                } else {
                  element.isInvalid = true;
                  element.isCrossedminLength = false;
                }
                return element;
              } else if (element.range != null) {
                const range = element.range.split('-');
                const min = Number(range[0]);
                const max = Number(range[1]);
                if (Number(value) >= min && Number(value) <= max) {
                  const temp = value.split('.');
                  if (temp[1].length > 2) {
                    // return null;
                    return element;
                  } else {
                    element.attributeNameValue = value;
                  }

                  element.isCrossedRange = false;
                } else {
                  element.isCrossedRange = true;
                }

                return element;
              } else if (re.test(value) && isSanitized(value)) {
                element.isInvalid = false;
                element.isCrossedLength = false;
                element.isCrossedminLength = false;
                if (
                  element.attributeDataType === 'Numeric' &&
                  value.toString() === '.00'
                ) {
                  element.zero = false;

                  return element;
                } else if (
                  element.attributeDataType === 'Numeric' &&
                  value.toString() === '.'
                ) {
                  element.zero = true;

                  element.attributeNameValue = value;
                } else {
                  element.attributeNameValue = value;
                  element.zero = true;
                }
              } else {
                element.isInvalid = true;
                element.isCrossedLength = false;
                element.isCrossedminLength = false;
                return element;
              }
            }

            if (isSanitized(value)) {
              element.attributeNameValue = value;
            }
          } else {
            element.isCrossedLength = false;
          }

          return element;
        },
      );
      return subelement;
    });
    setSendSaveData({
      section: '2-Product Information',
      subGroups: coreAttributesR,
    });
  };
  const onClickContinue = () => {
    let checkCoreAttribute = false;
    let packCoreAttribute = false;
    // Validate the Data
    const coreAttributesR = country?.map((subItem, subIndex) => {
      const subelement = subItem;
      subelement.attributes = subItem.attributes.map(
        (secSubItem, secSubIndex) => {
          const element = secSubItem;
          element.isgreater = false;
          if (
            ((!element.attributeNameValue ||element.attributeNameValue === '') && element.mandatory === 'Y') ||
            element.isCrossedminLength ||
            Number(mrp) < Number(mop)
          ) {
            checkCoreAttribute = true;
          }
          return element;
        },
      );
      return subelement;
    });
    setCountry(coreAttributesR);
    /** Data Leak issue for fetchpack */
    if (fetchPack && props?.fetchApiData?.components) {
      const packAttributesR = fetchPack?.map((packItem, index) => {
        return packItem?.sections?.map((item) => {
          if (item.section === '2-Product Information') {
            return item.subGroups.map((subItem, subIndex) => {
              const subelement = subItem;
              subelement.attributes = subItem?.attributes?.map(
                (secSubItem, secSubIndex) => {
                  const element = secSubItem;
                  if (
                    ((element.attributeNameValue === '' ||
                      element.attributeNameValue === null) &&
                      element.mandatory === 'Y') ||
                    element.isCrossedminLength
                  ) {
                    packCoreAttribute = true;
                  }
                  return element;
                },
              );
              return subelement;
            });
          }
          return null;
        });
      });
    }
    if (checkCoreAttribute === true) {
      setShowContent(true);
      setConfirmProduct(true);
      props?.saveApi({
        userId,
        token,
        sendsaveData: {section: '2-Product Information', subGroups: country},
      });
    } else if (packCoreAttribute === false && checkCoreAttribute === false) {
      setShowContent(false);
      setConfirmProduct(false);
      props?.saveApi({
        userId,
        token,
        sendsaveData: {section: '2-Product Information', subGroups: country},
      });
      setCheck(true);
      setTimeout(() => {
        history.push('/3-Company Information');
        localStorage.setItem('url2', '3-Company Information');
      }, PAGE_RENDER_TIMEOUT_IN_MILLISECONDS);
    } else {
      setShowContent(true);
      setConfirmProduct(true);
    }
  };
  const CustomStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isDisabled ?
          '49A7A2' :
          isSelected ?
          '#49A7A2' :
          isFocused ?
          '#E0E3E4' :
          null,
        color: isDisabled ? '#000000' : isSelected ? '#ffffff ' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };
  const getDisplayBox = (fieldType, item, index) => {
    if (item.fieldType === 'Select') {
      return (
        <div>
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          <Select
            aria-label={item.attributeId}
            className="select-box"
            options={item.attrNameLov}
            onBlur={saving}
            maxMenuHeight={200}
            value={{
              value: item.attributeNameValue,
              label: item.attributeNameValue,
            }}
            styles={CustomStyles}
            onChange={(e) => {
              changeData(item.attributeId, e.lovName);
            }}
          />
          <ReactTooltip id={'HSN CODE'} place="top" effect="solid">
            {Localization.HSN_code}
          </ReactTooltip>
          <div className="info-icon-hsn">
            <img
              data-tip
              data-for="HSN CODE"
              src={`${process.env.PUBLIC_URL}/assets/info.png`}
              alt="info"
            />
          </div>

          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength}
              {item.length}
            </div>
          )}
          {item.isCrossedminLength && (
            <div className="errormsg">
              {Localization.MinLength} {item.minLength}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showContent && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else if (item.fieldType === 'Textarea') {
      return (
        <div className="comlabel1">
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          <textarea
            aria-label={item.attributeId}
            name={item.attributeId}
            onBlur={saving}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            value={item.attributeNameValue}
            className="companyArea"
            readOnly={item.readOnly === 'Y' ? true : false}
            onChange={(e) => {
              if (isSanitized(e.target.value)) {
                changeData(item.attributeId, sanitize(e.target.value));
              }
            }}
          />
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          <ReactTooltip id={'keyFeatures'} place="top" effect="solid">
            {Localization.keyFeatures}
          </ReactTooltip>
          <div className="keyicon">
            <img
              data-tip
              data-for="keyFeatures"
              src={`${process.env.PUBLIC_URL}/assets/info.png`}
              alt="info"
            />
          </div>
          {item.isCrossedminLength && (
            <div className="errormsg">
              {Localization.MinLength}
              {item.minLength}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showContent && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else {
      return (
        <div>
          <div>
            {item.attributeName}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          <input
            aria-label={item.attributeId}
            name={item.attributeId}
            onBlur={saving}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            step="0.01"
            value={
              item.attributeId === 'vendorArticleCode' ?
                item.attributeNameValue.replace(/ /g, '') :
                item.attributeNameValue
            }
            className="product-rectangle"
            readOnly={item.readOnly === 'Y' ? true : false}
            onChange={(e) => {
              if (isSanitized(e.target.value)) {
                changeData(item.attributeId, sanitize(e.target.value));
              }
            }}
          />
          <ReactTooltip id={'Vendor Product name'} place="top" effect="solid">
            {Localization.VendorName}
          </ReactTooltip>
          <ReactTooltip id={'Vendor Article code'} place="top" effect="solid">
            {Localization.VendorCode}
          </ReactTooltip>
          <ReactTooltip
            id={'Vendor MOP (₹ incl. taxes)'}
            place="top"
            effect="solid"
          >
            {Localization.VendorMOP}
          </ReactTooltip>
          {item.attributeName === 'Vendor Product name' ||
          item.attributeName === 'Vendor Article code' ||
          item.attributeName === 'Vendor MOP (₹ incl. taxes)' ? (
            <span>
              <div
                className={
                  item.attributeName === 'Vendor Product name' ?
                    'info-icon' :
                    'info-icon1' &&
                      item.attributeName === 'Vendor MOP (₹ incl. taxes)' ?
                    'info-icon2' :
                    'info-icon1'
                }
              >
                <img
                  data-tip
                  data-for={
                    item.attributeName === 'Vendor Product name' ?
                      'Vendor Product name' :
                      'Vendor Article code' &&
                        item.attributeName === 'Vendor Article code' ?
                      'Vendor Article code' :
                      'Vendor MOP (₹ incl. taxes)'
                  }
                  src={`${process.env.PUBLIC_URL}/assets/info.png`}
                  alt="info"
                />
              </div>
            </span>
          ) : (
            ''
          )}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedminLength && (
            <div className="errormsg">
              {Localization.MinLength} {item.minLength}
            </div>
          )}
          {item.isInvalid && item.zero && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {item.zero === false && item.isInvalid === false && (
            <div className="errormsg">{Localization.priceZero}</div>
          )}
          {Number(mrp) < Number(mop) &&
          (item.attributeId === 'mop' || item.attributeId === 'mrp') ? (
            <div className="errormsg">{Localization.MOPError}</div>
          ) : (
            ''
          )}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showContent && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    }
  };
  const checkClickcontinueVal = (checkClickContinue) => {
    setcheckClickcontinue(checkClickContinue);
  };
  return (
    <div className="rectangle">
      <ProductReturn
        props={props}
        country={country}
        getDisplayBox={getDisplayBox}
        fetchPack={fetchPack}
        showContent={showContent}
        setShowContent={setShowContent}
        changeData={changeData}
        saveDataToast={savingToast}
        checkClickcontinue={checkClickcontinue}
        checkClickcontinueVal={checkClickcontinueVal}
        onClickContinue={onClickContinue}
        confirmProduct={confirmProduct}
        // {...(article && article.productType === 'pack' && {linkedComponents: data &&
        // data.getPackComponentsByPHCategory ? data.getPackComponentsByPHCategory: [], linkedComponentsLoading: loading})}
        linkedComponents={
          data && data.getPackComponentsByPHCategory ?
            data.getPackComponentsByPHCategory :
            []
        }
        linkedComponentsLoading={loading}
        fetchService={fetchData}
        brandName={brandName}
      />
      <div className="all-rights-reserved-product1">
        {Localization.Disclaimer}
      </div>
      <div className="all-rights-reserved-product">
        {Localization.Allrights}
      </div>
      <Toaster ref={toasterRef} varient={varient} msg={msg} />
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    primaryData: state.productReducer.primaryData,
    fetchApiData: state.fetchReducer.fetchApiData,
    savePackApiData: state.savePackReducer.savePackApiData,
    isSuccess: state.saveReducer.isSuccess,
    isSuccessBlank: state.saveReducer.isSuccessBlank,
    saveLoader: state.saveReducer.loading,
    packLoader: state.savePackReducer.loading,
    errorApiCheck: state.productReducer.errorApiCheck,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    coreAttributeApi: (query) => dispatch(loadCoreAttribute(query)),
    saveApi: ({userId, token, sendsaveData}) =>
      dispatch(loadSave({userId, token, sendsaveData})),
    fetchService: ({userId, token, isPreviewPage}) =>
      dispatch(loadFetchData({userId, token, isPreviewPage})),
    savePackApi: ({userId, token, sendPackData}) =>
      dispatch(loadPackSave({userId, token, sendPackData})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);
