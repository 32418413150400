export const cameraSingleFn = ({subItem, value}) => {
    subItem.attributes?.map(
      (cameraItem, cameraIndex) => {
        if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && value === 'No Rear Camera') {
          if (cameraItem.attributeName.startsWith('Rear')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        }
        if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && value === 'Single Rear Camera') {
          if (cameraItem.attributeName === 'Rear Camera 1') {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Rear')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && value === 'Dual Rear Camera') {
          if (cameraItem.attributeName === 'Rear Camera 1' || cameraItem.attributeName === 'Rear Camera 2') {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Rear')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && value === 'Triple Rear Camera') {
          if (cameraItem.attributeName === 'Rear Camera 1' || cameraItem.attributeName === 'Rear Camera 2' ||
                              cameraItem.attributeName === 'Rear Camera 3') {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Rear')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && value === 'Quad Rear Camera') {
          if (cameraItem.attributeName === 'Rear Camera 1' || cameraItem.attributeName === 'Rear Camera 2' ||
                              cameraItem.attributeName === 'Rear Camera 3' || cameraItem.attributeName === 'Rear Camera 4') {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Rear')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && value === 'Penta Rear Camera') {
          if (cameraItem.attributeName === 'Rear Camera 1' || cameraItem.attributeName === 'Rear Camera 2' ||
                              cameraItem.attributeName === 'Rear Camera 3' || cameraItem.attributeName === 'Rear Camera 4' || cameraItem.attributeName === 'Rear Camera 5') {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Rear')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && value === 'Hexa Rear Camera') {
          if (cameraItem.attributeName.includes('Rear Camera 1') || cameraItem.attributeName.includes('Rear Camera 2') ||
                              cameraItem.attributeName.includes('Rear Camera 3') || cameraItem.attributeName.includes('Rear Camera 4') ||
                              cameraItem.attributeName.includes('Rear Camera 4') || cameraItem.attributeName.includes('Rear Camera 5') || cameraItem.attributeName.includes('Rear Camera 6')) {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Rear')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        }
        if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && value === 'No Front Camera') {
          if (cameraItem.attributeName.startsWith('Front')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        }
        if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && value === 'No Front Camera') {
          if (cameraItem.attributeName.startsWith('Front')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        }
        if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && value === 'Single Front Camera') {
          if (cameraItem.attributeName === 'Front Camera 1') {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Front')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && value === 'Dual Front Camera') {
          if (cameraItem.attributeName === 'Front Camera 1' || cameraItem.attributeName === 'Front Camera 2') {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Front')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
            return null;
          }
        } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && value === 'Triple Front Camera') {
          if (cameraItem.attributeName === 'Front Camera 1' || cameraItem.attributeName === 'Front Camera 2' ||
                              cameraItem.attributeName === 'Front Camera 3') {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Front')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && value === 'Quad Front Camera') {
          if (cameraItem.attributeName.includes('Front Camera 1') || cameraItem.attributeName.includes('Front Camera 2') ||
                              cameraItem.attributeName.includes('Front Camera 3') || cameraItem.attributeName.includes('Front Camera 4')) {
            cameraItem.readOnly = false;
            cameraItem.mandatory = 'Y';
          } else if (cameraItem.attributeName.startsWith('Front')) {
            cameraItem.readOnly = true;
            cameraItem.mandatory = 'N';
            cameraItem.attributeNameValue = '';
          }
        }
        return null;
      });
      return null;
    };
    export const saveCameraFn = ({subItem, element}) => {
      subItem.attributes?.map(
        (cameraItem, cameraIndex) => {
          if (element.sequence === cameraItem.sequence) {
            if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && element.attributeNameValue === 'No Rear Camera') {
              if (cameraItem.attributeName.startsWith('Rear')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            }
            if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && element.attributeNameValue === 'Single Rear Camera') {
              if (cameraItem.attributeName === 'Rear Camera 1') {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Rear')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && element.attributeNameValue === 'Dual Rear Camera') {
              if (cameraItem.attributeName.includes('Rear Camera 1') || cameraItem.attributeName.includes('Rear Camera 2')) {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Rear')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && element.attributeNameValue === 'Triple Rear Camera') {
              if (cameraItem.attributeName.includes('Rear Camera 1') || cameraItem.attributeName.includes('Rear Camera 2') ||
                                      cameraItem.attributeName.includes('Rear Camera 3')) {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Rear')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && element.attributeNameValue === 'Quad Rear Camera') {
              if (cameraItem.attributeName.includes('Rear Camera 1') || cameraItem.attributeName.includes('Rear Camera 2') ||
                                      cameraItem.attributeName.includes('Rear Camera 3') || cameraItem.attributeName.includes('Rear Camera 4')) {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Rear')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && element.attributeNameValue === 'Penta Rear Camera') {
              if (cameraItem.attributeName.includes('Rear Camera 1') || cameraItem.attributeName.includes('Rear Camera 2') ||
                                      cameraItem.attributeName.includes('Rear Camera 3') || cameraItem.attributeName.includes('Rear Camera 4') ||
                                      cameraItem.attributeName.includes('Rear Camera 4')) {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Rear')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-RearCameraConfiguration')].attributeName === 'Rear Camera Configuration' && element.attributeNameValue === 'Hexa Rear Camera') {
              if (cameraItem.attributeName.includes('Rear Camera 1') || cameraItem.attributeName.includes('Rear Camera 2') ||
                                      cameraItem.attributeName.includes('Rear Camera 3') || cameraItem.attributeName.includes('Rear Camera 4') ||
                                      cameraItem.attributeName.includes('Rear Camera 4') || cameraItem.attributeName.includes('Rear Camera 5') || cameraItem.attributeName.includes('Rear Camera 6')) {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Rear')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            }
            if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && element.attributeNameValue === 'Single Front Camera') {
              if (cameraItem.attributeName.includes('Front Camera 1')) {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Front')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && element.attributeNameValue === 'Dual Front Camera') {
              if (cameraItem.attributeName.includes('Front Camera 1') || cameraItem.attributeName.includes('Front Camera 2')) {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Front')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && element.attributeNameValue === 'Triple Front Camera') {
              if (cameraItem.attributeName.includes('Front Camera 1') || cameraItem.attributeName.includes('Front Camera 2') ||
                                      cameraItem.attributeName.includes('Front Camera 3')) {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Front')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            } else if (subItem.attributes[subItem.attributes.findIndex((data) => data.attributeId === 'SG-M&TCamera-FrontCameraConfiguration')].attributeName === 'Front Camera Configuration' && element.attributeNameValue === 'Quad Front Camera') {
              if (cameraItem.attributeName.includes('Front Camera 1') || cameraItem.attributeName.includes('Front Camera 2') ||
                                      cameraItem.attributeName.includes('Front Camera 3') || cameraItem.attributeName.includes('Front Camera 4')) {
                cameraItem.readOnly = false;
                cameraItem.mandatory = 'Y';
              } else if (cameraItem.attributeName.startsWith('Front')) {
                cameraItem.readOnly = true;
                cameraItem.mandatory = 'N';
              }
            }
          }
          return null;
        });
        return null;
    };
export default (cameraSingleFn, saveCameraFn);
