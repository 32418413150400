import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {fetchService} from '../../services/fetchServices';

export function* fetchWatcherAsyn(action) {
  try {
    const response = yield call(
      fetchService,
      action.payload.token,
      action.payload.isPreviewPage,
    );
    if (response.status >= 200 && response.status < 300) {
      const fetchApiData = yield response.data;
      yield put({type: actionTypes.FETCH_COMPLETE, payload: fetchApiData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.FETCH_ERROR, payload: error});
  }
}
export function* fetchWatcher() {
  yield takeEvery(actionTypes.FETCH, fetchWatcherAsyn);
}

export const fetchSaga = [fetchWatcher()];
