import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import './Dimension.css';
import Localization from '../../common/Localization';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {loadCoreAttributeDimension} from '../../store/actions/dimensionAction';
import {loadSave} from '../../store/actions/saveAction';
import {loadFetchData} from '../../store/actions/fetchAction';
import {loadPackSave} from '../../store/actions/savePackAction';
import Toaster from '../Shared/Toaster';
import DimensionReturnData from './DimensionReturnData';
import {saveData, saveDataToast, elementRange} from './dimensionSave';
import {preventNonNumericalInput} from '../Shared/nonNumericalInputFunction';
import {PAGE_RENDER_TIMEOUT_IN_MILLISECONDS} from '../utils/Constants';
export const Dimension = (props) => {
  const {register} = useForm();
  const history = useHistory();
  const [, setData1] = useState([]);
  const [country, setCountry] = useState([]);
  const [showData, setShowData] = useState();
  const [, setSendSaveData] = useState({});
  const [confirmDimension, setConfirmDimension] = useState();
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
    .token;
  const formHeader = JSON.parse(localStorage.getItem('formHeader'));
  console.log(formHeader);
  const group = formHeader.group;
  const userId = localStorage.getItem('userId');
  const [callapi] = useState(true);
  const [fetchPack, setFetchPack] = useState(null);
  const toasterRef = useRef(null);
  const [varient, setVarient] = useState('');
  const [msg, setMsg] = useState('');
  const [check, setCheck] = useState(false);
  const [, setContDisable] = useState(false);
  const [checkClickcontinue, setcheckClickcontinue] = useState(false);
  const [maximum, setMax] = useState('');
  const [isPreviewPage, setisPreviewPage] = useState(false);

  let dimensionWidth;
  let dimensionHeight;
  let dimensionLengthInch;
  let dimensionWidthInch;
  let dimensionHeightInch;
  let dimensionLength;
  let dimensionGm;
  let dimensionKg;
  let dimensionQty;
  useEffect(() => {
    if (token && callapi) {
      props?.coreAttributeDimensionApi(token);
    }
  }, [callapi]);
  useEffect(() => {
    if (localStorage.getItem('url3') !== '4-Dimensions') {
      history.push('/3-Company Information');
    }
  }, [history]);
  useEffect(() => {
    if (props?.errorApiCheck === true) {
      history.push('/ApiErrorPage');
    }
  }, [props?.errorApiCheck]);
  useEffect(() => {
    if (
      props?.fetchApiData &&
      props?.fetchApiData?.sections?.[
        props?.fetchApiData.sections?.findIndex(
          (p) => p.section === '4-Dimensions',
        )
      ]?.section === '4-Dimensions'
    ) {
      setCountry(
        props?.fetchApiData?.sections[
          props?.fetchApiData.sections?.findIndex(
            (p) => p.section === '4-Dimensions',
          )
        ]?.subGroups,
      );
      if (props?.fetchApiData?.components) {
        setFetchPack(props?.fetchApiData?.components);
      }
    } else {
      const coreAttributesR = props?.primaryData?.subGroups.map(
        (subItem, subIndex) => {
          const subelement = subItem;
          subelement.attributes = subItem.attributes.map(
            (secSubItem, secSubIndex) => {
              const element = secSubItem;
              element.attributeNameValue = '';
              return element;
            },
          );
          return subelement;
        },
      );
      if (props?.fetchApiData?.components) {
        setFetchPack(props?.fetchApiData?.components);
      }
      setCountry(coreAttributesR);
    }
  }, [props.primaryData, props.fetchApiData]);
  const roundToTwo = (num) => {
    return +(Math.round(num + 'e+2') + 'e-2');
  };
  const roundToThree = (num) => {
    return +(Math.round(num + 'e+3') + 'e-3');
  };
  const negative = (evt) => {
    preventNonNumericalInput(evt);
  };
  const changeValue = (id, value) => {
    const coreAttributesR = country.map((subItem, subIndex) => {
      const subelement = subItem;
      subelement.attributes = subItem.attributes.map(
        (secSubItem, secSubIndex) => {
          const element = secSubItem;
          if (
            (element.isCrossedLength === true &&
              value.length <= Number(element.length)) ||
            element.isInvalid === true ||
            element.isCrossedRange === true
          ) {
            element.isCrossedLength = false;
            element.isInvalid = false;
            element.isCrossedRange = false;
          }
          if (id === element.attributeId) {
            if (element.validRegex != null) {
              const re = new RegExp(element.validRegex);
              // const re = new RegExp('^(\\d{0,3})(\\.(?!00)\\d{0,3})?$');
              // const re = new RegExp('^(?!0)(\d{0,3})(\.(?!00)\d{0,3})?$');
              if (value === '') {
                element.attributeNameValue = '';

                if (element.uom !== null && element.uom === 'UoM-g') {
                  subItem.attributes[secSubIndex + 1].attributeNameValue = '';
                }
                if (element.uom !== null && element.uom === 'UoM-Kg') {
                  subItem.attributes[secSubIndex - 1].attributeNameValue = '';
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-cm' &&
                  element.attributeName === 'Width (cm)'
                ) {
                  subItem.attributes[secSubIndex + 3].attributeNameValue = '';
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-Inches' &&
                  element.attributeName === 'Width (in)'
                ) {
                  subItem.attributes[secSubIndex - 3].attributeNameValue = '';
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-cm' &&
                  element.attributeName === 'Length (cm)'
                ) {
                  subItem.attributes[secSubIndex + 3].attributeNameValue = '';
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-Inches' &&
                  element.attributeName === 'Length (in)'
                ) {
                  subItem.attributes[secSubIndex - 3].attributeNameValue = '';
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-cm' &&
                  element.attributeName === 'Height (cm)'
                ) {
                  subItem.attributes[secSubIndex + 3].attributeNameValue = '';
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-Inches' &&
                  element.attributeName === 'Height (in)'
                ) {
                  subItem.attributes[secSubIndex - 3].attributeNameValue = '';
                }
              } else if (
                element.length !== '' &&
                value.length > Number(element.length)
              ) {
                element.isCrossedLength = true;
                return element;
              } else if (
                element.range != null &&
              //  element.attributeId !== 'quantityInMasterCarton'
                element.attributeId === 'quantityInMasterCarton'
              ) {
                elementRange({
                  element,
                  value,
                  subItem,
                  secSubIndex,
                  roundToTwo,
                  roundToThree,
                  setMax,
                });
                return element;
              } else if (re.test(value)) {
                // } else if (true) {
                element.isInvalid = false;
                element.isCrossedLength = false;
                element.attributeNameValue = value;
                if (element.uom != null && element.uom === 'UoM-g') {
                  subItem.attributes[secSubIndex + 1].attributeNameValue =
                    '' + roundToThree(Number(value) / 1000);

                    if (element.attributeId === 'netWeightCD') {
                      subItem.attributes[secSubIndex + 1].attributeNameValue =
                      '' + roundToTwo(Number(value) / 1000);
                    }
                }
                if (element.uom != null && element.uom === 'UoM-Kg') {
                  subItem.attributes[secSubIndex - 1].attributeNameValue =
                    '' + roundToThree(Number(value) * 1000);

                    if (element.attributeId === 'weightUnitCD - kg') {
                      subItem.attributes[secSubIndex - 1].attributeNameValue =
                      '' + roundToTwo(Number(value) * 1000);
                    }
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-cm' &&
                  element.attributeName === 'Width (cm)'
                ) {
                  subItem.attributes[secSubIndex + 3].attributeNameValue =
                    '' + roundToTwo(Number(value) / 2.54);
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-Inches' &&
                  element.attributeName === 'Width (in)'
                ) {
                  subItem.attributes[secSubIndex - 3].attributeNameValue =
                    '' + roundToTwo(Number(value) / 0.393701);
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-cm' &&
                  element.attributeName === 'Length (cm)'
                ) {
                  subItem.attributes[secSubIndex + 3].attributeNameValue =
                    '' + roundToTwo(Number(value) / 2.54);
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-Inches' &&
                  element.attributeName === 'Length (in)'
                ) {
                  subItem.attributes[secSubIndex - 3].attributeNameValue =
                    '' + roundToTwo(Number(value) / 0.393701);
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-cm' &&
                  element.attributeName === 'Height (cm)'
                ) {
                  subItem.attributes[secSubIndex + 3].attributeNameValue =
                    '' + roundToTwo(Number(value) / 2.54);
                }
                if (
                  element.uom !== null &&
                  element.uom === 'UoM-Inches' &&
                  element.attributeName === 'Height (in)'
                ) {
                  subItem.attributes[secSubIndex - 3].attributeNameValue =
                    '' + roundToTwo(Number(value) / 0.393701);
                }
              } else {
                element.isInvalid = true;
                element.isCrossedLength = false;
                return element;
              }
            }
            element.attributeNameValue = value;
            if (
              element.attributeId === 'widthCD' ||
              element.attributeId === 'lengthCD' ||
              element.attributeId === 'heightCD' ||
              element.attributeId === 'lengthCD - inch' ||
              element.attributeId === 'widthCD - inch' ||
              element.attributeId === 'heightCD - inch' ||
              element.attributeId === 'netWeightCD' ||
              element.attributeId === 'weightUnitCD - kg' ||
              element.attributeId === 'quantityInMasterCarton'
            ) {
              if (element.attributeNameValue !== '') {
                country.forEach((checkItem, checkIndex) => {
                  const checkelement = checkItem;
                  checkelement.attributes = checkItem.attributes.map(
                    (checkSubItem) => {
                      if (
                        checkSubItem.attributeId === 'widthCD' ||
                        checkSubItem.attributeId === 'lengthCD' ||
                        checkSubItem.attributeId === 'heightCD' ||
                        checkSubItem.attributeId === 'lengthCD - inch' ||
                        checkSubItem.attributeId === 'widthCD - inch' ||
                        checkSubItem.attributeId === 'heightCD - inch' ||
                        checkSubItem.attributeId === 'netWeightCD' ||
                        checkSubItem.attributeId === 'weightUnitCD - kg' ||
                        checkSubItem.attributeId === 'quantityInMasterCarton'
                      ) {
                        checkSubItem.mandatory = 'Y';
                      }
                      return checkSubItem;
                    },
                  );
                  return checkelement;
                });
              } else {
                country.forEach((checkItem, checkIndex) => {
                  const checkelement = checkItem;
                  checkelement.attributes = checkItem.attributes.map(
                    (checkSubItem) => {
                      if (
                        checkSubItem.attributeId === 'widthCD' ||
                        checkSubItem.attributeId === 'lengthCD' ||
                        checkSubItem.attributeId === 'heightCD' ||
                        checkSubItem.attributeId === 'lengthCD - inch' ||
                        checkSubItem.attributeId === 'widthCD - inch' ||
                        checkSubItem.attributeId === 'heightCD - inch' ||
                        checkSubItem.attributeId === 'netWeightCD' ||
                        checkSubItem.attributeId === 'weightUnitCD - kg' ||
                        checkSubItem.attributeId === 'quantityInMasterCarton'
                      ) {
                        if (checkSubItem.attributeId === 'lengthCD') {
                          dimensionLength = checkSubItem.attributeNameValue;
                        }
                        if (checkSubItem.attributeId === 'heightCD') {
                          dimensionHeight = checkSubItem.attributeNameValue;
                        }
                        if (checkSubItem.attributeId === 'widthCD') {
                          dimensionWidth = checkSubItem.attributeNameValue;
                        }
                        if (checkSubItem.attributeId === 'lengthCD - inch') {
                          dimensionLengthInch = checkSubItem.attributeNameValue;
                        }
                        if (checkSubItem.attributeId === 'widthCD - inch') {
                          dimensionWidthInch = checkSubItem.attributeNameValue;
                        }
                        if (checkSubItem.attributeId === 'heightCD - inch') {
                          dimensionHeightInch = checkSubItem.attributeNameValue;
                        }
                        if (
                          checkSubItem.attributeId === 'quantityInMasterCarton'
                        ) {
                          dimensionQty = checkSubItem.attributeNameValue;
                        }
                        if (checkSubItem.attributeId === 'netWeightCD') {
                          dimensionGm = checkSubItem.attributeNameValue;
                        }
                        if (checkSubItem.attributeId === 'weightUnitCD - kg') {
                          dimensionKg = checkSubItem.attributeNameValue;
                        }
                        if (
                          dimensionWidth === '' &&
                          dimensionLength === '' &&
                          dimensionHeight === '' &&
                          dimensionLengthInch === '' &&
                          dimensionWidthInch === '' &&
                          dimensionHeightInch === '' &&
                          dimensionGm === '' &&
                          dimensionKg === '' &&
                          dimensionQty === ''
                        ) {
                          checkItem.attributes.map((data) => {
                            if (
                              data.attributeId === 'widthCD' ||
                              data.attributeId === 'lengthCD' ||
                              data.attributeId === 'heightCD' ||
                              data.attributeId === 'lengthCD - inch' ||
                              data.attributeId === 'widthCD - inch' ||
                              data.attributeId === 'heightCD - inch' ||
                              data.attributeId === 'netWeightCD' ||
                              data.attributeId === 'weightUnitCD - kg' ||
                              data.attributeId === 'quantityInMasterCarton'
                            ) {
                              data.mandatory = 'N';
                            }
                          });
                        }
                      }
                      return checkSubItem;
                    },
                  );
                  return checkelement;
                });
              }
            }
          }
          return element;
        },
      );
      return subelement;
    });
    setCountry(coreAttributesR);
    setData1(value);
    setSendSaveData({section: '4-Dimensions', subGroups: coreAttributesR});
  };

  const showToastMsg = (msgs, varients) => {
    setVarient(varients);
    setMsg(msgs);
    toasterRef.current.openToast();
  };
  const saving = () => {
    saveData({props, userId, token, country, checkClickcontinue});
  };
  const getCheckVal = (checks) => {
    setCheck(checks);
  };
  const getConfirmDimension = (confirmDim) => {
    setConfirmDimension(confirmDim);
  };
  const savingToast = async () => {
    await saveLocalPackUserData().then(({packCoreAttribute, packAttributesR}) => {
      if (fetchPack) {
          saveDataToast({
            props,
            userId,
            token,
            country: packAttributesR.flat().filter((val) => val != null).flat(),
            fetchPack,
            getCheckVal,
            getConfirmDimension,
          });
      } else {
        saveDataToast({
          props,
          userId,
          token,
          country,
          fetchPack,
          getCheckVal,
          getConfirmDimension,
        });
      }
    });
  };
  useEffect(() => {
    if (props?.isSuccess !== undefined) {
      if (check && props?.isSuccess) {
        showToastMsg(Localization.SuccessfulSaveToast, 'success');
        setCheck(false);
      }
      if (props?.isSuccess === false && props?.isSuccessBlank === false) {
        showToastMsg(Localization.UnsuccessfulSaveToast, 'error');
        setContDisable(true);
      }
    }
  }, [check, props.isSuccess]);

  const onClickContinue = () => {
    let checkAttribute1 = false;
    /* eslint-disable no-unused-vars */
    const coreAttributesR = country?.map((subItem, subIndex) => {
      const subelement = subItem;
      subelement.attributes = subItem.attributes.map(
        (secSubItem, secSubIndex) => {
          const element = secSubItem;
          if (
            element.attributeNameValue === '' &&
            element.mandatory === 'Y' &&
            group.toLowerCase() != 'services'
          ) {
            checkAttribute1 = true;
          }
          return element;
        },
      );
      return subelement;
    });
    if (checkAttribute1 === true) {
      setShowData(true);
      setConfirmDimension(true);
      props.saveApi({
        userId,
        token,
        sendsaveData: {section: '4-Dimensions', subGroups: country},
      });
    } else {
      setShowData(false);
      setConfirmDimension(false);
      props.saveApi({
        userId,
        token,
        sendsaveData: {section: '4-Dimensions', subGroups: country},
      });
      setCheck(true);
      setTimeout(() => {
        history.push('/5-Item Details');
        localStorage.setItem('url4', '5-Item Details');
      }, PAGE_RENDER_TIMEOUT_IN_MILLISECONDS);
    }
    setCountry(coreAttributesR);
  };
  const checkClickcontinueVal = (checkClickContinue) => {
    setcheckClickcontinue(checkClickContinue);
  };
  const packClickContinue = () => {
    let packCoreAttribute = false;
    if (fetchPack) {
      const packAttributesR =
        fetchPack &&
        fetchPack?.map((packItem, index) => {
          return packItem?.sections?.map((item) => {
            if (item.section === '4-Dimensions') {
              return item.subGroups.map((subItem, subIndex) => {
                const subelement = subItem;
                subelement.attributes = subItem?.attributes?.map(
                  (secSubItem, secSubIndex) => {
                    const element = secSubItem;
                    if (
                      (element.attributeNameValue === '' ||
                        element.attributeNameValue === null) &&
                      element.mandatory === 'Y'
                    ) {
                      packCoreAttribute = true;
                    }
                    return element;
                  },
                );
                return subelement;
              });
            }
            return null;
          });
        });
      setCountry(packAttributesR);
    }
    if (packCoreAttribute) {
      setShowData(true);
      setConfirmDimension(true);
    } else {
      setCheck(true);
      setTimeout(() => {
        history.push('/5-Item Details');
        localStorage.setItem('url4', '5-Item Details');
      }, PAGE_RENDER_TIMEOUT_IN_MILLISECONDS);
      setShowData(false);
      setConfirmDimension(false);
    }
  };
  const getDisplayBox = (fieldType, item, index) => {
    if (item.fieldType === 'Select') {
      return (
        <div>
          <div className="bigCartonLabel">
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>

          <select
            aria-label={item.attributeId}
            className="product-box-product1"
            type={item.attributeDataType === 'enum'}
            name={item.attributeId}
            onBlur={saving}
            value={item.attributeNameValue}
            ref={register}
            onChange={(e) => {
              changeValue(item.attributeId, e.target.value);
            }}
          >
            <option>Select</option>
            {item &&
              Array.isArray(item.attrNameLov) &&
              item.attrNameLov.map((e, key) => {
                return <option key={e.lovId}>{e.lovName}</option>;
              })}
          </select>
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">
              {Localization.MaxRange} {maximum}
            </div>
          )}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else if (item.fieldType === 'Textarea') {
      return (
        <div className="comlabel1">
          <div className="bigCartonLabel">
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          <textarea
            aria-label={item.attributeId}
            name={item.attributeId}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            value={item.attributeNameValue}
            onBlur={saving}
            className="arearectangle"
            ref={register}
            readOnly={item.readOnly === 'Y' ? true : false}
            onChange={(e) => {
              changeValue(item.attributeId, sanitize(e.target.value));
            }}
          />
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">
              {Localization.MaxRange} {maximum}
            </div>
          )}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else {
      return (
        <div>
          <div className="bigCartonLabel">
            {item.attributeName}

            {item.mandatory === 'Y' && group.toLowerCase() != 'services' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          <input
            aria-label={item.attributeId}
            name={item.attributeId}
            onBlur={saving}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            value={item.attributeNameValue}
            className="dm-box"
            step={item.attributeId === 'quantityInMasterCarton' ? '1' : '0.01'}
            inputMode="numeric"
            onKeyPress={(evt) => {
              item.attributeDataType !== 'String' && negative(evt);
            }}
            onKeyDown={(evt) =>
              (evt.key === 'e' ||
                evt.key === 'E' ||
                evt.key === '+' ||
                evt.key === '-') &&
              evt.preventDefault() &&
              negative(evt)
            }
            ref={register}
            readOnly={
              item.readOnly === 'Y' || group.toLowerCase() == 'services' ?
                true :
                false
            }
            disabled={group.toLowerCase() == 'services' ? true : false}
            {...(group.toLowerCase() == 'services' && {
              style: {background: '#d3d3d3'},
            })}
            onChange={(e) => {
              changeValue(item.attributeId, e.target.value);
            }}
          />
          {item.attributeName === 'NetWt (gms)' ||
          item.attributeName === 'Height (cm)' ? (
            <span>
              <div className="arrow-icon-1">
                <img
                  className="arrow-image"
                  src={process.env.PUBLIC_URL + 'assets/arrow.png'}
                  alt="arrow"
                />
              </div>
            </span>
          ) : (
            ''
          )}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">
              {Localization.MaxRange} {maximum}
            </div>
          )}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    }
  };

  /**
   * Saves fetchPack data to country variable
   */
  const saveLocalPackUserData = async () => {
    let packCoreAttribute = false;
    let packAttributesR = null;
    if (fetchPack) {
       packAttributesR =
        fetchPack &&
        fetchPack?.map((packItem, index) => {
          return packItem?.sections?.map((item) => {
            if (item.section === '4-Dimensions') {
              return item.subGroups.map((subItem, subIndex) => {
                const subelement = subItem;
                subelement.attributes = subItem?.attributes?.map(
                  (secSubItem, secSubIndex) => {
                    const element = secSubItem;
                    if (
                      (element.attributeNameValue === '' ||
                        element.attributeNameValue === null) &&
                      element.mandatory === 'Y'
                    ) {
                      packCoreAttribute = true;
                    }
                    return element;
                  },
                );
                return subelement;
              });
            }
            return null;
          });
        });
      await setCountry(packAttributesR[1]);
    }
    return {packCoreAttribute, packAttributesR};
  };
  return (
    <div className="rectangle">
      <DimensionReturnData
        props={props}
        country={country}
        getDisplayBox={getDisplayBox}
        fetchPack={fetchPack}
        showData={showData}
        setShowData={setShowData}
        savePackApi={props.savePackApi}
        saveDataToast={savingToast}
        checkClickcontinue={checkClickcontinue}
        checkClickcontinueVal={checkClickcontinueVal}
        confirmDimension={confirmDimension}
        onClickContinue={onClickContinue}
        packClickContinue={packClickContinue}
      />
      <div className="all-rights-reserved-dimension1">
        {Localization.Disclaimer}
      </div>
      <div className="all-rights-reserved-dimension">
        {Localization.Allrights}
      </div>
      <Toaster ref={toasterRef} varient={varient} msg={msg} />
    </div>
  );
};
export const mapStateToProps = (state) => {
  return {
    primaryData: state.dimensionReducer.primaryData,
    fetchApiData: state.fetchReducer.fetchApiData,
    savePackApiData: state.savePackReducer.savePackApiData,
    isSuccess: state.saveReducer.isSuccess,
    isSuccessBlank: state.saveReducer.isSuccessBlank,
    saveLoader: state.saveReducer.loading,
    packLoader: state.savePackReducer.loading,
    errorApiCheck: state.dimensionReducer.errorApiCheck,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    coreAttributeDimensionApi: (query) =>
      dispatch(loadCoreAttributeDimension(query)),
    saveApi: ({userId, token, sendsaveData}) =>
      dispatch(loadSave({userId, token, sendsaveData})),
    fetchService: ({userId, token, isPreviewPage}) =>
      dispatch(loadFetchData({userId, token, isPreviewPage})),
    savePackApi: ({userId, token, sendPackData}) =>
      dispatch(loadPackSave({userId, token, sendPackData})),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dimension);
