import React from 'react';
import './Layout.css';

function Layout() {
  return (
    <div>
      <div className="logo">
        <img src={process.env.PUBLIC_URL + '/assets/MicrosoftTeams-image.png'} height='80px' alt="logo" />
      </div>
    </div>
  );
}


export default Layout;
