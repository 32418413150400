import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function loginReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_VALIDATE_OTP:
      return {...state, loading: true};
    case actionTypes.VALIDATE_OTP_LOADING_COMPLETE:
      return {...state, loading: false, otpData: action.payload};
    case actionTypes.VALIDATE_OTP_LOADING_ERROR:
      return {...state, loading: false, otpData: action.payload};
    case actionTypes.SEND_OTP:
      return {...state, loading: true, errorApiCheck: false};
    case actionTypes.SEND_OTP_COMPLETE:
      return {...state, loading: false, otpMsg: action.payload, errorApiCheck: false};
    case actionTypes.SEND_OTP_ERROR:
      return {...state, loading: false, otpMsg: action.payload, errorApiCheck: true};
    case actionTypes.VALIDATE_OTP:
      return {...state, loading: true};
    case actionTypes.VALIDATE_OTP_COMPLETE:
      return {...state, loading: false, valotpMsg: action.payload};
    case actionTypes.VALIDATE_OTP_ERROR:
      return {...state, loading: false, valotpMsg: action.payload};
    case actionTypes.API_ERROR:
      return {...state, loading: false, apiErrorMessage: action.payload, errorApiCheck: true};
    case actionTypes.LOGOUT:
      return {...state, loading: true};
    case actionTypes.LOGOUT_COMPLETE:
      return {};
    case actionTypes.LOGOUT_ERROR:
      return {...state, loading: false};
     case actionTypes.VALIDATE_OTP_ALREADY_LOGGEDIN_ERROR:
       return {...state, loading: false, userAlreadyLoggedin: action.payload, errorMultipleLogin: true};
    default:
      return state;
  }
}
