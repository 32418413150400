import config from '../../config/config';

export const PHOTOS_FETCHED = 'PHOTOS_FETCHED';
export const PHOTOS_UPLOADED = 'PHOTOS_UPLOADED';
export const DELETE_UPLOADED_PHOTO = 'DELETE_UPLOADED_PHOTO';
export const UPDATE_UPLOADED_PHOTO = 'UPDATE_UPLOADED_PHOTO';


// export const XSS_SANITIZE_REGEX = /[*\"<>[\]{}`\\()';&$%#^!?=~]/gi;
export const XSS_SANITIZE_REGEX = /[\"<>[\]{}`\\';&$%#^!?=~]/gi;
export const EMAIL_VALIDATION_REGEX = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;

export const PAGE_RENDER_TIMEOUT_IN_MILLISECONDS = 7000;
export const IMAGE_BASE_URL = `https://res.cloudinary.com/${config.cloudName}`;

export const AUTO_LOGOUT_IN_MILLISECONDS = 1 * 45 * 60 * 1000;
export const AUTO_LOGOUT_WARNING_IN_MILLISECONDS = 30 * 60 * 1000;
