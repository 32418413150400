import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {classificationAttributeCompanyApi} from '../../services/companyService';


export function* companyWatcherAsyn(action) {
  try {
    const response = yield call(classificationAttributeCompanyApi, action.payload);
    if (response.status >= 200 && response.status < 300) {
      const primaryData = yield response.data;
      yield put({type: actionTypes.CLASSIFICATION_ATTRIBUTE_COMPANY_COMPLETE, payload: primaryData});
    } else if (!(response.status >= 200 && response.status < 300)) {
      yield put({type: actionTypes.API_ERROR, payload: null});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.CLASSIFICATION_ATTRIBUTE_COMPANY_ERROR, payload: error});
  }
}
export function* companyWatcher() {
  yield takeEvery(actionTypes.CLASSIFICATION_ATTRIBUTE_COMPANY, companyWatcherAsyn);
}
export const companySaga=[
  companyWatcher(),

];
