import {HttpService} from './HttpService';
import {SEND_OTP, VALIDATE_OTP, LOGOUT} from '../config/apiEndpoints';
import axios from 'axios';
import {getAuthToken} from '../components/utils/AuthenticationUtils';
import localization from '../common/Localization';

export async function sendOtpService(postData) {
  try {
    // const endpoint = SEND_OTP + `${userId}`;
    // const response = await HttpService.post(endpoint, JSON.stringify(postData), {

    // @EPICS-2117 need to uncomment
      const endpoint = SEND_OTP;
      // const endpoint = 'http://localhost:8080/vendorportal/sendOTP/';
      const response = await HttpService.post(endpoint, postData, {})
        .then((res)=>{
          return res;
        })
        .catch((err) =>{
          return {err};
        });
    return response;
  } catch (err) {
    return {err};
  }
}
export async function validateOtpService(userId, otp) {
  try {
    // const endpoint = VALIDATE_OTP + `${userId}`;

    // @EPICS-2117 need to uncomment
    const endpoint = VALIDATE_OTP;
    let flag='N';
    // const endpoint = 'http://localhost:8080/vendorportal/validateOTP/';
    const response = await HttpService.post(endpoint, ({'otpnum': otp, 'loginId': userId}))
    .then((res)=>{
      return res;
    })
    .catch((err) =>{
       if (err.response.data.message===localization.UserAlreadyLoggedin) {
        flag = 'Y';
      }
      return {err};
    });
    if (flag==='Y') {
      return {status: localization.UserAlreadyLoggedin, data: localization.UserAlreadyLoggedin};
    }
 return {status: 'success', data: response.data};
} catch (err) {
return {err};
}
}

export async function fetchvalidateOTP() {
  try {
    const endpoint = 'validateOTP';
    return (await HttpService.get(endpoint));
  } catch (err) {
    return {data: []};
  }
}

export async function logoutService() {
  try {
      const endpoint = LOGOUT;
    // const endpoint = 'http://localhost:8080/vendorportal'+LOGOUT;
    const headers = {
      'Authorization': `Bearer ${getAuthToken()}`,
    };
    // return (await HttpService.post(endpoint));
     const response = await HttpService.post(endpoint, null, {headers});
    // const response = await axios.post(endpoint, null, {headers});
    if ( typeof window !== 'undefined') {
     // window.localStorage.clear();
      setTimeout(() => {
        window.localStorage.clear();
        window.location.href = '/login';
      }, 1000);
    }
    return response;
  } catch (error) {
   // window.localStorage.clear();
    setTimeout(() => {
      window.localStorage.clear();
      window.location.href = '/login';
    }, 1000);
    return {data: null};
  }
}
