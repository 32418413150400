import React, {useEffect, useState, useRef, useContext} from 'react';
import Grid from '@mui/material/Grid';
import {Box, Skeleton} from '@mui/material';
import FilterAttribute from './FilterAttribute';
import classes from './FilterAttributes.module.css';
import {SearchContext} from '../../../context/SearchContext';

const FilterAttributes = () => {
  const context = useContext(SearchContext);
  const {subGroups} = context;
  return (
    <>
      {subGroups &&
        subGroups.length > 0 &&
        subGroups.map((subGroup) => (
          <section key={subGroup.subGroupIdentifier}>
            <header
              aria-label={subGroup.subGroupIdentifier}
              className={`${classes['filter-group__header']}`}
            >
              {subGroup.subGroupIdentifierName}
            </header>
            <Grid
              container
              justifycontent="space-around"
              style={{width: '96%'}}
            >
              {subGroup.attributes.map((attribute) => (
                <Grid
                  item
                  lg={12}
                  xl={12}
                  style={{width: '100%'}}
                  key={attribute.attributeId}
                >
                  <FilterAttribute attribute={attribute} />
                </Grid>
              ))}
            </Grid>
          </section>
        ))}

      {!subGroups && (
        <Box sx={{paddingRight: '15px'}}>
          <Skeleton
            className={classes['filter-group__header__skeleton']}
            variant="text"
            width={150}
          />
          <div className={classes['filter__container']}>
            <Skeleton
              variant="text"
              width={100}
              className={classes['filter__main_label']}
            />{' '}
            <Skeleton variant="rectangular" height={35} />{' '}
          </div>
          <div className={classes['filter__container']}>
            <Skeleton
              variant="text"
              width={100}
              className={classes['filter__main_label']}
            />{' '}
            <Skeleton variant="rectangular" height={35} />{' '}
          </div>
          <div className={classes['filter__container']}>
            <Skeleton
              variant="text"
              width={100}
              className={classes['filter__main_label']}
            />{' '}
            <Skeleton variant="rectangular" height={35} />{' '}
          </div>
          <div className={classes['filter__container']}>
            <Skeleton
              variant="text"
              width={100}
              className={classes['filter__main_label']}
            />{' '}
            <Skeleton variant="rectangular" height={35} />{' '}
          </div>
        </Box>
      )}
    </>
  );
};

export default React.memo(FilterAttributes);
