import React, {useEffect, useState, useRef} from 'react';
import Grid from '@mui/material/Grid';

const FilterRadio = ({attribute: item, onChange}) => {
  return (
    <>
      <Grid container justifycontent="space-around" style={{width: '100%'}}>
        {item.attrNameLov.map((radioItem, radioIndex) => {
          return (
            <Grid
              item
              lg={12}
              xl={12}
              style={{width: '100%'}}
              key={radioItem.lovId}
            >
              <span key={radioIndex}>
                <input
                  style={{marginLeft: 0}}
                  aria-label={item.attributeId}
                  type="radio"
                  value={radioItem.lovName}
                  checked={item.attributeNameValue === radioItem.lovId}
                  onClick={() => onChange({...item, attributeNameValue: radioItem.lovId}) }
                />
                <span className="radiolabel" style={{fontSize: '0.9rem'}}>
                  {radioItem.lovName}
                </span>
              </span>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default FilterRadio;
