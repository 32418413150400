import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {youTubeUrlApi} from '../.././services/youtubeUrlService';


export function* youTubeUrlWatcherAsyn(action) {
  try {
    const response = yield call(youTubeUrlApi, action.payload.token, action.payload.data);
    if (response.status >= 200 && response.status < 300) {
      const saveUrlData = yield response.data;
      yield put({type: actionTypes.SUBMIT_URL_COMPLETE, payload: saveUrlData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.SUBMIT_URL_ERROR, payload: error});
  }
}
export function* youTubeUrlWatcher() {
  yield takeEvery(actionTypes.SUBMIT_URL, youTubeUrlWatcherAsyn);
}

export const youTubeUrlSaga=[
  youTubeUrlWatcher(),
];

