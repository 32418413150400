import React, {createContext, useState, useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  gql,
  useBackgroundQuery,
  useLazyQuery,
  useSuspenseQuery,
} from '@apollo/client';
import {loader} from 'graphql.macro';
import {loadSearchFilters} from '../store/actions/fetchAction';
import {add, update} from '../store/slices/filterSlice';
import {clone as createProductClone} from '../services/SearchService';
import {retriggerApi} from '../services/submitServices';
import {ToasterContext} from './ToasterContext';
import Localization from '../common/Localization';
import {updateFormHeader} from '../components/utils/utils';
import {
  getAuthToken,
  getUserId,
} from '../components/utils/AuthenticationUtils';
class Condition {
  field;
  operator;
  value;

  constructor(field, operator, value) {
    this.field = field;
    this.operator = operator;
    this.value = value;
  }
}

class Search {
  _andConditions;
  _orConditions;

  constructor() {
    this._andConditions = [];
    this._orConditions = [];
    return this;
  }

  set andConditions(andConditions) {
    this._andConditions = andConditions;
  }

  get andConditions() {
    return this._andConditions;
  }

  set orConditions(orConditions) {
    this._orConditions = orConditions;
  }

  get orConditions() {
    return this._orConditions;
  }
}

const initialState = {
  filters: null,
};

export const SearchContext = createContext(initialState);

const QUERY_ITEMS = gql`
  query getData($param: Search, $size: Int, $start: Int) {
    queryItems(search: $param, size: $size, start: $start) {
      totalPages
      totalItems
      currentPage
      first
      pageSize
      last
      items {
        articleCode
        l0Category
        l0CategoryName
        l1Category
        l1CategoryName
        l2Category
        l2CategoryName
        productType
        productTypeName
        codeCreationDate
        dateInMs
        images
        createdBy
      }
    }
  }
`;

const GET_ITEM_KAFKA_MESSAGE = gql`
  query getArticle($articleCode: String!) {
    getArticle(articleCode: $articleCode) {
      kafkaMessage
    }
  }
`;

const DATA_SIZE_LIMIT = 100;
export const SearchContextProvider = ({children}) => {
  const [queryItems, {loading, data}] = useLazyQuery(QUERY_ITEMS);
  const [
    queryKafkaMessage,
    {loading: loadingKafkaMessage, data: dataKafkaMessage},
  ] = useLazyQuery(GET_ITEM_KAFKA_MESSAGE);
  const state = useSelector((state) => state.filterReducer);
  const toastContext = useContext(ToasterContext);
  const {showToastMsg} = toastContext;
  const [clone, setClone] = useState({
    status: null,
    loading: false,
    error: null,
  });
  const [retrigger, setRetrigger] = useState({
    loading: false,
    statue: null,
    error: null,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const {filters: subGroups} = state;

  const {filters} = useSelector((state) => state.filters);

  // State Normalization
  useEffect(() => {
    if (subGroups && subGroups.length > 0) {
      const attributes = subGroups.flatMap((subGroup) => subGroup?.attributes);
      attributes.forEach((attribute) => dispatch(add(attribute)));
    }
  }, [subGroups]);

  useEffect(() => {
    dispatch(loadSearchFilters());
  }, []);

  useEffect(() => {
    console.log(dataKafkaMessage?.getArticle);
    console.log(dataKafkaMessage && dataKafkaMessage?.getArticle?.kafkaMessage);
  }, [dataKafkaMessage]);

  const onClone = async ({
    articleCode,
    group,
    category,
    subCategory,
    productType,
  }) => {
    setClone((prev) => ({status: null, loading: true, error: null}));
    const result = await createProductClone({articleCode}).catch((err) => {
      setClone((prev) => ({
        status: err,
        loading: false,
        error: err,
      }));
      showToastMsg(Localization.UnsuccessfulSaveToast, 'error');
    });
    if (result.data === 'Successfully Added') {
      setClone((prev) => ({
        status: result.data,
        loading: true,
        error: null,
      }));
      console.log(result);
      showToastMsg(Localization.SuccessfulSaveToast, 'success');
      updateFormHeader({group, category, subCategory, productType});
      setTimeout(() => history.push('/creation'), 2000);
      // history.push('/creation');
      // window.location.href = '/creation';
    }
  };

  const onRetrigger = async (msg, code) => {
    setRetrigger({loading: true, status: null, error: null});
    const result = await retriggerApi({msg}).catch((error) => {
      showToastMsg(
        `Oops! We have got an error. Please retrigger ${code} again..`,
        'error',
      );

      setRetrigger({status: null, loading: false, error});
    });
    showToastMsg(
      `Article : ${code} has been successfully retriggered`,
      'success',
    );
    setRetrigger({
      status: 'successfully pushed',
      loading: false,
      error: null,
    });
  };

  const onFilterRevert = () => {
    if (filters) {
      Object.values(filters).forEach((attribute) =>
        dispatch(update({...attribute, attributeNameValue: ''})),
      );
    }
  };
  const computeFilters = () => {
    const queryCriterias = getFilledCriterias(filters);

    // Create the AND and OR Conditions

    const andCriterias = getAndConditions(queryCriterias);

    console.log(andCriterias);
    const orCriterias = getOrConditions(queryCriterias);
    // Send it to GraphQL Query
    const search = new Search();
    if (andCriterias && andCriterias.length > 0) {
      search.andConditions = JSON.parse(JSON.stringify(andCriterias));
    }
    if (orCriterias && orCriterias.length > 0) {
      search.orConditions = JSON.parse(JSON.stringify(orCriterias));
    }

    const {andConditions, orConditions} = search;
    return {andConditions, orConditions};
  };

  const onFilterApply = async () => {
    console.log('Apply button clicked');
    console.log(filters);

    // Get the Filled Query Criterias
    callGraphqlQueryService({start: 0, size: DATA_SIZE_LIMIT});
  };

  // This method is called when last page is reached in pagination view
  const onFetchPaginatedRows = async ({start, size}) => {
    callGraphqlQueryService({start, size});
  };

  const callGraphqlQueryService = ({start, size}) => {
    const {andConditions, orConditions} = computeFilters();
    queryItems({
      variables: {
        param: {
          andConditions,
          orConditions,
        },
        size,
        start,
      },
    });
  };

  const getFilledCriterias = (filters) => {
    if (filters && Object.keys(filters).length > 0) {
      const queryCriterias = Object.keys(filters)
        .map((key) => filters[key])
        .filter(
          (filter) =>
            filter &&
            filter.attributeNameValue != null &&
            filter.attributeNameValue != '',
        );

      console.log(queryCriterias);
      return queryCriterias;
    }
    return [];
  };

  const getAndConditions = (criterias) => {
    if (criterias && criterias.length > 0) {
      const andCriterias = criterias
        // Filter out non-single value criterias
        .filter(
          (criteria) =>
            criteria.attributeDataType != 'enum' ||
            (criteria.attributeDataType === 'enum' &&
              criteria.attributeNameValue.length > 0 &&
              criteria.attributeNameValue.length < 2),
        )
        // process Query value
        .map((criteria) => ({
          ...criteria,
          articleOperator:
            criteria.articleOperator && !(criteria.articleOperator === '') ?
              criteria.articleOperator :
              'EQUAL',
        }))
        .map((criteria) => ({
          ...criteria,
          attributeNameValue: processAndCriteriaValue(criteria),
        }))
        .map(
          (criteria) =>
            new Condition(
              criteria.articleField,
              criteria.articleOperator,
              criteria.attributeNameValue,
            ),
        );

      return andCriterias;
    }

    return [];
  };

  // Process criteria values for and conditions
  const processAndCriteriaValue = (criteria) => {
    // For enum type filters lovId will be mapped to attribute field
    if (criteria && criteria.attributeNameValue) {
      const value = criteria.attributeNameValue;
      const dataType = criteria.attributeDataType;
      if (dataType === 'enum' && value.length > 0 && value.length < 2) {
        return value[0].lovId;
      } else if (dataType === 'date' && value != '' && Date.parse(value) > 0) {
        return Date.parse(value);
      } else if (
        dataType === 'radio' &&
        criteria.attributeId === 'GN-RT-Relevant-Timeline'
      ) {
        return processTimelineCriteria(criteria);
      } else if (dataType === 'radio' && value) {
        return value;
      }
      return value;
    }
    return '';
  };

  const processTimelineCriteria = (criteria) => {
    if (
      !criteria ||
      criteria.attributeId != 'GN-RT-Relevant-Timeline' ||
      criteria.attributeDataType != 'radio'
    ) {
      return 0;
    }
    const value = criteria.attributeNameValue;
    const id = criteria.attributeId;
    const lovs = criteria.attrNameLov;

    let selectedLov =
      lovs &&
      lovs.length > 0 &&
      value &&
      value != '' &&
      lovs.filter((lov) => lov.lovId === value);

    if (selectedLov && selectedLov.length > 0) {
      selectedLov = selectedLov[0];
      let factor = 0;
      switch (selectedLov.uom) {
        case 'hour':
          factor = 60;
          break;
        case 'day':
          factor = 60 * 24;
          break;
        case 'month':
          factor = 60 * 24 * 30;
          break;
        case 'year':
          factor = 60 * 24 * 365;
          break;
        default:
          return 0;
      }
      const millisToSubtract = !isNaN(parseFloat(selectedLov.scale)) ?
        parseFloat(selectedLov.scale) * factor * 60 * 1000 :
        0;
      return Date.parse(new Date()) - millisToSubtract;
    }

    return 0;
  };

  // Get OR Conditions
  const getOrConditions = (criterias) => {
    if (criterias && criterias.length > 0) {
      const orCriterias = criterias
        .filter((criteria) => {
          return (
            criteria.attributeNameValue &&
            criteria.attributeNameValue != '' &&
            criteria.attributeDataType === 'enum' &&
            Array.isArray(criteria.attributeNameValue) &&
            criteria.attributeNameValue.length > 1
          );
        })
        .flatMap((criteria) =>
          criteria?.attributeNameValue
            .map((lov) => ({
              ...criteria,
              ...lov,
              attributeNameValue: lov.lovId,
            }))
            .map(
              (criteria) =>
                new Condition(
                  criteria.articleField,
                  criteria.articleOperator,
                  criteria.attributeNameValue,
                ),
            ),
        );

      console.log(orCriterias);
      return orCriterias;
    }
  };

  const getKafkaMessage = (articleCode) => {
    queryKafkaMessage({
      variables: {
        articleCode,
      },
    });
  };
  const value = {
    subGroups,
    clone,
    queryLoading: loading,
    result: data && data.queryItems ? data.queryItems : {items: []},
    loadingKafkaMessage,
    kafkaMessage:
      dataKafkaMessage && dataKafkaMessage?.getArticle?.kafkaMessage,
    retrigger,
    onClone,
    onFilterApply,
    getKafkaMessage,
    onRetrigger,
    setRetrigger,
    onFetchPaginatedRows,
    onFilterRevert,
  };

  return (
    <SearchContext.Provider value={{...value}}>
      {children}
    </SearchContext.Provider>
  );
};
