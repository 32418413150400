import React, {useEffect} from 'react';
import Localization from '../../common/Localization';
import './PageNotFound/PageNotFound.css';
import {useHistory} from 'react-router-dom';

const ApiErrorPage = () => {
  const history = useHistory();
  const redirectHomepage = () => {
    window.location.href = '/login';
  };
  useEffect(() => {
    window.addEventListener(
      'popstate',
      (event) => {
        if (event.state) {
          window.location.href = '/login';
        }
      },
      false,
    );
  }, [history]);
  return (
    <>
      <div>
        {/* <img className="bimage" src={process.env.PUBLIC_URL + '/assets/bg 1.jpg'} alt="back-ground"/> */}
        <div className="mainbox">
          <div className="page-not-found">{Localization.ApiErrorMsg} </div>
          <div className="letsgo">{Localization.Letsgo}</div>
          <button className="button-page" onClick={redirectHomepage}>
            <div className="home">{Localization.Home}</div>
          </button>
          <div className="try">{Localization.Try} </div>
        </div>
        <div>
          <div className="all-rights-reserved-page1">
            {Localization.Disclaimer}
          </div>
          <div className="all-rights-reserved-page">
            {Localization.Allrights}
          </div>
        </div>
      </div>{' '}
    </>
  );
};
export default ApiErrorPage;
