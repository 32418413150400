import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  // loading: false,
  // article: null,
  // error: null,
};

export default function fetchArticleReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_ARTICLE_DETAILS:
      return {...state, loading: true, article: null, error: null};
    case actionTypes.FETCH_ARTICLE_DETAILS_COMPLETE:
      return {...state, loading: false, article: action.payload, error: null};
    case actionTypes.FETCH_ARTICLE_DETAILS_ERROR:
      return {...state, loading: false, error: action.payload, article: null};
    default:
      return state;
  }
}
