import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {componentDetailsApi} from '../../services/packServices';

export function* componentWorker(action) {
    try {
        const response = yield call(componentDetailsApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            const componentDetails = response.data;
            yield put({type: actionTypes.GET_COMPONENT_SUCCESS, payload: componentDetails});
        } else {
            throw response;
        }
    } catch (error) {
        yield put({type: actionTypes.GET_COMPONENT_ERROR, payload: error});
    }
}

export function* componentWatcher() {
    yield takeEvery(actionTypes.GET_COMPONENT, componentWorker);
}

export const componentSaga = [
    componentWatcher(),
];
