import {all} from 'redux-saga/effects';
import {
  OtpWatcher,
  SendOtpWatcher,
  ValidateOtpWatcher,
  logoutWatcher,
} from './loginSaga';
import {creationWatcher} from './creationSaga';
import {productWatcher} from './productSaga';
import {dimensionWatcher} from './dimensionSaga';
import {companyWatcher} from './companySaga';
import {itemWatcher} from './itemSaga';
import {additionalWatcher} from './additionalSaga';
import {saveWatcher} from './saveSaga';
import {fetchWatcher} from './fetchSaga';
import {fetchArticleWatcher} from './fetchArticleSaga';
import {submitWatcher} from './SubmitSaga';
import {emailWatcher} from './emailSaga';
import {articleWatcher} from './articleSaga';
import {youTubeUrlWatcher} from './youTubeUrlSaga';
import {packWatcher} from './packSaga';
import {savePackWatcher} from './savePackSaga';
import {fetchFilterWatcher} from './fetchFilterSaga';
import {deleteCloudinaryAssetWatcher, deleteWatcher, deleteCurrentSession} from './deleteSaga';
import {filterWatcher} from './filterSaga';
import {componentWatcher} from './componentSaga';
export default function* rootSaga() {
  yield all([
    creationWatcher(),
    productWatcher(),
    dimensionWatcher(),
    additionalWatcher(),
    companyWatcher(),
    itemWatcher(),
    saveWatcher(),
    fetchWatcher(),
    submitWatcher(),
    emailWatcher(),
    articleWatcher(),
    youTubeUrlWatcher(),
    packWatcher(),
    savePackWatcher(),
    fetchArticleWatcher(),
    fetchFilterWatcher(),
    filterWatcher(),
    OtpWatcher(),
    SendOtpWatcher(),
    ValidateOtpWatcher(),
    logoutWatcher(),
    deleteCloudinaryAssetWatcher(),
    deleteWatcher(),
    componentWatcher(),
    deleteCurrentSession(),
  ]);
}
