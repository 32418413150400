import {HttpService} from './HttpService';
import {PRODUCT_DETAIL} from '../config/apiEndpoints';

export async function fetchService(token, isPreviewPage) {
  try {
    // const endpoint = PRODUCT_DETAIL+`${userId}` + `?isPreviewPage=${isPreviewPage}`;

    // @EPICS-2117 need to uncomment
    const endpoint = PRODUCT_DETAIL + `?isPreviewPage=${isPreviewPage}`;
    // const endpoint = `http://localhost:8080/vendorportal/getProductData?isPreviewPage=${isPreviewPage}`;

    const headers = {
      'Authorization': `Bearer ${token}`,
    };
    return (await HttpService.get(endpoint, {headers: headers}));
  } catch (err) {
    return {err};
  }
}
