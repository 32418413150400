import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {classificationAttributeAdditionalApi} from '../../services/additionalService';


export function* additionalWatcherAsyn(action) {
  try {
    const response = yield call(classificationAttributeAdditionalApi, action.payload);
    if (response.status >= 200 && response.status < 300) {
      const primaryData = yield response.data;
      yield put({type: actionTypes.CLASSIFICATION_ATTRIBUTE_ADDITIONAL_COMPLETE, payload: primaryData});
    } else if (!(response.status >= 200 && response.status < 300)) {
      yield put({type: actionTypes.API_ERROR, payload: null});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.CLASSIFICATION_ATTRIBUTE_ADDITIONAL_ERROR, payload: error});
  }
}
export function* additionalWatcher() {
  yield takeEvery(actionTypes.CLASSIFICATION_ATTRIBUTE_ADDITIONAL, additionalWatcherAsyn);
}
export const additionalSaga=[
  additionalWatcher(),
];
