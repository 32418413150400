import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {coreAttributeDimensionApi} from '../../services/dimensionService';

export function* dimensionWatcherAsyn(action) {
  try {
    const response = yield call(coreAttributeDimensionApi, action.payload);

    if (response.status >= 200 && response.status < 300) {
      const primaryData = yield response.data;

      yield put({type: actionTypes.CORE_ATTRIBUTE_DIMENSION_COMPLETE, payload: primaryData});
    } else if (!(response.status >= 200 && response.status < 300)) {
      yield put({type: actionTypes.API_ERROR, payload: null});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.CORE_ATTRIBUTE_DIMENSION_ERROR, payload: error});
  }
}
export function* dimensionWatcher() {
  yield takeEvery(actionTypes.CORE_ATTRIBUTE_DIMENSION, dimensionWatcherAsyn);
}
export const dimensionSaga=[
  dimensionWatcher(),
];
