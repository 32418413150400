import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
  overflow: 'auto',
};

function PreviewModal({
  children,
  open,
  handleClose,
  width,
  sx,
}) {
  console.log(children);
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={'scrollable'} sx={{...style, ...sx}} width={width}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}

export default React.memo(PreviewModal);
