import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function articleReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.GENERATE_ARTICLE_CODE:
      return {...state, loading: true, errorMessage: null, articleApiData: null, isSuccess: false};
    case actionTypes.GENERATE_ARTICLE_CODE_COMPLETE:
      return {...state, loading: false, articleApiData: action.payload, isSuccess: true, errorMessage: null};
    case actionTypes.GENERATE_ARTICLE_CODE_ERROR:
      return {...state, loading: false, errorMessage: action.payload, isSuccess: false, articleApiData: null};
    default:
      return state;
  }
}
