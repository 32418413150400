import * as actionTypes from './actionsTypes';

export const loadYoutubeUrl=({userId, token, sendUrlData})=>{
  return {

    type: actionTypes.SUBMIT_URL,
    payload: {userId: userId, token: token, data: sendUrlData},

  };
};
