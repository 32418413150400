import {HttpService} from './HttpService';
import {
  CLOUDINARY_ASSET_DELETION,
  PRODUCT_DELETION,
  DELETE_JWT_TOKEN,
} from '../config/apiEndpoints';
import {getAuthToken, getUserId} from '../components/utils/AuthenticationUtils';

export async function deleteApi(token) {
  try {
    // const endpoint = PRODUCT_DELETION + `${userId}`;

    // @EPICS-2117 need to uncomment
    const endpoint = PRODUCT_DELETION;
    // const endpoint = `http://localhost:8080/vendorportal/deleteSaveData`;

    return await HttpService.post(endpoint, '', {
      headers: {Authorization: `Bearer ${token}`},
    });
  } catch (err) {
    return {err};
  }
}

export async function deleteCloudinaryAssetApi(payload, token) {
  try {
    // const userId = localStorage.getItem('userId');
    const endpoint = CLOUDINARY_ASSET_DELETION;
    // return HttpService.delete(endpoint, {
    //   headers: {Authorization: `Bearer ${token}`},
    //   data: {
    //     ...payload,
    //   },
    // });
    return HttpService.post(
      endpoint,
      {
        ...payload,
      },
      {
        headers: {Authorization: `Bearer ${token}`},
      },
    );
  } catch (err) {
    return {err};
  }
}

  export async function deleteCurrentSessionApi(userId) {
    try {
       // const endpoint = PRODUCT_DELETION + `${userId}`;

    // @EPICS-2117 need to uncomment
     const endpoint = DELETE_JWT_TOKEN;
    // const endpoint = `http://localhost:8080/vendorportal/${DELETE_JWT_TOKEN}`;
     return await HttpService.post(endpoint, {loginId: getUserId()}, {
        // headers: {Authorization: `Bearer ${getAuthToken()}`},
      });
    } catch (err) {
      return {err};
    }
  }

