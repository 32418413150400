import {put, call, takeLatest} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {fetchFilter} from '../../services/filterService';

export function* fetchFilterWorker(action) {
  try {
    const response = yield call(fetchFilter);
    if (response.status >= 200 && response.status < 300) {
      const payload = yield response.data;

      yield put({
        type: actionTypes.FETCH_FILTERS_COMPLETE,
        payload,
      });
    } else {
      throw response;
    }
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_FILTERS_ERROR,
      payload: error,
    });
  }
}
export function* fetchFilterWatcher() {
  yield takeLatest(actionTypes.FETCH_FILTERS, fetchFilterWorker);
}

export const fetchFilterSaga = [fetchFilterWatcher()];
