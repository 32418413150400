import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import './Company.css';
import Localization from '../../common/Localization';
import {loadClassificationCompanyAttribute} from '../../store/actions/companyAction';
import {loadSave} from '../../store/actions/saveAction';
import {loadFetchData} from '../../store/actions/fetchAction';
import Select from 'react-select';
import {useHistory} from 'react-router-dom';
import Toaster from '../Shared/Toaster';
import ReactTooltip from 'react-tooltip';
import CompanyReturnData from './CompanyReturnData';
import {saveData, saveDataToast} from './companyFunction';
import {companyAdd, companyDelete} from './addDeleteCompany';
import {sanitize} from '../utils/utils';
import {EMAIL_VALIDATION_REGEX, PAGE_RENDER_TIMEOUT_IN_MILLISECONDS} from '../utils/Constants';
export const Company = (props) => {
  const history = useHistory();
  const [prod, setProd] = useState([]);
  const [sendsaveData, setSendSaveData] = useState({});
  const [showData, setShowData] = useState(false);
  const [confirmCompany, setConfirmCompany] = useState();
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
      .token;
  const userId = localStorage.getItem('userId');
  const code = props?.fetchApiData?.l2Category;
  const toasterRef = useRef(null);
  const [varient, setVarient] = useState('');
  const [msg, setMsg] = useState('');
  const [check, setCheck] = useState(false);
  const [contDisable, setContDisable]=useState(false);
  const [checkClickcontinue, setcheckClickcontinue]=useState(false);
  const [, setEmail] = useState(false);
  const [, setCheckDuplicate] = useState(false);
  const [, setMultipleemailCheck] = useState(false);
  const [isPreviewPage, setisPreviewPage] = useState(false);

  useEffect(() => {
    if (token && code) {
      const query = token;
      props?.classificationCompanyAttributeApi({query, code});
    }
  }, [token, code]);
  useEffect(() => {
    if (props ?.errorApiCheck === true) {
      history.push('/ApiErrorPage');
    }
  }, [props ?.errorApiCheck]);
  useEffect(() => {
    if (localStorage.getItem('url2')!=='3-Company Information') {
      history.push('/2-Product Information');
    }
  }, [history]);
  const attributeLovName = (element) => {
    element.attrNameLov.map((lovItem, lovIndex) => {
      lovItem['value'] = lovItem.lovName;
      lovItem['label'] = lovItem.lovName;
      return null;
    });
  };
  useEffect(() => {
    if (props?.fetchApiData && props?.fetchApiData?.sections?.[props?.fetchApiData.sections?.findIndex((p) => p.section === '3-Company Information')]?.section === '3-Company Information') {
      const coreAttributesR = props?.fetchApiData.sections[props?.fetchApiData.sections?.findIndex((p) => p.section === '3-Company Information')]?.subGroups.map(
          (subItem, subIndex) => {
            const subelement = subItem;

            subelement.attributes = subItem.attributes.map(
                (secSubItem, secSubIndex) => {
                  const element = secSubItem;
                  if (element.attrNameLov !== null) {
                    attributeLovName(element);
                  }
                  return element;
                },
            );
            return subelement;
          },
      );
      setProd(coreAttributesR);
    } else {
      const classAttributeR = props?.primaryData?.subGroups.map(
          (subItem, subIndex) => {
            const subelement = subItem;
            subelement.attributes = subItem.attributes.map(
                (secSubItem, secSubIndex) => {
                  const element = secSubItem;
                  element.isInvalid = false;
                  element.isCrossedLength = false;
                  element.attributeNameValue = '';
                  if (element.attrNameLov !== null) {
                    attributeLovName(element);
                  }
                  return element;
                },
            );
            return subelement;
          },
      );


      setProd(classAttributeR);
    }
  }, [props.fetchApiData, props.primaryData]);

  const changeData = (selectedItem, value) => {
    const classAttributeRes = prod?.map((subItem, subIndex) => {
      const subelement = subItem;
      subelement.attributes = subItem.attributes.map(
          (secSubItem, secSubIndex) => {
            const element = secSubItem;
            if ((element.isCrossedLength === true && (value.length <= Number(element.length))) ||
            element.isInvalid === true) {
              element.isCrossedLength = false;
              element.isInvalid = false;
            }
            element.isDuplicate = false;
            if (element.sequence) {
              if (selectedItem.sequence === element.sequence &&
              selectedItem.attributeId === element.attributeId) {
                if (element.validRegex != null) {
                  const re = new RegExp(element.validRegex);
                  if (value === '') {
                    element.attributeNameValue = '';
                  } else if (
                    element.length !== ''&& value.length > Number(element.length)
                  ) {
                    element.isCrossedLength = true;
                    return element;
                  } else if (
                    element.length !== ''&& element.length.includes(',')
                  ) {
                    if (value.toString().includes('.')&&Number(value.match(/\./g).length)>1) {
                      return element;
                    }
                    const tempd= element.length.split(',');
                    const befored=Number(tempd[0]);
                    const afterd=Number(tempd[1]);
                    if (value.length > befored && ! value.toString().includes('.')) {
                      element.isCrossedLength = true;
                    } else if (value.toString().includes('.')) {
                      const temp = value.toString().split('.');
                      if (temp[1].length>afterd) {
                        return null;
                      } else {
                        element.attributeNameValue = value;
                        element.isCrossedLength = false;
                      }
                    } else {
                      element.attributeNameValue = value;
                      element.isCrossedLength = false;
                    }
                    return element;
                  } else if (element.minLength != null &&
                  (value.length < Number(element.minLength))) {
                    element.isCrossedminLength = true;

                    if (re.test(value)) {
                      element.isInvalid = false;
                      element.attributeNameValue = value;
                    } else {
                      element.isInvalid = true;
                      element.isCrossedminLength = false;
                    }
                    return element;
                  } else if (element.range != null) {
                    const range = element.range.split('-');
                    const min = Number(range[0]);
                    const max = Number(range[1]);
                    if (Number(value) >= min && Number(value) <= max) {
                      element.attributeNameValue = value;
                      element.isCrossedRange = false;
                    } else {
                      element.isCrossedRange = true;
                    }

                    return element;
                  } else if (re.test(value)) {
                    element.isInvalid = false;
                    element.isCrossedLength = false;
                    element.isCrossedminLength = false;
                    element.attributeNameValue = value;
                  } else {
                    element.isInvalid = true;
                    element.isCrossedLength = false;
                    element.isCrossedminLength = false;
                    return element;
                  }
                }
                element.attributeNameValue = value;
              }
            } else if (selectedItem.attributeId === element.attributeId) {
              if ((element.isCrossedLength === true && (value.length <= Number(element.length))) ||
              element.isInvalid === true) {
                element.isCrossedLength = false;
                element.isInvalid = false;
              }
              if (element.validRegex != null) {
                const re = new RegExp(element.validRegex);
                if (value === '') {
                  element.attributeNameValue = '';
                } else if (
                  element.length !== ''&& value.length > Number(element.length)
                ) {
                  element.isCrossedLength = true;
                  return element;
                } else if (
                  element.length !== ''&& element.length.includes(',')
                ) {
                  if (value.toString().includes('.')&&Number(value.match(/\./g).length)>1) {
                    return element;
                  }
                  const tempd= element.length.split(',');
                  const befored=Number(tempd[0]);
                  const afterd=Number(tempd[1]);
                  if (value.length > befored && ! value.toString().includes('.')) {
                    element.isCrossedLength = true;
                  } else if (value.toString().includes('.')) {
                    const temp = value.toString().split('.');
                    if (temp[1].length>afterd) {
                      return null;
                    } else {
                      element.attributeNameValue = value;
                      element.isCrossedLength = false;
                    }
                  } else {
                    element.attributeNameValue = value;
                    element.isCrossedLength = false;
                  }
                  return element;
                } else if (element.minLength != null &&
                (value.length < Number(element.minLength))) {
                  element.isCrossedminLength = true;

                  if (re.test(value)) {
                    element.isInvalid = false;
                    element.attributeNameValue = value;
                  } else {
                    element.isInvalid = true;
                    element.isCrossedminLength = false;
                  }
                  return element;
                } else if (element.range != null) {
                  const range = element.range.split('-');
                  const min = Number(range[0]);
                  const max = Number(range[1]);
                  if (Number(value) >= min && Number(value) <= max) {
                    element.attributeNameValue = value;
                    element.isCrossedRange = false;
                  } else {
                    element.isCrossedRange = true;
                  }

                  return element;
                } else if (re.test(value)) {
                  element.isInvalid = false;
                  element.isCrossedLength = false;
                  element.isCrossedminLength = false;
                  element.attributeNameValue = value;
                } else {
                  element.isInvalid = true;
                  element.isCrossedLength = false;
                  element.isCrossedminLength = false;
                  return element;
                }
              }
              element.attributeNameValue = value;
            } else {
              element.isCrossedLength = false;
            }
            return element;
          },
      );
      return subelement;
    });
    setProd(classAttributeRes);
    setSendSaveData({section: '3-Company Information', subGroups: classAttributeRes});
  };

  const showToastMsg = (msgs, varients) => {
    setVarient(varients);
    setMsg(msgs);
    toasterRef.current.openToast();
  };
  const CustomStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? 'black' : isSelected ? '#49A7A2' : isFocused ? '#E0E3E4' : null,
        color: isDisabled ? '#000000' : isSelected ? '#ffffff ' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };
  const getClassAttr = (attribute) => {
    setProd(attribute);
  };
  const saving=()=>{
    saveData({props, userId, token, prod, checkClickcontinue, getClassAttr});
  };
  const getCheckVal = (checks) => {
    setCheck(checks);
  };
   const getConfirmCompany = (confirmComp) => {
    setConfirmCompany(confirmComp);
  };
  const savingToast=()=>{
  saveDataToast({props, userId, token, prod, getCheckVal, getClassAttr, getConfirmCompany});
  };
  useEffect(() => {
    if (props?.isSuccess!==undefined) {
      if (check && props?.isSuccess) {
        showToastMsg(Localization.SuccessfulSaveToast, 'success');
        setCheck(false);
      }
      if ( Object.keys(sendsaveData).length !== 0 && props?.isSuccess === false && props?.isSuccessBlank === false) {
        showToastMsg(Localization.UnsuccessfulSaveToast, 'error');
        setContDisable(true);
      }
    }
  }, [check, props.isSuccess, sendsaveData]);

  const onClickContinue = () => {
    let checkAttribute = false;
    const classAttributeR = prod?.map((subItem, subIndex) => {
      const subelement = subItem;
      subelement.attributes = subItem.attributes.map(
          (secSubItem, secSubIndex) => {
            const element = secSubItem;
            if ((element.attributeNameValue === '' && element.mandatory === 'Y')|| element.isCrossedminLength) {
              checkAttribute = true;
            } else if (element.fieldType === 'Email') {
              const reg = /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/;
              // if (reg.test(checkItem.attributeNameValue) === false) {
                if (EMAIL_VALIDATION_REGEX.test(element.attributeNameValue) === false) {
                setEmail(true);
                checkAttribute = true;
              } else {
                setEmail(false);
              }
            }
             if (element.sequence) {
              subItem.attributes.map((checkItem, checkIndex) => {
                if (element.attributeId === checkItem.attributeId && element.attributeNameValue !== '' && secSubIndex !== checkIndex && element.attributeNameValue === checkItem.attributeNameValue) {
                  checkItem.isDuplicate = true;
                  setCheckDuplicate(true);
                  checkAttribute = true;
                } else if (checkItem.fieldType === 'Email') {
                  if (checkItem.attributeNameValue) {
                    const reg = /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/;
                    // if (reg.test(checkItem.attributeNameValue) === false) {
                      if (EMAIL_VALIDATION_REGEX.test(checkItem.attributeNameValue) === false) {
                     setMultipleemailCheck(true);
                    }
                  }
                }
                return checkItem;
              });
            }
            return element;
          },
      );
      return subelement;
    });
    if (checkAttribute === true) {
      setShowData(true);
      setConfirmCompany(true);
     // props.saveApi({userId, token, sendsaveData: {section: '3-Company Information', subGroups: classAttributeR}});
    } else {
      setShowData(false);
      setConfirmCompany(false);
      props.saveApi({userId, token, sendsaveData});
      setCheck(true);
      if (!contDisable) {
        setTimeout(() => {
          history.push('/4-Dimensions');
          localStorage.setItem('url3', '4-Dimensions');
        }, PAGE_RENDER_TIMEOUT_IN_MILLISECONDS);
      }
    }
    setProd(classAttributeR);
  };
  const getProdVal = (prods) => {
    setProd(prods);
  };
  const addData = (item, index) => {
    companyAdd({item, index, prod, getProdVal});
  };
  const getProdDelVal = (prods) => {
    setProd(prods);
  };
  const deleteData = (index, selectedItem, item) => {
    companyDelete({item, index, selectedItem, prod, getProdDelVal});
  };
  const getDisplayBox = (boxtype, item, index) => {
    if (item.fieldType === 'Select') {
      return (
        <div>
          {' '}
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
                <span></span>
              )}
          </div>
          {item.multiValued === 'Y' ? (
            <div>
              <Select className="select-box" options={item.attrNameLov} maxMenuHeight={200}
                styles={CustomStyles}
                value={{value: item.attributeNameValue, label: item.attributeNameValue}}
                onBlur={saving}
                aria-label={item.attributeId}
                onChange={(e) => {
                  changeData(item, e.lovName);
                }}
              />

              <div className="plus-item-company">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                  alt="plus"
                  onClick={() => addData(item, index)}
                />
              </div>
            </div>
          ) : (
              <div>
                <Select className="select-box" options={item.attrNameLov} maxMenuHeight={200}
                  styles={CustomStyles}
                  value={{value: item.attributeNameValue, label: item.attributeNameValue}}
                  onBlur={saving}
                  aria-label={item.attributeId}
                  onChange={(e) => {
                    changeData(item, e.lovName);
                  }}
                />
                {item.attributeId === 'SG-CompanyContactInformation-CountryofOrigin' ?<ReactTooltip id={'Country of Prod'} place="top" effect="solid">{Localization.CountryofOriginInfoText}
                </ReactTooltip> : (item.attributeId === 'SG-CompanyContactInformation-CountryofBrand') ?
              <ReactTooltip id={'Country of Brand'} place="top" effect="solid">
                {Localization.CountryofBrandInfoText}
              </ReactTooltip> : ''}

                {item.attributeId === 'SG-CompanyContactInformation-CountryofOrigin' ? (
                  <span>
                    <div className="info-icon-company1">
                      <img data-tip
                        data-for={'Country of Prod'}
                        src={`${process.env.PUBLIC_URL}/assets/info.png`} alt="info" />
                    </div>
                  </span>
                ) : item.attributeId === 'SG-CompanyContactInformation-CountryofBrand' ? (
                  <span>
                    <div className="info-icon-company2">
                      <img data-tip
                        data-for={'Country of Brand'}
                        src={`${process.env.PUBLIC_URL}/assets/info.png`} alt="info" />
                    </div>
                  </span>
                ) : (
                  ''
                )}

              </div>
            )}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg">
              {Localization.Character}
            </div>
          )}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else if (item.fieldType === 'Textarea') {
      return (
        <div>
          {' '}
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
                <span></span>
              )}
          </div>
          {item.multiValued === 'Y' ? (
            <div>
              <textarea
                aria-label={item.attributeId}
                name={item.attributeId}
                type={item.attributeDataType === 'String' ? 'text' : 'number'}
                value={item.attributeNameValue}
                onBlur={saving}
                // className="companyArea"
                className="rectangle3"
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  changeData(item, sanitize(e.target.value));
                }}
              />
              <div className="man-plus-1">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                  alt="plus"
                  onClick={() => addData(item, index)}
                />
              </div>
            </div>
          ) : (
              <div>
                <textarea
                  aria-label={item.attributeId}
                  name={item.attributeId}
                  type={item.attributeDataType === 'String' ? 'text' : 'number'}
                  value={item.attributeNameValue}
                  onBlur={saving}
                  // className="companyArea1"
                  className="rectangle3"
                  onChange={(e) => {
                    changeData(item, sanitize(e.target.value));
                  }}
                />
                {item.sequence && item.sequence !== 0 ? (
                  <div className="delete">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                      alt="plus"
                      onClick={() => deleteData(index, item)}
                    />
                  </div>
                ) : (
                    <span></span>
                  )}
              </div>
            )}
            <ReactTooltip id={'Vendor Product name'} place="top" effect="solid">
            {Localization.MarketerAddress}
          </ReactTooltip>

          {item.attributeName === 'Manufacturer/Importer/Marketer Name & Address' ||
            item.attributeName === '' ? (
              <span>
                <div className="info-icon-company">
                  <img data-tip
                    data-for={item.attributeName === 'Manufacturer/Importer/Marketer Name & Address' ? 'Vendor Product name' : 'Vendor Article code'}
                    src={`${process.env.PUBLIC_URL}/assets/info.png`} alt="info" />
                </div>
              </span>
            ) : (
              ''
            )}
          {item.isCrossedLength && (
            <div className="error-text-aria">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isInvalid && (
            <div className="error-text-aria">
              {Localization.Character}
            </div>
          )}

          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="error-text-aria">{Localization.Field}</div>}
          {item.isDuplicate && (<div className="error-text-aria">{Localization.InputMismatch}</div>)}
        </div>
      );
    } else if (item.fieldType === 'Email') {
      return (
        <div className='companyEmail'>
          {' '}
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
                <span></span>
              )}
          </div>
          {item.multiValued === 'Y' ? (
            <div>
              <input
                aria-label={item.attributeId}
                name={item.attributeId}
                onBlur={saving}
                type={item.attributeDataType === 'String' ? 'text' : 'text'}
                value={item.attributeNameValue}
               // pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
                className="rectangle3"
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  changeData(item, sanitize(e.target.value));
                }}
              />
              <div className="plus-item-company">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                  alt="plus"
                  onClick={() => addData(item, index)}
                />
              </div>
            </div>
          ) : (
              <div>
                <input
                 aria-label={item.attributeId}
                  name={item.attributeId}
                  onBlur={saving}
                  type={item.attributeDataType === 'String' ? 'text' : 'number'}
                  value={item.attributeNameValue}
                 // pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
                  className="rectangle3"
                  readOnly={item.readOnly === 'Y' ? true : false}
                  onChange={(e) => {
                    changeData(item, sanitize(e.target.value));
                  }}
                />
                {item.sequence && item.sequence !== 0 ? (
                  <div className="delete1">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                      alt="plus"
                      onClick={() => deleteData(index, item)}
                    />
                  </div>
                ) : (
                    <span></span>
                  )}
              </div>
            )}
          {item.isCrossedLength && (
            <div className="error-text">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isInvalid && (
            <div className="error-text">
              {Localization.Character}
            </div>
          )}
          {item.isCrossedminLength && <div className="errormsg">{Localization.MinLength} {item.minLength}</div>}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="error-text">{Localization.Field}</div>}
          {item.isDuplicate && (<div className="error-text">{Localization.InputMismatch}</div>)}
        </div>
      );
    } else {
      return (
        <div>
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
                <span></span>
              )}
          </div>
          {item.multiValued === 'Y' ? (
            <div>
              <input
               aria-label={item.attributeId}
                name={item.attributeId}
                onBlur={saving}
                type={item.attributeDataType === 'String' ? 'text' : ''}
                value={item.attributeNameValue}
                className="rectangle3"
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  changeData(item, sanitize(e.target.value));
                }}
              />{' '}
              <div className="plus-item-company">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                  alt="plus"
                  onClick={() => addData(item, index)}
                />
              </div>
            </div>

          ) : (
              <div>
                <input
                 aria-label={item.attributeId}
                  name={item.attributeId}
                  onBlur={saving}
                  type={item.attributeDataType === 'String' ? 'text' : ''}
                  value={item.attributeNameValue}
                  className="rectangl3"
                  readOnly={item.readOnly === 'Y' ? true : false}
                  onChange={(e) => {
                    changeData(item, sanitize(e.target.value));
                  }}
                />
                {item.sequence && item.sequence !== 0 ? (
                  <div className="delete1">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                      alt="plus"
                      onClick={() => deleteData(index, item)}
                    />
                  </div>
                ) : (
                    <span></span>
                  )}
              </div>
            )}

          {item.isCrossedLength && (
            <div className="errormsgnum">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsgnum">
              {Localization.Character}
            </div>
          )}
          {item.isCrossedminLength && <div className="errormsg">{Localization.MinLength} {item.minLength}</div>}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="errormsgnum">{Localization.Field}</div>}
          {item.isDuplicate && (<div className="error-text">{Localization.InputMismatch}</div>)}
        </div>
      );
    }
  };
  const checkClickcontinueVal = (checkClickContinue) => {
    setcheckClickcontinue(checkClickContinue);
  };
  return (
    <div className="rectangle">
        <CompanyReturnData props={props} prod={prod} getDisplayBox={getDisplayBox} showData={showData} setShowData={setShowData} saveDataToast={savingToast} checkClickcontinue={checkClickcontinue} checkClickcontinueVal={checkClickcontinueVal} confirmCompany={confirmCompany} contDisable={contDisable} onClickContinue={onClickContinue}/>
      <div className="all-rights-reserved-company1">{Localization.Disclaimer}</div>
      <div className="all-rights-reserved-company">{Localization.Allrights}</div>
      <Toaster ref={toasterRef} varient={varient} msg={msg} />
    </div>
  );
};
export const mapStateToProps = (state) => ({
  primaryData: state.companyReducer.primaryData,
  saveApiData: state.saveReducer.saveApiData,
  fetchApiData: state.fetchReducer.fetchApiData,
  isSuccess: state.saveReducer.isSuccess,
  isSuccessBlank: state.saveReducer.isSuccessBlank,
  saveLoader: state.saveReducer.loading,
  errorApiCheck: state.companyReducer.errorApiCheck,
});

export const mapDispatchToProps = (dispatch) => ({
  classificationCompanyAttributeApi: (query) =>
    dispatch(loadClassificationCompanyAttribute(query)),
  saveApi: ({userId, token, sendsaveData}) =>
    dispatch(loadSave({userId, token, sendsaveData})),
  fetchService: ({userId, token, isPreviewPage}) =>
    dispatch(loadFetchData({userId, token, isPreviewPage})),
});
export default connect(mapStateToProps, mapDispatchToProps)(Company);
