import React from 'react';
import './Additional.css';
import Localization from '../../common/Localization';
import Layout from '../Shared/Layout/Layout';
import WelcomeHeader from '../Shared/WelcomeHeader/WelcomeHeader';
import FormHeader from '../Shared/FormHeader/FormHeader';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';

const AdditionalReturnData = ({props, prod, getDisplayBox, saveDataToast, fetchPack, checkClickcontinue, checkClickcontinueVal, confirmAddition, contDisable, onClickContinue}) => {
    const {handleSubmit} = useForm();
 return (
<>
<Layout />
<WelcomeHeader />
<FormHeader />
<div className="header-fix4">
<Link to= {props?.fetchApiData?.sections?'/creation':'/6-Additional Details'}>
          <p className="group-information1">

            {Localization.Groupinfo}

          </p></Link>
  <Link to={props?.fetchApiData?.sections ? props?.fetchApiData?.sections[props?.fetchApiData?.sections?.findIndex((p) => p.section === '2-Product Information')]?.section : '/6-Additional Details'}>
    <div className="product-information-additional">{Localization.Productinfo}</div>
  </Link>
  <Link to={props?.fetchApiData?.sections ? props?.fetchApiData?.sections[props?.fetchApiData?.sections?.findIndex((p) => p?.section === '3-Company Information')]?.section : '/6-Additional Details'}>
    <div className="company-info-comp">{Localization.Companyinfo}</div>
  </Link>
  <Link to={props?.fetchApiData?.sections ? props?.fetchApiData?.sections[props?.fetchApiData?.sections?.findIndex((p) => p?.section === '4-Dimensions')]?.section : '/6-Additional Details'}>
    <div className="dimensions1">{Localization.Dimensions}</div>
  </Link>
  <Link to={props?.fetchApiData?.sections ? props?.fetchApiData?.sections[props?.fetchApiData?.sections?.findIndex((p) => p?.section === '5-Item Details')]?.section : '/6-Additional Details'}>
    <div className="itemadditional">{Localization.Itemdetails}</div>
  </Link>
       <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')].section : '/6-Additional Details') : '/6-Additional Details'}>
    <div className="additional">{Localization.Additional}</div>
  </Link>
  <Link to={confirmAddition && props?.fetchApiData?.sections && props?.fetchApiData?.sections[props?.fetchApiData?.sections?.findIndex((p) => p?.section === '6-Additional Details')]?.section ? '/fileupload' : '/6-Additional Details'}>
    <div className="file">{Localization.Fileupload}</div>
  </Link>
  <div className="line-rectangle1"></div>
        <div className="orange-line1"></div>
        <div className="ovaladd"></div>
        <div className="ovaladd1"></div>
        <div className="ovaladd2"></div>
        <div className="ovaladd3"></div>
        <div className="ovaladd4"></div>
        <div className="ovaladd5"></div>
        <div className="ovaladd6"></div>
</div>
<div className="white-modal-item">
        <form onSubmit={handleSubmit(onClickContinue)} >
          {prod &&
            Array.isArray(prod) &&
            prod.length > 0 &&
            prod.map((item, index) => {
              return (
                <div key={index}>
                  <div className=" row item-information1" aria-label={item.subGroupIdentifier}>
                    {item.subGroupIdentifierName}
                  </div>
                  <div className="row item-box">
                    {item.attributes.map((subItem, subIndex) => {
                      if (subItem.attributeId === 'SG-AudioMicSpecifications-MicrophoneAvailability' &&
                      subItem.attributeNameValue !== 'Yes') {
                        item.attributes.forEach((attr) => {
                          if (attr.attributeId !== 'SG-AudioMicSpecifications-MicrophoneAvailability') {
                            attr.disable = true;
                            attr.mandatory = false;
                          };
                        });
                      }
                      return (
                        <div className="item-box1 col-xl-3 col-lg-4 col-md-6 col-sm-6" key={subIndex}>
                          {getDisplayBox(subItem.type, subItem, subIndex)}
                        </div>
                      );
                    })}
                  </div>
                  {index !== prod.length - 1 ? (
                    <div className="st-line-additional" />
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })}
          <div className=" row additional-back">
            <a href="/5-Item Details">
              {' '}
              <img
                src={process.env.PUBLIC_URL + '/assets/camera-back.png'}
                alt="Arrow"
              />{' '}
            </a>
            <div className="dimension-button">
              <button
                type="submit"
                className="button-save-dimension"
                onClick={saveDataToast}
              >
                <div className="save-dimension">{Localization.Save}</div>
              </button>
              <button
                className="button-continue-dimension"
                disabled={(props?.fetchApiData?.productType === 'pack' && fetchPack ?(props?.saveLoader&&props?.packLoader):(props?.saveLoader))}
                onClick={onClickContinue} onMouseDownCapture={() => checkClickcontinueVal(true) }
              >
                <div className="dimension-continue">{Localization.Continue}</div>
              </button>
            </div>
          </div>
          {confirmAddition && <div className="confirmmsg-add" data-testid="genericmsg">{Localization.GenericMessage}</div>}
        </form>
      </div>
</>
);
};
export default AdditionalReturnData;
