import {put, takeEvery, call, takeLatest} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {fetchvalidateOTP, logoutService, sendOtpService, validateOtpService} from '../../services/loginServices';
import localization from '../../common/Localization';

export function* getOtpData(action) {
  try {
    const response = yield call(fetchvalidateOTP);
    if (response.status >= 200 && response.status < 300) {
      const otpData = yield response.data;
      yield put({type: actionTypes.VALIDATE_OTP_LOADING_COMPLETE, payload: otpData});
    } else if (!(response.status >= 200 && response.status < 300)) {
      yield put({type: actionTypes.API_ERROR, payload: null});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.VALIDATE_OTP_LOADING_ERROR, payload: error});
  }
}


export function* OtpWatcher() {
  yield takeEvery(actionTypes.LOAD_VALIDATE_OTP, getOtpData);
}

export function* SendOtpData(val) {
  try {
    const response = yield call(sendOtpService, val.payload);
    if (response.status >= 200 && response.status < 300) {
      const otpData = yield response.data;
      yield put({type: actionTypes.SEND_OTP_COMPLETE,
        payload: otpData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.SEND_OTP_ERROR, payload: error});
  }
}


export function* SendOtpWatcher() {
  yield takeEvery(actionTypes.SEND_OTP, SendOtpData);
}

export function* ValidateOtpData(action) {
  try {
    const response = yield call(validateOtpService, action.payload.user, action.payload.otp);
    const otpData = yield response.data;
    if (response.status >= 200 && response.status < 300) {
      // const otpData = yield response.data;
      yield put({
        type: actionTypes.VALIDATE_OTP_COMPLETE,
        payload: otpData,
      });
    } else if (response.data ===localization.UserAlreadyLoggedin) {
      yield put({
        type: actionTypes.VALIDATE_OTP_ALREADY_LOGGEDIN_ERROR,
        payload: otpData,
      });
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.VALIDATE_OTP_ERROR, payload: error});
  }
}


export function* ValidateOtpWatcher(value) {
  yield takeEvery(actionTypes.VALIDATE_OTP, ValidateOtpData);
}

export function* logout(action) {
  try {
    const response = yield call(logoutService);
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: actionTypes.LOGOUT_COMPLETE,
      });
    } else if (response.status >= 400 && response.status < 500 || !response.status) {
      // localStorage.clear();
      // window.location.href = '/login';
      throw new Error('Token not valid');
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOGOUT_ERROR,
    });
  }
}

export function* logoutWatcher(value) {
  yield takeLatest(actionTypes.LOGOUT, logout);
}


