import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {classificationAttributeItemApi} from '../../services/itemServices';


export function* itemWatcherAsyn(action) {
  try {
    const response = yield call(classificationAttributeItemApi, action.payload);
    if (response.status >= 200 && response.status < 300) {
      const primaryData = yield response.data;
      yield put({type: actionTypes.CLASSIFICATION_ATTRIBUTE_ITEM_COMPLETE, payload: primaryData});
    } else if (!(response.status >= 200 && response.status < 300)) {
      yield put({type: actionTypes.API_ERROR, payload: null});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.CLASSIFICATION_ATTRIBUTE_ITEM_ERROR, payload: error});
  }
}
export function* itemWatcher() {
  yield takeEvery(actionTypes.CLASSIFICATION_ATTRIBUTE_ITEM, itemWatcherAsyn);
}


export const itemSaga=[

  itemWatcher(),

];
