import React, {useEffect, useState} from 'react';
import 'react-dropdown/style.css';
import Layout from '../Shared/Layout/Layout';
import WelcomeHeader from '../Shared/WelcomeHeader/WelcomeHeader';
import SearchHeader from '../Shared/SearchHeader/SearchHeader';
import Filter from './Filter/Filter';

import SearchResult from './SearchResult/SearchResult';
import classes from './SearchReturnData.module.css';

const SearchReturnData = () => {
  return (
    <>
      <Layout />
      <WelcomeHeader />
      <SearchHeader />
      <section className={`white-modal-item ${classes.search__container}`}>
        <Filter />
        <SearchResult />
      </section>
    </>
  );
};

export default SearchReturnData;
