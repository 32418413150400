import React, {useState} from 'react';
import './File.css';
import {Link} from 'react-router-dom';
import Localization from '../../common/Localization';
import {
  CloudinaryContext,
  Image,
  Video,
  Transformation,
} from 'cloudinary-react';
import config from '../../config/config';
import {Button} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {deleteCloudinaryAsset} from '../../store/actions/deleteAction';
import {getAuthToken} from '../utils/AuthenticationUtils';

const FileReturnData = ({
  props,
  showModal,
  uploaded,
  images,
  beginImageUpload,
  fetchData,
  uploaded2,
  images2,
  beginVideoUpload,
  uploaded3,
  images4,
  beginManualUpload,
  uploaded4,
  images3,
  beginBrochureUpload,
  uploaded5,
  images5,
  beginMiscUpload,
  callApiUrl,
  openModal,
  refetch,

  onlyimages,
  onlyimages2,
  onlyimages3,
  onlyimages4,
  onlyimages5,
  fetchApiData,
}) => {
  const [index, setIndex] = useState('0');
  const maxlimit = 20;
  const IMAGE_BASE_URL = `https://res.cloudinary.com/${config.cloudName}/`;
  const dispatch = useDispatch();
  const token = getAuthToken();
  function openTab(index, e) {
    let i;
    setIndex(index);
    const tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    e.currentTarget.className += ' active';
  }

  const deleteUploadedAsset = async (id, resourceType) => {
    await dispatch(
      deleteCloudinaryAsset({
        payload: {
          [resourceType]: [id],
        },
        token: getAuthToken(),
      }),
    );

    refetch();
  };

  const deleteImage = (id) => {
    deleteUploadedAsset(id, 'images');
  };
  const deleteVideo = (id) => {
    deleteUploadedAsset(id, 'videos');
  };
  const deleteManual = (id) => {
    deleteUploadedAsset(id, 'manual');
  };
  const deleteOthers = (id) => {
    deleteUploadedAsset(id, 'others');
  };
  const deleteBrochure = (id) => {
    deleteUploadedAsset(id, 'brochure');
  };
  return (
    <>
      <div className="header-fix4">
        <div className="gray-line-item" />
        <div className="orange-line-file" />
        <div className="oval-item" />
        <div className="oval1-item" />
        <div className="oval2-item" />
        <div className="oval3-item" />
        <div className="oval4-item" />
        <div className="oval5-file" />
        <div className="oval6-file" />
        <Link
          to={props?.fetchApiData?.sections ? '/creation' : '/5-Item Details'}
        >
          {' '}
          <div className="group-information">{Localization.Groupinfo}</div>
        </Link>
        <Link
          to={
            props?.fetchApiData?.sections ?
              props?.fetchApiData.sections[
                  props?.fetchApiData.sections?.findIndex(
                    (p) => p.section === '2-Product Information',
                  )
                ]?.section :
              '/5-Item Details'
          }
        >
          <div className="product-information">{Localization.Productinfo}</div>{' '}
        </Link>
        <Link
          to={
            props?.fetchApiData?.sections ?
              props?.fetchApiData?.sections[
                  props?.fetchApiData.sections?.findIndex(
                    (p) => p?.section === '3-Company Information',
                  )
                ]?.section :
              '/5-Item Details'
          }
        >
          <div className="company-info-item">{Localization.Companyinfo}</div>
        </Link>
        <Link
          to={
            props?.fetchApiData?.sections ?
              props?.fetchApiData?.sections[
                  props?.fetchApiData.sections?.findIndex(
                    (p) => p?.section === '4-Dimensions',
                  )
                ]?.section :
              '/5-Item Details'
          }
        >
          {' '}
          <div className="dimensions-item">{Localization.Dimensions}</div>
        </Link>
        <Link
          to={
            props?.fetchApiData?.sections ?
              props?.fetchApiData?.sections[
                  props?.fetchApiData.sections?.findIndex(
                    (p) => p?.section === '5-Item Details',
                  )
                ]?.section :
              '/5-Item Details'
          }
        >
          <div className="item-item">{Localization.Itemdetails}</div>
        </Link>
        <Link
          to={
            props?.fetchApiData?.sections ?
              props?.fetchApiData?.sections[
                  props?.fetchApiData?.sections?.findIndex(
                    (p) => p?.section === '6-Additional Details',
                  )
                ] ?
                props?.fetchApiData?.sections[
                    props?.fetchApiData?.sections?.findIndex(
                      (p) => p?.section === '6-Additional Details',
                    )
                  ].section :
                '/5-Item Details' :
              '/5-Item Details'
          }
        >
          <div className="additional-information-file">
            {Localization.Additional}
          </div>
        </Link>
        <Link
          to={
            props?.fetchApiData?.sections &&
            props?.fetchApiData?.sections[
              props?.fetchApiData.sections?.findIndex(
                (p) => p?.section === '6-Additional Details',
              )
            ]?.section ?
              '/fileupload' :
              '/5-Item Details'
          }
        >
          <div className="file-information-file">{Localization.Fileupload}</div>
        </Link>
      </div>
      <div className="white-modal-file">
        <div className="file-upload">
          {Localization.Fileupload}
          <div>
            <img
              className="guide-line-icon"
              src={process.env.PUBLIC_URL + '/assets/Guideline.png'}
              alt="guideline"
            />
            <a href="#">
              <div
                className="guide-line-text"
                onClick={() => {
                  showModal();
                }}
              >
                {Localization.Guideline}
              </div>
            </a>
          </div>
        </div>
        <div className="middle-box">
          <div className="tab">
            <button className="tablinks" onClick={(e) => openTab('0', e)}>
              {Localization.Image}
            </button>
            <button className="tablinks" onClick={(e) => openTab('1', e)}>
              {Localization.Video}
            </button>
            <button className="tablinks" onClick={(e) => openTab('3', e)}>
              {Localization.Manual}
            </button>
            <button className="tablinks" onClick={(e) => openTab('4', e)}>
              {Localization.Brochure}
            </button>
            <button className="tablinks" onClick={(e) => openTab('5', e)}>
              {Localization.Misc}
            </button>
          </div>
          {index === '0' ? (
            <CloudinaryContext cloudName={config.cloudName} id="Image">
              <div style={{padding: '10px', color: 'orange'}}>
                   {Localization.SimulaneousAssetUploadWarning}
                </div>
              {uploaded ? (
                <div>
                  <div style={{height: '208px', overflowY: 'scroll'}}>
                    <div
                      style={{
                        display: 'grid',
                        height: '100%',
                        gridTemplateColumns: '188px 188px 188px 188px 188px',
                        gridGap: '12px',
                        marginTop: '2%',
                        marginLeft: '1.5%',
                      }}
                    >
                      {onlyimages.map((i, index) => {
                        return (
                            <div
                              key= {i}
                              style={{
                                flexDirection: 'column',
                                width: '100%',
                                height: 'auto',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  border: '2px solid #ECEEEE',
                                }}
                              >
                                <Image
                                  key={index}
                                  publicId={i}
                                  fetch-format="auto"
                                  quality="auto"
                                  className="center"
                                >
                                  <Transformation
                                    gravity="face"
                                    height="96"
                                    width="110"
                                    crop="thumb"
                                  />
                                </Image>
                              </div>
                              <div
                                style={{
                                  backgroundColor: '#ECEEEE',
                                  font: 8,
                                  fontFamily: 'Mercedes',
                                }}
                              >
                                <span>
                                  {' '}
                                  <img
                                    style={{
                                      margin: '2.5%',
                                      height: '7%',
                                      width: '7%',
                                    }}
                                    src={
                                      process.env.PUBLIC_URL +
                                      '/assets/Upload.png'
                                    }
                                    alt=""
                                  />
                                </span>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="file-boxUpload">
                    <p
                      className="click-here-to-upload"
                      onClick={() => beginImageUpload()}
                    >
                      {Localization.Clickhere}
                    </p>
                    <div
                      className="green-box"
                      onClick={() => beginImageUpload()}
                    >
                      {' '}
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.images ? (
                      <div className="uploadfiles">
                        {Localization.UploadedFiles}
                      </div>
                    ) : null}

                    <div className="uploadimage">
                      {fetchApiData?.images?.map((i, index) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              width={'25px'}
                              style={{
                                margin: '3.5%',
                                width: '25px',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                // process.env.PUBLIC_URL + '/assets/Upload.png'
                                i.includes('.pdf') ?
                                  process.env.PUBLIC_URL +
                                    '/assets/Upload.png' :
                                  IMAGE_BASE_URL + i
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={IMAGE_BASE_URL + i}>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteImage(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="file-icon">
                    <img
                      src={process.env.PUBLIC_URL + '/assets/File upload.png'}
                      alt="plus"
                    />
                    <div className="drop-your-files-here">
                      {Localization.DropYourFilesHere}{' '}
                    </div>
                  </div>
                  <div className="file-box2">
                    <p
                      className="click-here-to-upload"
                      onClick={() => beginImageUpload()}
                    >
                      {Localization.Clickhere}
                    </p>
                    <div
                      className="green-box"
                      onClick={() => beginImageUpload()}
                    >
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.images ? (
                      <div className="uploadfiles">
                        {Localization.UploadedFiles}
                      </div>
                    ) : null}

                    <div className="uploadimage">
                      {fetchApiData?.images?.map((i, index) => {
                        return (
                          <span
                            key = {i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              style={{
                                margin: '3.5%',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                // process.env.PUBLIC_URL + '/assets/Upload.png'
                                i.includes('.pdf') ?
                                  process.env.PUBLIC_URL +
                                    '/assets/Upload.png' :
                                  IMAGE_BASE_URL + i
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={IMAGE_BASE_URL + i}>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteImage(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </CloudinaryContext>
          ) : null}
          {index === '1' ? (
            <CloudinaryContext cloudName={config.cloudName} id="Video">
               <div style={{padding: '10px', color: 'orange'}}>
                   {Localization.SimulaneousAssetUploadWarning}
                </div>
              {uploaded2 ? (
                <div>
                  <div style={{height: '208px', overflowY: 'scroll'}}>
                    <div
                      style={{
                        display: 'grid',
                        height: '100%',
                        gridTemplateColumns: '188px 188px 188px 188px 188px',
                        gridGap: '12px',
                        marginTop: '2%',
                        marginLeft: '1.5%',
                      }}
                    >
                      {onlyimages2.map((i, index) => {
                        return (
                            <div
                              key={i}
                              style={{
                                flexDirection: 'column',
                                width: '100%',
                                height: 'auto',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  border: '2px solid #ECEEEE',
                                }}
                              >
                                <Video
                                  key={index}
                                  publicId={i}
                                  fetch-format="auto"
                                  quality="auto"
                                  className="center"
                                  style={{
                                    marginRight: '.5%',
                                    height: '15%',
                                    width: '100%',
                                  }}
                                >
                                  <Transformation
                                    gravity="face"
                                    width="150"
                                    height="96"
                                    crop="crop"
                                  />
                                </Video>
                              </div>
                              <div
                                style={{
                                  backgroundColor: '#ECEEEE',
                                  font: 8,
                                  fontFamily: 'Mercedes',
                                }}
                              >
                                <span>
                                  {' '}
                                  <img
                                    style={{
                                      margin: '2.5%',
                                      height: '7%',
                                      width: '7%',
                                    }}
                                    src={
                                      process.env.PUBLIC_URL +
                                      '/assets/Upload.png'
                                    }
                                    alt=""
                                  />
                                </span>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="file-boxUpload">
                    <div
                      className="click-here-to-upload"
                      style={{cursor: 'pointer'}}
                      onClick={() => beginVideoUpload()}
                    >
                      {Localization.Clickhere}
                    </div>
                    <div
                      className="green-box"
                      onClick={() => beginVideoUpload()}
                    >
                      {' '}
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.videos ? (
                      <div className="uploadfiles">
                        {Localization.UploadedFiles}
                      </div>
                    ) : null}
                    <div className="uploadimage">
                      {fetchApiData?.videos?.map((i, index) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              style={{
                                margin: '3.5%',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                i.includes('.mp4') ?
                                  `${IMAGE_BASE_URL}video/upload/${i.replace(
                                      '.mp4',
                                      '.jpg',
                                    )}` :
                                  process.env.PUBLIC_URL +
                                    '/assets/Upload.png'
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={`${IMAGE_BASE_URL}video/upload/${i}`}>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteVideo(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="file-icon">
                    {' '}
                    <img
                      src={process.env.PUBLIC_URL + '/assets/File upload.png'}
                      alt="plus"
                    />
                    <div className="drop-your-files-here">
                      {Localization.DropYourFilesHere}{' '}
                    </div>
                  </div>
                  <div className="file-box2">
                    <p
                      className="click-here-to-upload"
                      onClick={() => beginVideoUpload()}
                    >
                      {Localization.Clickhere}
                    </p>
                    <div
                      className="green-box"
                      onClick={() => beginVideoUpload()}
                    >
                      {' '}
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.videos ? (
                      <div className="uploadfiles">
                        {Localization.UploadedFiles}
                      </div>
                    ) : null}
                    <div className="uploadimage">
                      {fetchApiData?.videos?.map((i, index) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              style={{
                                margin: '3.5%',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                i.includes('.mp4') ?
                                `${IMAGE_BASE_URL}video/upload/${i.replace(
                                    '.mp4',
                                    '.jpg',
                                  )}` :
                                process.env.PUBLIC_URL +
                                  '/assets/Upload.png'
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={`${IMAGE_BASE_URL}video/upload/${i}`}>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteVideo(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </CloudinaryContext>
          ) : null}
          {index === '3' ? (
            <CloudinaryContext cloudName={config.cloudName} id="Image">
               <div style={{padding: '10px', color: 'orange'}}>
                   {Localization.SimulaneousAssetUploadWarning}
                </div>
              {uploaded3 ? (
                <div>
                  <div style={{height: '208px', overflowY: 'scroll'}}>
                    <div
                      style={{
                        display: 'grid',
                        height: '100%',
                        gridTemplateColumns: '188px 188px 188px 188px 188px',
                        gridGap: '12px',
                        marginTop: '2%',
                        marginLeft: '1.5%',
                      }}
                    >
                      {onlyimages4.map((i, index) => {
                        return (
                            <div
                              key={i}
                              style={{
                                flexDirection: 'column',
                                width: '100%',
                                height: 'auto',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  border: '2px solid #ECEEEE',
                                }}
                              >
                                <Image
                                  key={index}
                                  publicId={i}
                                  fetch-format="auto"
                                  quality="auto"
                                  className="center"
                                >
                                  <Transformation
                                    gravity="face"
                                    height="96"
                                    width="150"
                                    crop="thumb"
                                  />
                                </Image>
                              </div>
                              <div
                                style={{
                                  backgroundColor: '#ECEEEE',
                                  font: 8,
                                  fontFamily: 'Mercedes',
                                }}
                              >
                                <span>
                                  {' '}
                                  <img
                                    style={{
                                      margin: '2.5%',
                                      height: '7%',
                                      width: '7%',
                                    }}
                                    src={
                                      process.env.PUBLIC_URL +
                                      '/assets/Upload.png'
                                    }
                                    alt=""
                                  />
                                </span>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="file-boxUpload">
                    <div
                      className="click-here-to-upload"
                      style={{cursor: 'pointer'}}
                      onClick={() => beginManualUpload()}
                    >
                      {Localization.Clickhere}
                    </div>
                    <div
                      className="green-box"
                      onClick={() => beginManualUpload()}
                    >
                      {' '}
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.manual ? (
                      <div className="uploadfiles">
                        {Localization.UploadedFiles}
                      </div>
                    ) : null}
                    <div className="uploadimage">
                      {fetchApiData?.manual?.map((i, index) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              style={{
                                margin: '3.5%',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                i.includes('.pdf') ? `${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}` : process.env.PUBLIC_URL + '/assets/Upload.png'
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={`${IMAGE_BASE_URL}image/upload/${i}`}>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteManual(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="file-icon">
                    {' '}
                    <img
                      src={process.env.PUBLIC_URL + '/assets/File upload.png'}
                      alt="plus"
                    />
                    <div className="drop-your-files-here">
                      {Localization.DropYourFilesHere}{' '}
                    </div>
                  </div>
                  <div className="file-box2">
                    <p
                      className="click-here-to-upload"
                      onClick={() => beginManualUpload()}
                    >
                      {Localization.Clickhere}
                    </p>
                    <div
                      className="green-box"
                      onClick={() => beginManualUpload()}
                    >
                      {' '}
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.manual ? (
                      <div className="uploadfiles">
                        {Localization.UploadedFiles}
                      </div>
                    ) : null}

                    <div className="uploadimage">
                      {fetchApiData?.manual?.map((i, index) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              style={{
                                margin: '3.5%',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                i.includes('.pdf') ? `${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}` : process.env.PUBLIC_URL + '/assets/Upload.png'
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={`${IMAGE_BASE_URL}image/upload/${i}`}>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteManual(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </CloudinaryContext>
          ) : null}
          {index === '4' ? (
            <CloudinaryContext cloudName={config.cloudName} id="Image">
               <div style={{padding: '10px', color: 'orange'}}>
                   {Localization.SimulaneousAssetUploadWarning}
                </div>
              {uploaded4 ? (
                <div>
                  <div style={{height: '208px', overflowY: 'scroll'}}>
                    <div
                      style={{
                        display: 'grid',
                        height: '100%',
                        gridTemplateColumns: '188px 188px 188px 188px 188px',
                        gridGap: '12px',
                        marginTop: '2%',
                        marginLeft: '1.5%',
                      }}
                    >
                      {onlyimages3.map((i, index) => {
                        return (
                            <div
                              key={i}
                              style={{
                                flexDirection: 'column',
                                width: '100%',
                                height: 'auto',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  border: '2px solid #ECEEEE',
                                }}
                              >
                                <Image
                                  key={index}
                                  publicId={i}
                                  fetch-format="auto"
                                  quality="auto"
                                  className="center"
                                >
                                  <Transformation
                                    gravity="face"
                                    height="96"
                                    width="150"
                                    crop="thumb"
                                  />
                                </Image>
                              </div>
                              <div
                                style={{
                                  backgroundColor: '#ECEEEE',
                                  font: 8,
                                  fontFamily: 'Mercedes',
                                }}
                              >
                                <span>
                                  {' '}
                                  <img
                                    style={{
                                      margin: '2.5%',
                                      height: '7%',
                                      width: '7%',
                                    }}
                                    src={
                                      process.env.PUBLIC_URL +
                                      '/assets/Upload.png'
                                    }
                                    alt=""
                                  />
                                </span>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="file-boxUpload">
                    <div
                      className="click-here-to-upload"
                      style={{cursor: 'pointer'}}
                      onClick={() => beginBrochureUpload()}
                    >
                      {Localization.Clickhere}
                    </div>
                    <div
                      className="green-box"
                      onClick={() => beginBrochureUpload()}
                    >
                      {' '}
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.brochure ? (
                      <div className="uploadfiles">
                        {' '}
                        {Localization.UploadedFiles}
                      </div>
                    ) : null}

                    <div className="uploadimage">
                      {fetchApiData?.brochure?.map((i, index) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              style={{
                                margin: '3.5%',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                i.includes('.pdf')? `${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}`: process.env.PUBLIC_URL + '/assets/Upload.png'
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={ i.includes('.pptx')?
                              `${IMAGE_BASE_URL}raw/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}`:`${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}`
                            }>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteBrochure(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="file-icon">
                    {' '}
                    <img
                      src={process.env.PUBLIC_URL + '/assets/File upload.png'}
                      alt="plus"
                    />
                    <div className="drop-your-files-here">
                      {Localization.DropYourFilesHere}{' '}
                    </div>
                  </div>
                  <div className="file-box2">
                    <p
                      className="click-here-to-upload"
                      onClick={() => beginBrochureUpload()}
                    >
                      {Localization.Clickhere}
                    </p>
                    <div
                      className="green-box"
                      onClick={() => beginBrochureUpload()}
                    >
                      {' '}
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.brochure ? (
                      <div className="uploadfiles">
                        {Localization.UploadedFiles}
                      </div>
                    ) : null}

                    <div className="uploadimage">
                      {fetchApiData?.brochure?.map((i, index) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              style={{
                                margin: '3.5%',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                i.includes('.pdf')? `${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}`: process.env.PUBLIC_URL + '/assets/Upload.png'
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={ i.includes('.pptx')?
                              `${IMAGE_BASE_URL}raw/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}`:`${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}`
                            }>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteBrochure(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </CloudinaryContext>
          ) : null}
          {index === '5' ? (
            <CloudinaryContext cloudName={config.cloudName} id="Image">
               <div style={{padding: '10px', color: 'orange'}}>
                   {Localization.SimulaneousAssetUploadWarning}
                </div>
              {uploaded5 ? (
                <div>
                  <div style={{height: '208px', overflowY: 'scroll'}}>
                    <div
                      style={{
                        display: 'grid',
                        height: '100%',
                        gridTemplateColumns: '188px 188px 188px 188px 188px',
                        gridGap: '12px',
                        marginTop: '2%',
                        marginLeft: '1.5%',
                      }}
                    >
                      {onlyimages5.map((i, index) => {
                        return (
                            <div
                              key={i}
                              style={{
                                flexDirection: 'column',
                                width: '100%',
                                height: 'auto',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  border: '2px solid #ECEEEE',
                                }}
                              >
                                <Image
                                  key={index}
                                  publicId={i}
                                  fetch-format="auto"
                                  quality="auto"
                                  className="center"
                                >
                                  <Transformation
                                    gravity="face"
                                    height="96"
                                    width="150"
                                    crop="thumb"
                                  />
                                </Image>
                              </div>
                              <div
                                style={{
                                  backgroundColor: '#ECEEEE',
                                  font: 8,
                                  fontFamily: 'Mercedes',
                                }}
                              >
                                <span>
                                  {' '}
                                  <img
                                    style={{
                                      margin: '2.5%',
                                      height: '7%',
                                      width: '7%',
                                    }}
                                    src={
                                      process.env.PUBLIC_URL +
                                      '/assets/Upload.png'
                                    }
                                    alt=""
                                  />
                                </span>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="file-boxUpload">
                    <div
                      className="click-here-to-upload"
                      style={{cursor: 'pointer'}}
                      onClick={() => beginMiscUpload()}
                    >
                      {Localization.Clickhere}
                    </div>
                    <div
                      className="green-box"
                      onClick={() => beginMiscUpload()}
                    >
                      {' '}
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.others ? (
                      <div className="uploadfiles">
                        {Localization.UploadedFiles}
                      </div>
                    ) : null}

                    <div className="uploadimage">
                      {fetchApiData?.others?.map((i, index) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              style={{
                                margin: '3.5%',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                // process.env.PUBLIC_URL + '/assets/Upload.png'
                                `${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}`
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={ i.includes('.pptx') ? `${IMAGE_BASE_URL}raw/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}` : `${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}` }>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteOthers(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="file-icon">
                    {' '}
                    <img
                      src={process.env.PUBLIC_URL + '/assets/File upload.png'}
                      alt="plus"
                    />
                    <div className="drop-your-files-here">
                      {Localization.DropYourFilesHere}{' '}
                    </div>
                  </div>
                  <div className="file-box2">
                    <p
                      className="click-here-to-upload"
                      onClick={() => beginMiscUpload()}
                    >
                      {Localization.Clickhere}
                    </p>
                    <div
                      className="green-box"
                      onClick={() => beginMiscUpload()}
                    >
                      {' '}
                      <img
                        className="plus"
                        src={process.env.PUBLIC_URL + '/assets/Pluswhite.png'}
                        alt="plus"
                      />
                    </div>
                    {fetchApiData?.others ? (
                      <div className="uploadfiles">
                        {Localization.UploadedFiles}{' '}
                      </div>
                    ) : null}

                    <div className="uploadimage">
                    {fetchApiData?.others?.map((i, index) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: 'inline-flex',
                              width: 'fit-content',
                              minWidth: '170px',
                              height: '40px',
                              // border: '0.5px dotted grey',
                            }}
                          >
                            {' '}
                            <img
                              style={{
                                margin: '3.5%',
                                height: '30px',
                                marginRight: '10px',
                              }}
                              src={
                                // process.env.PUBLIC_URL + '/assets/Upload.png'
                                `${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}`
                              }
                              alt=""
                            />
                            <span
                              style={{
                                margin: 'auto',
                                minWidth: '130px',
                                flex: 2,
                              }}
                            >
                              <a href={ i.includes('.pptx') ? `${IMAGE_BASE_URL}raw/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}` : `${IMAGE_BASE_URL}image/upload/c_thumb,g_face,h_96,w_150/f_auto,q_auto/v1/${i}` }>
                                {i.substring(i.lastIndexOf('/') + 1).length >
                                maxlimit ?
                                  i
                                      .substring(i.lastIndexOf('/') + 1)
                                      .substring(0, maxlimit) + '...' :
                                  i.substring(i.lastIndexOf('/') + 1)}
                              </a>
                            </span>
                            <Button
                              style={{
                                display: 'inline-flex',
                                width: 'fit-content',
                              }}
                              onClick={() => deleteOthers(i)}
                            >
                              <strong>X</strong>
                            </Button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </CloudinaryContext>
          ) : null}
        </div>
        <div
          style={{
            marginTop: index === '2' ? null : '34%',
            marginBottom: '4%',
            height: '12%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="camera-back-fs">
            <a href="/6-Additional Details">
              {' '}
              <img
                src={process.env.PUBLIC_URL + '/assets/camera-back.png'}
                alt="Arrow"
              />{' '}
            </a>
          </div>
          <button
            type="submit"
            className="button-save-file"
            onClick={() => callApiUrl()}
          >
            <div className="save-file">{Localization.Save}</div>
          </button>
          <button className="button-continue-file" onClick={openModal}>
            <div className="continue-file">
              <div className="file-preview">{Localization.Preview}</div>
            </div>
          </button>
        </div>
      </div>
      <div className="all-rights-reserved-fileupload1">
        {Localization.Disclaimer}
      </div>
      <div className="all-rights-reserved-fileupload">
        {Localization.Allrights}
      </div>
    </>
  );
};
export default FileReturnData;
