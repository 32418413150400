import {HttpService} from './HttpService';
import {ARTICLE} from '../config/apiEndpoints';
export async function articleApi(token) {
  try {
    const endpoint = ARTICLE;
    return (await HttpService.post( endpoint, null, {headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded'}}));
  } catch (err) {
    return {err};
  }
}
