import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function youTubeUrlReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.SUBMIT_URL:
      return {...state, loading: true};
    case actionTypes.SUBMIT_URL_COMPLETE:
      return {...state, loading: false, saveUrlData: action.payload, isSuccess: true};
    case actionTypes.SUBMIT_URL_ERROR:
      return {...state, loading: false, errorMessage: undefined, isSuccess: false};
    case actionTypes.EMPTYSAVE:
      return {...state, loading: false, saveUrlData: undefined};
    default:
      return state;
  }
}

