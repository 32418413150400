import {HttpService} from './HttpService';
import {PRODUCT_ADDITION} from '../config/apiEndpoints';
import axios from 'axios';
export async function saveApi(token, sendsaveData) {
  // token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJnV2lhaTJkMEMvSmI0Tk1hblMvNnlESjAvWm1SZ3pMZkNIQTROKzBkdmYzNiIsImV4cCI6MTcwNDMyOTUxNSwiaWF0IjoxNzA0MjkzNTE1fQ.JUvF3sLACrYo6vPYxhcPl6pu15EVnFi4aG2K47ylboTmwImzbAz2YzVd7JBQSUQfbebn7FIBri05HZH8p03rTw';
  try {
    const endpoint = PRODUCT_ADDITION;
    //  return (await HttpService.put( endpoint, JSON.stringify(sendsaveData), {headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'}}));
    return (await HttpService.post( endpoint, JSON.stringify(sendsaveData), {headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'}}));
    // return (await HttpService.put( endpoint, JSON.stringify([]), {headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'}}));
    // return (await axios.put( `http://localhost:8080/vendorportal/productData?userId=${userId}`, JSON.stringify(sendsaveData), {headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'}}));
  } catch (err) {
    return {err};
  }
}
