  export const elementRange = ({element, value}) =>{
    const range = element.range.split('-');
    const min = Number(range[0]);
    const max = Number(range[1]);
    if (Number(value) >= min && Number(value) <= max) {
      element.attributeNameValue = value;
      element.isCrossedRange = false;
    } else {
      element.isCrossedRange = true;
    }
    return null;
  };
  export const elementRegex = ({element, value, subelement}) => {
    const re = new RegExp(element.validRegex);
    if (value === '') {
      element.attributeNameValue = '';
    } else if (
      element.length !== '' &&
value.length > Number(element.length)
    ) {
      if (subelement.subGroupIdentifierName === 'In The Box') {
          if ((element.attributeName.includes('Quantity')) ||(element.attributeName.includes('Qty')) ) {
               element.isCrossedLength = false;
          }
       } else {
           element.isCrossedLength = true;
        }
      return element;
    } else if (re.test(value)) {
      element.isInvalid = false;
      element.isCrossedLength = false;
      element.attributeNameValue = value;
    } else {
      if (subelement.subGroupIdentifierName === 'In The Box') {
        if ((element.attributeName.includes('Quantity')) ||(element.attributeName.includes('Qty')) ) {
           element.isInvalid = false;
      }
     } else {
      element.isInvalid = true;
      element.isCrossedLength = false;
      }
      return element;
    }
    return null;
  };
  export const packAddData = ({item, index, indexPos, country1, getCountryVal, subGroupIdentifierName}) => {
    const classAttributeRes = country1?.map((items, indexs) => {
      return items?.map((subItem, subIndex)=>{
        const subelement = subItem;
        if (subelement.attributes[index] ) {
          if (subGroupIdentifierName === 'In The Box') {
            if (subelement.attributes[index].attributeId === item.attributeId && indexPos === indexs) {
              const data1 = {...subelement.attributes[index - 1]};
              data1.multiValued = 'N';
              data1.attributeNameValue = '';
              const data2 = {...subelement.attributes[index]};
              data2.multiValued = 'N';
              data2.attributeNameValue = '';
              let indxPos;
              let sectionValue;
              if (data1.sequence && data2.sequence) {
                subelement.attributes?.map((elm, ind) => {
                  if (elm.attributeId === item.attributeId) {
                    sectionValue = '' + (Number(elm.sequence) + 1);
                  }
                  return null;
                });
                indxPos = index - 1 + 2 * Number(sectionValue);
              } else {
                subelement.attributes[index - 1].sequence = '0';
                subelement.attributes[index].sequence = '0';
                sectionValue = '1';
                indxPos = index + 1;
              }
              data1.sequence = sectionValue;
              data2.sequence = sectionValue;
              data1.attributeName = data1.attributeName + '_' +(sectionValue);
              data2.attributeName = data2.attributeName + '_' +(sectionValue);
              subelement.attributes.splice(indxPos, 0, data1, data2);
            }
          } else if (
            subelement.attributes[index].attributeId === item.attributeId && indexPos === indexs
          ) {
            const data = {...subelement.attributes[index]};
            data.multiValued = 'N';
            data.attributeNameValue = '';


            let indxPos;
            let sectionValue;
            if (data.sequence) {
              subelement.attributes?.map((elm, ind) => {
                if (elm.attributeId === item.attributeId) {
                  sectionValue = '' + (Number(elm.sequence) + 1);
                }
                return null;
              });
              indxPos = index + Number(sectionValue);
            } else {
              subelement.attributes[index].sequence = '0';
              sectionValue = '1';
              indxPos = index + 1;
            }
            data.sequence = sectionValue;
            data.attributeName = data.attributeName + '_'+ sectionValue;
            subelement.attributes.splice(indxPos, 0, data);
          }
        }
        return subelement;
      });
    });

    getCountryVal(classAttributeRes);
    return null;
  };
  export const packDeleteData = ({item, index, indexPos, country1, getCountryDelVal, subGroupIdentifierName}) => {
    const classAttributeRes = country1?.map((items, indexs) => {
      return items?.map((subItem, subIndex)=>{
        const subelement = subItem;
        if (subelement.attributes[index]) {
          if (subGroupIdentifierName === 'In The Box') {
            if (subelement.attributes[index].attributeId === item.attributeId && indexPos === indexs) {
              subelement.attributes.splice(index, 1);
              subelement.attributes.splice(index - 1, 1);
            }
          } else if (
            subelement.attributes[index].attributeId === item.attributeId &&
          subelement.attributes[index].sequence === item.sequence && indexPos === indexs
          ) {
            subelement.attributes.splice(index, 1);
          }
        }
        return subelement;
      });
    });
    getCountryDelVal(classAttributeRes);
    return null;
  };

export default (elementRange, elementRegex, packAddData, packDeleteData);

