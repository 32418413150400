export const speakerType = ({value, prod}) => {
  if (value !== '' ) {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-Sound-SpeakerType') {
                  checkSubItem.mandatory='Y';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-Sound-SpeakerType') {
                  checkSubItem.mandatory='N';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export const filterType = ({value, prod}) => {
  if (value === 'Yes' ) {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-AirCoolerFunctions-AirPurificationFilterType') {
                  checkSubItem.mandatory='Y';
                }

                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  } else {
    prod?.map(
        (checkItem, checkIndex) => {
          const checkelement = checkItem;
          checkelement.attributes = checkItem.attributes.map(
              (checkSubItem, checkSubIndex) => {
                if (checkSubItem.attributeId === 'SG-AirCoolerFunctions-AirPurificationFilterType') {
                  checkSubItem.mandatory='N';
                }
                return checkSubItem;
              },
          );
          return checkelement;
        },
    );
  }
  return null;
};
export default (filterType, speakerType);
