
import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function packReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.PACK_ATTRIBUTE:
      return {...state, loading: true};
    case actionTypes.PACK_ATTRIBUTE_COMPLETE:
      return {...state, loading: false, packData: action.payload};
    case actionTypes.PACK_ATTRIBUTE_ERROR:
      return {...state, loading: false, errorMessage: action.payload};
    default:
      return state;
  }
}
