import React from 'react';
import './Item.css';
import Localization from '../../common/Localization';
import Layout from '../Shared/Layout/Layout';
import WelcomeHeader from '../Shared/WelcomeHeader/WelcomeHeader';
import FormHeader from '../Shared/FormHeader/FormHeader';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import PackItem from './PackItem';

const ItemReturnData = ({props, prod, getDisplayBox, saveDataToast, fetchPack, showDetails, setShowDetails, checkClickcontinueVal, confirmItem, onClickContinueItem}) => {
    const {handleSubmit} = useForm();
    return (
        <>
    <Layout />
    <WelcomeHeader />
    <FormHeader />
    <div className="header-fix4">
      <div className="gray-line-item" data-testid='test8'/>
      <div className="orange-line-item" data-testid='test9' />
      <div className="oval-item" data-testid='test10' />
      <div className="oval1-item" data-testid='test11'/>
      <div className="oval2-item" data-testid='test12'/>
      <div className="oval3-item" data-testid='test13'/>
      <div className="oval4-item" data-testid='test14'/>
      <div className="oval5-item" data-testid='test15'/>
      <div className="oval6-item" data-testid='test16'/>
      <Link to={props?.fetchApiData?.sections ? '/creation' : '/5-Item Details'}>
        {' '}
        <div className="group-information" data-testid='test1'>{Localization.Groupinfo}</div>
      </Link>
      <Link to={props?.fetchApiData?.sections ? props?.fetchApiData?.sections[props?.fetchApiData.sections?.findIndex((p) => p.section === '2-Product Information')]?.section : '/5-Item Details'}>
        <div className="product-information" data-testid='test2'>{Localization.Productinfo}</div>{' '}
      </Link>
      <Link to={props?.fetchApiData?.sections ? props?.fetchApiData?.sections[props?.fetchApiData.sections?.findIndex((p) => p?.section === '3-Company Information')]?.section : '/5-Item Details'}>
        <div className="company-info-item" data-testid='test3'>{Localization.Companyinfo}</div>
      </Link>
      <Link to={props?.fetchApiData?.sections ? props?.fetchApiData?.sections[props?.fetchApiData.sections?.findIndex((p) => p?.section === '4-Dimensions')]?.section : '/5-Item Details'}>
        {' '}
        <div className="dimensions-item" data-testid='test4'>{Localization.Dimensions}</div>
      </Link>
          <Link to={props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '5-Item Details')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '5-Item Details')].section : '/5-Item Details') : '/5-Item Details'}>
        <div className="item-item" data-testid='test5'>{Localization.Itemdetails}</div>
      </Link>
          <Link to={confirmItem && props ?.fetchApiData ?.sections ? (props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')] ? props ?.fetchApiData ?.sections[props ?.fetchApiData ?.sections ?.findIndex((p) => p ?.section === '6-Additional Details')].section : '/5-Item Details') : '/5-Item Details'}>
        <div className="additional-information" data-testid='test6'>
          {Localization.Additional}
        </div>
      </Link>
      <Link to={confirmItem && props?.fetchApiData?.sections && props?.fetchApiData?.sections[props?.fetchApiData.sections?.findIndex((p) => p?.section === '6-Additional Details')]?.section ? '/fileupload' : '/5-Item Details'}>
        <div className="file-information" data-testid='test6'>{Localization.Fileupload}</div>
      </Link>
    </div>
    <div className="white-modal-item">
      <form onSubmit={handleSubmit(onClickContinueItem)} className="">
        {prod &&
              Array.isArray(prod) &&
              prod.length > 0 &&
              prod.map((item, index) => {
                return (
                  <div className="" key={index}>
                    <div className="item-information1">
                      {item.subGroupIdentifierName}
                    </div>
                    <div className="row item-box">
                      {item.attributes.map((subItem, subIndex) => {
                        return (
                          <div className="item-box1 col-sm-6 col-md-6 col-lg-3 col-xs-6" key={subIndex}>
                            {getDisplayBox(
                                subItem.type,
                                subItem,
                                subIndex,
                                item.subGroupIdentifierName,
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {index !== prod.length - 1 ? (
                      <div className="st-line-item" />
                    ) : (
                        <div></div>
                      )}
                  </div>
                );
              })}
        {fetchPack && <PackItem
          linkName="5-Item Details"
          packData={fetchPack}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          savePackApi={props.savePackApi}
          getDisplayBox={getDisplayBox}
        />}
        <div className=" row item-back">
          <a href="/4-Dimensions">
            {' '}
            <img
              src={process.env.PUBLIC_URL + '/assets/camera-back.png'}
              alt="Arrow"
            />{' '}
          </a>
          <div className="item-button">
            <button
            aria-label={Localization.Save}
              type="submit"
              className="item-save-comp"
              onClick={() => saveDataToast()}
            >
              <div>{Localization.Save}</div>
            </button>
            <button
              aria-label={Localization.Continue}
              className="button-continue-comp"
              onClick={onClickContinueItem} onMouseDown={() => checkClickcontinueVal(true)} onMouseDownCapture={() => checkClickcontinueVal(true)} disabled={props?.fetchApiData?.productType === 'pack' && fetchPack ? (props.saveLoader && props.packLoader) : (props.saveLoader)}
            >
              <div className="company-continue">{Localization.Continue}</div>
            </button>
          </div>
        </div>
        {confirmItem && <div className="confirmmsg-item">{Localization.GenericMessage}</div>}
      </form>
    </div>
</>
);
};
export default ItemReturnData;
