  export const saveData = ({props, userId, token, prod, checkClickcontinue}) => {
    if (!checkClickcontinue) {
      props.saveApi({userId, token, sendsaveData: {section: '6-Additional Details', subGroups: prod}});
    }
    return null;
  };
  export const saveDataToast = ({props, userId, token, prod, getCheckVal, getConfirmAddition}) => {
     let isValid = 0;
    let count = 0;
      prod?.forEach(
                    (checkItem, checkIndex) => {
                      const checkelement = checkItem;
                      checkelement.attributes = checkItem.attributes.map(
                          (checkSubItem) => {
                            if (checkSubItem?.attributeNameValue!== '' && checkSubItem?.mandatory === 'Y') {
                              isValid++;
                            }
                            if (checkSubItem?.mandatory === 'Y') {
                            count++;
                             }
                            return checkSubItem;
                          },
                      );
                         return checkelement;
                    },
                );
    props.saveApi({userId, token, sendsaveData: {section: '6-Additional Details', subGroups: prod}});
    getCheckVal(true);
     if (isValid === count) {
        getConfirmAddition(false);
        } else {
           getConfirmAddition(true);
        }
        return null;
  };
  export const elementRange = ({element, value}) => {
    const range = element.range.split('-');
    const min = Number(range[0]);
    const max = Number(range[1]);
    if (Number(value) >= min && Number(value) <= max) {
      if (value.toString().includes('.')) {
        const temp = value.toString().split('.');
        if (temp[1].length>2) {
          return null;
        } else {
          element.attributeNameValue = value;
        }
      } else {
        element.attributeNameValue = value;
      }
      element.isCrossedRange = false;
    } else {
      if (value==='') {
        element.attributeNameValue = value;
      } else {
        element.isCrossedRange = true;
      }
    }
    return null;
  };
 export const elementRegex = ({element, value}) => {
    const re = new RegExp(element.validRegex);
    if (value === '') {
      element.attributeNameValue = '';
    } else if (
      element.length !== ''&& value.length > Number(element.length)
    ) {
      element.isCrossedLength = true;
      return element;
    } else if (re.test(value)) {
      element.isInvalid = false;
      element.isCrossedLength = false;
      element.attributeNameValue = value;
    } else {
      element.isInvalid = true;
      element.isCrossedLength = false;
      return element;
    }
    return null;
  };
  export const classAttributeAdd = ({item, index, prod, getProdVal}) => {
    let j = 0;
    let k = 0;
    if (item.fieldType==='Select') {
      prod?.map(
          (checkItem, checkIndex) => {
            const checkelement = checkItem;
            checkelement.attributes = checkItem.attributes.map(
                (checkSubItem, checkSubIndex) => {
                  if (item.attributeId === checkSubItem.attributeId) {
                    k++;
                  }
                  return checkSubItem;
                },
            );
            return checkelement;
          },
      );

      if (k > (item.attrNameLov.length-1)) {
        return null;
      }
    } else {
      prod?.map(
          (checkItem, checkIndex) => {
            const checkelement = checkItem;
            checkelement.attributes = checkItem.attributes.map(
                (checkSubItem, checkSubIndex) => {
                  if (item.attributeId === checkSubItem.attributeId) {
                    j++;
                  }
                  return checkSubItem;
                },
            );
            return checkelement;
          },
      );

      if (j > 10) {
        return;
      }
    }
    const classAttributeRes = prod.map(
        (subItem, subIndex) => {
          const subelement = subItem;
          if (subelement.attributes[index]) {
            if (subelement.attributes[index].attributeId === item.attributeId) {
              const data = {...subelement.attributes[index]};
              data.multiValued = 'N';
              data.mandatory= 'Y';
              data.attributeNameValue = '';
              let indxPos;
              let sectionValue;
              if (data.sequence) {
                subelement.attributes.map((elm, ind) => {
                  if (elm.attributeId === item.attributeId) {
                    sectionValue = '' + (Number(elm.sequence) + 1);
                  }
                  return null;
                });
                indxPos = index + Number(sectionValue);
              } else {
                subelement.attributes[index].sequence = '0';
                sectionValue = '1';
                indxPos = index + 1;
              }
              data.sequence = sectionValue;
              data.attributeName = data.attributeName +'_'+(sectionValue);

              subelement.attributes.splice(indxPos, 0, data);
            }
          }
          return subelement;
        },
    );
    getProdVal(classAttributeRes);
  };
  export const classAttributeDelete = ({index, selectedItem, prod, getProdDelVal}) => {
    let deleteIndex=0;
    const classAttributeRes = prod.map((subItem, subIndex) => {
      const subelement = subItem;
      if (subelement.attributes) {
        subelement.attributes.map((dItem, dIndex)=>{
          if (dItem.attributeId===selectedItem.attributeId&&selectedItem.fieldType==='Select') {
            dItem.attrNameLov.map((tempitem, tempIndex)=>{
              if (selectedItem.attributeNameValue===tempitem.lovName) {
                tempitem.isDisabled=false;
              }
              return tempitem;
            });
          }
          if (dIndex>index && dItem.attributeId===selectedItem.attributeId) {
            subelement.attributes[dIndex-1].attributeNameValue=subelement.attributes[dIndex].attributeNameValue;
            subelement.attributes[dIndex-1].attrNameLov=subelement.attributes[dIndex].attrNameLov;

            deleteIndex = dIndex;
          }
          return dItem;
        });

        if (deleteIndex>0) {
          subelement.attributes.splice(deleteIndex, 1);
        } else {
          subelement.attributes.splice(index, 1);
        }
      }


      return subelement;
    });
    getProdDelVal(classAttributeRes);
  };
  export const onClickContinueAdd = ({props, userId, token, prod, sendsaveData, getShowData, getConfirmAddition, getCheck, proceed, getClassAttr}) => {
    let checkClassAttribute = false;
    let checkDuplicate = false;
    let duplicateCount = 0;
    const classAttributeR = prod?.map((subItem, subIndex) => {
      const subelement = subItem;
      subelement.attributes = subItem.attributes.map(
          (secSubItem, secSubIndex) => {
            const element = secSubItem;
            if (element.attributeNameValue === '' && element.mandatory === 'Y') {
              checkClassAttribute = true;
            }
            if (element.sequence) {
              subItem.attributes.map((checkItem, checkIndex) => {
                if (element.attributeId === checkItem.attributeId && element.attributeNameValue !== '' &&
                secSubIndex !== checkIndex && element.attributeNameValue === checkItem.attributeNameValue) {
                  checkItem.isDuplicate = true;
                  checkDuplicate=true;
                  duplicateCount++;
                }
                return checkItem;
              });
            }
            return element;
          },
      );
      return subelement;
    });
    getClassAttr(classAttributeR);
    if (checkClassAttribute || checkDuplicate || duplicateCount > 0) {
      getShowData(true);
      getConfirmAddition(true);
    } else if (checkClassAttribute === false) {
      getShowData(false);
      getConfirmAddition(false);
      props.saveApi({userId, token, sendsaveData: {section: '6-Additional Details', subGroups: prod}});
      if (sendsaveData) {
        getCheck(true);
      }
      if (checkDuplicate === false) {
      proceed();
      }
  }
  return null;
};
export default (saveData, saveDataToast, elementRange, elementRegex, classAttributeAdd, classAttributeDelete, onClickContinueAdd);
