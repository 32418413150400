import * as actionTypes from './actionsTypes';

export const loadDeleteData =({userId, token})=>{
  return {
    type: actionTypes.DELETE,
    payload: {userId: userId, token: token},
  };
};

/**
 * Creates an action to delete cloudinary asset
 * @param {Object} payload
 * @returns {import('react').ReducerAction}
 */
export const deleteCloudinaryAsset = ({payload, token}) => {
  return {
    type: actionTypes.DELETE_CLOUDINARY_ASSET,
    payload: {payload, token},
  };
};

export const deleteCurrentSession =(userId)=>{
  return {
    type: actionTypes.DELETE_CURRENT_SESSION,
    payload: {userId: userId},
  };
};
