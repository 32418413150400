import React, {useEffect, useState} from 'react';
import '../ProductInformation/pack.css';
import Localization from '../../common/Localization';
import {useForm} from 'react-hook-form';
import {preventNonNumericalInput} from '../Shared/nonNumericalInputFunction';
import {elementRange} from './dimensionSave';

export const PackDimension = (props) => {
  const {register} = useForm();
  const [country1, setCountry1] = useState([]);
  const [saveFlag, setSaveFlag] = useState(0);
  const userId = localStorage.getItem('userId');
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
      .token;
  const [productArray, setProductArray] = useState(null);
  const [maximum, setMax] = useState('');

  let dimensionWidth;
  let dimensionHeight;
  let dimensionLengthInch;
  let dimensionWidthInch;
  let dimensionHeightInch;
  let dimensionLength;
  let dimensionGm;
  let dimensionKg;
  let dimensionQty;
  useEffect(() => {
    if (props?.packData) {
      const coreAttributesR = props?.packData?.map((packItem, index) => {
        return packItem?.sections?.map((item) => {
          if (item.section === props?.linkName) {
            return item.subGroups.map((subItem, subIndex) => {
              const subelement = subItem;
              subelement.attributes = subItem?.attributes?.map(
                  (secSubItem, secSubIndex) => {
                    const element = secSubItem;
                    if (secSubItem.attributeNameValue===null) {
                      secSubItem.attributeNameValue='';
                    }
                    element.attributeNameValue = secSubItem.attributeNameValue;
                    return element;
                  },
              );
              return subelement;
            });
          }
          return null;
        });
      });
      const packAttributesR = coreAttributesR.map((item, index) => {
        return item[props?.packData?.[0]?.sections?.findIndex((p) => p.section === props?.linkName)]?.map((subItem, subIndex) => {
          return subItem;
        });
      });
      setCountry1(packAttributesR);
      const createProductArray = props?.packData?.map((packItem, index) => {
        return packItem?.sections?.map((item) => {
          if (item.section === '2-Product Information') {
            return item.subGroups.map((subItem, subIndex) => {
              const subelement = subItem;
              subelement.attributes = subItem?.attributes?.map(
                  (secSubItem, secSubIndex) => {
                    const element = secSubItem;
                    element.attributeNameValue = secSubItem.attributeNameValue;
                    return element;
                  },
              );
              return subelement;
            });
          }
          return null;
        });
      });
      const productArrayItem = createProductArray.map((item, index) => {
        return item[props?.packData?.[0]?.sections?.findIndex((p) => p.section === '2-Product Information')]?.map((subItem, subIndex) => {
          return subItem;
        });
      });

      setProductArray(productArrayItem);
    }
  }, [props.packData, props.linkName]);

  useEffect(() => {
    console.log('saving pack data....');
    if (props.packData && props.savePackApi) {
      saveData();
    }
  }, [country1, saveFlag]);

  const roundToTwo=(num)=> {
    return +(Math.round(num + 'e+2')+ 'e-2');
  };
  const roundToThree=(num)=> {
    return +(Math.round(num + 'e+3')+ 'e-3');
  };
  function saveData() {
    props.savePackApi({userId, token, sendPackData: {'components': props.packData}});
  }
  const negative=(evt)=>{
    preventNonNumericalInput(evt);
  };
  const changeValue = (id, indexPos, value) => {
    const coreAttributesR = country1.map((item, index) => {
      return item?.map((subItem, subIndex)=>{
        const subelement = subItem;
        subelement.attributes = subItem.attributes.map(
            (secSubItem, secSubIndex) => {
              const element = secSubItem;
              if ((element.isCrossedLength === true && (value.length <= Number(element.length))) ||
              element.isInvalid === true || ( element.isCrossedRange=== true)) {
                element.isCrossedLength = false;
                element.isInvalid = false;
                element.isCrossedRange= false;
              }
              if (id === element.attributeId && indexPos === index) {
                if (element.validRegex != null) {
                  const re = new RegExp(element.validRegex);
                  if (value === '') {
                    element.attributeNameValue = '';
                    if (element.uom !== null && element.uom === 'UoM-g') {
                      subItem.attributes[secSubIndex + 1].attributeNameValue = '';
                    }
                    if (element.uom !== null && element.uom === 'UoM-Kg') {
                      subItem.attributes[secSubIndex - 1].attributeNameValue = '';
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-cm' &&
                    element.attributeName === 'Width (cm)'
                    ) {
                      subItem.attributes[secSubIndex + 3].attributeNameValue = '';
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-Inches' &&
                    element.attributeName === 'Width (in)'
                    ) {
                      subItem.attributes[secSubIndex - 3].attributeNameValue = '';
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-cm' &&
                    element.attributeName === 'Length (cm)'
                    ) {
                      subItem.attributes[secSubIndex + 3].attributeNameValue = '';
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-Inches' &&
                    element.attributeName === 'Length (in)'
                    ) {
                      subItem.attributes[secSubIndex - 3].attributeNameValue = '';
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-cm' &&
                    element.attributeName === 'Height (cm)'
                    ) {
                      subItem.attributes[secSubIndex + 3].attributeNameValue = '';
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-Inches' &&
                    element.attributeName === 'Height (in)'
                    ) {
                      subItem.attributes[secSubIndex - 3].attributeNameValue = '';
                    }
                  } else if (
                    element.length !== '' &&
                  value.length > Number(element.length)
                  ) {
                    element.isCrossedLength = true;
                    return element;
                  } else if (element.range != null && element.attributeId !== 'quantityInMasterCarton') {
                    elementRange({element, value, subItem, secSubIndex, roundToTwo, roundToThree, setMax});
                    return element;
                  } else if (re.test(value)) {
                    element.isInvalid = false;
                    element.isCrossedLength = false;
                    element.attributeNameValue = value;
                    if (element.uom != null && element.uom === 'UoM-g') {
                      subItem.attributes[secSubIndex + 1].attributeNameValue =
                      '' + roundToThree(Number(value) / 1000);
                    }
                    if (element.uom != null && element.uom === 'UoM-Kg') {
                      subItem.attributes[secSubIndex - 1].attributeNameValue =
                      '' + roundToThree(Number(value) * 1000);
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-cm' &&
                    element.attributeName === 'Width (cm)'
                    ) {
                      subItem.attributes[secSubIndex + 3].attributeNameValue =
                      '' + roundToTwo(Number(value) *0.393701);
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-Inches' &&
                    element.attributeName === 'Width (in)'
                    ) {
                      subItem.attributes[secSubIndex - 3].attributeNameValue =
                      '' + roundToTwo(Number(value) / 0.393701);
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-cm' &&
                    element.attributeName === 'Length (cm)'
                    ) {
                      subItem.attributes[secSubIndex + 3].attributeNameValue =
                      '' + roundToTwo(Number(value) *0.393701);
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-Inches' &&
                    element.attributeName === 'Length (in)'
                    ) {
                      subItem.attributes[secSubIndex - 3].attributeNameValue =
                      '' + roundToTwo(Number(value) / 0.393701);
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-cm' &&
                    element.attributeName === 'Height (cm)'
                    ) {
                      subItem.attributes[secSubIndex + 3].attributeNameValue =
                      '' + roundToTwo(Number(value) * 0.393701);
                    }
                    if (
                      element.uom !== null &&
                    element.uom === 'UoM-Inches' &&
                    element.attributeName === 'Height (in)'
                    ) {
                      subItem.attributes[secSubIndex - 3].attributeNameValue =
                      '' + roundToTwo(Number(value) / 0.393701);
                    }
                  } else {
                    element.isInvalid = true;
                    element.isCrossedLength = false;
                    return element;
                  }
                }
                if (subItem.subGroupIdentifierName === 'Master Carton Dimensions') {
                  if (element.attributeNameValue !== '') {
                    country1.forEach(
                        (checkItem, checkIndex) => {
                          return checkItem.map((checkItemSub) => {
                            const checkelement = checkItemSub;
                            checkelement.attributes = checkItemSub.attributes?.map(
                                (checkSubItem, checkSubIndex) => {
                                  if (subItem.subGroupIdentifierName === 'Master Carton Dimensions') {
                                    checkSubItem.mandatory = 'Y';
                                  }
                                  return checkSubItem;
                                },
                            );
                            return checkelement;
                          });
                        },
                    );
                  } else {
                    country1.forEach(
                        (checkItem, checkIndex) => {
                          return checkItem.map((checkItemSub) => {
                            const checkelement = checkItemSub;
                            checkelement.attributes = checkItemSub.attributes?.map(
                                (checkSubItem, checkSubIndex) => {
                                  if (checkSubItem.attributeId === 'widthCD' || checkSubItem.attributeId === 'lengthCD' || checkSubItem.attributeId === 'heightCD' || checkSubItem.attributeId === 'lengthCD - inch' || checkSubItem.attributeId === 'widthCD - inch' || checkSubItem.attributeId === 'heightCD - inch' || checkSubItem.attributeId === 'netWeightCD' || checkSubItem.attributeId === 'weightUnitCD - kg' || checkSubItem.attributeId === 'quantityInMasterCarton') {
                                    if (checkSubItem.attributeId === 'lengthCD') {
                                      dimensionLength = checkSubItem.attributeNameValue;
                                    }
                                    if (checkSubItem.attributeId === 'heightCD') {
                                      dimensionHeight = checkSubItem.attributeNameValue;
                                    }
                                    if (checkSubItem.attributeId === 'widthCD') {
                                      dimensionWidth = checkSubItem.attributeNameValue;
                                    }
                                    if (checkSubItem.attributeId === 'lengthCD - inch') {
                                      dimensionLengthInch = checkSubItem.attributeNameValue;
                                    }
                                    if (checkSubItem.attributeId === 'widthCD - inch') {
                                      dimensionWidthInch = checkSubItem.attributeNameValue;
                                    }
                                    if (checkSubItem.attributeId === 'heightCD - inch') {
                                      dimensionHeightInch = checkSubItem.attributeNameValue;
                                    }
                                    if (checkSubItem.attributeId === 'netWeightCD') {
                                      dimensionGm = checkSubItem.attributeNameValue;
                                    }
                                    if (checkSubItem.attributeId === 'weightUnitCD - kg') {
                                      dimensionKg = checkSubItem.attributeNameValue;
                                    }
                                    if (checkSubItem.attributeId === 'quantityInMasterCarton') {
                                      dimensionQty = checkSubItem.attributeNameValue;
                                    }
                                    if (((dimensionWidth==='')&&(dimensionLength === '') && (dimensionHeight === '') && (dimensionLengthInch === '') && (dimensionWidthInch === '') && (dimensionHeightInch === '') && (dimensionQty === '') && (dimensionGm === '') && (dimensionKg === ''))) {
                                      checkItemSub.attributes?.map((data) => {
                                   if (data.attributeId === 'widthCD' || data.attributeId === 'lengthCD' || data.attributeId === 'heightCD' || data.attributeId === 'lengthCD - inch' || data.attributeId === 'widthCD - inch' || data.attributeId === 'heightCD - inch' || data.attributeId === 'netWeightCD' || data.attributeId === 'weightUnitCD - kg' || data.attributeId === 'quantityInMasterCarton') {
                                          data.mandatory = 'N';
                                        }
                                      });
                                    }
                                  }
                                  return checkSubItem;
                                },
                            );
                            return checkelement;
                          });
                        },
                    );
                  }
                }
                element.attributeNameValue = value;
              }
              return element;
            },
        );
        return subelement;
      });
    });
    setCountry1(coreAttributesR);
    setSaveFlag(Math.random() * 100);
  };

  const getDisplayBox = (fieldType, item, index, indexPos, component) => {
    const code = component && component?.code;
    if (fieldType === 'Select') {
      return (
        <div>
          <div className="bigCartonLabel">
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          <select
            aria-label={item.attributeId}
            className={`product-box-product1 `}
            // ${
            //   code && code.length > 0 && 'disabled'
            // }
            type={item.attributeDataType === 'enum'}
            name={item.attributeId}
            onBlur={saveData}
            value={item.attributeNameValue}
            // disabled={component && component?.code}
            ref={register}
            readOnly={item.readOnly === 'Y' ? true : false}
            onChange={(e) => {
              changeValue(item.attributeId, indexPos, e.target.value);
            }}
          >
            <option>Select</option>
            {item &&
              Array.isArray(item.attrNameLov) &&
              item.attrNameLov.map((e, key) => {
                return <option key={e.lovId}>{e.lovName}</option>;
              })}
          </select>

          {item.isCrossedLength && (
            <div className="error-text">{Localization.Only}
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">{Localization.MaxRange} {maximum}</div>
          )}
          {(item.attributeNameValue === ''|| item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showData && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else if (fieldType === 'Textarea') {
      return (
        <div className="comlabel1">
          <div className='bigCartonLabel'>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          <textarea
            aria-label={item.attributeId}
            name={item.attributeId}
            onBlur={saveData}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            value={item.attributeNameValue}
            className={`arearectangle`}
            // ${
            //   code && code.length > 0 && 'disabled'
            // }
            ref={register}
            readOnly={item.readOnly === 'Y' ? true : false}
            // disabled={component && component?.code}
            onChange={(e) => {
              changeValue(item.attributeId, indexPos, e.target.value);
            }}
          />
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">{Localization.MaxRange} {maximum}</div>
          )}
          {(item.attributeNameValue === ''|| item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showData && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else {
      return (
        <div>
          <div className='bigCartonLabel'>
            {item.attributeName}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          <input
            aria-label={item.attributeId}
            name={item.attributeId}
            onBlur={saveData}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            value={item.attributeNameValue}
            onKeyPress= {(evt)=>{
              item.attributeDataType !== 'String' && negative(evt);
            }}
            onKeyDown={ (evt) => (evt.key === 'e' || evt.key === 'E'||evt.key==='+'||evt.key==='-') && evt.preventDefault()&& negative(evt)}
            className={`dm-box `}
            // ${
            //   code && code.length > 0 && 'disabled'
            // }
            ref={register}
            readOnly={item.readOnly === 'Y' ? true : false}
            // disabled={component && component?.code}
            onChange={(e) => {
              changeValue(item.attributeId, indexPos, e.target.value);
            }}
          />
          {item.attributeName === 'NetWt (gms)' ||
          item.attributeName === 'Height (cm)' ? (
            <span>
              <div className="arrow-icon-1">
                <img
                  src={process.env.PUBLIC_URL + 'assets/arrow.png'}
                  alt="arrow"
                  className="arrow-image"
                />
              </div>
            </span>
          ) : (
            ''
          )}

          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">{Localization.MaxRange} {maximum}</div>
          )}
          {(item.attributeNameValue === ''|| item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showData && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    }
  };
  return (
    <div>
      {country1 &&
        Array.isArray(country1) &&
        country1.length > 0 &&
        country1.map((item, indexes) => {
          return (
            <div className="packColor" key={indexes}>
              <div className="pack-component-dimension">{Localization.Component}{indexes+1} - {productArray[indexes][0].attributes[0].attributeNameValue}</div>
              <div>
                {item?.map((items, index) => {
                  return (
                    <div className="pack-rectangle" key={index}>
                      <div className="row item-information1">
                        {items.subGroupIdentifierName}
                      </div>
                      <div className="row dimention-box1">
                        {items.attributes.map((subItem, subIndex) => {
                          return (
                            <div className="item-box1 col-sm-2" key={subIndex}>
                              {getDisplayBox(
                                  subItem.fieldType,
                                  subItem,
                                  subIndex,
                                  indexes,
                                  props?.packData[indexes],
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PackDimension;
