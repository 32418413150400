import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {packAttributeApi} from '../../services/packServices';


export function* packWatcherAsyn(action) {
  try {
    const response = yield call(packAttributeApi, action.payload);
    if (response.status >= 200 && response.status < 300) {
      const packData = yield response.data;
      yield put({type: actionTypes.PACK_ATTRIBUTE_COMPLETE, payload: packData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.PACK_ATTRIBUTE_ERROR, payload: error});
  }
}
export function* packWatcher() {
  yield takeEvery(actionTypes.PACK_ATTRIBUTE, packWatcherAsyn);
}


export const packSaga=[

  packWatcher(),

];
