import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import './Item.css';
import Localization from '../../common/Localization';
import 'react-dropdown/style.css';
import {loadClassificationItemAttribute} from '../../store/actions/itemAction';
import {loadSave} from '../../store/actions/saveAction';
import {loadFetchData} from '../../store/actions/fetchAction';
import {useForm} from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import {useHistory} from 'react-router-dom';
import {loadPackSave} from '../../store/actions/savePackAction';
import Select from 'react-select';
import Toaster from '../Shared/Toaster';
import ItemReturnData from './ItemReturnData';
import {cameraSingleFn, saveCameraFn} from './cameraFunction';
import {
  audioCategory,
  simDetail,
  primarySimDetail,
  laptopCategory,
  vaccumCategory,
  airPurifierCategory,
  smartHomeIdeal,
  desktopResolution,
  desktopRatio,
  desktopWifiSupported,
  hdmiType,
  telivision3DType,
  telivisionOSType,
  telivisionStorageCapacity,
  desktopEthernet,
  televisionInRadius,
  airPurifierWaterBucket,
  suitableType,
  washerAndDryer,
} from './conditionalItem';
import {preventNonNumericalInput} from '../Shared/nonNumericalInputFunction';
import {
  preventNonNumericalInputDefault,
  saveData,
  saveDataToast,
  elementRange,
  elementRegex,
  onClickContinue,
} from './itemFunction';
import {classAttributeAdd, classAttributeDelete} from './addDeleteFunction';
import {sanitize} from '../utils/utils';
import {PAGE_RENDER_TIMEOUT_IN_MILLISECONDS} from '../utils/Constants';
export const Item = (props) => {
  const {register} = useForm();
  const history = useHistory();
  const [, setData] = useState({});
  const [, setData2] = useState([]);
  const [prod, setProd] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [, setSendSaveData] = useState();
  const [contDisable, setContDisable] = useState(false);
  const [confirmItem, setConfirmItem] = useState();
  const [fetchPack, setFetchPack] = useState();
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
    .token;
  const userId = localStorage.getItem('userId');
  const code = props?.fetchApiData?.l2Category;
  // const brandCode = props?.fetchApiData?.brandCode;
  const brandName = props?.fetchApiData?.brandName;
  const [callapi] = useState(true);
  const toasterRef = useRef(null);
  const [varient, setVarient] = useState('');
  const [msg, setMsg] = useState('');
  const [check, setCheck] = useState(false);
  const [grossValue, setGrossValue] = useState(0);
  const [fridgeValue, setFridgeValue] = useState(0);
  const [freezerValue, setFreezerValue] = useState(0);
  const [maxProductPriceRange, setMaxProductPriceRange] = useState(0);
  const [minProductPriceRange, setMinProductPriceRange] = useState(0);
  const [checkClickcontinue, setcheckClickcontinue] = useState(false);
  const [isPreviewPage, setisPreviewPage] = useState(false);
  // const [modelSeries, setModelSeries] = useState(null);

  useEffect(() => {
    if (token && callapi && code) {
      const query = token;
      props.classificationItemAttributeApi({query, code});
    }
  }, [token, callapi, code]);

  useEffect(() => {
    if (localStorage.getItem('url4') !== '5-Item Details') {
      history.push('/4-Dimensions');
    }
  }, [history]);
  useEffect(() => {
    if (props?.errorApiCheck === true) {
      history.push('/ApiErrorPage');
    }
  }, [props?.errorApiCheck]);
  useEffect(() => {
    if (
      props?.fetchApiData &&
      props?.fetchApiData?.sections?.[
        props?.fetchApiData.sections?.findIndex(
          (p) => p.section === '5-Item Details',
        )
      ]?.section === '5-Item Details'
    ) {
      const coreAttributesR = props?.fetchApiData.sections[
        props?.fetchApiData.sections?.findIndex(
          (p) => p.section === '5-Item Details',
        )
      ]?.subGroups?.map((subItem, subIndex) => {
        const subelement = subItem;

        subelement.attributes = subItem.attributes.map(
          (secSubItem, secSubIndex) => {
            const element = secSubItem;
            if (element.attrNameLov !== null) {
              element.attrNameLov.map((lovItem) => {
                lovItem['value'] = lovItem.lovName;
                lovItem['label'] = lovItem.lovName;
                return null;
              });

              subelement.attributes.map((secondSubelement, secondSubIndex) => {
                if (secondSubelement.attrNameLov) {
                  secondSubelement.attrNameLov.map((lovItem, lovIndex) => {
                    if (element.attributeNameValue === lovItem.lovName) {
                      lovItem.isDisabled = true;
                    } else {
                      lovItem.isDisabled = false;
                    }
                    return lovItem;
                  });
                }
                return secondSubelement;
              });
            }
            if (
              subelement.subGroupIdentifierName === 'Camera' &&
              element.fieldType === 'Select'
            ) {
              saveCameraFn({subItem, element});
            }
            if (
              element.attributeId ===
              'SG-RefrigeratorCategory-GrossRefrigeratorCapacity'
            ) {
              setGrossValue(element.attributeNameValue);
            }
            if (
              element.attributeId ===
              'SG-RefrigeratorPhysicalAttributes-FridgeCapacity'
            ) {
              setFridgeValue(element.attributeNameValue);
            }
            if (
              element.attributeId ===
              'SG-RefrigeratorPhysicalAttributes-FreezerCapacity'
            ) {
              setFreezerValue(element.attributeNameValue);
            }
            if (element.attributeName === 'Installation & Demo applicable') {
              if (element.attributeNameValue === 'No') {
                subelement.attributes[secSubIndex + 1].disable = true;
                subelement.attributes[secSubIndex + 1].mandatory = 'N';
              } else {
                subelement.attributes[secSubIndex + 1].disable = false;
                subelement.attributes[secSubIndex + 1].mandatory = 'Y';
              }
            }
            return element;
          },
        );
        return subelement;
      });
      setProd(coreAttributesR);
      props?.fetchApiData?.components &&
        setFetchPack(props?.fetchApiData?.components);
    } else {
      const classAttributeR = props?.primaryData?.subGroups.map(
        (subItem, subIndex) => {
          const subelement = subItem;
          subelement.attributes = subItem.attributes.map(
            (secSubItem, secSubIndex) => {
              const element = secSubItem;
              element.isInvalid = false;
              element.isCrossedLength = false;
              if (secSubItem.attributeNameValue === null) {
                element.attributeNameValue = '';
              } else {
                element.attributeNameValue = secSubItem.attributeNameValue;
              }
              if (element.attrNameLov !== null) {
                element.attrNameLov.map((lovItem, lovIndex) => {
                  lovItem['value'] = lovItem.lovName;
                  lovItem['label'] = lovItem.lovName;
                  return null;
                });
              }
              if (subItem.subGroupIdentifierName === 'Camera') {
                element.readOnly = true;
                if (
                  element.attributeName.includes('Rear Camera Configuration') ||
                  element.attributeName.includes('Front Camera Configuration')
                ) {
                  element.disable = false;
                  element.readOnly = false;
                } else {
                  element.disable = false;
                }
              }
              if (subItem.subGroupIdentifierName === 'After Sales & Services') {
                element.disable = false;
              }

              return element;
            },
          );
          return subelement;
        },
      );
      props?.fetchApiData?.components &&
        setFetchPack(props?.fetchApiData?.components);
      setProd(classAttributeR);
    }
  }, [props?.fetchApiData, props?.primaryData]);
  const roundToTwo = (num) => {
    return +(Math.round(num + 'e+2') + 'e-2');
  };
  const showToastMsg = (msgs, varients) => {
    setVarient(varients);
    setMsg(msgs);
    toasterRef.current.openToast();
  };
  const CustomStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isDisabled ?
          '#49A7A2' :
          isSelected ?
          '#49A7A2' :
          isFocused ?
          '#E0E3E4' :
          null,
        color: isDisabled ? '#ffffff' : isSelected ? '#ffffff' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };
  const negative = (evt) => {
    preventNonNumericalInput(evt);
  };
  const negativeAllow = (evt) => {
    preventNonNumericalInputDefault(evt);
  };
  const saving = () => {
    saveData({
      props,
      userId,
      token,
      prod,
      checkClickcontinue,
      fridgeValue,
      freezerValue,
      grossValue,
    });
  };
  const getCheckVal = (checks) => {
    setCheck(checks);
  };
  const savingToast = () => {
    saveDataToast({
      props,
      userId,
      token,
      prod,
      fridgeValue,
      freezerValue,
      grossValue,
      getCheckVal,
      getConfirmItem,
    });
  };
  useEffect(() => {
    if (props?.isSuccess !== undefined) {
      if (check && props?.isSuccess) {
        showToastMsg(Localization.SuccessfulSaveToast, 'success');
        setCheck(false);
      }
      if (props?.isSuccess === false && props?.isSuccessBlank === false) {
        showToastMsg(Localization.UnsuccessfulSaveToast, 'error');
        setContDisable(true);
      }
    }
  }, [check, props?.isSuccess]);
  const changeData = (selectedItem, value, index) => {
    if (
      selectedItem.attributeId ===
      'SG-RefrigeratorCategory-GrossRefrigeratorCapacity'
    ) {
      setGrossValue(value);
    }
    if (
      selectedItem.attributeId ===
      'SG-RefrigeratorPhysicalAttributes-FridgeCapacity'
    ) {
      setFridgeValue(value);
    }
    if (
      selectedItem.attributeId ===
      'SG-RefrigeratorPhysicalAttributes-FreezerCapacity'
    ) {
      setFreezerValue(value);
    }
    if (selectedItem.attributeId === 'SG-I&WCategory-ProductPriceRange-Min') {
      setMinProductPriceRange(value);
    }
    if (selectedItem.attributeId === 'SG-I&WCategory-ProductPriceRange-Max') {
      setMaxProductPriceRange(value);
    }
    // if (selectedItem.attributeId === 'SG-ManufacturerDetails-ModelSeries') {
    //   setModelSeries(value);
    // }
    const classAttributeRes = prod.map((subItem, subIndex) => {
      const subelement = subItem;
      subelement.attributes = subItem.attributes.map(
        (secSubItem, secSubIndex) => {
          const element = secSubItem;
          if (
            (element.isCrossedLength === true &&
              value.length <= Number(element.length)) ||
            element.isInvalid === true ||
            element.isCrossedRange === true
          ) {
            element.isCrossedLength = false;
            element.isInvalid = false;
            element.isCrossedRange = false;
          }
          element.isDuplicate = false;
          if (element.sequence) {
            if (
              selectedItem.sequence === element.sequence &&
              selectedItem.attributeId === element.attributeId
            ) {
              // to disable select box option item with section
              if (element.attrNameLov !== null) {
                element.attrNameLov.map((lovItem, lovIndex) => {
                  if (element.attributeNameValue === lovItem.lovName) {
                    lovItem.isDisabled = false;
                  }
                  if (value === lovItem.lovName) {
                    lovItem.isDisabled = true;
                  }

                  return lovItem;
                });
              }
              if (element.range != null) {
                elementRange({element, value});
                return element;
              }
              if (element.validRegex != null) {
                elementRegex({element, value, subelement});
                return element;
              } else if (
                element.length !== '' &&
                value.length > Number(element.length)
              ) {
                element.isCrossedLength = true;
                return element;
              }
              element.attributeNameValue = value;
            }
          } else if (selectedItem.attributeId === element.attributeId) {
            // to disable select box option item without section
            if (
              (element.isCrossedLength === true &&
                value.length <= Number(element.length)) ||
              element.isInvalid === true ||
              element.isCrossedRange === true
            ) {
              element.isCrossedLength = false;
              element.isInvalid = false;
              element.isCrossedRange = false;
            }
            if (element.range != null) {
              elementRange({element, value});
            }
            if (
              element.attributeId === 'SG-InTheBox-OthersDetails' &&
              value !== ''
            ) {
              subelement.attributes[secSubIndex + 1].mandatory = 'Y';
            } else if (
              element.attributeId === 'SG-InTheBox-OthersDetails' &&
              value === ''
            ) {
              subelement.attributes[secSubIndex + 1].mandatory = 'N';
            }
            if (
              subelement.subGroupIdentifierName === 'After Sales & Services' &&
              selectedItem.fieldType === 'Radio'
            ) {
              if (value === 'No') {
                subelement.attributes[secSubIndex + 1].disable = true;
                subelement.attributes[secSubIndex + 1].attributeNameValue = '';
                subelement.attributes[secSubIndex + 1].mandatory = 'N';
              } else {
                subelement.attributes[secSubIndex + 1].disable = false;
                subelement.attributes[secSubIndex + 1].mandatory = 'Y';
              }
            }
            if (
              element.attributeId === 'SG-AirPurifierCategory-AirPurifierType'
            ) {
              airPurifierWaterBucket({value, prod});
            }
            if (
              element.attributeId === 'SG-TelevisionCategory-TelevisionType'
            ) {
              televisionInRadius({value, prod});
            }
            if (element.attributeId === 'SG-DesktopCategory-DesktopType') {
              desktopEthernet({value, prod});
            }
            // if (element.attributeId === 'SG-TelevisionCategory-TelevisionFeatures') {
            //   telivisionStorageCapacity({value, prod});
            // }
            // if (element.attributeId === 'SG-TelevisionCategory-TelevisionFeatures') {
            //   telivisionOSType({value, prod});
            // }
            if (
              element.attributeId === 'SG-TelevisionCategory-TelevisionFeatures'
            ) {
              telivision3DType({value, prod});
            }
            if (element.attributeId === 'SG-Port&ConnectorDetails-HDMIType') {
              hdmiType({value, prod});
            }
            if (element.attributeId === 'SG-DesktopCategory-DesktopType') {
              desktopWifiSupported({value, prod});
            }
            if (element.attributeId === 'SG-DesktopCategory-DesktopType') {
              desktopRatio({value, prod});
            }
            if (element.attributeId === 'SG-DesktopCategory-DesktopType') {
              desktopResolution({value, prod});
            }
            if (
              element.attributeId === 'SG-AirPurifierCategory-AirPurifierType'
            ) {
              airPurifierCategory({value, prod});
            }
            if (element.attributeId === 'SG-SHDCategory-ProductType') {
              suitableType({value, prod});
            }
            if (element.attributeId === 'SG-SHDCategory-ProductType') {
              smartHomeIdeal({value, prod});
            }
            if (element.attributeId === 'SG-SHDCategory-ProductType') {
              vaccumCategory({value, prod});
            }
            if (element.attributeId === 'SG-MachineCategory-MachineType') {
              washerAndDryer({value, prod});
            }
            // if (element.attributeId === 'SG-I&WCategory-ProductPriceRange-Max') {
            //   if (value < minProductPriceRange) {
            //     element.mandatory = 'Y';
            //     element.isInvalid = true;
            //     element.isCrossedRange = true;
            //   } else {
            //     element.isInvalid = false;
            //     element.isCrossedRange = false;
            //   }
            // }
            // if (element.attributeId === 'SG-I&WCategory-ProductPriceRange-Min') {
            //   if (value > maxProductPriceRange) {
            //     element.mandatory = 'Y';
            //     element.isInvalid = true;
            //     element.isCrossedRange = true;
            //   } else {
            //     element.isInvalid = false;
            //     element.isCrossedRange = false;
            //   }
            // }

            if (
              element.uom !== null &&
              element.uom === 'UoM-cm' &&
              (element.attributeName === 'Screen Size in CM (cm)' ||
                element.attributeName === 'Size (In Cms)')
            ) {
              if (value !== '') {
                if (element.isCrossedRange === false) {
                  subItem.attributes[secSubIndex + 1].attributeNameValue =
                    '' + roundToTwo(Number(value) / 2.54);
                }
              } else {
                subItem.attributes[secSubIndex + 1].attributeNameValue = '';
              }
            }
            if (
              element.uom !== null &&
              element.uom === 'UoM-Inches' &&
              (element.attributeName === 'Screen Size in Inches (Inches)' ||
                element.attributeName === 'Size (In Inches)')
            ) {
              if (value !== '') {
                if (element.isCrossedRange === false) {
                  subItem.attributes[secSubIndex - 1].attributeNameValue =
                    '' + roundToTwo(Number(value) / 0.393701);
                }
              } else {
                subItem.attributes[secSubIndex - 1].attributeNameValue = '';
              }
            }
            if (
              element.uom !== null &&
              (element.uom === 'UoM-Sq. Ft.' || element.uom === 'UoM-Sq.ft') &&
              (element.attributeName === 'Coverage Area (Sq.Ft)' ||
                element.attributeName === 'Coverage Area (Sq. Ft.)' ||
                element.attributeName === 'Approximate Coverage Area(Sq.Ft)' ||
                element.attributeName ===
                  'Approximate Coverage Area In Sq.ft (Sq.ft)' ||
                element.attributeName ===
                  'Approximate Coverage Area In Sq.ft (Sq. Ft.)')
            ) {
              if (value !== '') {
                if (element.isCrossedRange === false) {
                  subItem.attributes[secSubIndex + 1].attributeNameValue =
                    '' + roundToTwo(Number(value) / 10.764);
                }
              } else {
                subItem.attributes[secSubIndex + 1].attributeNameValue = '';
              }
            }
            if (
              element.uom !== null &&
              element.uom === 'UoM-Sq. M.' &&
              (element.attributeName === 'Coverage Area (Sq.M)' ||
                element.attributeName === 'Coverage Area (Sq. M.)' ||
                element.attributeName === 'Approximate Coverage Area(Sq.M)' ||
                element.attributeName ===
                  'Approximate Coverage Area In Sq.Mtr (Sq. M.)')
            ) {
              if (value !== '') {
                if (element.isCrossedRange === false) {
                  subItem.attributes[secSubIndex - 1].attributeNameValue =
                    '' + roundToTwo(Number(value) * 10.764);
                }
              } else {
                subItem.attributes[secSubIndex - 1].attributeNameValue = '';
              }
            }
            if (
              element.uom !== null &&
              element.uom === 'UoM-Ft' &&
              element.attributeName ===
                'Approximate Air Throw Distance In Ft (Ft)'
            ) {
              if (value !== '') {
                if (element.isCrossedRange === false) {
                  subItem.attributes[secSubIndex + 1].attributeNameValue =
                    '' + roundToTwo(Number(value) / 3.281);
                }
              } else {
                subItem.attributes[secSubIndex + 1].attributeNameValue = '';
              }
            }
            if (
              element.uom !== null &&
              element.uom === 'UoM-Meters' &&
              element.attributeName ===
                'Approximate Air Throw Distance In Mtr (Meters)'
            ) {
              if (value !== '') {
                if (element.isCrossedRange === false) {
                  subItem.attributes[secSubIndex - 1].attributeNameValue =
                    '' + roundToTwo(Number(value) * 3.281);
                }
              } else {
                subItem.attributes[secSubIndex - 1].attributeNameValue = '';
              }
            }
            if (element.attrNameLov != null) {
              element.attrNameLov.map((lovItem, lovIndex) => {
                if (value === lovItem.lovName) {
                  lovItem.isDisabled = true;
                } else {
                  lovItem.isDisabled = false;
                }
                return lovItem;
              });
            }
            if (subelement.subGroupIdentifierName === 'Camera') {
              cameraSingleFn({subItem, value});
            }

            // if (element.attributeId === 'SG-LaptopCategory-Lifestyle') {
            //   laptopCategory({value, prod});
            // }
            if (element.attributeId === 'SG-SIMDetails-NumberofSIMsSupported') {
              primarySimDetail({value, prod});
            }
            if (element.attributeId === 'SG-SIMDetails-NumberofSIMsSupported') {
              simDetail({value, prod});
            }
            if (subelement.subGroupIdentifierName === 'Audio Category') {
              subItem.attributes.map((type) => {
                audioCategory({type, value, prod});
              });
            }
            if (element.range != null) {
              elementRange({element, value});
              return element;
            }
            if (element.validRegex != null) {
              elementRegex({element, value, subelement});
              return element;
            } else if (
              element.length !== '' &&
              value.length > Number(element.length)
            ) {
              element.isCrossedLength = true;
              return element;
            }
            element.attributeNameValue = value;
          }
          if (
            selectedItem.attributeId === 'SG-ManufacturerDetails-ModelSeries' &&
            element.attributeId === 'SG-ManufacturerDetails-DBV' &&
            (!element.attributeNameValue ||
              element.attributeNameValue.length > 0)
          ) {
            console.log(brandName);
            element.attributeNameValue = selectedItem.attributeNameValue +'_'+ brandName;
          } else if (selectedItem.attributeId === 'SG-ManufacturerDetails-ModelSeries' &&
          element.attributeId === 'SG-ManufacturerDetails-DBV') {
            element.attributeNameValue = selectedItem.attributeNameValue +'_'+ brandName;
          }
          return element;
        },
      );
      return subelement;
    });
    setProd(classAttributeRes);
    setData2(value);
    setSendSaveData({
      section: '5-Item Details',
      subGroups: classAttributeRes,
    });
  };

  /**
   *Validates whether given price value lies within the user provided price range for fields : Product Price Range
   * @param {Number} value
   * @returns {Boolean}
   */
  const isValidProductPrice = async (value, item) => {
    console.log(maxProductPriceRange);
    console.log(minProductPriceRange);
    return (
      value <= Number(maxProductPriceRange) &&
      value >= Number(minProductPriceRange)
    );
  };

  const validateProductRange = ({element, value}) => {};
  const getShowDetails = (show) => {
    setShowDetails(show);
  };
  const getConfirmItem = (confirm) => {
    setConfirmItem(confirm);
  };
  const getCheck = (checks) => {
    setCheck(checks);
  };
  const proceed = () => {
    setTimeout(() => {
      // Check if all data is saved
      history.push('/6-Additional Details');
      localStorage.setItem('url5', '6-Additional Details');
    }, PAGE_RENDER_TIMEOUT_IN_MILLISECONDS);
  };
  const getClassAttr = (attribute) => {
    setProd(attribute);
  };
  const onClickContinueItem = () => {
    onClickContinue({
      props,
      userId,
      token,
      prod,
      fridgeValue,
      freezerValue,
      grossValue,
      fetchPack,
      minProductPriceRange,
      maxProductPriceRange,
      getShowDetails,
      getConfirmItem,
      getCheck,
      proceed,
      getClassAttr,
    });
  };
  const getProdVal = (prods) => {
    setProd(prods);
  };
  const addData = (item, index, subGroupIdentifierName) => {
    classAttributeAdd({
      item,
      index,
      subGroupIdentifierName,
      prod,
      getProdVal,
    });
  };
  const getProdDelVal = (prods) => {
    setProd(prods);
  };
  const deleteData = (item, index, subGroupIdentifierName, selectedItem) => {
    classAttributeDelete({
      item,
      index,
      subGroupIdentifierName,
      selectedItem,
      prod,
      getProdDelVal,
    });
  };
  const getDisplayBox = (fieldType, item, index, subGroupIdentifierName) => {
    if (item.attributeId === 'SG-I&WCategory-ProductPriceRange-Min') {
      setMinProductPriceRange(item.attributeNameValue);
    }
    if (item.attributeId === 'SG-I&WCategory-ProductPriceRange-Max') {
      setMaxProductPriceRange(item.attributeNameValue);
    }

    if (item.fieldType === 'Select') {
      return (
        <div>
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">*</span>
            ) : (
              <span></span>
            )}
          </div>
          {item.multiValued === 'Y' ? (
            <div className="in-the-box">
              <Select
                className="select-box inthebox"
                options={item.attrNameLov}
                maxMenuHeight={200}
                onBlur={saving}
                styles={CustomStyles}
                aria-label={item.attributeId}
                value={{
                  value: item.attributeNameValue,
                  label: item.attributeNameValue,
                }}
                onChange={(e) => {
                  changeData(item, e.lovName);
                }}
              />

              {subGroupIdentifierName !== 'In The Box' && (
                <div
                  className="AddData"
                  onClick={() => addData(item, index, subGroupIdentifierName)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                    alt="plus"
                  />
                </div>
              )}
              {subGroupIdentifierName === 'In The Box' &&
                item.attributeId === 'SG-InTheBox-MainProduct' && (
                  <div
                    className="AddData"
                    onClick={() => addData(item, index, subGroupIdentifierName)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                      alt="plus"
                    />
                  </div>
                )}
            </div>
          ) : (
            <div>
              <Select
                className="select-box"
                options={item.attrNameLov}
                maxMenuHeight={200}
                styles={CustomStyles}
                isDisabled={item.disable}
                aria-label={item.attributeId}
                onBlur={saving}
                value={{
                  value: item.attributeNameValue,
                  label: item.attributeNameValue,
                }}
                onChange={(e) => {
                  changeData(item, e.lovName);
                }}
              />
              {subGroupIdentifierName !== 'In The Box' &&
              subGroupIdentifierName !== 'Camera' &&
              item.sequence &&
              item.sequence !== 0 ? (
                <div className="delete-item">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                    alt="delete"
                    onClick={() =>
                      deleteData(item, index, subGroupIdentifierName)
                    }
                  />
                </div>
              ) : (
                <span></span>
              )}
              {subGroupIdentifierName === 'In The Box' &&
              item.attributeId === 'SG-InTheBox-MainProduct' ? (
                <div className="delete-item">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                    alt="delete"
                    onClick={() =>
                      deleteData(item, index, subGroupIdentifierName)
                    }
                  />
                </div>
              ) : (
                <span></span>
              )}
              {subGroupIdentifierName === 'Camera' &&
              item.attributeName.startsWith('Camera Configuration') &&
              item.sequence &&
              item.sequence !== 0 ? (
                <div className="deleteData">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                    alt="delete"
                    onClick={() =>
                      deleteData(item, index, subGroupIdentifierName)
                    }
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          )}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">
              {Localization.Range} {item.range}
            </div>
          )}
          {item.isDuplicate && (
            <div className="error-text">{Localization.InputMismatch}</div>
          )}
          {item.isInvalid && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {(item.attributeNameValue === '' ||
            item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            showDetails && <div className="errormsg">{Localization.Field}</div>}
          {item.attributeId === 'SG-I&WCategory-ProductPriceRange-Max' ||
            (item.attributeId === 'SG-I&WCategory-ProductPriceRange-Min' &&
              maxProductPriceRange < minProductPriceRange && (
                <div className="errormsg">
                  {Localization.ProductPriceRangeError}
                </div>
              ))}
        </div>
      );
    } else if (item.fieldType === 'Textarea') {
      return (
        <div className="comlabel1">
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">*</span>
            ) : (
              <span></span>
            )}
          </div>
          <textarea
            aria-label={item.attributeId}
            name={item.attributeId}
            onBlur={saving}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            value={item.attributeNameValue}
            className={
              `${item.readOnly === 'Y' && 'input-disabled'} ${item.attributeName === 'Model Series' ||
              item.fieldType === 'Textarea' ?
                'rectangle3' :
                'companyArea'}`
            }
            ref={register}
            readOnly={item.readOnly === 'Y' ? true : false}
            onChange={(e) => {
              changeData(item, sanitize(e.target.value));
            }}
          />{' '}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">
              {Localization.Range} {item.range}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {item.isDuplicate && (
            <div className="error-text">{Localization.InputMismatch}</div>
          )}
          {(item.attributeNameValue === '' ||
            item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            showDetails && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else if (item.fieldType === 'Radio') {
      return (
        <div className="comlabel1">
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">*</span>
            ) : (
              <span></span>
            )}
          </div>
          {item.attrNameLov.map((radioItem, radioIndex) => {
            return (
              <span key={radioIndex}>
                <input
                  aria-label={item.attributeId}
                  type="radio"
                  value={radioItem.lovName}
                  onBlur={saving}
                  checked={item.attributeNameValue === radioItem.lovName}
                  onKeyPress={(evt) => {
                    item.attributeDataType !== 'String' &&
                      preventNonNumericalInput(evt);
                  }}
                  onChange={(e) => {
                    changeData(item, sanitize(e.target.value));
                  }}
                />
                <span className="radiolabel">{radioItem.lovName}</span>
              </span>
            );
          })}
          <ReactTooltip id={'Vendor Product name'} place="top" effect="solid">
            {Localization.DemoAndInstallation}
          </ReactTooltip>

          {item.attributeName === 'Installation & Demo applicables' ||
          item.attributeName === '' ? (
            <span>
              <div className="info-icon-item">
                <img
                  data-tip
                  data-for={
                    item.attributeName === 'Installation & Demo applicables' ?
                      'Vendor Product name' :
                      'Vendor Article code'
                  }
                  src={`${process.env.PUBLIC_URL}/assets/info.png`}
                  alt="info"
                />
              </div>
            </span>
          ) : (
            ''
          )}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">
              {Localization.Range} {item.range}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {(item.attributeNameValue === '' ||
            item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            showDetails && <div className="errormsg">{Localization.Field}</div>}
        </div>
      );
    } else {
      return (
        <div className="">
          <div>
            {item.attributeName}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">*</span>
            ) : (
              <span></span>
            )}
          </div>
          {item.multiValued === 'Y' ? (
            <div className="in-the-box">
              {' '}
              <input
                aria-label={item.attributeId}
                name={item.attributeId}
                onBlur={saving}
                type={item.attributeDataType === 'String' ? 'text' : 'number'}
                value={item.attributeNameValue}
                className={
                  `rectangle3 ${item.readOnly === 'Y' && 'input-disabled'}`
                  // item.attributeName === 'Qty' ? 'qty-item-box' : 'rectangle3'
                }
                onKeyPress={(evt) => {
                  if (
                    item.noDataProvidedValue === '-1' &&
                    item.attributeDataType !== 'String'
                  ) {
                    negativeAllow(evt);
                  } else {
                    if (item.attributeDataType !== 'String') {
                      negative(evt);
                    }
                  }
                }}
                onKeyDown={(evt) => {
                  if (item.attributeDataType !== 'String') {
                    (evt.key === 'e' ||
                      evt.key === 'E' ||
                      evt.key === '+' ||
                      evt.key === '-') &&
                      evt.preventDefault();
                  }
                }}
                ref={register}
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  changeData(item, sanitize(e.target.value));
                }}
              />
              {item.attributeId !== 'SG-InTheBox-OthersDetails' && (
                <div
                  className={
                    'plus-item'
                    // item.attributeName === 'Qty' ? 'plus-item-Qty' : 'plus-item'
                  }
                >
                  <img
                    className="plus-img"
                    src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                    alt="plus"
                    onClick={() => addData(item, index, subGroupIdentifierName)}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              {item.attributeName === '' ? (
                <div className="space"></div>
              ) : (
                <div></div>
              )}
              <input
                aria-label={item.attributeId}
                name={item.attributeId}
                type={item.attributeDataType === 'String' ? 'text' : 'number'}
                value={item.attributeNameValue}
                step={
                  item.attributeDataType === 'Float/Number' ||
                  item.attributeDataType === 'number' ?
                    '0.01' :
                    ''
                }
                onKeyPress={(evt) => {
                  if (
                    item.noDataProvidedValue === '-1' &&
                    item.attributeDataType !== 'String'
                  ) {
                    negativeAllow(evt);
                  } else {
                    if (item.attributeDataType !== 'String') {
                      negative(evt);
                    }
                  }
                }}
                onKeyDown={(evt) => {
                  if (
                    item.noDataProvidedValue === '-1' &&
                    item.attributeDataType !== 'String'
                  ) {
                    (evt.key === 'e' || evt.key === 'E' || evt.key === '+') &&
                      evt.preventDefault();
                  } else {
                    if (item.attributeDataType !== 'String') {
                      (evt.key === 'e' ||
                        evt.key === 'E' ||
                        evt.key === '+' ||
                        evt.key === '-') &&
                        evt.preventDefault();
                    }
                  }
                }}
                onBlur={saving}
                className={
                  `${item.readOnly === 'Y' && 'input-disabled'} ${item.readOnly ?
                    subGroupIdentifierName === 'Camera' ?
                      'cam-box' :
                      'hiddenBox' :
                    subGroupIdentifierName === 'In The Box' &&
                      item.attributeId !== 'SG-InTheBox-OthersDetails' ?
                    'rectangle3' :
                    'rectangle3'}`
                  // 'qty-item-box' :
                  // 'rectangle3'
                }
                ref={register}
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  changeData(item, sanitize(e.target.value));
                }}
              />

              {item.sequence &&
              item.sequence !== 0 &&
              item.attributeId !== 'SG-InTheBox-OthersDetails' &&
              subGroupIdentifierName !== 'Camera' ? (
                <div
                  className={
                    subGroupIdentifierName === 'In The Box' ?
                      'delete-item' :
                      'delete-item'
                  }
                >
                  <img
                    className={
                      item.attributeId === 'SG-InTheBox-MainProduct-Quantity' ?
                        'delete-img' :
                        'delete-img1'
                    }
                    src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                    alt="delete"
                    onClick={() =>
                      deleteData(item, index, subGroupIdentifierName)
                    }
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          )}
          {item.attributeName === 'Screen Size(in CM)' ||
          item.attributeName === '' ? (
            <span>
              <div className="arrow-icon-item">
                <img
                  src={process.env.PUBLIC_URL + 'assets/arrow.png'}
                  alt="arrow"
                />
              </div>
            </span>
          ) : (
            ''
          )}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg">
              {Localization.Range} {item.range}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {Number(fridgeValue) + Number(freezerValue) > Number(grossValue) &&
          (item.attributeId ===
            'SG-RefrigeratorCategory-GrossRefrigeratorCapacity' ||
            item.attributeId ===
              'SG-RefrigeratorPhysicalAttributes-FridgeCapacity' ||
            item.attributeId ===
              'SG-RefrigeratorPhysicalAttributes-FreezerCapacity') ? (
            <div className="errormsg">{Localization.GrossError}</div>
          ) : (
            ''
          )}
          {item.isDuplicate && (
            <div className="error-text">{Localization.InputMismatch}</div>
          )}
          {(item.attributeNameValue === '' ||
            item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            showDetails && <div className="errormsg">{Localization.Field}</div>}
          {(item.attributeId === 'SG-I&WCategory-ProductPriceRange-Max' ||
            item.attributeId === 'SG-I&WCategory-ProductPriceRange-Min') &&
            Number(maxProductPriceRange) < Number(minProductPriceRange) && (
              <div className="errormsg">
                {Localization.ProductPriceRangeError}
              </div>
            )}
        </div>
      );
    }
  };
  useEffect(() => {
    if (props?.primaryData) {
      setData(props.primaryData);
    }
  }, [props.primaryData]);
  const checkClickcontinueVal = (checkClickContinue) => {
    setcheckClickcontinue(checkClickContinue);
  };
  return (
    <div className="rectangle">
      <ItemReturnData
        props={props}
        prod={prod}
        getDisplayBox={getDisplayBox}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        saveDataToast={savingToast}
        fetchPack={fetchPack}
        checkClickcontinue={checkClickcontinue}
        checkClickcontinueVal={checkClickcontinueVal}
        confirmItem={confirmItem}
        contDisable={contDisable}
        onClickContinueItem={onClickContinueItem}
      />
      <div className="all-rights-reserved-product1">
        {Localization.Disclaimer}
      </div>
      <div className="all-rights-reserved-product">
        {Localization.Allrights}
      </div>
      <Toaster ref={toasterRef} varient={varient} msg={msg} />
    </div>
  );
};

export const mapStateToProps = (state) => ({
  primaryData: state.itemReducer.primaryData,
  saveApiData: state.saveReducer.saveApiData,
  fetchApiData: state.fetchReducer.fetchApiData,
  savePackApiData: state.savePackReducer.savePackApiData,
  isSuccess: state.saveReducer.isSuccess,
  isSuccessBlank: state.saveReducer.isSuccessBlank,
  saveLoader: state.saveReducer.loading,
  packLoader: state.savePackReducer.loading,
  errorApiCheck: state.itemReducer.errorApiCheck,
});

export const mapDispatchToProps = (dispatch) => ({
  classificationItemAttributeApi: ({query, code}) =>
    dispatch(loadClassificationItemAttribute({query, code})),
  saveApi: ({userId, token, sendsaveData}) =>
    dispatch(loadSave({userId, token, sendsaveData})),
  fetchService: ({userId, token, isPreviewPage}) =>
    dispatch(loadFetchData({userId, token, isPreviewPage})),
  savePackApi: ({userId, token, sendPackData}) =>
    dispatch(loadPackSave({userId, token, sendPackData})),
});
export default connect(mapStateToProps, mapDispatchToProps)(Item);
