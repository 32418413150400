import {HttpService} from './HttpService';
import {CLASSIFIFCATION} from '../config/apiEndpoints';

export async function classificationAttributeAdditionalApi({query, code}) {
  try {
    const endpoint = CLASSIFIFCATION+code+'&section=6-Additional Details';
    const headers = {
      'Authorization': `Bearer ${query}`,
    };
    return (await HttpService.get(endpoint, {headers: headers}));
  } catch (err) {
    return {err};
  }
}
