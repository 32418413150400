import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import './Login.css';
import OtpInput from 'react-otp-input';
import Localization from '../../common/Localization';
import Layout from '../Shared/Layout/Layout';
import {useHistory} from 'react-router-dom';
import {loadFetchData} from '../../store/actions/fetchAction';
import {loadSendOtp, loadValidateOtp} from '../../store/actions/loginAction';
import Modal from 'react-modal';
import {loadDeleteData, deleteCurrentSession} from '../../store/actions/deleteAction';
export const Login = (props) => {
  const [, setMessageUser] = useState([]);
  const [disableLogin, setDisableLogin] = useState(false);
  const [otp, setOtp] = useState('');
  const [user, setUser] = useState();
  const [mess] = useState();
  const [enable] = useState(false);
  const [enableResendOTP, setEnableResendOTP] = useState(true);
  const [disableResendOTP, setDisableResendOTP] = useState();
  const [, setEnableSendOTP] = useState(false);
  const [disableSendOTP, setDisableSendOTP] = useState();
  const [, setValid] = useState(null);
  const [validUser, setValidUser] = useState('');
  const [validOTP, setValidOTP] = useState('');
  const [token, setToken] = useState();
  const history = useHistory();
  const [counter, setCounter] = useState(0);
  const [mod, setMod] = React.useState(false);
  const [mod2, setMod2] = React.useState(false);
  const [, setText] = useState('');
  const [userId, setUserId] = useState('');
  const [, setGettoken] = useState('');
  let timer;
  const [visible, setVisible] = useState(true);
  const [isPreviewPage, setisPreviewPage] = useState(false);
  const isUserAlreadyLoggedIn = useSelector((state) => state.loginReducer);
  const deleteSessionData = useSelector((state) => state.deleteReducer);

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '50%',
      left: '47%',
      right: 'auto',
      bottom: 'auto',
      height: 285,
      width: 520,
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden',
    },
  };
  const customStylesSecondModal = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '50%',
      left: '47%',
      right: 'auto',
      bottom: 'auto',
      height: 230,
      width: 520,
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden',
    },
  };

  useEffect(() => {
    if (props?.deleteApiData) {
      setTimeout(() => {
        history.replace({
          pathname: '/creation',
          welcome: user,
          token: token,
        });
    }, 3000);
    }
  }, [props?.deleteApiData]);
  const continueNew=(()=> {
    setText('No');
    setMod(false);
    setMod2(true);
    // props.deleteService({userId, token});
  });
  const newForm=(()=> {
    localStorage.removeItem('formHeader');
    props.deleteService({userId, token});
    setText('No');
    setMod(false);
    // setTimeout(() => {
    //     history.replace({
    //       pathname: '/creation',
    //       welcome: user,
    //       token: token,
    //     });
    // }, 3000);
  });

  const deleteCurrentSession=(()=> {
    const userId = localStorage.getItem('userId');
    setUserId(userId);
    localStorage.removeItem('formHeader');
    props.deleteCurrentSessionData(userId);
    setText('No');
    // setMod(false);
    // setTimeout(() => {
    //     history.replace({
    //       pathname: '/creation',
    //       welcome: user,
    //       token: token,
    //     });
    // }, 3000);
  });

  const reloadPage=(()=> {
    window.location.reload();
  });
const next=(()=> {
    setText('Yes');
    setMod(false);
    if (props.fetchApiData.sections !== null && props.fetchApiData.sections.length>0) {
      props.fetchApiData.sections.map((item, i) => {
        if ((item.section !== null) && (item.subGroups !== null)) {
          if (props.fetchApiData.sections[props.fetchApiData.sections.length - 1].section === '2-Product Information') {
            localStorage.setItem('url1', '2-Product Information')
            ;
          }
          if (props.fetchApiData.sections[props.fetchApiData.sections.length - 1].section === '3-Company Information') {
            localStorage.setItem('url1', '2-Product Information');
            localStorage.setItem('url2', '3-Company Information');
          }
          if (props.fetchApiData.sections[props.fetchApiData.sections.length - 1].section === '4-Dimensions') {
            localStorage.setItem('url1', '2-Product Information');
            localStorage.setItem('url2', '3-Company Information');
            localStorage.setItem('url3', '4-Dimensions');
          }
          if (props.fetchApiData.sections[props.fetchApiData.sections.length - 1].section === '5-Item Details') {
            localStorage.setItem('url1', '2-Product Information');
            localStorage.setItem('url2', '3-Company Information');
            localStorage.setItem('url3', '4-Dimensions');
            localStorage.setItem('url4', '5-Item Details');
          }
          if (props.fetchApiData.sections[props.fetchApiData.sections.length - 1].section === '6-Additional Details') {
            localStorage.setItem('url1', '2-Product Information');
            localStorage.setItem('url2', '3-Company Information');
            localStorage.setItem('url3', '4-Dimensions');
            localStorage.setItem('url4', '5-Item Details');
            localStorage.setItem('url5', '6-Additional Details');
            localStorage.setItem('url6', 'fileupload');
          }
          if (localStorage.getItem('url6' === 'fileupload')) {
            localStorage.setItem('url1', '2-Product Information');
            localStorage.setItem('url2', '3-Company Information');
            localStorage.setItem('url3', '4-Dimensions');
            localStorage.setItem('url4', '5-Item Details');
            localStorage.setItem('url5', '6-Additional Details');
            localStorage.setItem('url6', 'fileupload');
            localStorage.setItem('url7', 'thankyou');
          }
          props.fetchApiData &&
            props.fetchApiData.sections &&
            Array.isArray(props.fetchApiData.sections) &&
            props.fetchApiData.sections.length > 0 ?
            history.replace({
              pathname:
                '/' +
                props.fetchApiData.sections[props.fetchApiData.sections.length - 1].section,
              welcome: user,
              token: token,
            }) :
            history.replace({
              pathname: '/creation',
              welcome: user,
              token: token,
            });
        } else {
          history.replace({
            pathname: '/creation',
            welcome: user,
            token: token,
          });
        }
        setMod(false);
        return null;
      });
    } else if (props.fetchApiData.sections.length===0) {
      history.replace({
        pathname: '/creation',
        welcome: user,
        token: token,
      });
      setMod(false);
    }
  });
  useEffect(() => {
    if (props.loggedIn) {
      if (!props.loggedIn.err && props.loggedIn.data.token !== null) {
        localStorage.setItem('myValueInLocalStorage', JSON.stringify(props.loggedIn));
        const myValueInLocalStorage = localStorage.getItem(
            'myValueInLocalStorage',
        );
        const userId = localStorage.getItem('userId');
        setUserId(userId);
        const token = JSON.parse(myValueInLocalStorage).data.token;
        setToken(token);
        setGettoken(token);
        props.fetchService({userId, token, isPreviewPage});
      } else {
        setValidOTP(Localization.PleaseEnterUserIDOTP);
        history.replace({pathname: '/'});
        setMod(false);
      }
    }
  }, [props.loggedIn]);

  useEffect(() => {
    if (props.fetchApiData) {
      if (!props.fetchApiData?.err && props.fetchApiData?.sections) {
        setMod(true);

        const obj = {
          group: props.fetchApiData.l0CategoryName, category: props.fetchApiData.l1CategoryName,
          subCategory: props.fetchApiData.l2CategoryName,
          productType: props.fetchApiData.productTypeName,
        };
        localStorage.setItem('formHeader', JSON.stringify(obj));
      } else {
        history.replace({pathname: '/creation', welcome: user, token: token});
        setMod(false);
      }
    }
  }, [props.fetchApiData]);
  const validateOTP =(()=> {
    if (user === '' || user === undefined) {
      setValidOTP(Localization.PleaseEnterUserIDOTP);
    }
    if (user !== '' && user !== undefined) {
      props.validateOTPData({user, otp});
      setValidOTP('');
    }
  });

  useEffect(() => {
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      clearTimeout(timer);
      setDisableResendOTP(false);
      setEnableResendOTP(false);
      setDisableSendOTP(false);
      setVisible(false);
    }
  }, [counter]);
  useEffect(() => {
    if (user === '' || otp === '') {
      setDisableResendOTP(true);
      setEnableResendOTP(true);
    }
  }, [user]);
  useEffect(() => {
    if (props.userValid) {
      localStorage.setItem('userId', user);
      if (props.userValid.message === null) {
        setMessageUser(props.userValid.message);
        if (props.userValid?.expirationTime) {
          setCounter((props.userValid?.expirationTime) * 60);
         // clearTimeout(timer);
          clearInterval(timer);
          setDisableResendOTP(true);
          setEnableResendOTP(true);
        }
      } else if (props.userValid.message === Localization.IncorrectUser) {
        setDisableResendOTP(true);
        setEnableResendOTP(true);
        setDisableSendOTP(false);
        setEnableSendOTP(false);
      } else {
        setMessageUser(props.userValid.mobileNumber);
        if (props.userValid?.expirationTime) {
          setCounter((props.userValid?.expirationTime) * 60);
         // clearTimeout(timer);
          clearInterval(timer);
          setDisableResendOTP(true);
          setEnableResendOTP(true);
        }
      }
    }
  }, [props.userValid]);
  const validateUser = () => {
    if (!props.sendOtpData(user)) {
      props.sendOtpData(user);
    } else if (user === '' || user === undefined) {
      setValidUser(Localization.PleaseEnterUserID);
      setDisableResendOTP(!disableResendOTP);
      setEnableResendOTP(!enableResendOTP);
    } else if (user !== '' && user !== undefined) {
     // clearTimeout(timer);
      setValidUser('');
      setDisableSendOTP(true);
      setVisible(true);
    }
  };
  useEffect(() => {
    if (deleteSessionData.deleteCurrentSessionDetails !==null && deleteSessionData.deleteCurrentSessionDetails!==undefined) {
      validateOTP();
    }
  }, [deleteSessionData.deleteCurrentSessionDetails]);


  return (
    <div className="rmv-page-scroll">
      <Layout />
      <div className="title" data-testid="text1">
        {Localization.Start}
        <span className="your-product">{Localization.Your}</span>
      </div>
      <div className="pre-registered">
        {Localization.Pre} <br />
        {Localization.reg}
        <span className="mail">{Localization.email}</span>
      </div>
      <div className="footer row">
        <span className="disclaimer col-8">{Localization.Disclaimer}</span>
        <span className="rightReserved col-4">{Localization.All}</span>

      </div>
      <div className="square"></div>
      <div className="rectanglelogin1">
        <>
          <input
            aria-label='UserId'
            type="text"
            name="UserId"
            id="user"
            key="UserId"
            className="rectanglelogin2"
            value={user || ''}
            autoComplete='off'
            maxLength={100}
            onChange={(e) => {
              const input = e.target.value;
              const specials=/[*|\":<>[\]{}`\\()';@&$%#^!?.,=~-]/;
              if (!specials.test(input) && ((input && input.length <= 100 &&
                !input.includes('+') && !input.includes('_')) || !input )) {
              setUser(e.target.value);
              setDisableLogin(false);
              setValid(e.target.value);
              setOtp('');
              }
            }}
          />
          <div className="user">{Localization.UserIDLogin}</div>
        </>

        <div>
          {disableLogin ? (
            <button className="disablelogin" disabled={enable}>
              {Localization.Send}
            </button>
          ) : (
              <button
                disabled={enable}
                className="rectanglelogin3"
                onClick={() => {
                  setDisableLogin(true);
                  setDisableSendOTP(true);
                  validateUser();
                }}
              >
                {Localization.Send}
              </button>
            )}
          <div className="userid">
            {(user === '' || user === undefined) && validUser}
          </div>
        </div>
        <div className="enter-otp">{Localization.Enter}</div>
        <div className="you-will-get-otp">
          {' '}
          {Localization.You}
          <div className="sent">
            {validUser === '' &&
              props.userValid &&
              (props.userValid.message !== null ? (
                <div className="ot4">{props.userValid && props.userValid.message}</div>
              ) : (
                  [
                    props.loggedIn?.message ? (
                      <div className="err-msg">{ otp.length===0?'':props.loggedIn?.data?.message}</div>
                    ) : (visible && <div key = 'visibleKey'>
                          <div className="ot6">{Localization.Otp}</div>
                          <div className="ot5">
                            {props.userValid &&
                              (props.userValid.message !== null ?
                                '' :
                                props.userValid.emailId)}{' '}
                          </div>
                          <div className="ot7">{Localization.Mobilenum} </div>
                          <div className="ot8">
                            {props.userValid &&
                              (props.userValid.message !== null ?
                                '' :
                                props.userValid.mobileNumber)}{' '}
                          </div>
                        </div>),
                  ]
                ))}
            <div className="ot">{mess}</div>{' '}
          </div>
        </div>
        <div className="rectanglelogin4" style={{width: '85%'}}>
          {' '}
          <OtpInput
            value={otp}
            renderInput={(props) => <input {...props} />}
            onChange={(otp) => setOtp(otp)}
            numInputs={6}
            separator={<span>&nbsp;&nbsp;&nbsp;</span>}
            isDisabled={true ? (user === '' || user === undefined || (props.userValid &&
              props.userValid.message === Localization.IncorrectUser) || visible === false) : false}
            inputStyle={{
              height: '34px',
              width: '40px',
              border: '1px solid #D8D8D8',
            }}
            containerStyle={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '85%',
            }}
          />
        </div>
        <div>
          {/* {props.loggedIn?.data?.message != null && visible === true && (
            <div className="err-msg"> {otp.length===0?'':props.loggedIn?.data?.message} </div>
          )} */}
          {props.errorApiCheck===true ? <div className="err-msg">{Localization.ApiErrorMsg}</div> : props.loggedIn?.data?.message != null && visible === true && (
            <div className="err-msg"> {otp.length===0?'':props.loggedIn?.data?.message} </div>
          )}
        </div>
        <div className="err-msg">
          {(user === '' || user === undefined) && validOTP}
        </div>
        <div>
          {disableSendOTP ?
            <button
              className="rectanglelogin10"
              onClick={() => {
                validateOTP();
              }}
            >
              {' '}
              {Localization.Login}
            </button> : <button className="rectanglelogin11">
              {Localization.Login}
            </button>

          }
        </div>
        <div>
          <div className="didnt">{Localization.didnt}</div>
          <div>
            {disableResendOTP ?
              (<button className="resenddisable" disabled={enableResendOTP}>
                {Localization.Resend}
                {props.userValid &&
                  (props.userValid.message !==
                    Localization.IncorrectUser || validUser !==
                    Localization.PleaseEnterUserID || validOTP !==
                    Localization.PleaseEnterUserIDOTP) ?
                  <div className="timer">
                    {counter !== null ? <span className="in">in</span> : null}
                    {counter > 60 ? <span className="counter">{Math.floor(counter / 60)}:{counter % 60 < 10 ? '0' + counter % 60 : counter % 60}</span> : null}
                    {counter <= 60 ? <span className="counter" >00:{counter}</span> : null}
                  </div> :
                  null}
              </button>
              ) : (
                <button
                  disabled={enableResendOTP}
                  className="resendenable"
                  onClick={() => {
                    validateUser();
                  }}
                >
                  {Localization.Resend}
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="login">{Localization.Loginwith}</div>


      {mod && (
        <Modal
          isOpen={mod}
          contentLabel="SavedData Modal"
          style={customStyles}
          ariaHideApp={false}
        >

          <div className="imageWelcome">
            <img

              src={process.env.PUBLIC_URL + '/assets/Welcome Back!.png'}
              alt="aa"
            />
          </div>
          <div>
            <h1 className="top">
              {Localization.YouWish}
            </h1>
          </div>
          <div className="omega">
            <button
              type="submit"
              className="buttondiv-No1"
              onClick={() => next()}
            >
              {Localization.SavedData}
            </button>

            <button
              type="submit"
              className="buttondiv-Yes1"
              onClick={() => continueNew()}
            >
              {Localization.NewSession}
            </button>
          </div>
        </Modal>
      )}

      {mod2 && (
        <Modal
          isOpen={mod2}
          contentLabel="AreYouSure Modal"
          style={customStylesSecondModal}
        >


          <div>
            <h1 className="top1">
              {Localization.AreYouSure}
            </h1>
          </div>
          <div>
            <h1 className="top2">
              {Localization.DataEntered}
            </h1>
          </div>
          <div className="omega">
            <button
              type="submit"
              className="buttondiv-No1"
              onClick={() => next()}
            >
              {Localization.Cancel}
            </button>

            <button
              type="submit"
              className="buttondiv-Yes1"
              onClick={() => newForm()}
            >
              {Localization.NewSession}
            </button>
          </div>
        </Modal>
      )}
{isUserAlreadyLoggedIn.errorMultipleLogin===true && (
  <Modal
  isOpen={true}
  contentLabel="AreYouSure Modal"
  style={customStylesSecondModal}
>


  <div>
    <h1 className="top1">
      {Localization.SessionAlready}
    </h1>
  </div>
  <div>
    <h1 className="top2Session">
      {Localization.ClickContinue}
    </h1>
  </div>
  <div className="omega">
    <button
      type="submit"
      className="buttondiv-No1"
      onClick={() => reloadPage()}
    >
      {'No'}
    </button>

    <button
      type="submit"
      className="buttondiv-Yes1"
      onClick={() => deleteCurrentSession()}
    >
      {'Yes'}
    </button>
  </div>
</Modal>
)}
    </div>
  );
};
export const mapStateToProps = (state) => {
  return {
    userValid: state.loginReducer.otpMsg,
    loggedIn: state.loginReducer.valotpMsg,
    fetchApiData: state.fetchReducer.fetchApiData,
    deleteApiData: state.deleteReducer.deleteApiData,
    errorApiCheck: state.loginReducer.errorApiCheck,
    userAlreadyLoggedin: state.loginReducer.errorMultipleLogin,
    deleteCurrentSessionDetails: state.deleteReducer.deleteCurrentSession,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    sendOtpData: (user) => dispatch(loadSendOtp(user)),
    validateOTPData: ({user, otp}) =>
      dispatch(loadValidateOtp({user, otp})),
   fetchService: ({userId, token, isPreviewPage}) =>
      dispatch(loadFetchData({userId, token, isPreviewPage})),
    deleteService: ({userId, token}) =>
      dispatch(loadDeleteData({userId, token})),
    deleteCurrentSessionData: (userId) =>
      dispatch(deleteCurrentSession(userId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
