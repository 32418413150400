import {StyleSheet} from '@react-pdf/renderer';
export const styles = StyleSheet.create({
    omega: {
      display: 'flex',
      flexDirection: 'row',
    },
    ome: {
      height: 40,
      width: 50,
    },
    omega1: {
      marginLeft: 10,
    },
    alphabh: {
      fontSize: 14,
    },
    alpha: {
      fontSize: 14,
      marginTop: 8,
    },
    strip: {
      backgroundColor: '#cbf3f3',
      marginTop: 6,
      padding: 5,
    },
    FileStructure: {
      color: '#0F8686',
      fontSize: 14,
      marginLeft: 15,
      justifyContent: 'center',
    },
    image1: {
      height: 16,
      width: 9,
      position: 'absolute',
      right: 50,
      top: 35,
    },
    image0: {
      height: 16,
      width: 9,
      position: 'absolute',
      right: 50,
      top: 15,
    },
    groupfile: {
      marginTop: 3,
      flexDirection: 'row',
      marginLeft: 20,
      fontSize: 12,
      fontWeight: 'bold',
    },
    groupvalue: {
      marginTop: 10,
      flexDirection: 'row',
      fontSize: 12,
      fontWeight: 'Bold',
      marginLeft: 20,
    },
    FileData: {
      marginBottom: 12,
      fontSize: 12,
    },
    keyText: {
      width: 140,
      fontSize: 12,
    },
    valueText: {
      width: 140,
      fontSize: 10,
      color: 'grey',
    },
    dummy4: {
      marginTop: 'auto',
      height: 'auto',
    },
    dummyadd4: {
      marginTop: 10,
      height: 'auto',
      flex: 1,
    },
    header: {
      fontSize: 14,
      marginTop: 10,
      marginLeft: 20,
      color: 'black',
    },
    headeradd: {
      fontSize: 14,
      marginTop: 14,
      marginLeft: 20,
      color: 'black',
    },
    flexDirectionRow: {
      flexDirection: 'row',
      marginVertical: 10,
      flexWrap: 'wrap',
    },
    flexDirectionItem: {
      flexDirection: 'row',
      marginVertical: 10,
      flexWrap: 'wrap',
    },
    innerViewItem: {
      flexDirection: 'column',
      marginLeft: 20,
    },
    textValueItem: {
      width: 120,
      fontSize: 10,
      color: '#676767',
      marginRight: 5,
      marginBottom: 5,
    },
    dummy1: {
      width: 120,
      marginLeft: 20,
      marginTop: 'auto',
      height: 'auto',
    },
    dummyadd1: {
      width: 120,
      marginLeft: 20,
      marginTop: 4,

    },
    dummy2: {
      width: 120,
      fontSize: 12,
      marginVertical: 'auto',
      height: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    dummyadd2: {
      width: 120,
      fontSize: 12,
      marginVertical: 6,
    },
    dummy3: {
      marginVertical: 'auto',
      fontSize: 10,
      color: '#676767',
      height: 'auto',
      marginTop: 5,
      marginBottom: 5,
    },
    dummyadd3: {
      marginVertical: 7,
      fontSize: 10,
      color: '#676767',
    },
    packColor: {
      marginTop: 5,
      marginBottom: 5,
      paddingBottom: 5,
      backgroundColor: '#F4F6F7',
      flexWrap: 'wrap',
      marginVertical: 10,

    },
    packColorItem: {
      marginTop: 5,
      marginBottom: 5,
      paddingBottom: 5,
      backgroundColor: '#F4F6F7',
      marginVertical: 10,
      flexWrap: 'wrap',
    },
    page: {

    },
  });
  export default (styles);
