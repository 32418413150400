import {put, takeLatest, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {saveApi} from '../.././services/saveServices';
export function* saveWatcherAsyn(action) {
  try {
    const response = yield call(saveApi, action.payload.token, action.payload.data);
    if (response.status >= 200 && response.status < 300) {
      const saveApiData = yield response.data;
      yield put({type: actionTypes.SAVE_COMPLETE, payload: saveApiData});
    } else if (response.err.message === 'Request failed with status code 400' && response.err.response.data.message === 'Section is blank') {
      yield put({type: actionTypes.SAVE_BLANK});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.SAVE_ERROR, payload: error});
  }
}
export function* saveWatcher() {
  yield takeLatest(actionTypes.SAVE, saveWatcherAsyn);
}

export const saveSaga=[
  saveWatcher(),
];

