import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function fetchReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH:
      return {...state, loading: true};
    case actionTypes.FETCH_COMPLETE:
      return {...state, loading: false, fetchApiData: action.payload};
    case actionTypes.FETCH_ERROR:
      return {...state, loading: false, fetchApiData: action.payload};
    default:
      return state;
  }
}
