import React, {useEffect, useState, useRef, useContext} from 'react';
import Localization from '../../../common/Localization';
import FilterAttributes from './FilterAttributes';
import 'react-dropdown/style.css';

import Paper from '@mui/material/Paper';
import classes from './Filter.module.css';
import {SearchContext} from '../../../context/SearchContext';
import {CircularProgress, Button, styled} from '@mui/material';

const FilterButton = styled(Button)(({theme}) => ({
  'background': '#f28f7b',
  'borderRadius': '50px',
  '&:hover': {
    background: '#cb7260',
  },
}));

const SilentButton = styled(Button)(({theme}) => ({
  'borderRadius': '50px',
  '&:hover': {

  },
}));
const Filter = () => {
  const context = useContext(SearchContext);
  const {onFilterApply, queryLoading, onFilterRevert} = context;
  return (
    <aside className={classes.filter}>
      <header className={classes.filter__header}>
        {Localization.Filter.toUpperCase()}
      </header>
      <hr />
      <main className={`scrollable ${classes.filter__main}`}>
        <FilterAttributes />
      </main>
      <Paper elevation={0} className={`${classes.filter__footer}`}>
        <SilentButton className={classes['button-revert-group']}>
          <div
            className="creation-continue"
            style={{color: 'black', fontWeight: '600'}}
            onClick={onFilterRevert}
          >
            Revert
          </div>
        </SilentButton>
        {/* <button className={classes['button-continue-group']}>
          <div className="creation-continue" onClick={onFilterApply}>
            Apply
          </div>
        </button> */}
        <FilterButton
          variant="contained"
          className={classes['button-continue-group']}
          disableElevation
          onClick={onFilterApply}
          disabled={queryLoading}
          endIcon={
            queryLoading && (
              <CircularProgress
                color="inherit"
                size={15}
                sx={{color: 'grey'}}
                disableShrink
              />
            )
          }
        >
          Apply
        </FilterButton>
      </Paper>
    </aside>
  );
};

export default React.memo(Filter);
