import {createSlice} from '@reduxjs/toolkit';

const transform = (attribute) => {
return {key: attribute?.attributeId, value: attribute};
};

const filterSlice = createSlice({
  name: 'filters',
  initialState: {
    // Normalize or flatten filter attributes while adding
    filters: {},
    loading: false,
    error: null,
  },
  reducers: {
    // update an existing filter in state
    update: (state, action) => {
      const {key, value} = transform(action.payload);
      const keys = Object.keys(state.filters);
      if (keys.includes(key)) {
        state.filters[key] = value;
      }
    },

    // add a new filter to state but preserves that state of existing filter
    add: (state, action) => {
      const {key, value} = transform(action.payload);
      const keys = Object.keys(state.filters);
      console.log(action);
      if (!keys.includes(key)) {
        state.filters[key] = value;
      }
    },

    forceUpdate: (state, action) => {
      const {key, value} = action.payload;
      state.filters[key] = value;
    },

    // fetch dependent filters
    fetch: (state, action) => {
      state.loading = true;
      state.error = null;
    },

    error: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {update, add, fetch, error, forceUpdate} = filterSlice.actions;

export default filterSlice.reducer;
