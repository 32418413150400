import {HttpService} from './HttpService';
import {PRIMARY_HIERARCHY} from '../config/apiEndpoints';

export async function primaryHierarchyService(query) {
  try {
    const endpoint = PRIMARY_HIERARCHY;

    // const endpoint = 'http://localhost:8080/vendorportal/primaryHierarchy';
    const headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${query}`,
      // 'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJnUWUzY0lsK0dlZzVrR1lIdEtRN05IVU9pOHVrcFlHb0RpM0tCWnE5WGlVTCIsImV4cCI6MTcxNjI4ODY2MSwiaWF0IjoxNzE2Mjg1NjYxfQ.CUXuwWdawNoV1OKBJqhP6FdViFbJ1g6r-JJQQYVJgKgxR4hjxeU56ARdQn_GjIiZdwu-uaxe8d_FKzXLvgPZRw`,
    };
    return await HttpService.get(endpoint, {headers: headers});
  } catch (err) {
    return err;
  }
}
