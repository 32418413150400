import * as actionTypes from '../actions/actionsTypes';

const initialState = {};

export default function savePackReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_PACK:
      return {...state, loading: true, savePackApiData: null, error: null};
    case actionTypes.SAVE_PACK_COMPLETE:
      return {...state, loading: false, savePackApiData: action.payload, error: null};
    case actionTypes.SAVE_PACK_ERROR:
      return {...state, loading: false, savePackApiData: action.payload, error: action.payload};
    default:
      return state;
  }
}
