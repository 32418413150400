import {HttpService} from './HttpService';
import {YOUTUBEURL} from '../config/apiEndpoints';
export async function youTubeUrlApi(token, sendUrlData) {
  try {
    const endpoint = YOUTUBEURL;
    return (await HttpService.post( endpoint, sendUrlData, {headers: {'Authorization': `Bearer ${token}`}}));
  } catch (err) {
    return {err};
  }
}
