import {HttpService} from './HttpService';
import {getAuthToken, getUserId} from '../components/utils/AuthenticationUtils';
import {CLONE} from '../config/apiEndpoints';

export async function clone({articleCode}) {
  const token = getAuthToken();
  try {
    const endpoint = CLONE;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await HttpService.post(endpoint,
      // `http://localhost:8080/vendorportal${CLONE}?userId=${getUserId()}`,
      {articleCode},
      {
        headers: headers,
      },
    );
  } catch (err) {
    return err;
  }
}
