import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
const GuidelineModal = (props) => {
  useEffect(()=>{
    if (!props.show) {
      return null;
    }
  },
  );
  const onClose = (e) => {
    props.onClose && props.onClose(e);
  };


  return (
    <div id="modal">
      <Modal
        show={props.show}
        onHide={onClose}
      >
        <Modal.Header closeButton style={{width: '100%', height: '100%', zoom: '102%'}}>
          <iframe title="Guideline" width="800" height="600" allowFullScreen src = {props.url}></iframe>
        </Modal.Header>
      </Modal>
    </div>
  );
};

GuidelineModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
export default GuidelineModal;
