import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

import store from './store/configureStore';
import {Helmet} from 'react-helmet';
import {ApolloProvider} from '@apollo/client';
import {client} from './services/HttpService';

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
