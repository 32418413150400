import React from 'react';
import classes from './FilterDropdown.module.css';
import Select from 'react-select';

const FilterDropdown = ({attribute, onChange}) => {
  const isDisabled = !attribute.attrNameLov || attribute.attrNameLov.length < 1;
  const CustomStyles = {
    control: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        color: isDisabled ?
          '#ffffff' :
          isSelected ?
          '#ffffff' :
          data && data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  const handleChange = (e) => {
    const options = e ?
      e.map(({value, label}) => {
          return {lovId: value, lovName: label};
        }) :
      '';
    onChange({...attribute, attributeNameValue: options});
  };

  const transform = (data) =>
    data &&
    data.length > 0 &&
    data.map((option) => {
      return {value: option.lovId, label: option.lovName};
    });

  return (
    <div style={{cursor: isDisabled && 'not-allowed'}}>
      <Select
        className={classes['select-box']}
        options={transform(attribute?.attrNameLov)}
        maxMenuHeight={200}
        styles={CustomStyles}
        value={transform(attribute?.attributeNameValue)}
        aria-label={attribute.attributeId}
        defaultValue={transform(attribute?.attributeNameValue)}
        onChange={handleChange}
        isMulti={attribute.multiValued.toUpperCase() === 'Y'}
        isDisabled={!attribute.attrNameLov || attribute.attrNameLov.length < 1}
        getOptionLabel={(option) => `${option.label} ${option.value}`}
      />
    </div>
  );
};

export default FilterDropdown;
