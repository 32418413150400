import {put, takeLatest, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {savePackApi} from '../.././services/savePackService';


export function* savePackWatcherAsyn(action) {
  try {
    const response = yield call(savePackApi, action.payload.token, action.payload.data);
    if (response.status >= 200 && response.status < 300) {
      const savePackApiData = yield response.data;
      yield put({type: actionTypes.SAVE_PACK_COMPLETE, payload: savePackApiData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.SAVE_PACK_ERROR, payload: error});
  }
}
export function* savePackWatcher() {
  yield takeLatest(actionTypes.SAVE_PACK, savePackWatcherAsyn);
}

export const savePackSaga=[
  savePackWatcher(),
];

