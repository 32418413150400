import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  isSuccessBlank: false,
  loading: false,
};
export default function saveReducer(state=initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE:
      return {...state, loading: true};
    case actionTypes.SAVE_COMPLETE:
      return {...state, loading: false, saveApiData: action.payload, isSuccess: true, isSuccessBlank: false};
      case actionTypes.SAVE_BLANK:
      return {...state, loading: false, isSuccess: false, isSuccessBlank: true};
    case actionTypes.SAVE_ERROR:
      return {...state, loading: false, errorMessage: undefined, isSuccess: false, isSuccessBlank: false};
    default:
      return state;
  }
}
