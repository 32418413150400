export const COREATTRIBUTE_SECTION = '/coreattribute?section=<section>';
export const PRIMARY_HIERARCHY = '/primaryhierarchy';
export const SEND_OTP = '/sendotp';
export const VALIDATE_OTP = '/otp-validation';
export const PRODUCT_ADDITION = '/product-addition';
export const PRODUCT_DELETION = '/deletion';
export const CLOUDINARY_ASSET_DELETION = '/resources';
export const PRODUCT_DETAIL = '/productdetail';
export const ARTICLE_DETAIL = '/article/';
export const FILTER = '/filter';
export const CLONE = '/variant';
export const COREATTRIBUTE_SECTION_PRODUCT_SERVICE = '/coreattribute?section=2-Product Information';
export const COREATTRIBUTE_SECTION_DIMENSION_SERVICE = '/coreattribute?section=4-Dimensions';
export const CLASSIFIFCATION = '/classification?subcategory=';
export const ARTICLE = '/articlecode';
export const EMAIL = '/email/attachment';
export const SUBMIT = '/topim';
export const RETRIGGER = '/publish';
export const YOUTUBEURL='/productmultimediasaving';
export const PACK='/pack';
export const PACK_ADDITION='/componentsaving';
export const SIGNATURE='/signature-creation';
export const LOGOUT='/signout';
export const GET_COMPONENT = 'component';
export const DELETE_JWT_TOKEN = 'deletejwtToken';
