import React, {useState, useEffect} from 'react';
import Localization from '../../../common/Localization';
import {useHistory} from 'react-router-dom';
import './PageNotFound.css';

const PageNotFound = () => {
  const [showPage] = useState(true);
  const history = useHistory();
  const redirectHomepage = () => {
    history.push('/PageNotFound');
  };
  useEffect(() => {
    window.addEventListener('popstate', (event) => {
            if (event.state) {
              window.location.href='/login';
            }
           }, false);
  }, [history]);

  return (
    <>

      {showPage ?
<div>
  {/* <img className="bimage" src={process.env.PUBLIC_URL + '/assets/bg 1.jpg'} alt="back-ground"/> */}
  <div className="mainbox">
    <img className="pagenotfound" src={process.env.PUBLIC_URL + '/assets/404.png'} alt="logo" />
    <div className="msg"> {Localization.AccessDenied} </div>

    <div className="may-be-this-page-mov">{Localization.PageMoved} </div>
    <div className="letsgo">{Localization.Letsgo}</div>
    <button className="button-page" onClick={redirectHomepage}>
      <div className="home">{Localization.Home}</div>
    </button>
    <div className="try">{Localization.Try} </div>
  </div>
  <div>
      <div className="all-rights-reserved-page1">{Localization.Disclaimer}</div>
      <div className="all-rights-reserved-page">{Localization.Allrights}</div>
  </div>

</div>: <></>}


    </>

  );
};

export default PageNotFound;

