import React, {useEffect, useState, useRef} from 'react';
import './Additional.css';
import {connect} from 'react-redux';
import {loadClassificationAddAttribute} from '../../store/actions/additionalAction';
import Localization from '../../common/Localization';
import 'bootstrap/dist/css/bootstrap.min.css';
import {loadSave} from '../../store/actions/saveAction';
import {loadFetchData} from '../../store/actions/fetchAction';
import Select from 'react-select';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import Toaster from '../Shared/Toaster';
import AdditionalReturnData from './AdditionalReturnData';
import {preventNonNumericalInput} from '../Shared/nonNumericalInputFunction';
import {saveData, saveDataToast, elementRange, elementRegex, classAttributeAdd, classAttributeDelete, onClickContinueAdd} from './additionalFunction';
import {filterType, speakerType} from './conditionalAdditional';
import {sanitize, isSanitized} from '../utils/utils';
import {PAGE_RENDER_TIMEOUT_IN_MILLISECONDS, XSS_SANITIZE_REGEX} from '../utils/Constants';
export const Additional = (props) => {
  const {register} = useForm();
  const history = useHistory();
  const [, setMultiValuedData2] = useState([]);
  const [, setData2] = useState([]);
  const [prod, setProd] = useState([]);
  const [showData, setShowData] = useState(false);
  const [sendsaveData, setSendSaveData] = useState();
  const [confirmAddition, setConfirmAddition] = useState();
  const code = props?.fetchApiData?.l2Category;
  const [callapi] = useState(true);
  const toasterRef = useRef(null);
  const [varient, setVarient] = useState('');
  const [msg, setMsg] = useState('');
  const [check, setCheck] = useState(false);
  const [contDisable, setContDisable]=useState(false);
  const [checkClickcontinue, setcheckClickcontinue]=useState(false);
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
      .token;
  const userId = localStorage.getItem('userId');
  const [isPreviewPage, setisPreviewPage] = useState(false);


  useEffect(() => {
    if (token&&callapi&&code) {
      const query = token;
      props.classificationAddAttributeApi({query, code});
    }
  }, [token, callapi, code]);
  useEffect(() => {
    if (localStorage.getItem('url5')!=='6-Additional Details') {
      history.push('/5-Item Details');
    }
  }, [history]);
  useEffect(() => {
    if (props ?.errorApiCheck===true) {
      history.push('/ApiErrorPage');
    }
  }, [props ?.errorApiCheck]);
  const attributeLovName = (element) => {
    element.attrNameLov.map((lovItem, lovIndex)=>{
      lovItem['value']=lovItem.lovName;
      lovItem['label']=lovItem.lovName;
      return null;
    });
  };
  useEffect(() => {
    if (props?.fetchApiData && props?.fetchApiData?.sections?.[props?.fetchApiData.sections?.findIndex((p) => p.section === '6-Additional Details')]?.section==='6-Additional Details') {
      const coreAttributesR = props?.fetchApiData.sections[props?.fetchApiData.sections?.findIndex((p) => p.section === '6-Additional Details')]?.subGroups.map(
          (subItem, subIndex) => {
            const subelement = subItem;

            subelement.attributes = subItem.attributes.map(
                (secSubItem, secSubIndex) => {
                  const element = secSubItem;
                  if (element.attrNameLov!==null) {
                    attributeLovName(element);
                  }
                  return element;
                },
            );
            return subelement;
          },
      );
      setProd(coreAttributesR);
    } else {
      const classAttributeR = props?.primaryData?.subGroups.map((subItem, subIndex) => {
        const subelement = subItem;
        subelement.attributes = subItem.attributes.map(
            (secSubItem, secSubIndex) => {
              const element = secSubItem;
              element.isInvalid = false;
              element.isCrossedLength = false;
              element.attributeNameValue='';
              if (element.attrNameLov!==null) {
                attributeLovName(element);
              }
              return element;
            },
        );
        return subelement;
      });

      setProd(classAttributeR);
    }
  }, [props.fetchApiData, props.primaryData]);

  const roundToTwo = (num) => {
    return +(Math.round(num + 'e+2') + 'e-2');
  };
  const CustomStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? '#49A7A2' : isSelected ? '#49A7A2' : isFocused ? '#E0E3E4' : null,
        color: isDisabled ? '#ffffff' : isSelected ? '#ffffff' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };
  const changeData = (selectedItem, value) => {
    const classAttributeRes = prod.map((subItem, subIndex) => {
      const subelement = subItem;
      subelement.attributes = subItem.attributes.map(
          (secSubItem, secSubIndex) => {
            const element = secSubItem;
            if ((element.isCrossedLength === true && (value.length <= Number(element.length))) ||
             ( element.isInvalid === true) || element.isCrossedRange=== true ) {
              element.isCrossedLength = false;
              element.isInvalid = false;
              element.isCrossedRange= false;
            }
            if (element.sequence) {
              if (
                selectedItem.sequence === element.sequence &&
                selectedItem.attributeId === element.attributeId
              ) {
                // to disable select box option item with section
                if (element.attrNameLov != null) {
                  element.attrNameLov.map((lovItem, lovIndex) => {
                    if (element.attributeNameValue === lovItem.lovName) {
                      lovItem.isDisabled = false;
                    }
                    if (value === lovItem.lovName) {
                      lovItem.isDisabled = true;
                    }
                    return lovItem;
                  });
                }
                if (element.range != null) {
                  elementRange({element, value});
                  return element;
                }
                if (element.validRegex != null) {
                  elementRegex({element, value});
                  return element;
                } else if (element.length !== '' && value.length > Number(element.length)) {
                  element.isCrossedLength = true;
                  return element;
                }
                element.attributeNameValue = value;
              }
            } else if (selectedItem.attributeId === element.attributeId) {
              // to disable select box option item without section
              if ((element.isCrossedLength === true && (value.length <= Number(element.length))) ||
              (element.isInvalid === true) || element.isCrossedRange=== true) {
                element.isCrossedLength = false;
                element.isInvalid = false;
                element.isCrossedRange= false;
              }
              if (element.range != null) {
                elementRange({element, value});
              }
              if (element.attributeId === 'SG-Sound-SpeakerBrand' ) {
                speakerType({value, prod});
              }
              if (element.attributeId === 'SG-AirCoolerFunctions-AirPurificationFunction' ) {
                filterType({value, prod});
              }

              if (element.attributeId === 'SG-AudioMicSpecifications-MicrophoneAvailability') {
                if (value === 'No') {
                  subItem.attributes.forEach((a) => {
                    if (a.attributeId !== 'SG-AudioMicSpecifications-MicrophoneAvailability') {
                      a.disable = true;
                      a.attributeNameValue = '';
                      a.mandatory = false;
                      return a;
                    }
                  });
                } else {
                  subItem.attributes.forEach((a) => {
                    if (a.attributeId !== 'SG-AudioMicSpecifications-MicrophoneAvailability') {
                      a.disable = false;
                      a.mandatory = true;
                      return a;
                    }
                  });
                }
              }

              if (element.uom !== null &&
                element.uom === 'UoM-cm' && subItem.attributes[secSubIndex + 1].uom === 'UoM-Inches') {
                if (value!=='') {
                  if (element.isCrossedRange===false) {
                    subItem.attributes[secSubIndex + 1].attributeNameValue =
                  '' + roundToTwo(Number(value) / 2.54);
                  }
                } else {
                  subItem.attributes[secSubIndex + 1].attributeNameValue = ''
                  ;
                }
              }
              if (element.uom !== null &&
                  element.uom === 'UoM-Inches' && subItem.attributes[secSubIndex - 1].uom ==='UoM-cm') {
                if (value!=='') {
                  if (element.isCrossedRange===false) {
                    subItem.attributes[secSubIndex - 1].attributeNameValue =
                  '' + roundToTwo(Number(value) / 0.393701);
                  }
                } else {
                  subItem.attributes[secSubIndex - 1].attributeNameValue = ''
                  ;
                }
              }
              if (element.attrNameLov != null) {
                element.attrNameLov.map((lovItem, lovIndex) => {
                  if (value === lovItem.lovName) {
                    lovItem.isDisabled = true;
                  } else {
                    lovItem.isDisabled = false;
                  }
                  return lovItem;
                });
              }
              if (element.range != null) {
                elementRange({element, value});
                return element;
              }
              if (element.validRegex != null) {
                elementRegex({element, value});
                return element;
              } else if (element.length !== '' && value.length > Number(element.length)) {
                element.isCrossedLength = true;
                return element;
              }
              element.attributeNameValue = value;
            }
            return element;
          },
      );
      return subelement;
    },
    );
    setProd(classAttributeRes);
    setData2(value);
    setMultiValuedData2((multiValuedData2) => [...multiValuedData2, value]);
    setSendSaveData({section: '6-Additional Details', subGroups: classAttributeRes});
  };
  const showToastMsg = (msgs, varients) => {
    setVarient(varients);
    setMsg(msgs);
    toasterRef.current.openToast();
  };
  const negative=(evt)=>{
    preventNonNumericalInput(evt);
  };
  const saving=()=>{
    saveData({props, userId, token, prod, checkClickcontinue});
  };
  const getCheckVal = (checks) => {
    setCheck(checks);
  };
  const savingToast=()=>{
  saveDataToast({props, userId, token, prod, getCheckVal, getConfirmAddition});
  };
  useEffect(() => {
    if (props?.isSuccess!==undefined) {
      if (check && props?.isSuccess) {
        showToastMsg(Localization.SuccessfulSaveToast, 'success');
        setCheck(false);
      }
      if (props?.isSuccess === false && props?.isSuccessBlank === false) {
        showToastMsg(Localization.UnsuccessfulSaveToast, 'error');
        setContDisable(true);
      }
    }
  }, [check, props?.isSuccess]);
  const getShowData = (checks) => {
    setShowData(checks);
  };
  const getConfirmAddition = (checks) => {
    setConfirmAddition(checks);
  };
  const getCheck = (checks) => {
    setCheck(checks);
  };
  const proceed = () => {
    setTimeout(() => {
      history.push('/fileupload');
      localStorage.setItem('url6', 'fileupload');
    }, PAGE_RENDER_TIMEOUT_IN_MILLISECONDS);
  };
  const getClassAttr = (attribute) => {
    setProd(attribute);
  };
  const onClickContinue=()=>{
    onClickContinueAdd({props, userId, token, prod, sendsaveData, getShowData, getConfirmAddition, getCheck, proceed, getClassAttr});
  };
  const getProdVal = (prods) => {
    setProd(prods);
  };
  const addData = (item, index) => {
    classAttributeAdd({item, index, prod, getProdVal});
  };
  const getProdDelVal = (prods) => {
    setProd(prods);
  };
  const deleteData = (index, selectedItem) => {
    classAttributeDelete({index, selectedItem, prod, getProdDelVal});
  };
  const getDisplayBox = (fieldType, item, index) => {
    if (item.fieldType === 'Select') {
      return (
        <div>
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          {item.multiValued === 'Y' ? (
            <div>
              {' '}
              <Select className="select-box" options={item.attrNameLov} maxMenuHeight={200}
                styles={CustomStyles}
                value={{value: item.attributeNameValue, label: item.attributeNameValue}}
                isDisabled={item.disable}
                onBlur={saving}
                onChange={(e) => {
                  changeData(item, e.lovName);
                }}
                arial-label={item.attributeId}
              />
              <div className="addData-additional">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                  alt="plus"
                  onClick={() => addData(item, index)}
                />
              </div>
            </div>
          ) : (
            <div>
              <Select className="select-box" options={item.attrNameLov} maxMenuHeight={200}
                styles={CustomStyles}
                isDisabled={item.disable}
                value={{value: item.attributeNameValue, label: item.attributeNameValue}}
                onBlur={saving}
                onChange={(e) => {
                  changeData(item, e.lovName);
                }}
                arial-label={item.attributeId}
              />
              {item.sequence && item.sequence !== 0 ? (
                <div className="deleteData-additional">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                    alt="delete"
                    onClick={() => deleteData(index, item)}
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          )}

          {item.isCrossedLength && (
            <div className="errormsg-add">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg-add">
              {Localization.Range} {item.range}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg-add">
              {Localization.Character}
            </div>
          )}
          {item.isDuplicate && (<div className="error-text">{Localization.InputMismatch}</div>)}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="errormsg-add">{Localization.Field}</div>}
        </div>
      );
    } else if (fieldType === 'Textarea' || item.fieldType === 'Textarea') {
      return (
        <div className="comlabel1">
          {item.multiValued === 'Y' ? (
            <div>
              {' '}
              <div>
                {item.attributeName}{' '}
                {item.mandatory === 'Y' ? (
                  <span className="asteriskproduct">{Localization.Asterisk}</span>
                ) : (
                  <span></span>
                )}
              </div>{' '}
              <textarea
                aria-label={item.attributeId}
                name={item.attributeId}
                onBlur={saving}
                type={item.attributeDataType === 'String' ? 'text' : 'number'}
                value={item.attributeNameValue}
                // className="arearectangle"
                className="rectangle3"
                ref={register}
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  changeData(item, sanitize(e.target.value));
                }}
              />
              <div className="delete-additional">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                  alt="plus"
                  onClick={() => addData(item, index)}
                />
              </div>
            </div>
          ) : (
            <div>
              {' '}
              <div>
                {item.attributeName}{' '}
                {item.mandatory === 'Y' ? (
                  <span className="asteriskproduct">{Localization.Asterisk}</span>
                ) : (
                  <span></span>
                )}
              </div>{' '}
              <textarea
                aria-label={item.attributeId}
                name={item.attributeId}
                onBlur={saving}
                type={item.attributeDataType === 'String' ? 'text' : 'number'}
                value={item.attributeNameValue}
                // className="arearectangle"
                className="rectangle3"
                ref={register}
                onChange={(e) => {
                  changeData(item, sanitize(e.target.value));
                }}
              />
              {item.sequence && item.sequence !== 0 ? (
                <div className="delete-additional">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                    alt="delete"
                    onClick={() => deleteData(index, item)}
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          )}

          {item.isCrossedLength && (
            <div className="errormsg-add">
              {Localization.MaxLength} {item.length}.
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg-add">
              {Localization.Range} {item.range}
            </div>
          )}
          {item.isDuplicate && (<div className="error-text">{Localization.InputMismatch}</div>)}
          {item.isInvalid && (
            <div className="errormsg-add">
              {Localization.Character}
            </div>
          )}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="errormsg-add">{Localization.Field}</div>}
        </div>
      );
    } else if (item.fieldType === 'Radio') {
      return (
        <div className="comlabel1">
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          {item.attrNameLov.map((radioItem, radioIndex) => {
            return (
              <span key={radioIndex}>
                <input
                  aria-label={item.attributeId}
                  type="radio"
                  onBlur={saving}
                  onKeyPress= {(evt)=>{
                    item.attributeDataType !== 'String' && negative(evt);
                  }}
                  value={radioItem.lovName}
                  checked={item.attributeNameValue === radioItem.lovName}
                  onChange={(e) => {
                    changeData(item, e.target.value);
                  }}
                />
                <span className="radiolabel">{radioItem.lovName}</span>
              </span>
            );
          })}{' '}
          {item.isCrossedLength && (
            <div className="errormsg-add">
              {Localization.MaxLength} {item.length}.
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg-add">{Localization.Range} {item.range}</div>
          )}
          {item.isInvalid && (
            <div className="errormsg-add">
              {Localization.Character}
            </div>
          )}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="errormsg-add">{Localization.Field}</div>}
        </div>
      );
    } else {
      return (
        <div>
          <div>
            {item.attributeName}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">{Localization.Asterisk}</span>
            ) : (
              <span></span>
            )}
          </div>
          {item.multiValued === 'Y' ? (
            <div>
              <input
                name={item.attributeId}
                aria-label={item.attributeId}
                onBlur={saving}
                {...(item.disable && {disabled: item.disable, style: CustomStyles.option({}, {isDisabled: true})})}
                onKeyPress= {(evt)=>{
                  item.attributeDataType !== 'String' && negative();
                }}
                type={item.attributeDataType === 'String' ? 'text' : 'number'}
                value={item.attributeNameValue}
                step={item.attributeDataType === 'number' ? '0.01': ''}
                className="rectangle3"
                onKeyDown={(evt) => {
                  if (item.attributeDataType!== 'String')(evt.key === 'e' || evt.key === 'E' ||evt.key==='+'||evt.key==='-') && evt.preventDefault()&& preventNonNumericalInput(evt);
                }}
                ref={register}
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  const input = e.target.value;
                  const specials=/[*|\":<>[\]{}`\\()';@&$%#^!?.,=~-]/;
                  if (!XSS_SANITIZE_REGEX.test(input) && ((input &&
                    !input.includes('+') && !input.includes('_')) || !input )) {
                      changeData(item, sanitize(e.target.value));
                    }
                }}
              />{' '}
              <div className="addData-additional">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/plus.png`}
                  alt="plus"
                  onClick={() => addData(item, index)}
                />
              </div>
            </div>
          ) : (
            <div>
              <input
                name={item.attributeId}
                aria-label={item.attributeId}
                onBlur={saving}
                {...(item.disable && {disabled: item.disable, style: {backgroundColor: '#f2f2f2', color: '#ffffff', cursor: 'not-allowed'}})}
                onKeyPress= {()=>item.attributeId !=='SG-AudioMicSpecifications-MicrophoneSensitivity'?((evt)=>{
                  item.attributeDataType !== 'String' && negative(evt);
                }):('')}
                type={item.attributeDataType === 'String' ? 'text' : 'number'}
                value={item.attributeNameValue}
                step={item.attributeDataType === 'number' ? '0.01': ''}
                onKeyDown={item.attributeId !=='SG-AudioMicSpecifications-MicrophoneSensitivity'?((evt) => {
                  if (item.attributeDataType!== 'String')(evt.key === 'e' || evt.key === 'E'||evt.key==='+'||evt.key==='-') && evt.preventDefault();
                }):((evt) => {
                  if (item.attributeDataType!== 'String')(evt.key === 'e' || evt.key === 'E'||evt.key==='+') && evt.preventDefault();
                })}
                className={(item.attributeName ?
                    'rectangle3' :
                    'add-rectangle')
                }
                ref={register}
                readOnly={item.readOnly === 'Y' ? true : false}
                onChange={(e) => {
                  const input = e.target.value;
                  const specials=/[*|\":<>[\]{}`\\()';@&$%#^!?.,=~-]/;
                  if (!XSS_SANITIZE_REGEX.test(input) && ((input &&
                    !input.includes('+') && !input.includes('_')) || !input )) {
                      changeData(item, sanitize(e.target.value));
                    }
                }}
              />
              {item.sequence && item.sequence !== 0 ? (
                <div className="deleteData-additional">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/delete_row.png`}
                    alt="delete"
                    onClick={() => deleteData(index, item)}
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          )}

          {item.isCrossedLength && (
            <div className="errormsg-add">
              {Localization.MaxLength} {item.length}.
            </div>
          )}
          {item.isCrossedRange && (
            <div className="errormsg-add">
              {Localization.Range} {item.range}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg-add">
              {Localization.Character}
            </div>
          )}
          {item.isDuplicate && (<div className="error-text">{Localization.InputMismatch}</div>)}
          {item.attributeNameValue === '' &&
            item.mandatory === 'Y' &&
            showData && <div className="errormsg-add">{Localization.Field}</div>}
        </div>
      );
    }
  };
  const checkClickcontinueVal = (checkClickContinue) => {
    setcheckClickcontinue(checkClickContinue);
  };
  return (
    <div className="rectangle">
     <AdditionalReturnData props={props} prod={prod} getDisplayBox={getDisplayBox} showData={showData} setShowData={setShowData} saveDataToast={savingToast} checkClickcontinue={checkClickcontinue} checkClickcontinueVal={checkClickcontinueVal} confirmAddition={confirmAddition} contDisable={contDisable} onClickContinue={onClickContinue}/>
      <div className="all-rights-reserved-ad1">{Localization.Disclaimer}</div>
      <div className="all-rights-reserved-ad">{Localization.Allrights}</div>
      <Toaster ref={toasterRef} varient={varient} msg={msg} />
    </div>
  );
};
export const mapStateToProps = (state) => ({
  primaryData: state.additionalReducer.primaryData,
  saveApiData: state.saveReducer.saveApiData,
  fetchApiData: state.fetchReducer.fetchApiData,
  isSuccess: state.saveReducer.isSuccess,
  isSuccessBlank: state.saveReducer.isSuccessBlank,
  saveLoader: state.saveReducer.loading,
  errorApiCheck: state.additionalReducer.errorApiCheck,
});

export const mapDispatchToProps = (dispatch) => ({
  classificationAddAttributeApi: (query) =>
    dispatch(loadClassificationAddAttribute(query)),
  saveApi: ({userId, token, sendsaveData}) =>
    dispatch(loadSave({userId, token, sendsaveData})),
  fetchService: ({userId, token, isPreviewPage}) =>
    dispatch(loadFetchData({userId, token, isPreviewPage})),
});
export default connect(mapStateToProps, mapDispatchToProps)(Additional);
export {onClickContinueAdd};
