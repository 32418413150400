import React, {useEffect, useState} from 'react';
import './Product.css';
import './pack.css';
import Localization from '../../common/Localization';
import {useForm} from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import Modal from 'react-modal';
import {sanitize} from '../utils/utils';
import {useSelector, useDispatch} from 'react-redux';
import {
  gql,
  useBackgroundQuery,
  useLazyQuery,
  useSuspenseQuery,
} from '@apollo/client';
import {components} from 'react-select/dist/react-select.cjs.prod';
import {fetchComponent} from '../../store/actions/savePackAction';
import {componentDetailsApi} from '../../services/packServices';

const GET_COMPONENTS = gql`
  query getData($l2categoryCode: String, $brandName: String) {
    getPackComponentsByPHCategory(
      l2Category: $l2categoryCode
      brandName: $brandName
    ) {
      lovId
      lovName
    }
  }
`;

export const Pack = (props) => {
  const {register} = useForm();
  const dispatch = useDispatch();
  // const [getComponents, {loading, data}] = useLazyQuery(GET_COMPONENTS);
  const {
    linkedComponents: data,
    linkedComponents,
    linkedComponentsLoading,
  } = props;
  const article = useSelector((state) => state.fetchReducer.fetchApiData);
  const savePackReducer = useSelector((state) => state.savePackReducer);
  const componentReducer = useSelector((state) => state.componentReducer);
  const [country1, setCountry1] = useState([]);
  const [availableComponents, setAvailableComponents] = useState([]);
  const userId = localStorage.getItem('userId');
  const token = JSON.parse(localStorage.getItem('myValueInLocalStorage')).data
    .token;
  const [, setSendPackData] = useState();
  const [packData, setPackData] = useState(null);
  const [checked, setChecked] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalcheck, setModalCheck] = React.useState(false);
  const [primaryNo, setPrimaryNo] = useState();
  const [valueChangeFlag, setValueChangeFlag] = useState(0);
  const [linkedComponent, setLinkedComponent] = useState(null);
  // const codeLinkingItem = {
  //   attrNameLov: [],
  //   attributeDataType: 'enum',
  //   attributeId: 'ComponentCode',
  //   attributeName: 'Component Linking Code',
  //   attributeNameValue: '',
  //   fieldType: 'Select',
  //   length: '',
  //   mandatory: 'N',
  //   minLength: null,
  //   multiValued: 'N',
  //   noDataProvidedValue: null,
  //   range: null,
  //   readOnly: null,
  //   section: null,
  //   sequence: null,
  //   subGroupIdentifier: 'Vendor Product name',
  //   subGroupIdentifierName: 'Product Details',
  //   uom: null,
  //   validRegex: null,
  // };
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '50%',
      left: '47%',
      right: 'auto',
      bottom: 'auto',
      height: 180,
      width: 410,
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden',
    },
  };
  const customStyles1 = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      top: '50%',
      left: '47%',
      right: 'auto',
      bottom: 'auto',
      height: 210,
      width: 410,
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden',
    },
  };

  // useEffect(() => {
  //   if (!savePackReducer.loading &&
  //     savePackReducer.savePackApiData === 'Successfully Added' &&
  //     !savePackReducer.error) {
  //      // Refresh only the concerned component Data
  //     //  props?.fetchService();
  //   }
  // }, [savePackReducer]);

  useEffect(() => {
    if (props?.packData) {
      console.log('[useEffect for setPackData] is called.');
      setPackData(props.packData);
    }
  }, [props.packData]);

  useEffect(() => {
    console.log(country1);
    console.log('[country1] has changed');
    // https://tatacroma.atlassian.net/browse/VP-3065
    if (props.productType === 'pack' && props.packData && props.savePackApi) {
      // props.savePackApi({
      //   userId,
      //   token,
      //   sendPackData: {components: props.packData},
      // });
     // savePackData();
    }
  }, [country1, valueChangeFlag]);

  useEffect(() => {
    console.log(linkedComponents);
    if (data && data.length > 0) {
      setAvailableComponents(data);
    } else {
      setAvailableComponents([]);
    }
  }, [data, linkedComponents]);

  // useEffect(() => {
  //   if (linkedComponent) {
  //     console.log(linkedComponent);
  //   }
  // }, [linkedComponent]);

  const CustomStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isDisabled ?
          '#49A7A2' :
          isSelected ?
          '#49A7A2' :
          isFocused ?
          '#E0E3E4' :
          null,
        color: isDisabled ? '#000000' : isSelected ? '#ffffff ' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  // const fetchComponents = ({l2CategoryCode, brandName}) => {
  //   getComponents({
  //     variables: {
  //       l2CategoryCode,
  //       brandName,
  //     },
  //   });
  // };

  const deletePack = (i) => {
    props.packData.splice(i, 1);
    setPackData(props.packData);
    setSendPackData({components: props.packData});
    setChecked(packData?.findIndex((p) => p.status === 'Primary'));
    // props.savePackApi({
    //   userId,
    //   token,
    //   sendPackData: {components: props.packData},
    // });
    savePackData();
    const coreAttributesR = props?.packData?.map((packItem, index) => {
      return packItem?.sections?.map((item) => {
        if (item.section === props?.linkName) {
          return item.subGroups.map((subItem, subIndex) => {
            const subelement = subItem;
            subelement.attributes = subItem?.attributes?.map(
              (secSubItem, secSubIndex) => {
                const element = secSubItem;
                element.attributeNameValue = secSubItem.attributeNameValue;
                if (element.attrNameLov !== null) {
                  element.attrNameLov.map((lovItem, lovIndex) => {
                    // lovItem['value'] = lovItem.lovName;
                    // lovItem['label'] = lovItem.lovName;
                    return {...lovItem, value: lovItem['lovName'], label: lovItem['lovName']};
                  });
                }
                return element;
              },
            );
            return subelement;
          });
        }
        return null;
      });
    });

    const packAttributesR = coreAttributesR?.map((item, index) => {
      return item[
        packData?.[0]?.sections?.findIndex((p) => p.section === props?.linkName)
      ]?.map((subItem, subIndex) => {
        return subItem;
      });
    });
    setCountry1(packAttributesR);
  };

  function saveData(item) {
    console.log(item);
    console.log('[saveData] is called');
    // props.savePackApi({
    //   userId,
    //   token,
    //   sendPackData: {components: props.packData},
    // });
    savePackData();
  }

  const refreshFetchData = null;

  function savePackData() {
    console.log('[savePackData] is called');
    props.savePackApi({
      userId,
      token,
      sendPackData: {
        components: props.packData.map((component) => {
          if (component != null) {
            for (let i = 0; i < component.sections.length; i++) {
              const section = component.sections[i];
              if (section.section === props?.linkName) {
                for (let j = 0; j < section.subGroups.length; j++) {
                  const subGroup = section.subGroups[j];
                  for (let k = 0; k < subGroup.attributes.length; k++) {
                    const attribute = subGroup.attributes[k];
                    if (
                      attribute.attributeId === 'ComponentCode' &&
                      attribute.attributeNameValue &&
                      attribute.attributeNameValue.length > 0
                    ) {
                      const code = attribute.attributeNameValue.split(' ');
                      if (
                        linkedComponents &&
                        linkedComponents.length > 0 &&
                        linkedComponents
                          .map((lov) => lov.lovName)
                          .includes(attribute.attributeNameValue) &&
                        code.length >= 1 &&
                        !isNaN(parseInt(code[0]))
                      ) {
                        component.code = code[0];
                        break;
                      } else {
                        component.code = null;
                      }
                    }
                  }
                }
                break;
              }
            }
          }
          return component;
        }),
      },
    });

    // if (refreshFetchData) {
    //   clearTimeout(refreshFetchData);
    // }

    // refreshFetchData = setTimeout(() => {
    //   props?.fetchService();
    // }, 5000);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function closeChecked() {
    setModalCheck(false);
  }
  const setStatus = (i) => {
    setChecked(i);
    setModalCheck(false);
    const coreAttributesR = props?.packData?.map((packItem, index) => {
      const packIndex = packItem;
      if (i === index) {
        packIndex.status = 'Primary';
        return packIndex;
      } else {
        packIndex.status = 'Secondary';
        return packIndex;
      }
    });
    // props.savePackApi({
    //   userId,
    //   token,
    //   sendPackData: {components: props.packData},
    // });
    savePackData();
    return coreAttributesR;
  };

  useEffect(() => {
    if (packData) {
      const coreAttributesR = packData?.map((packItem, index) => {
        setChecked(packData?.findIndex((p) => p.status === 'Primary'));

        return packItem?.sections?.map((item) => {
          if (item.section === props?.linkName) {
            return item.subGroups.map((subItem, subIndex) => {
              const subelement = subItem;
              subelement.attributes = subItem?.attributes?.map(
                (secSubItem, secSubIndex) => {
                  const element = secSubItem;
                  if (secSubItem.attributeNameValue === null) {
                    secSubItem.attributeNameValue = '';
                  }
                  element.attributeNameValue = secSubItem.attributeNameValue;
                  if (element.attrNameLov !== null) {
                    element.attrNameLov.map((lovItem, lovIndex) => {
                      // lovItem['value'] = lovItem['lovName'];
                      // lovItem['label'] = lovItem['lovName'];
                      return {...lovItem, value: lovItem['lovName'], label: lovItem['lovName']};
                    });

                    if (element.attributeId === 'ComponentCode') {
                      // packItem['code'] = null;
                      if (linkedComponents && linkedComponents.length > 0) {
                        if (
                          element.attributeNameValue &&
                          element.attributeNameValue.length > 0 &&
                          (!linkedComponents
                            .map((lov) => lov.lovName)
                            .includes(element.attributeNameValue) ||
                            (!linkedComponentsLoading &&
                              linkedComponents &&
                              linkedComponents.length < 1))
                        ) {
                          // packItem['code'] = element.attributeNameValue;
                          element.attributeNameValue = '';
                        }
                      }
                      // else {
                      //   if (
                      //     linkedComponentsLoading ||
                      //     (linkedComponents && linkedComponents.length > 0)
                      //   ) {
                      //     element.attributeNameValue = '';
                      //   }
                      // }
                    }
                  }
                  return element;
                },
              );
              return subelement;
            });
          }
          return null;
        });
      });

      const packAttributesR = coreAttributesR?.map((item, index) => {
        return item[
          packData?.[0]?.sections?.findIndex(
            (p) => p.section === props?.linkName,
          )
        ]?.map((subItem, subIndex) => {
          return subItem;
        });
      });
      setCountry1(packAttributesR);
    }
  }, [linkedComponents]);

  useEffect(() => {
    console.log(
      '[useEffect] is called. Dependencies : [packData, setChecked, props.linkName]',
    );
    if (packData) {
      const coreAttributesR = packData?.map((packItem, index) => {
        setChecked(packData?.findIndex((p) => p.status === 'Primary'));
        return packItem?.sections?.map((item) => {
          if (item.section === props?.linkName) {
            return item.subGroups.map((subItem, subIndex) => {
              const subelement = subItem;
              subelement.attributes = subItem?.attributes?.map(
                (secSubItem, secSubIndex) => {
                  const element = secSubItem;
                  if (secSubItem.attributeNameValue === null) {
                    secSubItem.attributeNameValue = '';
                  }
                  element.attributeNameValue = secSubItem.attributeNameValue;
                  if (element.attrNameLov !== null) {
                    element.attrNameLov.map((lovItem, lovIndex) => {
                      // lovItem['value'] = lovItem.lovName;
                      // lovItem['label'] = lovItem.lovName;
                      return {...lovItem, value: lovItem['lovName'], label: lovItem['lovName']}; ;
                    });

                    if (element.attributeId === 'ComponentCode') {
                      element.attrNameLov = linkedComponents;
                    }
                  }
                  return element;
                },
              );
              return subelement;
            });
          }
          return null;
        });
      });

      const packAttributesR = coreAttributesR?.map((item, index) => {
        return item[
          packData?.[0]?.sections?.findIndex(
            (p) => p.section === props?.linkName,
          )
        ]?.map((subItem, subIndex) => {
          return subItem;
        });
      });
      setCountry1(packAttributesR);
    }
  }, [packData, setChecked, props.linkName, linkedComponents]);

  /** Non-Responsive Function */
  const changeData = (id, indexPos, value, item) => {
    console.log('[changeData] is called');
    const packAttributesR = country1.map((item, index) => {
      return item?.map((subItem, subIndex) => {
        const subelement = subItem;

        subelement.attributes = subItem.attributes.map(
          (secSubItem, secSubIndex) => {
            const element = secSubItem;
            if (id === element.attributeId && indexPos === index) {
              if (element.validRegex != null) {
                const re = new RegExp(element.validRegex);
                if (value === '') {
                  element.attributeNameValue = '';
                } else if (
                  element.length !== '' &&
                  value.length > Number(element.length)
                ) {
                  element.isCrossedLength = true;
                  return element;
                } else if (
                  element.minLength != null &&
                  value.length < Number(element.minLength)
                ) {
                  element.isCrossedminLength = true;

                  if (re.test(value)) {
                    element.isInvalid = false;
                    element.attributeNameValue = value;
                  } else {
                    element.isInvalid = true;
                    element.isCrossedminLength = false;
                  }
                  return element;
                } else if (element.range != null) {
                  const range = element.range.split('-');
                  const min = Number(range[0]);
                  const max = Number(range[1]);
                  if (Number(value) >= min && Number(value) <= max) {
                    element.attributeNameValue = value;
                    element.isCrossedRange = false;
                  } else {
                    element.isCrossedRange = true;
                  }

                  return element;
                } else if (re.test(value)) {
                  element.isInvalid = false;
                  element.isCrossedLength = false;
                  element.isCrossedminLength = false;
                  element.attributeNameValue = value;
                } else {
                  element.isInvalid = true;
                  element.isCrossedLength = false;
                  element.isCrossedminLength = false;
                  return element;
                }
              }
              element.attributeNameValue = value;
            }
            return element;
          },
        );
        return subelement;
      });
    });
    setCountry1(packAttributesR);
    setValueChangeFlag(Math.random() * 100);
  };

  /** Async Responsive & Performant Function */
  //  const changeData_v2 = (id, indexPos, value='', element, rootIndex, parentIndex, posIndex) => {
  //   console.log('[changeData_v2] is called');
  //   {
  //             // const element = secSubItem;
  //             if (id === element.attributeId) {
  //               if (element.validRegex != null) {
  //                 const re = new RegExp(element.validRegex);
  //                 if (value === '') {
  //                   element.attributeNameValue = '';
  //                 } else if (
  //                   element.length !== '' &&
  //                 value.length > Number(element.length)
  //                 ) {
  //                   element.isCrossedLength = true;
  //                   // return element;
  //                 } else if (element.minLength != null &&
  //                   (value.length < Number(element.minLength))) {
  //                   element.isCrossedminLength = true;

  //                   if (re.test(value)) {
  //                     element.isInvalid = false;
  //                     element.attributeNameValue = value;
  //                   } else {
  //                     element.isInvalid = true;
  //                     element.isCrossedminLength = false;
  //                   }
  //                  // return element;
  //                 } else if (element.range != null) {
  //                   const range = element.range.split('-');
  //                   const min = Number(range[0]);
  //                   const max = Number(range[1]);
  //                   if (Number(value) >= min && Number(value) <= max) {
  //                     element.attributeNameValue = value;
  //                     element.isCrossedRange = false;
  //                   } else {
  //                     element.isCrossedRange = true;
  //                   }

  //                  // return element;
  //                 } else if (re.test(value)) {
  //                   element.isInvalid = false;
  //                   element.isCrossedLength = false;
  //                   element.isCrossedminLength=false;
  //                   element.attributeNameValue = value;
  //                 } else {
  //                   element.isInvalid = true;
  //                   element.isCrossedLength = false;
  //                   element.isCrossedminLength=false;
  //                  // return element;
  //                 }
  //               }
  //               element.attributeNameValue = value;
  //             }
  //             console.log(element);
  //             const packAttributesR = JSON.parse(JSON.stringify(country1));
  //             packAttributesR[rootIndex][parentIndex].attributes[posIndex] = element;
  //             console.log(packAttributesR[rootIndex][parentIndex].attributes[posIndex] );
  //             setCountry1((prev) => packAttributesR);
  //            // return element;
  //           };
  //   // setCountry1(packAttributesR);
  // };

  const linkComponent = async (
    {code, l2CategoryCode, brandName},
    item,
    index,
    indexPos,
    rootIndex,
    parentIndex,
    posIndex,
  ) => {
    changeData('vendorProductName', rootIndex, '');
    changeData('vendorArticleCode', rootIndex, '');
    changeData('ean', rootIndex, '');
    changeData('hsn', rootIndex, '');
    const result = await componentDetailsApi({
      code,
      l2CategoryCode,
      brandName,
    }).catch((e) => console.log(e));

    const details = result.data;
    if (details != null) {
      changeData('vendorProductName', rootIndex, details.vendorProductName);
      changeData('vendorArticleCode', rootIndex, details.vendorArticleCode);
      // changeData('ean', rootIndex, details.ean);
      changeData('hsn', rootIndex, details.hsn);
    }
  };

  const getDisplayBox = (
    fieldType,
    item,
    index,
    indexPos,
    rootIndex,
    parentIndex,
    posIndex,
    onChangeText,
    code,
  ) => {
    //  console.log('[gerDisplayBox] is called');
    if (fieldType === 'Select') {
      return (
        <div>
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">*</span>
            ) : (
              <span></span>
            )}
          </div>

          <Select
            className="select-box"
            // options={[{lovName: 'No Code', lovId: ''}, ...item.attrNameLov].map((lov) => ({...lov, value: lov.lovId, label: lov.lovName}))}
            options={
              item.attributeId === 'ComponentCode' ?
                [{lovName: 'NO CODE', lovId: ''}, ...item.attrNameLov].map(
                    (lov) => ({...lov, value: lov.lovId, label: lov.lovName}),
                  ) :
                item.attrNameLov.map((lov) => ({...lov, value: lov.lovId, label: lov.lovName}))
            }
            aria-label={item.attributeId}
            styles={CustomStyles}
            onBlur={() => saveData(item)}
            maxMenuHeight={200}
            isDisabled={
              item.attributeId === 'ComponentCode' &&
                (props.linkedComponentsLoading ||
                  !item.attrNameLov ||
                  item?.attrNameLov.length < 1 ||
                  (item?.attrNameLov.length === 1 &&
                    item?.attrNameLov[0].lovId === '0'))
            }
            value={
              // item.attributeId === 'ComponentCode' &&
              // item.attributeNameValue &&
              // item.attributeNameValue.value ?
              //   {
              //       value: item.attributeNameValue.value,
              //       label: item.attributeNameValue.label,
              //     } :
              {
                value:
                  item?.attrNameLov &&
                  item?.attrNameLov.filter(
                    (lov) => lov.lovName === item.attributeNameValue,
                  ).length > 0 ?
                    item?.attrNameLov.filter(
                        (lov) => lov.lovName === item.attributeNameValue,
                      )[0] :
                    item.attributeNameValue,
                label: item.attributeNameValue,
              }
            }
            readOnly={item.readOnly === 'Y' ? true : false}
            onChange={(e) => {
              console.log('[onChangeText] called for Select');
              onChangeText(e.lovName);

              if (
                item.attributeId === 'ComponentCode' &&
                props?.brandName &&
                article &&
                article?.l2Category &&
                article.l2Category.length > 0
              ) {
                // setLinkedComponent({...item, attributeNameValue: {...e}});
                // item.attributeNameValue = {...e};
                //  setTimeout(props?.fetchService(), 5000);
                dispatch(
                  fetchComponent({
                    code: e.lovId,
                    brandName: props?.brandName,
                    l2CategoryCode: article?.l2Category,
                  }),
                );
                linkComponent(
                  {
                    code: e.lovId,
                    brandName: props?.brandName,
                    l2CategoryCode: article?.l2Category,
                  },
                  item,
                  index,
                  indexPos,
                  rootIndex,
                  parentIndex,
                  posIndex,
                );
              }
              // changeData_v2(item.attributeId, indexPos, e.lovName, item, rootIndex, parentIndex, posIndex);
            }}
          />
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {(item.attributeNameValue === '' ||
            item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showContent && (
              <div className="errormsg">{Localization.Field}</div>
            )}
        </div>
      );
    } else if (fieldType === 'Textarea') {
      return (
        <div className="comlabel1">
          <div>
            {item.attributeName}{' '}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">*</span>
            ) : (
              <span></span>
            )}
          </div>
          <textarea
            aria-label={item.attributeId}
            name={item.attributeId}
            onBlur={() => saveData(item)}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            value={item.attributeNameValue}
            className={`company-readOnly`}
            // ${
            //   code && code.length > 0 && 'disabled'
            // }

            ref={register}
            // disabled={code && code.length > 0}
            readOnly={item.readOnly === 'Y' ? true : false}
            onChange={(e) => {
              onChangeText(sanitize(e.target.value));
              // changeData_v2(item.attributeId, indexPos, e.target.value, item, rootIndex, parentIndex, posIndex);
            }}
          />
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {(item.attributeNameValue === '' ||
            item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showContent && (
              <div className="errormsg">{Localization.Field}</div>
            )}
        </div>
      );
    } else {
      return (
        <div>
          <div>
            {item.attributeName}
            {item.mandatory === 'Y' ? (
              <span className="asteriskproduct">*</span>
            ) : (
              <span></span>
            )}
          </div>
          <input
            aria-label={item.attributeId}
            name={item.attributeId}
            onBlur={() => saveData(item)}
            type={item.attributeDataType === 'String' ? 'text' : 'number'}
            value={item.attributeNameValue}
            className={`product-rectangle `}
            // ${
            //   code && code.length > 0 && item.attributeId !== 'ean' && 'disabled'
            // }
            readOnly={item.readOnly === 'Y' ? true : false}
            onKeyDown={(evt) => {
              if (item.attributeDataType !== 'String') {
                if (
                  evt.key === 'e' ||
                  evt.key === 'E' ||
                  evt.key === '+' ||
                  evt.key === '-'
                ) {
                  evt.preventDefault();
                }
              }
            }}
            ref={register}
         //   disabled={code && code.length > 0 && item.attributeId !== 'ean'}
            onChange={(e) => {
              onChangeText(sanitize(e.target.value));
            }}
          />
          <ReactTooltip id={'Vendor Product name'} place="top" effect="solid">
            {Localization.VendorName}
          </ReactTooltip>
          <ReactTooltip id={'Vendor Article code'} place="top" effect="solid">
            {Localization.VendorCode}
          </ReactTooltip>
          <ReactTooltip
            id={'Vendor MOP (₹ incl. taxes)'}
            place="top"
            effect="solid"
          >
            {Localization.VendorMOP}
          </ReactTooltip>
          {item.attributeName === 'Vendor Product name' ||
          item.attributeName === 'Vendor Article code' ||
          item.attributeName === 'Vendor MOP (₹ incl. taxes)' ? (
            <span>
              <div
                className={
                  item.attributeName === 'Vendor Product name' ?
                    'info-icon-pack' :
                    'info-icon-pack1' &&
                      item.attributeName === 'Vendor Article code' ?
                    'info-icon-pack1' :
                    'info-icon-pack2'
                }
              >
                <img
                  data-tip
                  data-for={
                    item.attributeName === 'Vendor Product name' ?
                      'Vendor Product name' :
                      'Vendor Article code' &&
                        item.attributeName === 'Vendor Article code' ?
                      'Vendor Article code' :
                      'Vendor MOP (₹ incl. taxes)'
                  }
                  src={`${process.env.PUBLIC_URL}/assets/info.png`}
                  alt="info"
                />
              </div>
            </span>
          ) : (
            ''
          )}
          {item.isCrossedLength && (
            <div className="errormsg">
              {Localization.MaxLength} {item.length}
            </div>
          )}
          {item.isCrossedminLength && (
            <div className="errormsg">
              {Localization.MinLength} {item.minLength}
            </div>
          )}
          {item.isInvalid && (
            <div className="errormsg">{Localization.Character}</div>
          )}
          {(item.attributeNameValue === '' ||
            item.attributeNameValue === null) &&
            item.mandatory === 'Y' &&
            props?.showContent && (
              <div className="errormsg">{Localization.Field}</div>
            )}
        </div>
      );
    }
  };

  return (
    <div>
      {country1 &&
        Array.isArray(country1) &&
        country1.length > 0 &&
        country1.map((item, indexes) => {
          return (
            <div className="packColor" key={indexes}>
              <div className="pack-label">
                <input
                  aria-label={item.attributeId}
                  value={indexes}
                  type="checkbox"
                  onChange={() => {
                    setPrimaryNo(indexes);
                    if (checked !== indexes) {
                      setModalCheck(true);
                    }
                  }}
                  checked={checked === indexes}
                />
                <span className="component-type">
                  {props?.packData[indexes]?.status}
                </span>
              </div>

              <span className="remove">
                <img
                  className="remove-img"
                  src={`${process.env.PUBLIC_URL}/assets/close.png`}
                  alt="delete"
                  onClick={() => {
                    props?.packData[indexes].status !== 'Primary' ?
                      deletePack(indexes) :
                      setIsOpen(true);
                  }}
                />
              </span>
              <div className="pack-component">
                {Localization.Component} {indexes + 1}
              </div>
              <div>
                {item?.map((items, index) => {
                  return (
                    <div
                      className="pack-rectangle"
                      key={items.subGroupIdentifierName}
                    >
                      <div className="row item-information1">
                        {items.subGroupIdentifierName}
                      </div>

                      <div className="row company-box1" style={{gap: '0'}}>
                        {/* {items.subGroupIdentifier === 'Vendor Product name' && (
                          <div
                            className="item-box1 col-sm-6 col-lg-3 col-md-6"
                            key={'i100'}
                          >
                            {getDisplayBox(
                              'Select',
                              {
                                ...codeLinkingItem,
                                attrNameLov: availableComponents,
                              },
                              100,
                              101,
                              101,
                              100,
                              101,
                              (value) => {
                                const rootIndex = 101;
                                const parentIndex = 100;
                                const posIndex = 101;
                                changeData(
                                  codeLinkingItem.attributeId,
                                  101,
                                  value,
                                  {
                                    ...codeLinkingItem,
                                    attrNameLov: availableComponents,
                                  },
                                  rootIndex,
                                  parentIndex,
                                  posIndex,
                                );
                              },
                            )}
                          </div>
                        )} */}
                        {items.attributes.map((subItem, subIndex) => {
                          return (
                            <div
                              className="item-box1 col-sm-6 col-lg-3 col-md-6"
                              key={subIndex}
                            >
                              {getDisplayBox(
                                subItem.fieldType,
                                subItem,
                                subIndex,
                                indexes,
                                indexes,
                                index,
                                subIndex,
                                (value) => {
                                  const rootIndex = indexes;
                                  const parentIndex = index;
                                  const posIndex = subIndex;
                                  changeData(
                                    subItem.attributeId,
                                    indexes,
                                    value,
                                    subItem,
                                    rootIndex,
                                    parentIndex,
                                    posIndex,
                                  );
                                },
                                props?.packData[indexes]?.code,
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                <div>
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                  >
                    {' '}
                    <div></div>
                    <span className="warningMessage">
                      {Localization.PackMessage}
                    </span>
                    <div className="removeWarn">
                      <div className="yesBody">
                        <button
                          className="button-Yes"
                          type="Yes"
                          onClick={() => setIsOpen(false)}
                        >
                          <div className="Yes">Ok</div>
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
                <div>
                  {' '}
                  <Modal
                    isOpen={modalcheck}
                    onRequestClose={closeChecked}
                    contentLabel="Example Modal"
                    style={customStyles1}
                  >
                    {' '}
                    <div>
                      <div>
                        <img
                          className="closePack"
                          src={process.env.PUBLIC_URL + '/assets/close.png'}
                          alt="logoutIcon"
                          onClick={() => setModalCheck(false)}
                        />
                      </div>
                    </div>
                    <div className="checkMessage">{Localization.Primary}</div>
                    <div className="checkMessage1">
                      {Localization.PrimaryMessage}
                    </div>
                    <div className="removeWarn1">
                      <div className="yesBody">
                        <button
                          className="button-Yes"
                          type="Yes"
                          onClick={() => setStatus(primaryNo)}
                        >
                          <div className="Yes">Ok</div>
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Pack;
