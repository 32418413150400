import React, {createContext, useState, useRef, useEffect} from 'react';
import Toaster from '../components/Shared/Toaster';

const initialState = {
  variant: null,
  message: null,
  showToastMsg: null,
};

export const ToasterContext = createContext(initialState);

export const ToasterContextProvider = ({children}) => {
  const toasterRef = useRef();
  const [state, setState] = useState({
    variant: null,
    message: null,
  });

  const {variant, message} = state;
  const showToastMsg = (message, variant) => {
    setState({variant, message});
    toasterRef.current.openToast();
  };
  const value = {
    variant,
    message,
    showToastMsg,
  };

  return (
    <ToasterContext.Provider value={{...value}}>
      {children}
      <Toaster ref={toasterRef} varient={variant} msg={message} />
    </ToasterContext.Provider>
  );
};
