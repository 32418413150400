import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {primaryHierarchyService} from '../../services/creationServices';

export function* creationWatcherAsyn(action) {
  try {
    const response = yield call(primaryHierarchyService, action.payload);
    if (response.status >= 200 && response.status < 300) {
      const primaryData = yield response.data;
      yield put({type: actionTypes.PRIMARY_HIERARCHY_COMPLETE, payload: primaryData});
    } else if (!(response.status >= 200 && response.status < 300)) {
      yield put({type: actionTypes.API_ERROR, payload: null});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.PRIMARY_HIERARCHY_ERROR, payload: error});
  }
}
export function* creationWatcher() {
  yield takeEvery(actionTypes.PRIMARY_HIERARCHY, creationWatcherAsyn);
}

export const creationSaga=[
  creationWatcher(),
];

