import {put, takeEvery, call} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionsTypes';
import {submitApi} from '../.././services/submitServices';


export function* submitWatcherAsyn(action) {
  try {
    const response = yield call(submitApi, action.payload.token);
    if (response.status >= 200 && response.status < 300) {
      const submitApiData = yield response.data;
      yield put({type: actionTypes.SUBMIT_COMPLETE, payload: submitApiData});
    } else {
      throw response;
    }
  } catch (error) {
    yield put({type: actionTypes.SUBMIT_ERROR, payload: error});
  }
}
export function* submitWatcher() {
  yield takeEvery(actionTypes.SUBMIT, submitWatcherAsyn);
}

export const submitSaga=[
  submitWatcher(),
];

